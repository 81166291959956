import React, { useMemo } from 'react';
import { CartBasePriceElement, CartPriceElement, CartPriceWrapper } from '../../style';
import { checkPromotionStatus } from '@pdp-rework-hub/utils';
import { IVariation } from '@pdp-rework-hub/utils-types';

interface ICartPriceProps {
  variant: IVariation;
}

const CartProductPrice = ({ variant }: ICartPriceProps) => {
  const isPromo = useMemo(() => checkPromotionStatus(variant), [variant]);
  return (
    <CartPriceWrapper>
      {isPromo && variant.price.list && <CartBasePriceElement>{variant.price.list.formatted}</CartBasePriceElement>}
      <CartPriceElement promo={isPromo}>{variant.price.sales.formatted}</CartPriceElement>
    </CartPriceWrapper>
  );
};

export default CartProductPrice;
