import React, { useCallback } from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { Button } from '@pdp-rework-hub/ui';
import { PDP_FREE_OFFER_LOGIN_REDIRECT, PDP_PLAY_ACTION } from '@pdp-rework-hub/utils-constants';
import { isMobile, sendAdobeEvent } from '@pdp-rework-hub/utils';

type PlayButtonProps = {
  variantId: string;
  registerMobileURL?: string | null;
} & WithTranslation;

const PlayButton = ({ variantId, registerMobileURL, t }: PlayButtonProps) => {
  const handleClick = () => {
    sendFreeToPlayTracking();
    
    if (isMobile() && registerMobileURL) {
      window.open(registerMobileURL, '_blank');
    } else {
      const eventName = isMobile() ? PDP_FREE_OFFER_LOGIN_REDIRECT : PDP_PLAY_ACTION;
      const playNowActionEvent = new CustomEvent<{ productId: string }>(eventName, {
        detail: {
          productId: variantId,
        },
      });
      document.dispatchEvent(playNowActionEvent);
    }
  };

  const sendFreeToPlayTracking = useCallback(() => {
    const trackingEvent = {
      location: 'buy selector',
      locationDetail: 'buy',
      category: 'interaction',
      locationPosition: 'overlay',
      action:'free to play: play now',
    };
    sendAdobeEvent(trackingEvent);
  }, []);

  return (
    <Button buttonType={'subscription'} handleClick={handleClick}>
      {t('global.playNow')}
    </Button>
  );
};

export default withTranslation()(PlayButton);
