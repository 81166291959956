export default {
  translations: {
    title: '{{gameName}}エディションを比較',
    subscription: {
      title: 'Ubisoft<span class="blue">+</span>に含まれています',
      classics: { title: 'Classicsコレクション', subtitle: 'Ubisoftの名作ゲームを楽しもう' },
      premium: { title: '100以上のPCゲーム', subtitle: '新作を含む' },
    },
    subscriptionModal: {
      classics: {
        title: 'Ubisoft<span class="blue">+</span> Classics',
        items: [
          { description: 'Ubisoft Connectでラインナップを拡大していくUbisoftの人気ゲームをプレイ', icon: 'check' },
          { description: 'スタンダードエディションへのアクセス', icon: 'check' },
          { description: 'DLCやゲームが20%割引', icon: 'check' },
        ],
      },
      premium: {
        title: 'Ubisoft<span class="blue">+</span>とは？',
        items: [
          {
            title: '新作と100以上のPCゲーム',
            description: '厳選されたゲームをクラウドで、さらに100以上の豊富なタイトルをPCでプレイ可能',
            icon: 'games',
          },
          {
            title: 'プレミアムエディション',
            description: '拡張パック、シーズンパス、追加コンテンツを利用',
            icon: 'diamond',
          },
          {
            title: '毎月の特典',
            description: '毎月新しいカスタマイズアイテム、ブースター、ゲーム内特典がもらえます',
            icon: 'reward',
          },
          {
            title: 'いつでもキャンセル、復帰が可能',
            description: 'いつでも簡単にサブスクリプションに復帰することが可能',
            icon: 'replay',
          },
        ],
      },
      learnMoreAboutUbisoftPlus: 'Ubisoft+の詳細はこちら',
    },
  },
};
