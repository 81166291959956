import React, { useCallback } from 'react';
import { Button } from '@pdp-rework-hub/ui';
import { RootState, useAppDispatch } from '../../../../../../store';
import { useSelector } from 'react-redux';
import { prepareProductEvent, sendAdobeEvent } from '@pdp-rework-hub/utils';
import { withTranslation, WithTranslation } from 'react-i18next';
import { IMaster } from '@pdp-rework-hub/utils-types';
import { addProductToCart } from '@pdp-rework-hub/slices';

interface ICartProductButtonProps extends WithTranslation {
  isDisabled: boolean;
}

const CartProductButton = ({ t, isDisabled }: ICartProductButtonProps) => {
  const dispatch = useAppDispatch();
  const { selectedMaster, selectedVariant, product, fetching } = useSelector((state: RootState) => ({
    product: state.products.data.product,
    fetching: state.products.fetching,
    selectedVariant: state.products.selectedVariant,
    selectedMaster: state.products.selectedMaster,
  }));

  if (!selectedMaster || !selectedVariant) {
    return null;
  }

  const handleAddToCart = useCallback(async () => {
    const baseTrackingEvent = {
      location: 'buy selector',
      locationDetail: 'buy',
      action: `product : add to cart`,
      category: 'cart',
      locationPosition: 'overlay',
      slotNumber: 5,
    };
    const productTrackingDetails = prepareProductEvent(product, selectedMaster as IMaster, selectedVariant);
    const trackingEvent = {
      ...baseTrackingEvent,
      ...productTrackingDetails,
    };

    sendAdobeEvent(trackingEvent);

    dispatch(addProductToCart(selectedVariant.id));
  }, [product, selectedMaster, selectedVariant]);

  return selectedVariant.price.sales.value > 0 ? (
    <Button buttonType="buy" handleClick={handleAddToCart} disabled={fetching || isDisabled}>
      {t('global.addToCart')}
    </Button>
  ) : null;
};

export default withTranslation()(CartProductButton);
