import styled from 'styled-components';

export const BlockContainer = styled.div`
  position: relative;
  width: 100%;
`;

export const ImageContainer = styled.div`
  &,
  div,
  img {
    width: 100%;
  }
`;
