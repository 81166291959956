import styled from 'styled-components';
import { getBreakpointValue } from '@pdp-rework-hub/utils';

interface IModalContainer {
  textColor: string;
}

export const ModalContainer = styled.div<IModalContainer>`
  .modal-container-mate {
    max-width: 60.25rem;
  }

  .ubisoft-plus-features-container span {
    color: ${({ textColor }) => textColor};
  }

  .blue {
    color: ${({ textColor }) => textColor};
  }

  a {
    color: ${({ theme }) => theme.colors.DodgerBlue};
    text-decoration: none;
  }
`;

interface ILeftImage {
  isClassicsSubscription: boolean;
}


export const LeftImage = styled.img<ILeftImage>`
  display: block;
  margin: auto;
  max-width: 100%;
  max-height: ${({ isClassicsSubscription }) => isClassicsSubscription ? "180px" : "100%"};
  ${({ isClassicsSubscription }) => isClassicsSubscription ? "" : "height: 100%"};
  border-radius: 8px;

  @media screen and (min-width: ${getBreakpointValue('tablet', 'min')}) {
    max-height: inherit;
  }
`;

