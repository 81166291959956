import styled, { css } from 'styled-components';
import breakpoints from '../../../../../themes/utils/breakpoints';
import { ProductOfferTileWrapper, TileSkeletonWrapperInner, TileSkeletonWrapperItem } from '../../../../UI/Tiles/style';

export const ProductOfferWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin: 1rem 0;
  width: calc(100% + 1.5rem);
  margin: 1rem -0.75rem;
  justify-content: center;

  @media screen and (max-width: ${breakpoints.tablet}) {
    width: 100%;
    margin: 0;
  }

  ${TileSkeletonWrapperInner} {
    padding: 1em;

    &:nth-child(1) {
      height: 100%;
      padding: 0.1em;

      ${TileSkeletonWrapperItem} {
        height: 100%;
      }
    }

    &:nth-child(2) {
      height: 0;
      padding: 0;
      display: none;

      ${TileSkeletonWrapperItem} {
        height: 100%;
        margin: 0;
      }
    }
  }

  ${ProductOfferTileWrapper} {
    margin-right: 1em;
    width: 100%;
  }
`;

export const ProductOfferImageWrapper = styled.div`
  max-width: 2.5rem;
  height: 2.5rem;

  @media screen and (min-width: ${breakpoints.tablet}) {
    height: 240px;
    max-width: 100%;

    img {
      height: 100%;
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;
    }
  }

  img {
    width: 100%;
    vertical-align: bottom;
    border-radius: 8px;
  }
`;

export const ProductOfferFeatureContent = styled.div`
  @media screen and (min-width: ${breakpoints.tablet}) {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 24px;
  }

  @media screen and (max-width: ${breakpoints.tablet}) {
    margin: 0;
  }
`;

interface IProductOfferFeatureTitleProps {
  as: string;
}

export const ProductOfferFeatureTile = styled.button<IProductOfferFeatureTitleProps>`
  display: flex;
  padding: 0.75rem;

  width: 100%;
  position: relative;
  transition: all 0.2s ease;
  box-shadow: 0 0 20px rgba(255, 255, 255, 0);
  height: 100%;
  color: ${({ theme }) => theme.colors.White};
  text-align: left;
  border: none;
  background: none;
  align-items: center;

  @media screen and (min-width: ${breakpoints.tablet}) {
    display: block;
    padding: 0;
  }

  h3 {
    font-size: 1rem;
    margin: 0;
    position: relative;
    padding: 0 0.8em 0 0;
  }

  ${({ as }) =>
    as === 'button' &&
    css`
      cursor: pointer;

      h3 {
        svg {
          position: absolute;
          right: -0.5em;
          top: 50%;
          height: 30px;
          margin-top: -15px;
          transition: all 0.2s ease-out;
          opacity: 0.6;

          @media screen and (max-width: ${breakpoints.tablet}) {
            fill: white;
          }
        }
      }
    `}
`;

export const ProductOfferFeatureText = styled.div`
  padding: 0.75rem;

  @media screen and (min-width: ${breakpoints.tablet}) {
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
    padding: 1rem;
    background: rgba(0, 0, 0, 0.8);
  }
`;

export const ProductOfferFeatureContentInner = styled.div`
  @media screen and (min-width: ${breakpoints.tablet}) {
    display: flex;
    flex-direction: column;
    max-width: 240px;
    width: 100%;

    &:hover {
      box-shadow: 0 0 10px rgba(255, 255, 255, 0.3);
      svg {
        transform: translateX(6px);
        opacity: 1;
      }

      ${ProductOfferFeatureText} {
        background: rgba(0, 0, 0, 0.9);
      }
    }
  }
`;

export const ProductOfferFeatureContentItem = styled.div`
  @media screen and (min-width: ${breakpoints.tablet}) {
    align-self: flex-end;
    width: 240px;
  }
`;
