export default {
  translations: {
    selectYour: {
      edition: 'Wähle deine Edition',
      version: 'Wähle deine Version',
      platform: 'Wähle deine Plattform',
      payment: 'Bezahloption wählen',
    },
    selectPlatform: 'Plattform wählen',
    releaseDate: 'Erscheinungsdatum:',
    buySelector: { subscription: { subscriptionSelection: 'Abonnement-Auswahl:' } },
    subscription: {
      title: 'Holen Sie sich {{gameName}} und vieles mehr mit Ubisoft<span class="blue">+</span>',
      alreadySubscribed: 'Du hast bereits ein Ubisoft+ Abonnement!',
      learnMoreAbout: 'Erfahre mehr über <0>Ubisoft+</0>',
      cancelNotice:
        'Du kannst jederzeit kündigen und zurückkehren. Deine Spielstände bleiben erhalten. <0>FAQ ansehen</0>',
      gamesSelection: {
        classics: {
          title: 'Legendäre Ubisoft-Spiele',
          description:
            'Genieße eine Auswahl legendärer Titel, darunter Assassin’s Creed Valhalla, Rainbow Six Siege und viele mehr.',
          link: { notSubscriber: 'Enthaltene Spiele ansehen', subscriber: 'Spiele durchsuchen' },
        },
        premium: {
          title: 'Über 100 Spiele',
          description:
            "Spielen Sie sofort die beliebtesten Titel von Ubisoft+ und einen grossen Spielekatalog mit Spielen wie Assassin's Creed Valhalla und Rainbow Six Siege.",
          link: { notSubscriber: "Alle enthaltenen Spiele ansehen'", subscriber: 'Spiele durchsuchen' },
        },
      },
      cards: {
        cloudGaming: {
          title: 'Cloud-Gaming',
          description:
            'Spiele via {{platforms}} direkt von deinem Smartphone, Tablet oder Fernseher aus – wo immer du willst und du eine gute Internetverbindung hast.',
          link: 'Cloud-Spiele ansehen',
        },
        consoles: {
          title: 'Konsole',
          description: 'Spiele mit unserem Gaming-Abonnement eine Auswahl von Spielen auf deiner {{platforms}}.',
          link: 'Enthaltene Konsolenspiele anzeigen',
        },
      },
      partOfUbisoftPlus: {
        released: 'Spiele {{game}} {{edition}} mit Ubisoft +',
        dlc: 'Spiele den {{dlc}}, der in {{game}} enthalten ist.',
        preOrder: 'Spiele {{game}} {{edition}}, sobald es am {{releaseDate}} veröffentlicht wird.',
        includedContent: 'Inhalt:',
      },
      firstParty: {
        legalLine:
          'Die Listenpreise gelten nur für die PC-Version.<br/>Bitte schau in den Stores der entsprechenden Anbieter nach der Preisgestaltung auf Konsolen.',
        pricingInfo: 'Schau im Store der Plattform nach dem Preis.',
      },
      learnMore: 'Mehr erfahren',
      playThisGame: 'Play this game in your <span class="blue">Library+</span>!',
    },
  },
};
