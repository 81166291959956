import {
  IAdobeEvent,
  IAdobeProductClickEvent,
  IAdobeProductProperties,
  IMaster,
  IProductModel,
  IVariation,
} from '@pdp-rework-hub/utils-types';
import { getProductDisplayPrice } from '../products';

declare global {
  interface Window {
    adobeAnalytics?: {
      track: (eventType: string, event: IAdobeEvent | IAdobeProductClickEvent) => void;
    };
  }
}

const sendAdobeEvent = (event: IAdobeEvent | IAdobeProductClickEvent, eventType = 'clickevent') => {
  if (process.env.NODE_ENV === 'development') {
    console.log(`tracking: ${eventType}`, event);
  } else if (window.adobeAnalytics) {
    window.adobeAnalytics.track(eventType, event);
  }
};

const prepareProductEvent = (product: IProductModel | null, master: IMaster, variant?: IVariation) => {
  const isPreOrder = product?.availability?.isPreOrder;
  const productOwnership = product?.ownership?.productOwnership;
  const isDemo = product?.offer?.isDemo;
  const isFreeWeekend = product?.offer?.isFreeWeekend;

  const masterVariations = master.variations.filter((variant) => variant);

  const available = variant
    ? variant.availability.addToCartPossible
    : masterVariations.some((variant: IVariation | undefined) => variant?.availability.addToCartPossible);

  const productDisplayPrice = variant ? getProductDisplayPrice(variant) : getProductDisplayPrice(master);
  const productDiscountRate = productDisplayPrice.discount;
  const isPromo = !!productDisplayPrice.discount;

  const { id } = variant ? variant : master;
  const platform = variant?.platform;
  const { name, edition } = master;

  const trackingEvent: IAdobeProductProperties = {
    productId: id,
    productName: name,
    productEdition: edition,
    productSalesType: !available ? 'not available' : isPreOrder ? 'preorder' : 'order',
    productDiscount: isPromo ? 'yes' : 'no',
    productDemo: isDemo ? 'yes' : 'no',
    productFreeWeekEnd: isFreeWeekend ? 'yes' : 'no',
    productOwnership: productOwnership ? 'yes' : 'no',
  };

  if (window.subscriptionAgeGateRating) {
    trackingEvent.adultGame = window.subscriptionAgeGateRating.ageGatingEnable ? 'yes' : 'no';
  }

  if (platform) {
    trackingEvent.productPlatform = platform;
  }
  if (isPromo && productDiscountRate) {
    trackingEvent.productDiscountName = 'standard discount';
    trackingEvent.productDiscountRate = parseFloat((productDiscountRate / 100).toFixed(2));
  }

  return trackingEvent;
};

export { prepareProductEvent, sendAdobeEvent };
