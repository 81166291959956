export default {
  translations: {
    global: {
      subscription: {
        subscribeNow: 'JOIN NOW',
        reactivate: 'Re-activate UBISOFT+',
        resubscribe: 'Resubscribe',
        subscribeUbisoftPlus: 'Subscribe to Ubisoft+',
        playNowUbisoftPlus: 'Play now with Ubisoft+',
        goToMyLibrary: 'Go to library',
        changePlan: 'Change Plan',
        upgradeNow: 'Upgrade Now',
        includedWithUbisoftPlus: 'Included with Ubisoft+',
        dayOne: 'Day 1 with Ubisoft+',
        service: 'Subscription service',
      },
      offerEnds: 'Offer ends on {{date}}',
      outOfStock: 'Out of stock',
      from: 'from',
      preOrder: 'PRE-ORDER',
      buyNow: 'BUY NOW',
      getTheGame: 'GET THE GAME',
      addToCart: 'Add to cart',
      playNow: 'Play now',
      seeMore: 'See more',
      month: 'month',
      months: 'months',
      day: 'day',
      days: 'days',
      year: 'year',
      years: 'years',
      buyGame: 'Buy the game',
      free: 'free',
      freeTrial: 'Free trial',
      tryForFree: 'Try for free',
      then: 'then',
      and: 'and',
      durationTerm: 'for',
      containedOffers: 'items included in this edition',
      gameOwned: 'You own this edition',
      seeDetails: 'see details',
      hide: 'hide',
      subscribed: 'Subscribed',
      buyOn: { playstation: 'Buy on Playstation', microsoft: 'Buy on Xbox', nintendo: 'Buy on Nintendo' },
      ageGateRestrictionMessage:
        "We're sorry but you may not access this content due to age gate. Please review our <0>Privacy Policy</0>",
      monthly: 'Monthly',
      yearly: 'Yearly',
      earlyAccess: 'Early Access',
    },
    platforms: {
      PC: 'PC',
      PS4: 'PS4',
      PS5: 'PS5',
      Xbox1: 'Xbox One',
      XboxX: 'Xbox Series X|S',
      Android: 'Android',
      iOS: 'iOS',
      Switch: 'Switch',
      Stadia: 'Stadia',
      Luna: 'Luna',
    },
    versions: { digital: 'Digital', physical: 'Physical' },
    subscription: { promoprice: { month: 'then {{price}} /month', year: 'then {{price}} /year' } },
  },
};
