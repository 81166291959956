export default {
  translations: {
    title: {
      classics:
        'Abonneer je om <span class="blue-variant">{{gameName}}</span> en een steeds wisselend aanbod iconische Ubisoft-games te spelen!',
      premium: 'Abonneer om <span class="blue-variant">{{gameName}}</span> en 100+ PC-games te spelen',
    },
    cancelAnytime: 'Elk moment opzegbaar',
    learnMore: 'Meer info over Ubisoft+',
    classics: 'CLASSICS',
    premium: 'PREMIUM',
  },
};
