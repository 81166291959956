import React, { ComponentType, useEffect, useMemo, useRef } from 'react';
import ModalWrapper from './ModalWrapper';
import { ModalContainer, ModalContainerInner, Overlay, ModalContent, ModalCloseButton } from './styles';
import { computeModalContainerVariants, ModalAnimationType, overlayVariants, toggleBodyOverflow } from './utils';
import CloseIcon from '../Icons/Close';
import { domAnimation, LazyMotion } from 'framer-motion';

interface IModalProps {
  handleClose: () => void;
  isOpen: boolean;
  handleOnOpen?: () => void;
  offsetY?: string;
  animated?: boolean;
  animationType?: ModalAnimationType;
  animationDuration?: number;
  backgroundColor?: string;
  textColor?: string;
  fullscreen?: boolean;
  hideClose?: boolean;
}

const Modal: ComponentType<IModalProps> = ({
  isOpen,
  handleClose,
  handleOnOpen,
  children,
  offsetY = '30%',
  animated = false,
  animationType = '',
  animationDuration = 0.2,
  backgroundColor,
  textColor,
  fullscreen = false,
  hideClose = false,
}) => {
  const modalRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const focusableElements = modalRef.current?.querySelectorAll<HTMLElement>(
      'a[href]:not([disabled]), button:not([disabled]), textarea:not([disabled]), input[type="text"]:not([disabled]), input[type="radio"]:not([disabled]), input[type="checkbox"]:not([disabled]), select:not([disabled]), [tabindex]:not([tabindex="-1"])'
    );
    if (isOpen && focusableElements?.length) {
      const firstFocusableElement = focusableElements[0];
      firstFocusableElement.focus();
    }
    const handleKeydown = (e: KeyboardEvent) => {
      if (e.key === 'Escape') {
        handleClose();
      }
      const currentShadowRoot = document.activeElement?.shadowRoot;
      const activeElement = currentShadowRoot?.activeElement;
      if (focusableElements?.length) {
        const firstFocusableElement = focusableElements[0];
        const lastFocusableElement = focusableElements[focusableElements.length - 1];
        if (e.key === 'Tab' || e.keyCode === 9) {
          if (e.shiftKey) {
            if (activeElement === firstFocusableElement) {
              lastFocusableElement.focus();
              e.preventDefault();
            }
          } else {
            if (activeElement === lastFocusableElement) {
              firstFocusableElement.focus();
              e.preventDefault();
            }
          }
        }
      }
    };

    if (handleOnOpen) {
      handleOnOpen();
    }

    toggleBodyOverflow(isOpen);
    window.addEventListener('keydown', handleKeydown);

    return () => {
      toggleBodyOverflow(false);
      window.removeEventListener('keydown', handleKeydown);
    };
  }, [isOpen, handleOnOpen, handleClose]);

  const containerVariants = useMemo(
    () => computeModalContainerVariants(animationType, animationDuration),
    [animationType, animationDuration]
  );

  return (
    <LazyMotion features={domAnimation}>
      <ModalWrapper condition={animated}>
        <Overlay
          className="overlay"
          initial="initial"
          animate="isOpen"
          exit="exit"
          variants={animated ? overlayVariants : undefined}
          onClick={handleClose}
        >
          <ModalContainer
            className="modal-container-mate"
            role="dialog"
            tabIndex={-1}
            variants={animated ? containerVariants : undefined}
            onClick={(e) => e.stopPropagation()}
            offsetY={offsetY}
            backgroundColor={backgroundColor}
            textColor={textColor}
            ref={modalRef}
            fullscreen={fullscreen}
          >
            <ModalContainerInner>
              {!hideClose ? (
                <ModalCloseButton
                  className="close"
                  onClick={handleClose}
                  tabIndex={0}
                  type="button"
                  aria-label="close"
                  textColor={textColor}
                >
                  <CloseIcon />
                </ModalCloseButton>
              ) : null}
              <ModalContent className="modal-content">{children}</ModalContent>
            </ModalContainerInner>
          </ModalContainer>
        </Overlay>
      </ModalWrapper>
    </LazyMotion>
  );
};

export default Modal;
