import React from 'react';
import { RootState } from '../../../store';
import { connect, ConnectedProps } from 'react-redux';
import { IMaster } from '@pdp-rework-hub/utils-types';

import { OffersListWrapper } from './style';
import OfferItem from '../OfferItem';
import { getMDMCustomerIdFromVariant } from '@pdp-rework-hub/utils';

const mapStateToProps = (state: RootState) => ({
  productOffers: state.products.productOffers,
});

const connector = connect(mapStateToProps);

type IOffersList = ConnectedProps<typeof connector> & {
  product: IMaster;
  textColor: string;
  slotNumber: number;
  locationPosition: string | null;
};

const OffersList = ({ product, productOffers, textColor, slotNumber, locationPosition }: IOffersList) => {
  const mdmCustomerOfferId = getMDMCustomerIdFromVariant([product]);
  const offers = productOffers && Object.keys(productOffers).filter((mdmId) => mdmId === mdmCustomerOfferId[0]);

  if (!offers.length) {
    return null;
  }

  const offer = productOffers[offers[0]];

  if (!offer) {
    return null;
  }

  const offersItems = offer.contains?.map((contain, index) => {
    if (!contain) {
      return null;
    }

    const productInfo = {
      productId: product.id,
      productName: product.name,
      productEdition: product.edition,
    };

    return (
      <OfferItem
        key={`offer-${product.id}-${index}`}
        offer={contain}
        slotNumber={slotNumber}
        textColor={textColor}
        index={index}
        productInfo={productInfo}
        locationPosition={locationPosition}
      />
    );
  });

  return offers.length ? (
    <>
      <OffersListWrapper>{offersItems}</OffersListWrapper>
    </>
  ) : null;
};

export default connector(OffersList);
