import colors from './colors';
import * as componentOptions from './components';
import globalColors from '../colors';

export default () => ({
  colors: {
    ...colors,
    ...globalColors,
  },

  fonts: {
    regular: 'Ubisoft Sans Regular',
    bold: 'Ubisoft Sans Bold',
  },

  breakpoints: {
    mobile: 480,
    tablet: 768,
    tabletLarge: 1025,
    desktop: 1280,
    desktopLarge: 1440,
  },

  ...componentOptions,
});
