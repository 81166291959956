import React, { ComponentType, createContext, Dispatch, ReactNode, SetStateAction, useContext, useState } from 'react';
import { setSelectedMaster, setSelectedOption, setSelectedVariant } from '@pdp-rework-hub/slices';
import { useAppDispatch } from '../store';

type ModalContextProps = {
  modalOpened: boolean;
  setModalOpened: Dispatch<SetStateAction<boolean>>;
  handleCloseModal: () => void;
};

const ModalContext = createContext<ModalContextProps | null>(null);

export const ModalProvider: React.ComponentType<{ children: ReactNode }> = ({ children }) => {
  const [modalOpened, setModalOpened] = useState(false);
  const dispatch = useAppDispatch();
  const handleCloseModal = () => {
    setModalOpened(false);
    dispatch(setSelectedOption(null));
    dispatch(setSelectedMaster(null));
    dispatch(setSelectedVariant(null));
  };

  return (
    <ModalContext.Provider value={{ modalOpened, setModalOpened, handleCloseModal }}>{children}</ModalContext.Provider>
  );
};

export const useModalContext = (): ModalContextProps => {
  const context = useContext(ModalContext);

  if (!context) {
    throw new Error('useModal must be used inside a ModalProvider');
  }

  return context;
};

export const withModalProvider = <P extends object>(Component: ComponentType<P>) => {
  return (props: P) => (
    <ModalProvider>
      <Component {...(props as P)} />
    </ModalProvider>
  );
};
