import React, { useEffect, useRef, useState } from 'react';

import { watchLocationPosition } from '@pdp-rework-hub/utils';
import { BreakpointsBackgroundImages, GlobalStyle, withLazyMotion } from '@pdp-rework-hub/ui';
import Container from '@mooncake/ui/lib/components/Container';

import AppContent from '../AppContent';
import { ICustomAppProps } from '../../AppProps';

const AppContainer = (props: ICustomAppProps) => {
  const [locationPosition, setLocationPosition] = useState<string | null>(null);
  const appRef = useRef<HTMLElement>(null);

  useEffect(() => {
    // For tracking purposes, we have to get the position of the block based on an attribute of the parent of the MFE
    watchLocationPosition(appRef).then((value) => {
      setLocationPosition(value);
    });
  }, []);

  const { background, backgroundcolor, split } = props;
  return (
    <Container ref={appRef} backgroundColor={backgroundcolor}>
      <GlobalStyle />
      {split ? (
        <AppContent {...props} position={locationPosition} />
      ) : (
        <BreakpointsBackgroundImages source={background}>
          <AppContent {...props} position={locationPosition} />
        </BreakpointsBackgroundImages>
      )}
    </Container>
  );
};

export default withLazyMotion(AppContainer);
