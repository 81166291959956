const breakpoints = {
  desktop: '1344px',
  minDesktop: '1345px',
  tablet: '1080px',
  minTablet: '1081px',
  mobile: '760px',
  minMobile: '761px',
};

export default breakpoints;
