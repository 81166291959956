export const listUSSSubscriptions = ['basic', 'premium', 'premiumAnywhere'];
export const cloudGamingSubscription = 'premiumAnywhere';
export const SELECTOR_OPTION_SUBSCRIPTION = 'SELECTOR_OPTION_SUBSCRIPTION';
export const SUBSCRIPTION_PROMOTION_FREE_TRIAL_TYPE = 'freeTrial';
export const SUBSCRIPTION_PROMOTION_DISCOUNT_TYPE = 'discount';

export const SUBSCRIPTION_IDS = {
  BASIC: '5ebe5e920d253c3638a9521b',
  PREMIUM: '5c12947dd9d14601d43eea95',
  PREMIUM_ANYWHERE: '5f44de7b5cdf9a0c2027ca78',
};

export const SUBSCRIPTION_OFFERIDS = {
  CLASSIC: '14755',
  PREMIUM: '15867',
  PREMIUM_ANYWHERE: '15867',
};

export const SUBSCRIPTIONS = {
  BASIC: {
    ID: SUBSCRIPTION_IDS.BASIC,
    TYPE: 'classics',
    OFFERID: SUBSCRIPTION_OFFERIDS.CLASSIC,
  },
  PREMIUM: {
    ID: SUBSCRIPTION_IDS.PREMIUM,
    TYPE: 'premium',
    OFFERID: SUBSCRIPTION_OFFERIDS.PREMIUM,
  },
  PREMIUM_ANYWHERE: {
    ID: SUBSCRIPTION_IDS.PREMIUM_ANYWHERE,
    TYPE: 'premiumAnywhere',
    OFFERID: SUBSCRIPTION_OFFERIDS.PREMIUM_ANYWHERE,
  },
};

export const SUBSCRIPTION_BUTTON_TYPE = {
  SUBSCRIBE: 'SUBSCRIBE',
  FREE_TRIAL: 'FREE_TRIAL',
  SUSPENDED: 'SUSPENDED',
  PLAY: 'PLAY',
  GO_TO_LIBRABRY: 'GO_TO_LIBRABRY',
  CHANGE: 'CHANGE',
} as const;

export const SUBSCRIPTION_PLAN_RECURRENCE = {
  MONTHLY: 'monthly',
  YEARLY: 'yearly',
} as const;
