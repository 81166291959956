import React from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';

import { useBuyCartAction } from './useBuyCartAction';

import CartProductPrice from '../Price/ProductPrice';
import ExternalStoreButton from '../Button/ExternalStoreButton';
import CartProductButton from '../Button/CartProductButton';

import { CartActionWrapper, CartButtonWrapper, ReleaseDate } from '../style';
import PlayButton from '../Button/PlayButton';

const BuyCartAction = ({ t }: WithTranslation) => {
  const cartAction = useBuyCartAction();

  if (!cartAction) {
    return null;
  }

  const {
    firstPartyVersion,
    hasRedirectVersion,
    disabled,
    isPreOrder,
    releaseDateToDisplay,
    selectedMaster,
    selectedVariant,
    isPlayButton,
  } = cartAction;

  return (
    <CartActionWrapper isDisabled={disabled}>
      {isPreOrder && releaseDateToDisplay ? (
        <ReleaseDate>
          <span>{t('releaseDate')}</span> {releaseDateToDisplay}
        </ReleaseDate>
      ) : null}
      {!firstPartyVersion && !isPlayButton && <CartProductPrice variant={selectedVariant} />}
      <CartButtonWrapper>
        {firstPartyVersion && hasRedirectVersion ? (
          <ExternalStoreButton
            isDisabled={disabled}
            externalLink={firstPartyVersion.redirectLink}
            type={selectedVariant.productsVersions?.type}
          />
        ) : isPlayButton ? (
          <PlayButton variantId={selectedVariant.id} registerMobileURL={selectedMaster.freeToPlayMobileRegisterURL} />
        ) : (
          <CartProductButton isDisabled={disabled} />
        )}
      </CartButtonWrapper>
    </CartActionWrapper>
  );
};

export default withTranslation()(BuyCartAction);
