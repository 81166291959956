import React from 'react';
import { useTheme } from 'styled-components';
import {
  appearingAnimationParams,
  TextResetContainer,
  HeadingResetContainer,
  generateImagesList,
} from '@pdp-rework-hub/ui';
import Container from '@mooncake/ui/lib/components/Container';

import TileTitleBrush from '../TileTitleBrush';
import { TileContainer, TileImage } from './styles';

interface ITile {
  title: string;
  description: string;
  image: string;
  alt?: string;
  backgroundColor?: string;
  brushColor?: string;
  index: number;
}

const Tile = ({ backgroundColor, brushColor, description, image, alt, title, index }: ITile) => {
  const theme = useTheme();

  const brushStyles = brushColor
    ? {
        bottom: '100%',
        transform: 'translateY(50%)',
      }
    : null;
  const imagesList = generateImagesList(
    {
      default: image,
    },
    false,
    alt
  );

  return (
    <TileContainer {...appearingAnimationParams(index * 0.1)}>
      <Container
        display={'flex'}
        flexDirection={'column'}
        padding={{
          default: '0.5rem',
          desktop: '0.75rem',
        }}
      >
        <TileImage>{imagesList}</TileImage>
        <Container
          backgroundColor={backgroundColor || theme.colors.text.secondary}
          color={theme.colors.text.primary}
          flex={'1'}
          position={'relative'}
          padding={
            !brushColor
              ? {
                  default: '1.5rem 1.25rem',
                  tabletLarge: '2rem',
                }
              : null
          }
        >
          <Container position={brushColor ? 'absolute' : 'relative'} style={brushStyles}>
            {brushColor ? <TileTitleBrush color={brushColor} /> : null}
            <Container position={'relative'} zIndex={'2'} padding={brushColor ? '0.5rem 1.875rem' : null}>
              <HeadingResetContainer
                as={'h3'}
                primary
                dangerouslySetInnerHTML={{ __html: title }}
                marginBottom={
                  !brushColor
                    ? {
                        default: '1rem',
                      }
                    : '0'
                }
                fontSize={{
                  default: '1.25rem',
                  desktop: '1.875rem',
                }}
                fontFamily={`Custom Font, ${theme.fonts.bold}`}
                lineHeight={'150%'}
              />
            </Container>
          </Container>
          <Container
            padding={
              brushColor
                ? {
                    default: '2.5rem 1.25rem 2rem',
                    tabletLarge: '2.5rem 2rem 2rem',
                  }
                : null
            }
          >
            <TextResetContainer
              as="div"
              dangerouslySetInnerHTML={{ __html: description }}
              marginBottom={'0'}
              fontSize={{
                default: '1rem',
                desktop: '1.125rem',
              }}
              lineHeight={'150%'}
            />
          </Container>
        </Container>
      </Container>
    </TileContainer>
  );
};

export default Tile;
