import React from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';
import { IMaster, IAdobeEvent, ISubscription } from '@pdp-rework-hub/utils-types';
import { prepareProductEvent, sendAdobeEvent } from '@pdp-rework-hub/utils';
import { Button, SubscriptionButton } from '@pdp-rework-hub/ui';
import { ButtonContainer } from './style';
import { useSelector } from 'react-redux';
import { RootState } from '../../../store';
import { PDP_BUYSUB_MODAL_OPEN, SUBSCRIPTION_OFFERIDS } from '@pdp-rework-hub/utils-constants';

interface ICardButtonProps extends WithTranslation {
  product: IMaster;
  index: number;
  position: string | null;
  isSubscription: boolean;
  currentSubscription?: ISubscription | null;
}

const CardButton = ({ currentSubscription, t, product, position, index, isSubscription }: ICardButtonProps) => {
  const {
    isMinor,
    productData,
    isPreOrder,
    isEarlyAccess,
    isSubscriptionButtonRestricted,
    isActiveSubscriber,
    userUpgradeRequired,
  } = useSelector((state: RootState) => ({
    isMinor: state.user.isMinor,
    productData: state.products.data.product,
    isPreOrder: state.products.data.product?.availability?.isPreOrder,
    isEarlyAccess: !!state.products.data.product?.masters.find(
      (targetedProduct) => targetedProduct.id === product.id && targetedProduct?.earlyAccessDuration !== null
    ),
    isSubscriptionButtonRestricted: state.products.isSubscriptionButtonRestricted,
    isActiveSubscriber: state.user.isActiveSubscriber,
    userUpgradeRequired: state.user.upgradeRequired,
  }));
  const baseTrackingEvent: IAdobeEvent = {
    location: 'comparison table',
    category: 'action',
    slotNumber: index + 1,
    action: 'product : get the game',
  };

  let subscriptionActionType = 'subscribe';
  let isModalTrigger = true;
  if (isActiveSubscriber) {
    subscriptionActionType =
      userUpgradeRequired && currentSubscription?.offerId === SUBSCRIPTION_OFFERIDS.PREMIUM_ANYWHERE
        ? 'change plan'
        : isPreOrder
        ? 'go to library'
        : 'play now';

    isModalTrigger =
      userUpgradeRequired && !isPreOrder && currentSubscription?.offerId === SUBSCRIPTION_OFFERIDS.PREMIUM_ANYWHERE;
  }

  const subscriptionEventDetails: IAdobeEvent = {
    location: 'comparison table',
    action: `ubisoft plus : ${subscriptionActionType}`,
    category: 'action',
    slotNumber: index + 1,
  };

  const productTrackingDetails = prepareProductEvent(productData, product);
  const trackingEvent = {
    ...baseTrackingEvent,
    ...productTrackingDetails,
  };

  if (position) {
    trackingEvent.locationPosition = position;
    subscriptionEventDetails.locationPosition = position;
  }

  const openSubModal = () => {
    const openSubModalEvent = new CustomEvent(PDP_BUYSUB_MODAL_OPEN, {
      detail: {
        productId: product.id,
      },
    });
    document.dispatchEvent(openSubModalEvent);
    sendAdobeEvent(trackingEvent);
  };

  return (
    <ButtonContainer>
      {isSubscription && currentSubscription ? (
        <SubscriptionButton
          isPreOrder={isPreOrder}
          trackingEvent={subscriptionEventDetails}
          isButtonRestricted={isSubscriptionButtonRestricted}
          isModalTrigger={isModalTrigger}
        />
      ) : (
        <Button
          buttonType={'buy'}
          classNames={['button', 'add-to-cart']}
          handleClick={() => openSubModal()}
          disabled={isMinor}
        >
          {isPreOrder || isEarlyAccess ? t('global.preOrder') : t('global.getTheGame')}
        </Button>
      )}
    </ButtonContainer>
  );
};

export default withTranslation()(CardButton);
