import { IBreakpoints } from '@pdp-rework-hub/utils-types';

export interface ICustomAppProps {
  backgroundimage?: IBreakpoints;
  gameimage?: string;
}

export const CustomAppDefaultProps = {};

export const CustomAppEventsConfigs = {};
