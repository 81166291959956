import React from 'react';
import { ContentWrapper } from './style';
import { IMaster } from '@pdp-rework-hub/utils-types';

import Text from '@mooncake/ui/lib/components/Text';

interface ISelectedProductContent {
  product: IMaster;
}

const SelectedProductContent = ({ product }: ISelectedProductContent) => {
  return (
    <ContentWrapper>
      <Text.Heading as={'h2'} bold fontSize={'1.25rem'} style={{
        textTransform: "unset"
      }}>
        {product.edition}
      </Text.Heading>
      <Text.Paragraph
        color="unset"
        fontFamily="unset"
        lineHeight="unset"
        textAlign="unset"
        fontSize={'1.125rem'}
        dangerouslySetInnerHTML={{ __html: product.shortDescription }}
      />
    </ContentWrapper>
  );
};

export default SelectedProductContent;
