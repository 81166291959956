const PSIcon = () => (
  <svg width="65" height="50" viewBox="0 0 65 50" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M39.0879 12.2353V27.8824C45.6762 31.0588 50.8527 27.8824 50.8527 19.4706C50.8527 10.8824 47.7938 7.05882 38.8527 4C35.3232 2.82353 28.7938 0.823529 24.3232 0V46.6471L34.9115 50V10.8824C34.9115 9.05882 35.735 7.82353 37.0291 8.23529C38.735 8.70588 39.0879 10.4118 39.0879 12.2353Z"
      fill="white"
    />
    <path
      d="M60.0288 32.7639C55.617 31.1168 49.97 30.5286 45.3817 31.058C40.7935 31.5286 37.4994 32.7051 37.4994 32.7051L36.9111 32.9404V39.2933L48.2053 35.2933C50.1464 34.5874 52.97 34.4698 54.5582 34.9404C56.0876 35.4698 55.7935 36.411 53.9111 37.1168L36.9111 43.1757V49.2933L59.97 40.9992C59.97 40.9992 63.0876 39.8227 64.3229 38.2345C65.617 36.7051 65.0288 34.3521 60.0288 32.7639Z"
      fill="white"
    />
    <path
      d="M1.32297 36.4108C-1.02997 38.1756 -0.147615 41.1756 4.97003 42.5873C10.4406 44.4108 16.0289 44.7638 21.2641 43.8814C21.6171 43.8226 21.97 43.7638 22.323 43.705V38.2344L17.0877 40.1167C15.1465 40.8226 12.3818 40.9991 10.7936 40.4697C9.26415 39.9403 9.55827 38.9991 11.4406 38.2932L22.323 34.4108V28.2344L7.1465 33.5873C7.1465 33.5873 3.44062 34.8226 1.32297 36.4108Z"
      fill="white"
    />
    <path
      d="M61.3234 47.5292C61.6763 47.4115 61.8528 47.1762 61.8528 46.8233C61.8528 46.2351 61.441 46.0586 60.9116 46.0586H59.7939V48.9409H60.3234V47.7056H60.6763C61.0881 47.7056 61.2057 47.8233 61.2645 48.1174C61.3234 48.2939 61.441 48.7056 61.4998 48.8821H62.0292C61.9704 48.588 61.8528 48.2939 61.7351 47.9998C61.6175 47.7056 61.4998 47.588 61.3234 47.5292ZM60.7351 47.3527H60.2645V46.4115H60.7351C61.0292 46.4115 61.2645 46.4704 61.2645 46.8821C61.3234 47.2351 61.0881 47.3527 60.7351 47.3527Z"
      fill="white"
    />
    <path
      d="M60.7345 45C59.2639 45 58.2051 46.1176 58.2051 47.5294C58.2051 48.9412 59.2051 50.0588 60.7345 50.0588C62.2051 50.0588 63.2051 48.9412 63.2051 47.5294C63.2639 46.1176 62.2639 45 60.7345 45ZM60.7345 49.6471C59.4404 49.6471 58.6168 48.7059 58.6168 47.5294C58.6168 46.3529 59.4404 45.4118 60.7345 45.4118C61.9698 45.4118 62.8521 46.3529 62.8521 47.5294C62.8521 48.7059 62.0286 49.6471 60.7345 49.6471Z"
      fill="white"
    />
  </svg>
);

export default PSIcon;
