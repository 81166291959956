import React, { useCallback } from 'react';
import Container from '@mooncake/ui/lib/components/Container';
import Text from '@mooncake/ui/lib/components/Text';
import { useTheme } from 'styled-components';
import { SubscriptionItemContainer, StyledLink } from './styles';
import { RootState } from '../../../../../store';
import { useSelector } from 'react-redux';
import { LunaLogoIcon, XboxLogo } from '@pdp-rework-hub/ui';
import { sendAdobeEvent } from '@pdp-rework-hub/utils';
import { ISubscription } from '@pdp-rework-hub/utils-types';

type SubscriptionItemProps = {
  title: string;
  description: string | React.ReactElement;
  type?: 'hundredPlusGames' | 'cloudGaming' | 'consoles';
  trackingAction: string;
  link?: {
    url: string;
    title: string;
  };
  image?: string;
  edition?: string;
  footerContent?: React.ReactElement | null;
  isWide?: boolean;
  subscription?: ISubscription;
};

const SubscriptionItem = ({
  description,
  edition,
  footerContent,
  image,
  title,
  trackingAction,
  type,
  link,
  isWide = false,
  subscription,
}: SubscriptionItemProps) => {
  const { selectedSubscription } = useSelector((state: RootState) => ({
    selectedSubscription: state.products.selectedSubscription,
  }));

  const subscriptionToUse = subscription || selectedSubscription;
  const theme = useTheme();
  const sendLinkTracking = useCallback(() => {
    const trackingEvent = {
      location: 'buy selector',
      locationDetail: `subscribe : ${subscriptionToUse?.name}`,
      category: 'action',
      locationPosition: 'overlay',
      action: trackingAction,
    };
    sendAdobeEvent(trackingEvent);
  }, [subscriptionToUse, trackingAction]);

  const titleContent = (
    <Text.Heading
      uppercase
      textAlign={isWide ? 'left' : 'center'}
      fontSize={'0.9375rem'}
      margin={isWide ? '0' : '0.625rem'}
      className={'subscription-title'}
    >
      <Text.Span bold fontSize={'1.25rem'}>
        {title}
      </Text.Span>
      {edition && ` | `}
      {edition}
    </Text.Heading>
  );

  return (
    <SubscriptionItemContainer isWide={isWide}>
      {!isWide && titleContent}
      {(image || type) && (
        <Container
          height={isWide ? '200px' : '230px'}
          margin={isWide ? '1rem 0' : '0 0 1rem'}
          display={'flex'}
          alignItems={'center'}
        >
          {image && <img src={image} alt={''} loading={'lazy'} />}
        </Container>
      )}
      <Container marginLeft={isWide ? '1rem' : '0'} marginTop={isWide ? '0' : 'auto'}>
        <Container marginBottom={'1rem'}>
          {isWide && titleContent}
          <Text.Paragraph
            color="unset"
            lineHeight="unset"
            fontFamily="unset"
            textAlign="unset"
            className={'subscription-content'}
            fontSize={{
              default: '0.75rem',
              tablet: '1rem',
            }}
          >
            {description}
          </Text.Paragraph>
          {(type === 'cloudGaming' || type === 'consoles') && subscriptionToUse?.anywhereAvailability && (
            <Container display={'flex'} alignItems={'center'} marginTop={'1rem'}>
              {type === 'cloudGaming' && subscriptionToUse?.anywhereAvailability.luna && (
                <Container marginRight={'1rem'}>
                  <LunaLogoIcon />
                </Container>
              )}
              {type === 'consoles' && subscriptionToUse?.anywhereAvailability?.xbox && (
                <Container marginRight={'1rem'}>
                  <XboxLogo />
                </Container>
              )}
            </Container>
          )}
        </Container>
        {(footerContent || link) && (
          <Container borderTop={'1px solid #484848'}>
            {footerContent ? (
              <Container padding={'1rem 0'} font-size={'0.875rem'} color={theme.colors.Gray}>
                {footerContent}
              </Container>
            ) : link ? (
              <StyledLink href={link.url} onClick={() => sendLinkTracking()} target={'_blank'}>
                {link.title}
              </StyledLink>
            ) : null}
          </Container>
        )}
      </Container>
    </SubscriptionItemContainer>
  );
};

export default SubscriptionItem;
