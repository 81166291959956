export default {
  translations: {
    selectYour: {
      edition: 'エディションを選択',
      version: 'バージョンを選択',
      platform: 'プラットフォームを選択',
      payment: 'お支払い方法を選択',
    },
    selectPlatform: 'プラットフォームを選択',
    releaseDate: '発売日:',
    buySelector: { subscription: { subscriptionSelection: 'サブスクリプションの選択' } },
    subscription: {
      title: '{{gameName}}とその他のUbisoft<span class="blue">+</span>',
      alreadySubscribed: 'あなたはすでにUbisoft+に加入しています！',
      learnMoreAbout: '<0>Ubisoft+</0> の詳細',
      cancelNotice: 'キャンセルしてもいつでも戻れます。保存したものはキープされます。 <0>よくある質問を見る</0>',
      gamesSelection: {
        classics: {
          title: 'Ubisoftの名作ゲーム',
          description:
            '『アサシン クリード ヴァルハラ』や『レインボーシックス シージ』などの厳選された名作タイトルをお楽しみください',
          link: { notSubscriber: 'ゲームのラインナップを見る', subscriber: 'ゲームを探す' },
        },
        premium: {
          title: '100以上のゲーム',
          description:
            'ファークライの全タイトルを今すぐプレイできるほか、アサシン クリード ヴァルハラやレインボーシックス シージなど、随時追加されるラインナップをプレイしよう',
          link: { notSubscriber: '含まれているすべてのゲームを表示', subscriber: 'ゲームを探す' },
        },
      },
      cards: {
        cloudGaming: {
          title: 'クラウドゲーミング',
          description:
            '{{platforms}}を使用して快適なインターネット環境でスマートフォンやタブレット、テレビから好きな場所ですぐにプレイしよう',
          link: 'クラウドゲームを表示',
        },
        consoles: {
          title: 'コンソール',
          description: 'サブスクリプションサービスなら、厳選されたゲームをあなたの{{platforms}}でプレイできます。',
          link: '収録されているコンソールゲームを見る',
        },
      },
      partOfUbisoftPlus: {
        released: 'Ubisoft+で{{game}} {{edition}}をプレイ',
        dlc: '{{game}}に含まれている{{dlc}}をプレイしよう',
        preOrder: '{{releaseDate}}に発売されたらすぐに{{game}}をプレイしよう',
        includedContent: '含まれるコンテンツ：',
      },
      firstParty: {
        legalLine:
          '掲載価格はPC版にのみ適用されます。<br/>家庭用ゲーム機での価格は、各プラットフォームのストアでご確認ください。',
        pricingInfo: 'プラットフォームのストアで価格を見る',
      },
      learnMore: '詳しくはこちら',
      playThisGame: 'Play this game in your <span class="blue">Library+</span>!',
    },
  },
};
