export default {
  translations: {
    selectYour: {
      edition: 'Select your edition',
      version: 'Select your version',
      platform: 'Select your platform',
      payment: 'Select payment option',
    },
    selectPlatform: 'Select platform',
    releaseDate: 'RELEASE DATE:',
    buySelector: { subscription: { subscriptionSelection: 'Subscription selection:' } },
    subscription: {
      title: 'Get {{gameName}} and much more with Ubisoft<span class="blue">+</span>',
      alreadySubscribed: 'You are already subscribed to Ubisoft+ !',
      learnMoreAbout: 'Learn more about <0>Ubisoft+</0>',
      cancelNotice: 'Cancel and come back anytime. <0>View FAQ</0>',
      gamesSelection: {
        classics: {
          title: "Ubisoft's Iconic games",
          description:
            "Enjoy a selection of iconic titles, including Assassin's Creed Valhalla, Rainbow Six Siege, and much more",
          link: { notSubscriber: 'View games included', subscriber: 'Browse games' },
        },
        premium: {
          title: '100+ Games',
          description:
            "Instantly play Ubisoft's most popular titles and a growing game catalog of games, including Assassin's Creed Valhalla and Rainbow Six Siege.",
          link: { notSubscriber: 'View all games included', subscriber: 'Browse games' },
        },
      },
      cards: {
        cloudGaming: {
          title: 'Cloud Gaming',
          description:
            'Play directly from your smartphone, tablet or TV from wherever you want with a good internet connection via {{platforms}}.',
          link: 'View cloud games',
        },
        consoles: {
          title: 'Consoles',
          description: 'Play a selection of games from your {{platforms}} consoles with our gaming subscription.',
          link: 'View included console games',
        },
      },
      partOfUbisoftPlus: {
        released: 'Play {{game}} {{edition}} with Ubisoft+.',
        dlc: 'Play the {{dlc}}, included with {{game}}.',
        preOrder: 'Play {{game}} {{edition}} as soon at it releases on {{releaseDate}} with Ubisoft+.',
        includedContent: 'Included content:',
      },
      firstParty: {
        legalLine:
          'Listed prices apply to PC versions only.<br/>Please check third-party platform stores for applicable pricing on consoles.',
        pricingInfo: 'See platform store for pricing',
      },
      learnMore: 'Learn more',
      playThisGame: 'Play this game in your <span class="blue">Library+</span>!',
    },
  },
};
