import { configureStore, Store } from '@reduxjs/toolkit';
import rootReducer from './reducers';
import { projectName } from '../mooncake.json';
import { CurriedGetDefaultMiddleware } from '@reduxjs/toolkit/dist/getDefaultMiddleware';
import { useDispatch } from 'react-redux';

export type RootState = ReturnType<typeof rootReducer>;

const storeConfig = {
  reducer: rootReducer,
  middleware: (getDefaultMiddleware: CurriedGetDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
  devTools: {
    name: projectName,
  },
};

export const multiInstanceStore = configureStore(storeConfig);

export default {
  storeConfig,
  multiInstanceStore,
};

export type AppStore = Store<RootState>;
export type AppDispatch = typeof multiInstanceStore.dispatch;
export type Dispatch = ReturnType<typeof configureStore>['dispatch'];
export const useAppDispatch: () => AppDispatch = useDispatch;