export default {
  translations: {
    title: {
      classics: '訂閱遊玩<span class="blue-variant">{{gameName}}</span>，以及一系列持續增加的經典 Ubisoft 遊戲！',
      premium: '訂閱服務立即遊玩 <span class="blue-variant">{{gameName}}</span> 和超過 100 款 PC 遊戲',
    },
    cancelAnytime: '可隨時取消',
    learnMore: '深入瞭解 Ubisoft+',
    classics: 'CLASSICS',
    premium: 'PREMIUM',
  },
};
