export default {
  translations: {
    global: {
      subscription: {
        subscribeNow: 'ISCRIVITI ORA',
        reactivate: "RIATTIVA L'ABBONAMENTO",
        resubscribe: 'Iscriviti di nuovo',
        subscribeUbisoftPlus: 'Abbonati a Ubisoft+',
        playNowUbisoftPlus: 'Gioca ora con Ubisoft+',
        goToMyLibrary: 'Vai alla libreria',
        changePlan: 'Cambia piano',
        upgradeNow: 'Upgrade Now',
        includedWithUbisoftPlus: 'Incluso con Ubisoft+',
        dayOne: "Il giorno dell'uscita con Ubisoft+",
        service: 'Servizio in abbonamento',
      },
      offerEnds: "L'offerta termina il {{date}}",
      outOfStock: 'Esaurito',
      from: 'da',
      preOrder: 'PRENOTA',
      buyNow: 'ACQUISTA ORA',
      getTheGame: 'OTTIENI',
      addToCart: 'Aggiungi al carrello',
      playNow: 'Gioca ora',
      seeMore: 'Scopri di più',
      month: 'mese',
      months: 'mesi',
      day: 'giorno',
      days: 'giorni',
      year: 'anno',
      years: 'anni',
      buyGame: 'Acquista il gioco',
      free: 'gratis',
      freeTrial: 'Prova gratuita',
      tryForFree: 'Prova gratis',
      then: 'poi',
      and: 'e',
      durationTerm: 'per',
      containedOffers: 'articoli inclusi in questa edizione',
      gameOwned: 'Possiedi questa edizione',
      seeDetails: 'Scopri i dettagli',
      hide: 'Nascondi',
      subscribed: 'Sottoscritto',
      buyOn: {
        playstation: 'Acquista su PlayStation',
        microsoft: 'Acquista su Xbox',
        nintendo: 'Acquista su Nintendo',
      },
      ageGateRestrictionMessage:
        "Purtroppo non puoi accedere a questo contenuto a causa di restrizioni d'età. Si prega di consultare la nostra <0>Privacy Policy</0>.",
      monthly: 'Mensile',
      yearly: 'Annuale',
      earlyAccess: 'Accesso anticipato',
    },
    platforms: {
      PC: 'PC',
      PS4: 'PS4',
      PS5: 'PS5',
      Xbox1: 'Xbox One',
      XboxX: 'Xbox Series X|S',
      Android: 'Android',
      iOS: 'iOS',
      Switch: 'Switch',
      Stadia: 'Stadia',
      Luna: 'Luna',
    },
    versions: { digital: 'Digitale', physical: 'Fisico' },
    subscription: { promoprice: { month: 'poi {{price}}/mese', year: 'poi {{price}}/anno' } },
  },
};
