import styled, { css } from 'styled-components';
import breakpoints from '../../../../themes/utils/breakpoints';

interface ICartActionWrapper {
  isDisabled: boolean;
}

export const CartActionWrapper = styled.div<ICartActionWrapper>`
  background-color: rgba(242,242,242,0.15);
  text-align: right;
  padding: 1em 2em 1em;

  ${({ isDisabled }) =>
    isDisabled &&
    css`
      opacity: 0.6;
    `}
`;

export const CartPriceWrapper = styled.div`
  width: 100%;
  text-align: right;
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
`;

interface CartPriceElementProps {
  promo?: boolean;
}

export const CartPriceElement = styled.div<CartPriceElementProps>`
  font-size: 1.6em;
  color: ${({ theme, promo }) => (promo ? theme.BuySelector.Colors.promo : 'white')};
`;

export const CartBasePriceElement = styled.div`
  color: ${({ theme }) => theme.BuySelector.Colors.textGrey};
  text-decoration: line-through;
  margin-right: 0.2em;
  font-size: 1.2em;
`;

export const CartButtonWrapper = styled.div`
  width: 100%;
  text-align: right;
  margin: 1rem 0 1rem;
`;

export const CartSubscriptionPriceWrapper = styled.div`
  width: 100%;
  text-align: right;
`;

export const SubRegularPriceWrapper = styled.div`
  span.price {
    font-size: 1.8em;
  }
`;

export const SubPromoPriceWrapper = styled.div``;

export const SubPromoPriceDescription = styled.div`
  margin-bottom: 0.5em;
  span.promotion-price {
    font-size: 1.8em;
    margin-right: 0.2em;
  }

  span.duration {
    font-size: 1.4em;
  }
`;

export const SubPromoPriceDescriptionInner = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: baseline;
`;

export const SubBasePriceDescription = styled.div`
  font-size: 1em;
`;

export const ReleaseDate = styled.div`
  font-size: 0.875rem;
  margin-bottom: 1.25rem;

  @media screen and (min-width: ${breakpoints.minTablet}) {
    font-size: 1.125rem;
  }
  span {
    font-family: ${({ theme }) => theme.fonts.bold};
  }
`;
