import { reset } from '@pdp-rework-hub/ui';
import styled from 'styled-components';

export const CancelNoticeWrapper = styled.div`
  ${reset};
  display: flex;
  align-items: center;
  background-color: rgba(255,255,255,0.22);
  padding: 1rem 2rem;


  a {
    color: ${({ theme }) => theme.colors.White};
  }
`;
