import { FreeOffer, FreeOfferStatus, IMaster } from '@pdp-rework-hub/utils-types';
import pick from 'lodash/pick';

export const getMasterFreeOfferType = (selectedMaster: IMaster): FreeOffer | null => {
  const offerPickedProperties: Array<keyof FreeOfferStatus> = [
    'isDemo',
    'isFreePlayGame',
    'isFreeToPlay',
    'isFreeWeekend',
    'isFreeWithPurchase',
    'isGiveaway',
    'isOpenBeta',
  ];
  const freeOfferStatus = pick<FreeOfferStatus>(selectedMaster.offer, offerPickedProperties);
  const freeOffer = Object.keys(freeOfferStatus).find((offerKey) =>
    Boolean(freeOfferStatus[offerKey as keyof typeof freeOfferStatus])
  );

  if (!freeOffer) {
    return null;
  }

  return freeOffer.replace(/^is/, '').toUpperCase() as FreeOffer;
};

export const getIsPlayProductAction = (selectedMaster: IMaster) => {
  const offerType = getMasterFreeOfferType(selectedMaster);

  return offerType === 'FREEPLAYGAME' || offerType === 'FREETOPLAY' || offerType === 'GIVEAWAY';
};
