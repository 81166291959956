import React from 'react';

const XboxIcon = () => {
  return (
    <svg width="56" height="18" viewBox="0 0 56 18" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M56 14.261L52.1381 9.03232L55.6667 4.28566H54.3414L51.4715 8.15815L48.6015 4.28566H47.2844L50.8048 9.04049L46.9511 14.2692H48.2763L51.4715 9.93916L54.6748 14.2692H56"
        fill="white"
      />
      <path
        d="M29.6906 14.261L25.8369 9.03232L29.3573 4.28566H28.0402L25.1703 8.15815L22.3003 4.28566H20.9751L24.5036 9.04049L20.6417 14.2692H21.9669L25.1703 9.93916L28.3654 14.2692H29.6906"
        fill="white"
      />
      <path
        d="M34.3167 13.2153C34.3167 13.2153 31.8289 13.2153 31.3655 13.2153V9.58786C31.8208 9.58786 34.3167 9.58786 34.3167 9.58786C35.4387 9.58786 36.7721 9.89015 36.7721 11.3199C36.7721 12.9702 35.2273 13.2153 34.3167 13.2153ZM31.3655 5.32323C31.8208 5.32323 34.3167 5.32323 34.3167 5.32323C35.1135 5.32323 36.455 5.5193 36.455 6.90817C36.455 7.9539 35.6745 8.58298 34.3167 8.58298C34.3167 8.58298 31.8289 8.58298 31.3655 8.58298V5.32323ZM37.0241 9.40813C36.8127 9.22839 36.5932 9.08951 36.3655 8.99147C37.0891 8.59932 37.5688 7.85587 37.5688 6.99804C37.5688 6.36079 37.333 4.28566 34.3086 4.28566H30.2354V8.59115C30.2354 8.59115 28.9183 8.59115 28.8776 8.59115C28.6988 8.8199 28.3004 9.36728 28.1459 9.58786C28.2922 9.58786 30.2354 9.58786 30.2354 9.58786V14.261H34.3086C36.52 14.261 37.894 13.1417 37.894 11.3444C37.9022 10.5601 37.6013 9.89015 37.0241 9.40813Z"
        fill="white"
      />
      <path
        d="M43.1949 13.4277C41.0404 13.4277 39.5363 11.7202 39.5363 9.26924C39.5363 6.8183 41.0404 5.11081 43.1949 5.11081C45.3413 5.11081 46.8373 6.8183 46.8373 9.26924C46.8373 11.712 45.3413 13.4277 43.1949 13.4277ZM43.1949 4.05691C40.3656 4.05691 38.3981 6.1974 38.3981 9.26924C38.3981 12.3411 40.3656 14.4816 43.1949 14.4816C46.008 14.4816 47.9755 12.3411 47.9755 9.26924C47.9755 6.1974 46.008 4.05691 43.1949 4.05691Z"
        fill="white"
      />
      <path
        d="M7.60289 17.4647C6.30292 17.3401 4.98604 16.8701 3.85326 16.1263C2.90458 15.5034 2.69043 15.2485 2.69043 14.7369C2.69043 13.7119 3.81193 11.9148 5.72808 9.86852C6.81765 8.70568 8.33366 7.34275 8.49709 7.37862C8.81645 7.45035 11.3657 9.95347 12.32 11.1295C13.8304 12.9946 14.5254 14.5199 14.1723 15.1994C13.9036 15.7167 12.2411 16.7266 11.02 17.1136C10.0131 17.4345 8.69058 17.5704 7.60289 17.4647ZM1.41112 13.6723C0.623999 12.4566 0.225742 11.2579 0.0322494 9.52684C-0.0316221 8.95486 -0.00907918 8.62829 0.176899 7.45413C0.407964 5.99115 1.23453 4.29975 2.23018 3.25773C2.65473 2.81412 2.69231 2.80279 3.20891 2.97835C3.83636 3.19166 4.50513 3.65604 5.54398 4.60178L6.15076 5.153L5.82013 5.56263C4.28158 7.46168 2.66037 10.1536 2.04983 11.8185C1.71733 12.7227 1.58395 13.6326 1.72672 14.0102C1.82253 14.265 1.73424 14.1706 1.41112 13.6723ZM15.2374 13.878C15.3144 13.4967 15.2167 12.7945 14.9857 12.0866C14.4879 10.5538 12.8178 7.69953 11.2849 5.76084L10.8021 5.15111L11.3243 4.66785C12.0063 4.03736 12.4797 3.65981 12.9906 3.34079C13.3945 3.08783 13.9694 2.86508 14.2173 2.86508C14.3695 2.86508 14.9068 3.42574 15.3407 4.03924C16.0133 4.98688 16.5073 6.13839 16.7572 7.3352C16.9187 8.10917 16.9319 9.76469 16.7835 10.5349C16.6614 11.1673 16.4021 11.9884 16.1504 12.5472C15.9607 12.9644 15.491 13.7742 15.2844 14.0385C15.1792 14.1706 15.1792 14.1706 15.2374 13.878ZM7.74191 2.57437C7.03368 2.21193 5.94223 1.82495 5.33921 1.71924C5.12694 1.68337 4.76813 1.66261 4.53706 1.67393C4.03924 1.69847 4.06179 1.67393 4.86018 1.2945C5.52331 0.979253 6.07749 0.794257 6.82892 0.635688C7.67428 0.456355 9.26167 0.454467 10.0939 0.631913C10.9918 0.822572 12.0495 1.21899 12.645 1.5871L12.8216 1.69659L12.4158 1.67582C11.608 1.63429 10.432 1.96275 9.16774 2.58193C8.78639 2.76881 8.45576 2.91794 8.43134 2.91228C8.4088 2.9085 8.09696 2.7556 7.74191 2.57437Z"
        fill="white"
      />
    </svg>
  );
};

export default XboxIcon;
