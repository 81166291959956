import { IBreakpoints } from '@pdp-rework-hub/utils-types';
import { BlockPosition } from './types';

export interface ICustomAppProps {
  backgroundimage?: IBreakpoints;
  blockposition?: BlockPosition;
  titlecontent?: string;
  titlecolor?: string;
  textcontent?: string;
  textcolor?: string;
}

export const CustomAppDefaultProps = {
  blockposition: 'Top-left',
};

export const CustomAppEventsConfigs = {};
