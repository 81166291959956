export default {
  translations: {
    global: {
      getTheGame: 'OBTENLO',
      day: 'día',
      days: 'días',
      tryForFree: 'Pruébalo gratis',
      ageGateRestrictionMessage:
        'Lo sentimos, pero es posible que no puedas acceder a este contenido debido a restricción de edad. Por favor, revisa nuestra política de <0>privacidad</0>',
    },
  },
};
