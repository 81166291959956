import { filterActivePromotionsFromSubscriptions } from '@pdp-rework-hub/utils';
import { IAdobeEvent, IAdobeProductClickEvent, ISubscription } from '@pdp-rework-hub/utils-types';
import { RootState } from '../../../../../store';
import { useSelector } from 'react-redux';
import { SUBSCRIPTION_IDS } from '@pdp-rework-hub/utils-constants';

export interface ISubCartAction {
  baseEventDetails: IAdobeEvent;
  activeSubscription?: ISubscription;
  disabled: boolean;
  isPreOrder: boolean;
  isSubscriptionButtonRestricted: boolean;
  releaseDateToDisplay: string | null;
  selectedSubscription: ISubscription | null;
  subscriptionEventDetails: IAdobeProductClickEvent | null;
}

export const useSubCartAction = (): ISubCartAction | null => {
  const {
    upgradeRequired,
    activeSubscription,
    isSubscriptionButtonRestricted,
    locale,
    productData,
    selectedSubscription,
    disabled,
  } = useSelector((state: RootState) => ({
    upgradeRequired: state.user.upgradeRequired,
    activeSubscription: state.user.activeSubscription,
    isSubscriptionButtonRestricted: state.products.isSubscriptionButtonRestricted,
    locale: state.app.locale,
    productData: state.products.data.product,
    selectedSubscription: state.products.selectedSubscription,
    disabled: state.user.isMinor,
  }));

  if (!productData) {
    return null;
  }

  const baseEventDetails: IAdobeEvent = {
    location: 'buy selector',
    locationDetail: 'subscribe',
    action: `ubisoft plus : add to cart`,
    category: 'cart',
    locationPosition: 'overlay',
    slotNumber: 3,
  };

  let subscriptionEventDetails: IAdobeProductClickEvent | null = null;
  if (selectedSubscription) {
    if (activeSubscription) {
      const selectedSubscriptionNeedUpgrade =
        upgradeRequired && selectedSubscription?.id === SUBSCRIPTION_IDS.PREMIUM_ANYWHERE;
      baseEventDetails.action = `ubisoft plus : ${selectedSubscriptionNeedUpgrade ? 'change plan' : 'play now'}`;
    }

    const { id, name } = selectedSubscription;
    const filteredPromotions = filterActivePromotionsFromSubscriptions([selectedSubscription]);
    subscriptionEventDetails = {
      ...baseEventDetails,
      productId: id,
      productName: name,
      productDiscount: filteredPromotions.length ? 'yes' : 'no',
      productSalesType: 'subscription',
    };

    if (selectedSubscription.percentageOff) {
      subscriptionEventDetails.productDiscountRate = selectedSubscription.percentageOff / 100;
    }

    if (filteredPromotions.length) {
      subscriptionEventDetails.productDiscountName = filteredPromotions[0].promotionName;
    }
  }
  
  const formattedReleaseDate = productData.details.productReleaseDateTime && new Date(productData.details.productReleaseDateTime);
  const isPreOrder = !!formattedReleaseDate && !productData.details.isReleased;
  const releaseDateToDisplay = productData.details.releaseDateString || (formattedReleaseDate ? formattedReleaseDate.toLocaleString(locale) : null);

  return {
    baseEventDetails,
    activeSubscription,
    disabled,
    isPreOrder,
    isSubscriptionButtonRestricted,
    selectedSubscription,
    subscriptionEventDetails,
    releaseDateToDisplay,
  };
};
