import { useEffect } from 'react';

export const useModelsDependencies = () => {
  useEffect(() => {
    if (process.env.NODE_ENV === 'development') {
      /* eslint @typescript-eslint/no-var-requires: "off" */
      setTimeout(() => {
        window.extendedProductModel = require('libs/test/mock/productModel.dev.json');
        window.subscriptionPricingDetails = require('libs/test/mock/subscriptionModel.dev.json');
      }, 1000);
    }
  }, []);
};
