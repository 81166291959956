import { MicroElement as BaseMicroElement, SfccMixin } from '@mooncake/core';
import kebabCase from 'lodash/kebabCase';
import { projectName } from '../mooncake.json';
import App, { AppProps, AppEventsConfigs } from './App';
import storeConfig from './store';

class MicroElement extends SfccMixin(BaseMicroElement) {
  constructor() {
    super(projectName, App, AppProps, AppEventsConfigs, storeConfig);
  }
}

const elementName = kebabCase(projectName);
if (!customElements.get(elementName)) {
  customElements.define(elementName, MicroElement);
} else {
  console.error(
    `A custom element with the name "${elementName}" already exists in the page. We can't define it again.`
  );
}
