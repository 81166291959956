import React from 'react';

interface IGamesIcon {
  width: string;
  height: string;
  color?: string;
}

const GamesIcon = ({ width, height, color = 'white' }: IGamesIcon) => {
  return (
    <svg width={width} height={height} viewBox="0 0 39 35" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M13.6094 34.125H30.1641L28.6406 30.4688H12.0859L13.6094 34.125Z" fill={color}/>
      <path d="M39 23.1563L30.875 3.65625H26.9141L35.0391 23.1563H39Z" fill={color}/>
      <path d="M35.0391 23.1563H17.7735L9.64844 3.65625H5.6875L7.21094 7.3125H2.4375L3.96094 10.9688H8.73438L15.3359 26.8125H27.1172L28.6406 30.4688H32.6016L31.0782 26.8125H36.5625L35.0391 23.1563Z" fill={color}/>
      <path d="M25.3906 0H8.125L9.64844 3.65625H26.9141L25.3906 0Z" fill={color}/>
      <path d="M0 10.9688L8.12501 30.4688H12.0859L3.96094 10.9688H0Z" fill={color}/>
      <path d="M18.4233 15.21H21.2048L22.4845 18.2813H26.4454L25.1657 15.21H28.1734L26.6499 11.5538H23.6423L22.3829 8.53125H18.422L19.6814 11.5538H16.8999L18.4233 15.21Z" fill={color}/>
    </svg>

  );
};

export default GamesIcon;
