import React from 'react';
import { useTheme } from 'styled-components';
import { m } from 'framer-motion';
import Container from '@mooncake/ui/lib/components/Container';
import Text from '@mooncake/ui/lib/components/Text';
import { appearingAnimationParams, GlobalStyle, TextResetContainer, withLazyMotion } from '@pdp-rework-hub/ui';
import { ICustomAppProps } from '../../AppProps';

import { Image, TextContainer, Heading } from './styles';

const Content = ({ image, linktitle, linkurl, linknewtab, textcontent, titlecontent }: ICustomAppProps) => {
  const theme = useTheme();
  return (
    <Container
      backgroundImage={'linear-gradient(180deg, #FFFFFF 0%, #E5E8F1 100%)'}
      padding={{
        default: '0 1rem 2rem',
        desktop: '2.5rem',
      }}
      overflow={'hidden'}
    >
      <GlobalStyle />
      <Container
        display={{
          tablet: 'flex',
        }}
        alignItems={'center'}
        maxWidth={'80rem'}
        margin={'auto'}
      >
        {image ? (
          <Container
            maxWidth={{
              default: '6.25rem',
              tablet: '20%',
            }}
            margin={{
              default: '-1.25rem 0 0 -1.25rem',
              tablet: '0 3rem 0 0',
              desktop: '0 4.5rem 0 0',
            }}
          >
            <m.div {...appearingAnimationParams(0.1)}>
              <Image src={image} alt={''} loading={'lazy'} />
            </m.div>
          </Container>
        ) : null}
        <TextContainer>
          {titlecontent ? <Heading dangerouslySetInnerHTML={{ __html: titlecontent }} /> : null}
          <TextResetContainer
            as="div"
            dangerouslySetInnerHTML={{ __html: textcontent }}
            marginBottom={'0'}
            fontSize={{
              default: '1rem',
              desktop: '1.125rem',
            }}
            color={theme.colors.Black}
          />
          {linktitle && linkurl ? (
            <Container marginTop={'1rem'}>
              <a href={linkurl} target={linknewtab ? '_blank' : '_self'} style={{
                color: theme.colors.Black,
                textDecoration: 'underline',
                fontFamily: 'unset',
                fontWeight: 'unset',
                fontSize: 'unset',
              }}>
                <Text.Span
                  fontSize={{
                    default: '1rem',
                    desktop: '1.125rem',
                  }}
                  color={theme.colors.Black}
                >
                  {linktitle}
                </Text.Span>
              </a>
            </Container>
          ) : null}
        </TextContainer>
      </Container>
    </Container>
  );
};

export default withLazyMotion(Content);
