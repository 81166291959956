import React from 'react';

const PCIcon = () => {
  return (
    <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M13.7082 3.01771L0.854492 5.281V15.843H13.7082V3.01771Z" fill="white" />
      <path d="M13.7082 17.3518H0.854492V27.9138L13.7082 29.4227V17.3518Z" fill="white" />
      <path d="M31.8545 0L15.2203 2.88363V15.843H31.8545V0Z" fill="white" />
      <path d="M31.8545 17.3518H15.2203V29.5684L31.8545 32V17.3518Z" fill="white" />
    </svg>
  );
};

export default PCIcon;
