import { workspaceTheme } from '@pdp-rework-hub/ui';
import { Themes } from '@pdp-rework-hub/utils-types';

const computedTheme = (themeName: Themes) => {
  const theme: any = workspaceTheme(themeName);

  return theme;
};

export default computedTheme;
