import styled, { css, CSSProperties } from 'styled-components';
import { m } from 'framer-motion';
import { baseLoadingBackground, BaseSkeleton } from '../Skeleton/Skeleton';
import breakpoints from '../../../themes/utils/breakpoints';
import colors from '../../../themes/utils/colors';

export const TileSkeletonWrapper = styled.div<CSSProperties>`
  width: 100%;
  height: ${({ height }) => height};
  background: ${({ theme }) => theme.BuySelector.Colors.background4};
  border-radius: 20px;
  margin: 1em 0;
  display: flex;
  flex-direction: ${({ flexFlow }) => flexFlow};
`;

interface TileSkeletonInnerProps extends CSSProperties {
  width: string;
  height: string;
}

export const TileSkeletonWrapperInner = styled.div<TileSkeletonInnerProps>`
  height: ${({ height }) => height};
  width: ${({ width }) => width};
  padding: ${({ padding }) => padding};
`;

export const TileSkeletonWrapperItem = styled(BaseSkeleton)<TileSkeletonInnerProps>`
  height: ${({ height }) => height};
  width: ${({ width }) => width};
  margin: ${({ margin }) => margin};
  border-radius: ${({ borderRadius }) => borderRadius};

  &::after {
    ${baseLoadingBackground}
  }
`;

interface ITileInnerProps extends CSSProperties {
  available?: boolean;
  active?: boolean;
}

export const TileInner = styled.button<ITileInnerProps>`
  width: 100%;
  min-height: 4.5rem;
  color: ${({ theme }) => theme.colors.White};
  text-align: left;
  background: transparent;
  border: 0;
  appearance: none;
  padding: 0.5rem;
  background: ${({ background, theme }) => (background === 'light' ? 'white' : theme.BuySelector.Colors.background5)};
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: ${({ flexFlow }) => flexFlow};
  overflow: hidden;
  border-radius: ${({ active }) => (active ? '4px 4px 0 0' : '4px')};
  position: relative;
  cursor: pointer;
  transition: all 0.2s ease;

  @media screen and (min-width: ${breakpoints.tablet}) {
    border-radius: 4px;
  }

  &:hover {
    @media screen and (min-width: ${breakpoints.tablet}) {
      background: ${({ theme, available }) => available && theme.BuySelector.Colors.background4};
    }
  }

  ${({ flexFlow }) =>
    flexFlow !== 'row'
      ? css`
          h4 {
            margin-top: 0.5rem !important;
            padding-right: 0 !important;
          }
        `
      : css``}
`;

interface ITileWrapper {
  disabled: boolean;
}

export const TileWrapper = styled.div<ITileWrapper>`
  margin: 1em 0;
  position: relative;

  ${({ disabled }) =>
    disabled &&
    css`
      opacity: 0.6;
    `}
`;

interface TileColumnWrapperProps {
  hasImage?: boolean;
  size: string;
  flexFlow: CSSProperties['flexFlow'];
  isSubscriptionTile?: boolean;
}

export const TileImageWrapper = styled.div<TileColumnWrapperProps>`
  ${({ size, flexFlow }) => css`
    ${flexFlow === 'row' ? 'width' : 'height'}: ${size};
    ${flexFlow === 'row' ? 'height' : 'width'}: 100%;
  `}
  display: flex;
  border-radius: 4px;
  overflow: hidden;
  position: relative;
  align-self: center;
  max-width: 6.25rem;
  ${({ isSubscriptionTile }) =>
    isSubscriptionTile
      ? css`
          padding: 0.5rem;
          min-height: 7.5rem;
          background-color: black;
          flex-direction: column;
          justify-content: space-around;
          align-items: center;
        `
      : css`
          svg {
            width: 100%;
            height: 100%;
            margin: auto;
          }
        `};

  img {
    display: block;
    width: 100%;
  }
`;

export const TileContent = styled.div<TileColumnWrapperProps>`
  ${({ size, flexFlow }) => css`
    ${flexFlow === 'row' ? 'width' : 'height'}: ${size};
  `}
  padding: ${({ flexFlow }) => (flexFlow === 'row' ? '0 1rem' : '0')};
  margin-right: auto;

  @media screen and (max-width: ${breakpoints.tablet}) {
    padding: ${({ flexFlow }) => (flexFlow === 'row' ? '0 0.5rem' : '0')};
  }

  h4 {
    display: inline-block;
    padding-right: 1.625rem;
    position: relative;
    margin: 0;
    white-space: nowrap;
    font-family: ${({ theme }) => theme.mainFontTweaks.bold};
  }

  h4 svg {
    margin: 0 0 -2px 5px;
  }

  .earlyAccess {
    margin-top: 0.375rem;
    background: ${({ theme }) => theme.colors.RedViolet};
    width: fit-content;
    border-radius: 4px;
    span {
      font-family: ${({ theme }) => theme.fonts.bold};
      font-size: 0.875rem;
      display: block;
      padding: 0.125rem 0.5rem;
    }
  }
`;

export const TilePrice = styled.div<TileColumnWrapperProps>`
  ${({ size, flexFlow }) => css`
    ${flexFlow === 'row' ? 'width' : 'height'}: ${size};
  `}
  text-align: right;
  margin-left: auto;
`;

export const TileCurrentPrice = styled.div`
  span.from {
    margin-right: 0.1em;
    font-size: 0.8em;
  }

  span.price {
    font-size: 1.05em;
  }
`;

export const TileBasePrice = styled.div`
  margin-top: 0.4em;
  text-decoration: line-through;
  color: #b5b5b5;
  font-size: 0.9em;
`;

export const OwnedSubscription = styled.div`
  display: flex;
  justify-content: center;
  align-content: center;

  svg {
    margin: auto;
  }

  p {
    font-family: ${({ theme }) => theme.fonts.bold};
    color: ${colors.owned};
    font-size: 1rem;
    margin-left: 0.5rem;
  }
`;

interface ISelectedTileOuterProps {
  available: boolean;
}

export const SelectedTileOuter = styled(m.div)<ISelectedTileOuterProps>`
  position: absolute;
  width: calc(100% + 0.7em);
  height: calc(100% + 0.7em);
  top: -0.35em;
  left: -0.35em;
  border: 2px solid ${({ theme }) => theme.colors.DodgerBlue};
  border-radius: 8px;
  filter: grayscale(${({ available }) => (available ? 0 : 1)});
  z-index: 1;
`;

export const ProductOfferTileWrapper = styled.div`
  display: flex;
  width: 280px;
  flex-flow: column wrap;
  background: ${({ theme }) => theme.BuySelector.Colors.background3};

  img {
    width: 100%;
  }

  @media screen and (max-width: ${breakpoints.tablet}) {
    flex-flow: row wrap;
  }
`;

export const ProductOfferTileContent = styled.div`
  display: flex;
  padding: 1em;
  color: white;
  justify-content: space-between;
`;

export const SeeMore = styled.a`
  cursor: pointer;
  font-family: unset;
  font-weight: unset;
  font-size: unset;
  color: unset;
  text-decoration: underline;

  &:hover {
    color: unset;
  }
`;

export const PackshotContainer = styled.div`
  position: relative;
  min-width: 80px;
  min-height: 100px;
  width: 100%;

  @media screen and (max-width: ${breakpoints.tablet}) {
    min-width: 60px;
    min-height: 80px;
  }

  img {
    display: block;
    position: relative;
    z-index: 1;
  }

  ${TileSkeletonWrapper} {
    margin: 0;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 0;
  }
`;
