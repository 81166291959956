export default {
  translations: {
    title: 'เปรียบเทียบ {{gameName}} เอดิชั่นต่างๆ',
    subscription: {
      title: 'รวมอยู่ใน Ubisoft<span class="blue">+</span>',
      classics: { title: 'คอลเลกชัน Classics', subtitle: 'สนุกไปกับเกมที่โด่งดังของ Ubisoft' },
      premium: { title: 'เกม PC มากกว่า 100 เกม', subtitle: 'รวมเกมใหม่ ๆ' },
    },
    subscriptionModal: {
      classics: {
        title: 'Ubisoft<span class="blue">+</span> Classics',
        items: [
          { description: 'เล่นเกมอันโด่งดังที่มีให้เลือกมากมายกับ Ubisoft Connect', icon: 'check' },
          { description: 'เข้าถึงสแตนดาร์ดเอดิชั่น', icon: 'check' },
          { description: 'ส่วนลด 20% สำหรับ DLC และเกมต่าง ๆ', icon: 'check' },
        ],
      },
      premium: {
        title: 'อะไรคือ Ubisoft<span class="blue">+</span>?',
        items: [
          {
            title: 'เกมออกใหม่และเกม PC มากกว่า 100 เกม',
            description: 'เล่นเกมที่เลือกจากคลาวด์และแคตตาล็อกขนาดใหญ่ที่มีเกมมากกว่า 100 เกมบน PC',
            icon: 'games',
          },
          {
            title: 'เวอร์ชันพรีเมียม',
            description: 'เข้าถึงส่วนขยาย พาสฤดูกาลต่างๆ และเนื้อหาเพิ่มเติม',
            icon: 'diamond',
          },
          {
            title: 'รางวัลรายเดือน',
            description: 'รับไอเทมสำหรับการปรับแต่งใหม่ๆ บูสเตอร์ และรางวัลในเกมกันไปทุกเดือน',
            icon: 'reward',
          },
          {
            title: 'ยกเลิกและกลับมาใหม่ได้ทุกเมื่อ',
            description: 'กลับสู่การเป็นสมาชิกได้ง่ายๆ ทุกเมื่อที่ต้องการ',
            icon: 'replay',
          },
        ],
      },
      learnMoreAboutUbisoftPlus: 'รู้จัก Ubisoft+ ให้มากขึ้น',
    },
  },
};
