import { SFCC_PLATFORMS } from '@pdp-rework-hub/utils-constants';
import { PremiumAnywherePlatforms } from '../subscription';

export interface IProductDisplayPrice {
  price: string | null;
  basePrice: string | null;
  discount: number | null;
  hasEqualPrices: boolean;
}

export interface ILabel {
  dlcType?: string | null;
  productActivationMethodString: string;
}

export interface IAvailability {
  addToCartPossible: boolean;
  itemsInCart: number;
  isItemInCart: boolean;
  isPreOrder: boolean;
  isOutOfStock: boolean;
  inventoryLevel: number;
  isUpdate: boolean;
}

export interface IPriceObject {
  price: MasterPrice;
  priceOptions: IPriceOptions;
}

export interface IPriceOptions {
  currentOptionModel?: string | null;
  isPromoPrice: boolean | null;
  showFromLabel: boolean | null;
  isRangePrice: boolean | null;
  promotionColor?: string | null;
  isPrepaidCard?: string | null;
  isFreeToPlay: boolean | null;
  ibexRecurrencyLabel?: string | null;
  isMaster: boolean | null;
  isRightSideFromText: boolean | null;
}

export interface IGenre {
  displayValue: string;
  categoryID: string;
  link: string;
}

export interface IRating {
  assetDescription: string | null;
  text: string;
  additionalText: string;
  ageRating: string;
}

export interface IOwnership {
  productOwnership: boolean;
  ownedOtherEdition: boolean;
}

export interface IProductModel {
  availability: IAvailability;
  details: IDetails;
  dlc?: {
    enableOAV2BaseGameDisclaimer?: boolean;
    isDlcType: boolean;
  };
  genres: IGenre[];
  label: ILabel;
  masters: IMaster[];
  media: IMedia;
  offer: IOffer;
  ownership: IOwnership;
  priceObject: IPriceObject;
  rating: IRating;
}

export interface IMaster {
  id: string;
  edition: string;
  master: boolean;
  media: IMedia;
  name: string;
  shortDescription: string;
  price: MasterPrice;
  mdmInstallmentIDString?: string;
  variations: IVariation[];
  partOfUbisoftPlus?: boolean;
  anywherePlatforms?: PremiumAnywherePlatforms[];
  productReleaseDateTime?: string;
  isReleased?: boolean;
  releaseDateString?: string;
  offer: IOffer;
  freeToPlayMobileRegisterURL: string | null;
  earlyAccessDuration: number | null;
  masterSubOffersDates: IMasterSubOfferDates[];
}

export interface IMasterSubOfferDates {
  editionMasterId: string;
  fromDate: string;
  offerId: string;
  offerName: string;
  toDate: string;
}

export type PlatformValue = typeof SFCC_PLATFORMS[keyof typeof SFCC_PLATFORMS]['id'];
export type Platforms = typeof SFCC_PLATFORMS & { [key in PlatformValue]: string };

export interface IProductVersion {
  digOrPhysVersionProductID: string;
  product: Record<never, unknown>;
  productID: string;
  isAvailable: boolean;
  isDigitalKey: boolean;
  displayValue: string;
  variationAttrValue: string;
  tc100: string;
  redirectLink: string;
}

export interface IProductsVersions {
  versions: IProductVersion[];
  type: string;
  count: number;
  hasRedirectVersion: boolean;
  numberOfVersionsAvailable: number;
}

export interface IVariation {
  alternativeVersionId?: string;
  id: string;
  platform: PlatformValue;
  variant: boolean;
  version: IVersion;
  price: IPrice;
  productsVersions?: Partial<IProductsVersions>;
  promotion: IPromotion[];
  availability: IVariantAvailability;
  mdmCustomerID?: string;
}

export interface IVariantAvailability {
  addToCartPossible: boolean;
  itemsInCart: number;
  isItemInCart: boolean;
  isPreOrder: boolean;
  isOutOfStock: boolean;
  inventoryLevel: number;
  isUpdate: boolean;
}

export interface IPromotion {
  id: string;
  discount: number;
  title: string;
  type?: string;
  startDate?: string;
  endDate: string;
}

export interface IVersion {
  displayValue: string;
  value?: boolean;
}

export type MasterPrice = IPriceRange | IPrice;

export interface IPriceRange {
  min: IPrice;
  max: IPrice;
}

export interface IPrice {
  sales: ISales;
  list: ISales | null;
}

export const isPriceRange = (object: MasterPrice): object is IPriceRange => (object as IPriceRange).min !== undefined;

export interface IPriceRange {
  min: IPrice;
  max: IPrice;
}

export interface IMediaDetails {
  alt?: string;
  title?: string;
  url: string;
}

export interface IMedia {
  edition_packshot: IMediaDetails[];
  horizontal: IMediaDetails[];
  large: IMediaDetails[];
  media_slider_PD: IMediaDetails[];
}

export const hasPackshot = (object: IMedia): object is IMedia =>
  object && (object as IMedia).edition_packshot.length > 0;

export interface IDetails {
  ID: string;
  isDigital: boolean;
  productReleaseDateTime: string;
  isReleased: boolean;
  releaseDateString: string;
  productName: string;
  platformName: string;
  isMasterProduct: boolean;
  isOnlineProduct: boolean;
  mdmCustomerOfferID: string;
  productPlatformsOrder: PlatformValue[];
  mdmIPCID: string;
  productBrand: string;
  productOnlineFrom: string;
  productOnlineTo: string;
  subscriptionExpirationDate: string;
  subOffersDates: ISubOfferDate[] | null;
  currentSubId: string | null;
  activeSubOffers: Array<{ offerId: number; productId: string }> | null;
}

export interface ISubOfferDate {
  offerId: string;
  offerName: string;
  from: string;
  to: string;
}

export interface IOffer {
  isGiveawayProductAvailable: IIsGiveawayProductAvailable;
  isGiveaway: boolean;
  isFreeWithPurchase: boolean;
  isFreePlayGame: boolean;
  isFreeToPlay: boolean;
  isOpenBeta: boolean;
  isDemo: boolean;
  isFreeWeekend: boolean;
}

export interface IIsGiveawayProductAvailable {
  success: boolean;
  pid?: string;
}

export interface ISales {
  value: number;
  currency: string;
  formatted: string;
  decimalPrice: string;
}

export type FreeOfferStatus = Pick<
  IOffer,
  'isDemo' | 'isFreePlayGame' | 'isFreeToPlay' | 'isFreeWeekend' | 'isFreeWithPurchase' | 'isGiveaway' | 'isOpenBeta'
>;

export type FreeOffer = {
  [K in keyof FreeOfferStatus]: `${K extends `is${infer U}` ? Uppercase<U> : never}`;
}[keyof FreeOfferStatus];
