import { SUBSCRIPTION_BUTTON_TYPE } from '@pdp-rework-hub/utils-constants';

export type SubscriptionButtonType = typeof SUBSCRIPTION_BUTTON_TYPE[keyof typeof SUBSCRIPTION_BUTTON_TYPE];

export type DurationUnit = 'day' | 'month' | 'year' | 'days' | 'months' | 'years';

export const SubscriptionStatus = {
  Active: 'Active',
  Inactive: 'Inactive',
  Suspended: 'Suspended',
} as const;

export type TSubscriptionStatus = typeof SubscriptionStatus[keyof typeof SubscriptionStatus] | null;

export interface ISubscriptionModel {
  subscriptions: ISubscription[];
}

export type PremiumAnywhereTypes = 'cloudGaming' | 'consoles';
export type PremiumAnywherePlatforms = 'luna' | 'xbox';

export interface ISubscription {
  id: string;
  name: string;
  includes: string[];
  basePrice: number;
  currency: string;
  basePriceFormatted: string;
  discountedPrice: number;
  discountedPriceFormatted: string;
  promotions: SubscriptionPromotion[];
  productSubscriptionType: string;
  rating: string;
  anywhereAvailability?: {
    [key in PremiumAnywherePlatforms]: boolean;
  };
  percentageOff?: number;
  offerId: string;
}

export interface SubscriptionPromotion {
  promotionid: string;
  promotionName: string;
  type: string;
  duration: string;
  durationUnit: DurationUnit;
  startDate: string;
  endDate: string;
  discount: string;
}
