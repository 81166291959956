import { configureStore, getDefaultMiddleware } from '@reduxjs/toolkit';
import rootReducer from './reducers';
import { projectName } from '../mooncake.json';

const storeConfig = {
  reducer: rootReducer,
  middleware: getDefaultMiddleware({
    serializableCheck: false,
  }),
  devTools: {
    name: projectName,
  },
};

export default {
  storeConfig,
  multiInstanceStore: configureStore(storeConfig),
};
