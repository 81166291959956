import styled from 'styled-components';
export const ContentContainer = styled.div`
  i {
    font-style: normal;
  }
  strong {
    font-weight: normal;
    font-family: ${({ theme }) => theme.fonts.bold};
  }
`;
