import React from 'react';
import { ButtonWrapper, HiddenTextContainer, ShowedTextContainer } from './style';
import { ButtonTypes } from '../index';
import { ExternalIcon } from '../Icons';

export interface IRedirection {
  type: 'category' | 'product' | 'custom' | 'page' | 'any';
  value: string;
}

export interface IButtonProps {
  buttonType?: ButtonTypes;
  children: React.ReactNode;
  redirection?: IRedirection;
  Tag?: any;
  tracking?: string;
  handleClick?: (e: React.MouseEvent<HTMLElement>) => void;
  disabled?: boolean;
  classNames?: string[];
  targetBlank?: boolean;
  handleOver?: (e: React.MouseEvent<HTMLElement>) => void;
  handleLeave?: (e: React.MouseEvent<HTMLElement>) => void;
  external?: boolean;
}

const Button = ({
  Tag = 'a',
  children,
  disabled = false,
  buttonType = 'primary',
  handleClick,
  handleOver,
  handleLeave,
  external = false,
}: IButtonProps) => {
  return (
    <ButtonWrapper
      as={Tag}
      buttonType={buttonType || 'primary'}
      data-alt-text={children}
      onClick={!disabled ? handleClick : () => false}
      disabled={disabled}
      onMouseOver={handleOver}
      onMouseOut={handleLeave}
      tabIndex={'0'}
      role={'button'}
    >
      <HiddenTextContainer aria-hidden>
        {children}
        {external ? <ExternalIcon /> : null}
      </HiddenTextContainer>
      <ShowedTextContainer>
        {children}
        {external ? <ExternalIcon /> : null}
      </ShowedTextContainer>
    </ButtonWrapper>
  );
};

export default Button;
