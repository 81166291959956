export default {
  translations: {
    global: {
      subscription: {
        subscribeNow: '立即订阅',
        resubscribe: '重新订阅',
        subscribeUbisoftPlus: '订阅Ubisoft+',
        playNowUbisoftPlus: '立即畅玩Ubisoft+',
        goToMyLibrary: '前往游戏库',
        includedWithUbisoftPlus: '包含在Ubisoft+中',
        dayOne: '首日使用Ubisoft+',
        service: '订阅服务',
      },
      offerEnds: '优惠至 {{date}} 止。',
      outOfStock: '无库存',
      from: 'xx起',
      preOrder: '预购',
      buyNow: '立即购买',
      getTheGame: '获取',
      addToCart: '加入购物车',
      playNow: '立即游玩',
      month: '月',
      months: '月',
      day: '日',
      days: '日',
      buyGame: '购买游戏',
      free: '免费',
      freeTrial: '免费试用',
      tryForFree: '免费试用',
      then: '之后',
      durationTerm: '为了',
      containedOffers: '包含在这一版本中',
      gameOwned: '你已拥有这一版本',
      seeDetails: '查看详情',
      hide: '隐藏',
      subscribed: '订户',
      buyOn: { playstation: '在Playstation上购买', microsoft: '在Xbox上购买', nintendo: '在Nintendo上购买' },
      ageGateRestrictionMessage: '很抱歉，由于年龄限制，你可能无法访问此内容。请查看我们的<0>隐私政策</0>',
      monthly: '按月',
      yearly: '按年',
      earlyAccess: '抢先游玩',
    },
    versions: { digital: '数字', physical: '实体' },
    subscription: { promoprice: { month: '然后 {{price}} /月', year: '然后 {{price}} /年' } },
  },
};
