export default {
  translations: {
    selectYour: {
      edition: 'Sélectionnez votre édition',
      version: 'Sélectionnez votre version',
      platform: 'Sélectionnez votre plateforme',
      payment: 'Sélectionnez un moyen de paiement',
    },
    selectPlatform: 'Sélectionnez la plateforme',
    releaseDate: 'DATE DE SORTIE:',
    buySelector: { subscription: { subscriptionSelection: "Choix de l'abonnement :" } },
    subscription: {
      title: 'Obtenez {{gameName}} et bien plus encore avec Ubisoft<span class="blue">+</span>',
      alreadySubscribed: 'Vous êtes déjà inscrit à Ubisoft+!',
      learnMoreAbout: 'En savoir plus sur <0>Ubisoft+</0>',
      cancelNotice: 'Annulez et revenez quand vous le souhaitez. <0>Voir la FAQ</0>',
      gamesSelection: {
        classics: {
          title: "Jeux cultes d'Ubisoft",
          description:
            "Profitez d'un éventail de jeux emblématiques, dont Assassin's Creed Valhalla, Rainbow Six Siege, et bien plus encore",
          link: { notSubscriber: 'Voir les jeux inclus', subscriber: 'Parcourir le catalogue de jeux' },
        },
        premium: {
          title: 'Plus de 100 jeux',
          description:
            "Jouez instantanément aux titres les plus populaires d'Ubisoft et à un grand catalogue de jeux, notamment Assassin's Creed Valhalla et Rainbow Six Siege.",
          link: { notSubscriber: 'Voir tous les jeux inclus', subscriber: 'Parcourir le catalogue de jeux' },
        },
      },
      cards: {
        cloudGaming: {
          title: 'Cloud Gaming',
          description:
            'Jouez directement depuis votre smartphone, votre tablette ou votre télévision, où que vous soyez avec une bonne connexion internet via {{platforms}}.',
          link: 'Voir les jeux disponibles depuis le Cloud',
        },
        consoles: {
          title: 'Consoles',
          description: 'Jouez à une sélection de jeux depuis votre {{platforms}} avec notre abonnement gaming.',
          link: 'Voir les jeux de console inclus',
        },
      },
      partOfUbisoftPlus: {
        released: 'Jouez à {{game}} {{edition}} avec Ubisoft+.',
        dlc: 'Play the {{dlc}}, included with {{game}}.',
        preOrder: 'Jouez {{game}} {{edition}} dès sa sortie le {{releaseDate}} avec Ubisoft+.',
        includedContent: 'Contenu inclu:',
      },
      firstParty: {
        legalLine:
          'Les prix affichés ne concernent que les versions PC.<br/>Veuillez consulter les boutiques des plateformes tierces pour connaître les prix des versions consoles.',
        pricingInfo: 'Pour connaître les prix, veuillez consulter la boutique de la plateforme concernée.',
      },
      learnMore: 'En savoir plus',
      playThisGame: 'Play this game in your <span class="blue">Library+</span>!',
    },
  },
};
