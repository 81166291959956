import { getTheme } from '@mooncake/ui';
import { Themes } from '@pdp-rework-hub/utils-types';
import * as themes from './themes';

const DEFAULT_THEME_NAME = 'store';

export const workspaceTheme = (themeName: Themes) => {
  const themeOverrides = {};
  let theme = getTheme(themeName, themeOverrides);

  if (typeof themes[DEFAULT_THEME_NAME] === 'function') {
    theme = themes[DEFAULT_THEME_NAME](theme);
  }

  if (typeof themes[themeName] === 'function' && themeName !== DEFAULT_THEME_NAME) {
    theme = themes[themeName](theme);
  }

  return theme;
};
