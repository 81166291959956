import React, { KeyboardEvent } from 'react';
import { RootState, useAppDispatch } from '../../../../../store';
import { WithTranslation, withTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { ProductEditionsWrapper } from '../style';
import { EditionsWrapper } from '../style';
import Tile from '../../../../UI/Tiles/Tile';
import { useTheme } from 'styled-components';
import { selectEdition } from '@pdp-rework-hub/slices';
import EditionLoader from '../EditonLoader';
import { IWithHandleSelectorScroll } from '../..';
import MobileShowcase from './MobileShowcase';
import { getProductDisplayPrice } from '@pdp-rework-hub/utils';
import { IMaster, hasPackshot, IVariation } from '@pdp-rework-hub/utils-types';
import { SHOP_URL } from '@pdp-rework-hub/utils-constants';

interface IProductEditionsProps extends WithTranslation, IWithHandleSelectorScroll {
  skeletonLength?: number;
}

const ProductEditions = ({ skeletonLength = 2, t, handleScrollEditionClick }: IProductEditionsProps) => {
  const productEditionsSelector = useSelector((state: RootState) => ({
    product: state.products.data.product,
    fetching: state.products.fetching,
    selectedMaster: state.products.selectedMaster,
  }));
  const { product, fetching, selectedMaster } = productEditionsSelector;
  const masters = product?.masters;
  const theme = useTheme();
  const dispatch = useAppDispatch();
  const loading = !masters?.length && fetching;

  if (loading) {
    return (
      <ProductEditionsWrapper>
        <h2>{t('selectYour.edition')}</h2>
        <EditionLoader length={skeletonLength} />
      </ProductEditionsWrapper>
    );
  }

  if (!masters || !selectedMaster) {
    return null;
  }

  const handleEditionClick = (product: IMaster) => {
    dispatch(selectEdition(product));
    handleScrollEditionClick(product);
  };

  const handleEditionKeyPress = (e: KeyboardEvent, product: IMaster) => {
    if (e.key.toLowerCase() !== 'enter') return;
    handleEditionClick(product);
  };

  return (
    <ProductEditionsWrapper>
      <h2>{t('selectYour.edition')}</h2>
      <EditionsWrapper>
        {masters.map((master: IMaster, index: number) => {
          const tileActive = master && selectedMaster && master.id === selectedMaster.id;
          const productDisplayPrice = getProductDisplayPrice(master);
          const isPromo = productDisplayPrice.basePrice !== null;
          const available = master.variations.some(
            (variant: IVariation) =>
              variant.availability.addToCartPossible ||
              (!variant.availability.addToCartPossible && variant.availability.isItemInCart)
          );

          if (!master.edition) {
            return null;
          }

          const packshotImageURL = hasPackshot(master.media)
            ? SHOP_URL + master.media.edition_packshot[0].url
            : undefined;

          return (
            <Tile
              key={`${master.id}_${index}`}
              masterId={master.id}
              background={theme.BuySelector.Colors.background4}
              title={master.edition}
              imageSource={packshotImageURL}
              altText={master.edition}
              tileItemSizes={['80px', '50%', 'auto']}
              handleClick={() => handleEditionClick(master)}
              active={tileActive}
              isFreePlayGame={master.offer.isFreePlayGame}
              priceData={{
                isPromo,
                hasEqualPrices: productDisplayPrice.hasEqualPrices,
                price: productDisplayPrice.price,
                basePrice: productDisplayPrice.basePrice,
                discount: productDisplayPrice.discount ? productDisplayPrice.discount.toString() : undefined,
              }}
              available={available}
              handleKeyPress={(e) => handleEditionKeyPress(e, master)}
            >
              {tileActive && <MobileShowcase product={master} />}
            </Tile>
          );
        })}
      </EditionsWrapper>
    </ProductEditionsWrapper>
  );
};

export default withTranslation()(ProductEditions);
