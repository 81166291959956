import React from 'react';

interface IChevronRightIcon {
  color?: string;
}

const ChevronRightIcon = ({ color = '#676767' }: IChevronRightIcon) => (
  <svg width="10" height="18" viewBox="0 0 10 18" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M0.73276 0.581997C1.03271 0.296333 1.50744 0.307911 1.79311 0.607859L9.29311 8.48286C9.56897 8.77251 9.56897 9.22769 9.29311 9.51734L1.79311 17.3923C1.50744 17.6923 1.03271 17.7039 0.73276 17.4182C0.432812 17.1325 0.421233 16.6578 0.706898 16.3579L7.71429 9.0001L0.706898 1.64234C0.421233 1.34239 0.432812 0.867662 0.73276 0.581997Z"
      fill={color}
    />
  </svg>
);

export default ChevronRightIcon;
