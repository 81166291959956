import React from 'react';
import Container from '@mooncake/ui/lib/components/Container';
import { PlusUbisoftPlusIcon } from '@pdp-rework-hub/ui';
import PartOfUbisoftPlus from './PartOfUbisoftPlus';

import IncludedGames from './IncludedGames';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../store';
import PremiumDetails from './PremiumDetails';
import { SUBSCRIPTION_OFFERIDS } from '@pdp-rework-hub/utils-constants';

const SubscriptionDetails = () => {
  const contentSelector = useSelector((state: RootState) => ({
    selectedSubscription: state.products.selectedSubscription,
  }));
  const { selectedSubscription } = contentSelector;

  if (!selectedSubscription) {
    return null;
  }

  const upmaTiles = [];
  let isWide = false;
  if (selectedSubscription.anywhereAvailability?.luna) {
    isWide = true;
    upmaTiles.push(
      <React.Fragment key={'subscription_cloudGaming_card'}>
        <Container margin={'0.625rem'}>
          <PlusUbisoftPlusIcon />
        </Container>
        <PremiumDetails type={'cloudGaming'} />
      </React.Fragment>
    );
  }
  if (selectedSubscription.anywhereAvailability?.xbox) {
    isWide = true;
    upmaTiles.push(
      <React.Fragment key={'subscription_consoles_card'}>
        <Container margin={'0.625rem'}>
          <PlusUbisoftPlusIcon />
        </Container>
        <PremiumDetails type={'consoles'} />
      </React.Fragment>
    );
  }

  return (
    <Container margin={'auto'}>
      {isWide && (
        <Container display={'flex'} flexDirection={'column'} alignItems={'center'} padding={'0 6rem'}>
          <PartOfUbisoftPlus isWide={isWide} />
        </Container>
      )}
      <Container display={'flex'} alignItems={'center'} justifyContent={'center'}>
        {!isWide ? <PartOfUbisoftPlus /> : null}
        <IncludedGames type={selectedSubscription.offerId === SUBSCRIPTION_OFFERIDS.CLASSIC ? 'classics' : 'premium'} />
        {upmaTiles}
      </Container>
    </Container>
  );
};

export default SubscriptionDetails;
