import React, { useCallback, useState, useEffect } from 'react';
import { RootState, useAppDispatch } from '../../../../../store';
import { WithTranslation, withTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { ProductEditionsWrapper, AccordionDetails, SubPlanTypeWrapper } from '../style';
import { EditionsWrapper } from '../style';
import Tile from '../../../../UI/Tiles/Tile';
import { useTheme } from 'styled-components';
import { setSelectedSubscription } from '@pdp-rework-hub/slices';
import EditionLoader from '../EditonLoader';
import TileSkeleton from '../../../../UI/Tiles/TileSkeleton';
import Text from '@mooncake/ui/lib/components/Text';
import { sendAdobeEvent, filterActivePromotionsFromSubscriptions } from '@pdp-rework-hub/utils';
import { IAdobeProductClickEvent, ISubscription, IAdobeEvent } from '@pdp-rework-hub/utils-types';
import { SUBSCRIPTION_PROMOTION_DISCOUNT_TYPE, SUBSCRIPTION_PLAN_RECURRENCE } from '@pdp-rework-hub/utils-constants';
import TileAccordion from '../../../../UI/Tiles/TileAccordion';
import PremiumDetails from '../../../Showcase/SubscriptionDetails/PremiumDetails';

interface ISubscriptionPlatformsProps extends WithTranslation {
  skeletonLength?: number;
}

const SubscriptionPlatforms = ({ skeletonLength = 2, t }: ISubscriptionPlatformsProps) => {
  const {
    subscriptions,
    fetching,
    selectedSubscription,
    availableSortedSubscriptions,
    activeSubscription,
    isActiveSubscriber,
  } = useSelector((state: RootState) => ({
    subscriptions: state.products.data.subscriptions,
    fetching: state.products.fetching,
    selectedSubscription: state.products.selectedSubscription,
    availableSortedSubscriptions: state.products.availableSortedSubscriptions,
    activeSubscription: state.user.activeSubscription,
    isActiveSubscriber: state.user.isActiveSubscriber,
  }));
  const [displayedSub, setDisplayedSub] = useState<ISubscription[]>([]);
  const [monthlySubs, setMonthlySubs] = useState<ISubscription[]>([]);
  const [yearlySubs, setYearlySubs] = useState<ISubscription[]>([]);

  useEffect(() => {
    const monthlyArray: ISubscription[] = [];
    const yearlyArray: ISubscription[] = [];

    availableSortedSubscriptions?.forEach((sub) => {
      if (sub.productSubscriptionType === SUBSCRIPTION_PLAN_RECURRENCE.MONTHLY) {
        monthlyArray.push(sub);
      }
      if (sub.productSubscriptionType === SUBSCRIPTION_PLAN_RECURRENCE.YEARLY) {
        yearlyArray.push(sub);
      }
    });

    setMonthlySubs(monthlyArray);
    setYearlySubs(yearlyArray);

    const subscriptionToDisplay = selectedSubscription?.productSubscriptionType === SUBSCRIPTION_PLAN_RECURRENCE.MONTHLY
      ? monthlyArray : yearlyArray;
    
    setDisplayedSub(subscriptionToDisplay);

  }, [availableSortedSubscriptions]);

  const theme = useTheme();
  const dispatch = useAppDispatch();

  const isSubscribed = !!(
    isActiveSubscriber &&
    activeSubscription &&
    availableSortedSubscriptions?.some((sub) => {
      return activeSubscription.offerId === sub.offerId;
    })
  );

  const handleEditionClick = useCallback(
    (product: ISubscription) => {
      const { id, name } = product;
      const filteredPromotions = filterActivePromotionsFromSubscriptions([product]);
      const trackingEvent: IAdobeProductClickEvent = {
        location: 'buy selector',
        locationDetail: 'subscribe',
        action: `offer : ${product.name} : select`,
        category: 'interaction',
        locationPosition: 'overlay',
        slotNumber: 2,
        productSalesType: 'subscription',
        productId: id,
        productName: name,
        productDiscount: filteredPromotions.length ? 'yes' : 'no',
      };

      if (product.percentageOff) {
        trackingEvent.productDiscountRate = product.percentageOff / 100;
      }

      if (filteredPromotions.length) {
        trackingEvent.productDiscountName = filteredPromotions[0].promotionName;
      }

      sendAdobeEvent(trackingEvent);
      dispatch(setSelectedSubscription(product));
    },
    [setSelectedSubscription, dispatch]
  );

  const handleSubPlanTypeClick = (planType: string) => {
    const trackingEvent: IAdobeEvent = {
      location: 'buy selector',
      locationDetail: 'subscribe',
      action: `billing frequency : Ubisoft+ ${planType} : select`,
      category: 'interaction',
      locationPosition: 'overlay',
    };

    if (planType === SUBSCRIPTION_PLAN_RECURRENCE.MONTHLY) {
      setDisplayedSub(monthlySubs);
    }

    if (planType === SUBSCRIPTION_PLAN_RECURRENCE.YEARLY) {
      setDisplayedSub(yearlySubs);
    }

    const selectedSub = availableSortedSubscriptions?.find((sub) => {
      return sub.offerId === selectedSubscription?.offerId && sub.productSubscriptionType === planType;
    });

    // sendAdobeEvent(trackingEvent);
    sendAdobeEvent(trackingEvent);
    if (selectedSub) {
      const filteredPromotions = filterActivePromotionsFromSubscriptions([selectedSub]);
      const trackingSubProductEvent: IAdobeProductClickEvent = {
        location: 'buy selector',
        locationDetail: 'subscribe',
        action: `offer : ${selectedSub.name} : selected`,
        category: 'selected',
        locationPosition: 'overlay',
        productSalesType: 'subscription',
        productId: selectedSub.id,
        productName: selectedSub.name,
        productDiscount: filteredPromotions.length ? 'yes' : 'no',
      };
      sendAdobeEvent(trackingSubProductEvent);
      dispatch(setSelectedSubscription(selectedSub));
    }
  };

  const loading = !subscriptions.length && fetching;

  if (loading) {
    return (
      <ProductEditionsWrapper>
        <TileSkeleton type="horizontal" width="100%" />
        <h2>{t('selectYour.platform')}</h2>
        <EditionLoader length={skeletonLength} />
      </ProductEditionsWrapper>
    );
  }

  const designAccordionTitle = (title: string) => (
    <Text.Heading as={'h3'} bold uppercase margin={'0'} fontSize={'0.875rem'}>
      {title}
    </Text.Heading>
  );

  if (!selectedSubscription || !availableSortedSubscriptions) {
    return null;
  }
  
  return (
    <ProductEditionsWrapper>
      {(!subscriptions.length && fetching) || subscriptions.length ? <h2>{t('selectPlatform')}</h2> : null}
      {!subscriptions.length && fetching ? (
        <EditionLoader length={skeletonLength} />
      ) : (
        <EditionsWrapper>
          {availableSortedSubscriptions &&
            displayedSub &&
            displayedSub.map((product, index) => {
              const filteredPromotions = filterActivePromotionsFromSubscriptions([product]);
              const isPromo =
                product.discountedPrice < product.basePrice &&
                filteredPromotions.length > 0 &&
                filteredPromotions[0].type === SUBSCRIPTION_PROMOTION_DISCOUNT_TYPE;
              const isCloudGamingUPMAEnabled = product.anywhereAvailability?.luna;
              const isConsolesUPMAEnabled = product.anywhereAvailability?.xbox;

              return (
                <Tile
                  key={`${product.id}_${index}`}
                  background={theme.BuySelector.Colors.background4}
                  title={product.name}
                  altText={product.name}
                  tileItemSizes={['80px', '50%', '35%']}
                  handleClick={() => handleEditionClick(product)}
                  priceData={{
                    isPromo,
                    hasEqualPrices: true,
                    basePrice: product.basePriceFormatted,
                    price: product.discountedPriceFormatted,
                    discount: isPromo ? product.percentageOff : undefined,
                  }}
                  active={selectedSubscription.offerId === product.offerId}
                  subscription={product}
                >
                  {isCloudGamingUPMAEnabled && (
                    <TileAccordion title={designAccordionTitle(t('subscription.cards.cloudGaming.title'))}>
                      <AccordionDetails>
                        <PremiumDetails type={'cloudGaming'} />
                      </AccordionDetails>
                    </TileAccordion>
                  )}
                  {isConsolesUPMAEnabled && (
                    <TileAccordion title={designAccordionTitle(t('subscription.cards.consoles.title'))}>
                      <AccordionDetails>
                        <PremiumDetails type={'consoles'} />
                      </AccordionDetails>
                    </TileAccordion>
                  )}
                </Tile>
              );
            })}
          {yearlySubs.length > 0 && !isSubscribed && (
            <div style={{ marginTop: '32px' }}>
              <h2>{t('selectYour.payment')}</h2>
              <SubPlanTypeWrapper>
                <Tile
                  background={theme.BuySelector.Colors.background4}
                  tileItemSizes={['80px', '50%', '35%']}
                  title={t('global.monthly')}
                  altText={t('global.monthly')}
                  handleClick={() => handleSubPlanTypeClick(SUBSCRIPTION_PLAN_RECURRENCE.MONTHLY)}
                  active={displayedSub === monthlySubs}
                  hasImage={false}
                />

                <Tile
                  background={theme.BuySelector.Colors.background4}
                  tileItemSizes={['280px', '50%', '35%']}
                  title={t('global.yearly')}
                  altText={t('global.yearly')}
                  handleClick={() => handleSubPlanTypeClick(SUBSCRIPTION_PLAN_RECURRENCE.YEARLY)}
                  active={displayedSub === yearlySubs}
                  hasImage={false}
                />
              </SubPlanTypeWrapper>
            </div>
          )}
        </EditionsWrapper>
      )}
    </ProductEditionsWrapper>
  );
};

export default withTranslation()(SubscriptionPlatforms);
