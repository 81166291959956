const appearingAnimationParams = (delay = 0) => ({
  initial: {
    opacity: 0,
    x: 4,
  },
  whileInView: {
    opacity: 1,
    x: 0,
  },
  transition: {
    type: 'spring',
    delay: 0.1 + delay,
  },
  viewport: {
    once: true,
  },
});

export { appearingAnimationParams };
