import { useSelector } from 'react-redux';
import { RootState } from '../../store';

export const useWithProductOffers = () => {
  const { selectedMaster, fetching, productOffers, fetchingProductOffer } = useSelector((state: RootState) => ({
    selectedMaster: state.products.selectedMaster,
    fetching: state.products.fetching,
    productOffers: state.products.productOffers,
    fetchingProductOffer: state.products.fetchingProductOffer,
  }));

  return { selectedMaster, fetching, productOffers, fetchingProductOffer };
};
