export interface ICustomAppProps {
  titlecontent?: string;
  textcontent?: string;
  linktitle?: string;
  linkurl?: string;
  linktracking?: string;
  linknewtab?: boolean;
  image?: string;
}

export const CustomAppDefaultProps = {};

export const CustomAppEventsConfigs = {};
