import { RootState } from '../../../store';
import { SELECTOR_OPTION_SUBSCRIPTION } from '@pdp-rework-hub/utils-constants';
import React from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { ICustomAppProps } from '../../../AppProps';
import SelectedProduct from './SelectedProduct';
import { ShowcaseWrapper, ShowcaseInner } from './style';
import SubscriptionDetails from './SubscriptionDetails';
import { loadLazyBackground } from '@pdp-rework-hub/ui';

const mapStateToProps = (state: RootState) => ({
  selectedOption: state.products.selectedOption,
});

const connector = connect(mapStateToProps);

type IShowcaseProps = ConnectedProps<typeof connector> & ICustomAppProps;

const Showcase = ({ background, selectedOption }: IShowcaseProps) => {
  const backgroundLoaded = background ? loadLazyBackground(background) : '';
  return (
    <ShowcaseWrapper style={{ backgroundImage: `url("${backgroundLoaded}")` }} tabIndex={-1}>
      <ShowcaseInner>
        {selectedOption === SELECTOR_OPTION_SUBSCRIPTION ? <SubscriptionDetails /> : <SelectedProduct />}
      </ShowcaseInner>
    </ShowcaseWrapper>
  );
};

export default connector(withTranslation()(Showcase));
