export default {
  translations: {
    selectYour: { edition: '选择你的版本', version: '选择你的版本', platform: '选择你的平台', payment: '选择付款方案' },
    selectPlatform: '选择平台',
    releaseDate: '发售日期:',
    buySelector: { subscription: { subscriptionSelection: '订阅选择：' } },
    subscription: {
      title: '{{gameName}} 以及更多育碧游戏<span class="blue">+</span>',
      alreadySubscribed: '你已经订阅了 Ubisoft+！',
      learnMoreAbout: '了解更多关于 <0>Ubisoft+</0>',
      cancelNotice: '你可以随时取消订阅和续订，你的进度会被保存。 <0>View FAQ</0>',
      gamesSelection: {
        classics: {
          title: 'Ubisoft 的标志性游戏',
          description: '畅玩一系列精选标志性游戏，包括《刺客信条：英灵殿》《彩虹六号：围攻》及其它游戏',
          link: { notSubscriber: '查看包含的游戏', subscriber: '浏览游戏' },
        },
        premium: {
          title: '100多个游戏',
          description:
            '可以游玩包含《孤岛惊魂》全系列在内的 不断扩大的游戏合集，包括《刺客信条：英灵殿》、《彩虹六号：围攻》，还有更多游戏等着你。',
          link: { notSubscriber: '查看所包含的所有游戏', subscriber: '浏览游戏' },
        },
      },
      cards: {
        cloudGaming: {
          title: '云游戏',
          description: '立即在你的手机、平板电脑或电视机，任何能够与{{platforms}}保持良好网络连接的设备上尽情游玩。',
          link: '查看云游戏',
        },
      },
      partOfUbisoftPlus: {
        released: '訂閱 Ubisoft+ 暢玩{{game}}{{edition}}。',
        dlc: '游玩包含在 {{game}} 里的 {{dlc}}。',
        preOrder: '在 {{game}} 于 {{releaseDate}} 发售时立即游玩。',
        includedContent: '包含内容：',
      },
      firstParty: {
        legalLine: '列出的价格仅适用于 PC 版本<br/>请查看第三方平台商城，了解主机上的适用价格。',
        pricingInfo: '查看平台商城了解价格',
      },
    },
  },
};
