export default {
  translations: {
    global: {
      subscription: {
        subscribeNow: 'เข้าร่วมเลย',
        reactivate: 'เปิดใช้งาน Ubisoft+ อีกครั้ง',
        resubscribe: 'กลับมาเป็นสมาชิกอีกครั้ง',
        subscribeUbisoftPlus: 'สมัครสมาชิก Ubisoft+',
        playNowUbisoftPlus: 'เล่นเลยด้วย Ubisoft+',
        goToMyLibrary: 'ไปที่ไลบรารี',
        changePlan: 'เปลี่ยนแผน',
        upgradeNow: 'Upgrade Now',
        includedWithUbisoftPlus: 'รวมอยู่ใน Ubisoft+',
        dayOne: 'เล่นวันแรกด้วย Ubisoft+',
        service: 'บริการสมัครสมาชิก',
      },
      offerEnds: 'ข้อเสนอสิ้นสุด {{date}}',
      outOfStock: 'สินค้าหมด',
      from: 'จาก',
      preOrder: 'สั่งซื้อล่วงหน้า',
      buyNow: 'ซื้อเลย',
      getTheGame: 'รับไปเลย',
      addToCart: 'หยิบใส่ตะกร้า',
      playNow: 'เล่นเลย',
      seeMore: 'ดูเพิ่มเติม',
      month: 'เดือน',
      months: 'เดือน',
      day: 'วัน',
      days: 'วัน',
      year: 'ปี',
      years: 'ปี',
      buyGame: 'ซื้อเกม',
      free: 'ฟรี',
      freeTrial: 'ทดลองใช้ฟรี',
      tryForFree: 'ลองเล่นฟรี',
      then: 'จากนั้น',
      and: 'และ',
      durationTerm: 'สำหรับ',
      containedOffers: 'รวมอยู่ในเอดิชั่นนี้',
      gameOwned: 'คุณเป็นเจ้าของเอดิชั่นนี้',
      seeDetails: 'ดูรายละเอียด',
      hide: 'ซ่อน',
      subscribed: 'สมัครสมาชิกแล้ว',
      buyOn: { playstation: 'ซื้อบน Playstation', microsoft: 'ซื้อบน Xbox', nintendo: 'ซื้อบน Nintendo' },
      ageGateRestrictionMessage:
        'เราขออภัย แต่คุณไม่สามารถเข้าถึงคอนเทนต์นี้ได้เนื่องจากข้อจำกัดด้านอายุ โปรดตรวจสอบ <0>Privacy Policy</0> อีกครั้ง',
      monthly: 'รายเดือน',
      yearly: 'รายปี',
      earlyAccess: 'การเข้าถึงล่วงหน้า',
    },
    platforms: {
      PC: 'PC',
      PS4: 'PS4',
      PS5: 'PS5',
      Xbox1: 'Xbox One',
      XboxX: 'Xbox Series X|S',
      Android: 'Android',
      iOS: 'iOS',
      Switch: 'Switch',
      Stadia: 'Stadia',
      Luna: 'Luna',
    },
    versions: { digital: 'ดิจิทัล', physical: 'ฟิสิคัล' },
    subscription: { promoprice: { month: 'จากนั้น {{price}}/เดือน', year: 'จากนั้น {{price}}/ปี' } },
  },
};
