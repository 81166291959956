import React from 'react';
import { CloseIconWrapper } from './styles';

interface ICloseIconProps {
  fill: string;
}

const CloseIcon = ({ fill = '#959fb8' }: Partial<ICloseIconProps>) => {
  return (
    <CloseIconWrapper fill={fill}>
      <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" viewBox="0 0 500 500">
        <title>close icon</title>
        <path d="M18.262 0L0 18.262 231.738 250 0 481.738 18.262 500 250 268.262 481.738 500 500 481.738 268.262 250 500 18.262 481.738 0 250 231.738z"></path>
      </svg>
    </CloseIconWrapper>
  );
};

export default CloseIcon;
