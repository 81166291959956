import {
  IProductModel,
  ISubscription,
  IUSSSubscription,
  SubscriptionPromotion,
  SubscriptionStatus,
  TSubscriptionStatus,
} from '@pdp-rework-hub/utils-types';

export const findActiveSubscription = (userSubscriptions: IUSSSubscription[]) => {
  return userSubscriptions.find((subscription) => subscription.status === 'active');
};

export const filterActivePromotionsFromSubscriptions = (subscriptions: ISubscription[]) => {
  const promotions: SubscriptionPromotion[] = [];
  subscriptions.forEach((subscription) => {
    if (subscription.promotions.length) {
      promotions.push(...subscription.promotions);
    }
  });

  return promotions.filter((promotion) => promotion.type);
};

export const getMappedEditionFromSubscription = (subscription: ISubscription, productData: IProductModel) => {
  const activeSubOffer = productData.details.activeSubOffers?.find(
    (offer) => offer.offerId.toString() === subscription.offerId.toString()
  );

  if (!activeSubOffer) {
    return;
  }

  return productData.masters.find((master) => master.id === activeSubOffer.productId);
};

export const getPremiumSubscriptionEdition = (productData: IProductModel, subscriptions?: ISubscription[] | null) => {
  if (!subscriptions || !subscriptions.length) {
    return null;
  }

  const premiumSubscription = subscriptions[subscriptions.length - 1];
  const premiumActiveSubOffer = productData.details.activeSubOffers?.find(
    (subOffer) => subOffer.offerId.toString() === premiumSubscription.offerId.toString()
  );

  if (!premiumActiveSubOffer) {
    return null;
  }

  return productData.masters.find((master) => master.id === premiumActiveSubOffer.productId);
};

export const parseSubscriptionStatus = (status: string | null): TSubscriptionStatus => {
  if (Object.values(SubscriptionStatus).find((availableStatus) => availableStatus === status)) {
    return status as TSubscriptionStatus;
  }
  return null;
};
