import { RootState } from '../../../../../store';
import { useSelector } from 'react-redux';
import React from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';
import Container from '@mooncake/ui/lib/components/Container';
import Text from '@mooncake/ui/lib/components/Text';
import SubscriptionItem from '../SubscriptionItem';
import { hasPackshot } from '@pdp-rework-hub/utils-types';
import OffersList from './OffersList';
import { SHOP_URL } from '@pdp-rework-hub/utils-constants';
import { PlusUbisoftPlusIcon } from '@pdp-rework-hub/ui';
import { getMappedEditionFromSubscription, getMDMCustomerIdFromVariant } from '@pdp-rework-hub/utils';

interface IPartOfUbisoftPlus extends WithTranslation {
  isWide?: boolean;
}

const PartOfUbisoftPlus = ({ t, isWide }: IPartOfUbisoftPlus) => {
  const { productData, productOffers, selectedSubscription } = useSelector((state: RootState) => ({
    productData: state.products.data.product,
    productOffers: state.products.productOffers,
    selectedSubscription: state.products.selectedSubscription,
  }));

  if (!selectedSubscription || !productData) {
    return null;
  }

  const partOfUbisoftPlusGame = getMappedEditionFromSubscription(selectedSubscription, productData);

  if (!partOfUbisoftPlusGame) {
    return null;
  }

  const gameName = productData.details.productName;
  const gameEdition = partOfUbisoftPlusGame.edition;
  const isPreOrder = productData.availability.isPreOrder;
  const isDLC = productData.dlc?.isDlcType;
  let partOfUbisoftDescription = t('subscription.partOfUbisoftPlus.released', {
    game: gameName,
    edition: gameEdition,
  });
  const mdmCustomerOfferId = getMDMCustomerIdFromVariant([partOfUbisoftPlusGame]);

  const partOfUbisoftFooterContent =
    productOffers && productOffers[mdmCustomerOfferId[0]] ? (
      <Container fontSize={'0.875rem'}>
        <Text.Span>{t('subscription.partOfUbisoftPlus.includedContent')} </Text.Span>
        <OffersList productId={mdmCustomerOfferId[0]} />
      </Container>
    ) : null;

  if (isPreOrder && productData.details.productReleaseDateTime) {
    const releaseDate = new Date(productData.details.productReleaseDateTime).toLocaleDateString();
    partOfUbisoftDescription = t('subscription.partOfUbisoftPlus.preOrder', {
      game: gameName,
      edition: gameEdition,
      releaseDate,
    });
  } else if (isDLC) {
    partOfUbisoftDescription = t('subscription.partOfUbisoftPlus.dlc', {
      game: gameName,
      dlc: partOfUbisoftPlusGame.name,
    });
  }

  const packshotImage = hasPackshot(partOfUbisoftPlusGame.media)
    ? SHOP_URL + partOfUbisoftPlusGame.media.edition_packshot[0].url
    : undefined;

  return (
    <>
      <SubscriptionItem
        type={'hundredPlusGames'}
        title={gameName || ''}
        edition={partOfUbisoftPlusGame.edition}
        description={partOfUbisoftDescription}
        trackingAction={''}
        image={packshotImage}
        footerContent={partOfUbisoftFooterContent}
        isWide={isWide}
      />
      <Container margin={'0.625rem'}>
        <PlusUbisoftPlusIcon />
      </Container>
    </>
  );
};

export default withTranslation()(PartOfUbisoftPlus);
