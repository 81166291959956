export default {
  translations: {
    title: {
      classics:
        'Inscreva-se para jogar <span class="blue-variant">{{gameName}}</span> e uma lista em crescimento de jogos icônicos da Ubisoft!',
      premium:
        'Faça sua assinatura para jogar <span class="blue-variant">{{gameName}}</span> e mais de 100 jogos para PC',
    },
    cancelAnytime: 'Cancele a qualquer momento',
    learnMore: 'Saiba mais sobre o Ubisoft+',
    classics: 'CLASSICS',
    premium: 'PREMIUM',
  },
};
