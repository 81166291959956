export default {
  translations: {
    title: {
      classics:
        '구독하고 <span class="blue-variant">{{gameName}}</span> 외에도 계속 추가되는 유비소프트의 상징적인 게임 모음집을 즐겨 보세요!',
      premium: '구독하고 <span class="blue-variant">{{gameName}}</span> 및 100개 이상의 PC 게임을 즐겨보세요',
    },
    cancelAnytime: '언제든 취소하세요',
    learnMore: 'Ubisoft+에 대해 더 알아보기',
    classics: 'CLASSICS',
    premium: 'PREMIUM',
  },
};
