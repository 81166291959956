import workspaceTranslations from '@pdp-rework-hub/i18n';
import { I18nConfig } from '@mooncake/core';
import customTranslations from '../languages';
import merge from 'lodash/merge';

const translations = merge({}, workspaceTranslations, customTranslations);

const i18nConfig = new I18nConfig(translations);

export const availableLanguages = i18nConfig.getAvailableLanguages();
const i18n = i18nConfig.init();

export default i18n;
