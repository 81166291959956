export default {
  translations: {
    title: '比較 {{gameName}} 版本',
    subscription: {
      title: '內含於 Ubisoft+<span class="blue">+</span>',
      classics: { title: 'Classics 游戏系列', subtitle: '体验 Ubisoft 标志性游戏' },
      premium: { title: '超過 100 款 PC 遊戲', subtitle: '包含最新作品' },
    },
    subscriptionModal: {
      classics: {
        title: 'Ubisoft<span class="blue">+</span> Classics',
        items: [
          { description: '透過 Ubisoft Connect 遊玩一系列持續增加的經典遊戲', icon: 'check' },
          { description: '遊玩標準版', icon: 'check' },
          { description: '擴充內容與遊戲享八折優惠', icon: 'check' },
        ],
      },
      premium: {
        title: '什麼是 Ubisoft<span class="blue">+</span>?',
        items: [
          {
            title: '新作品和超過 100 款 PC 遊戲',
            description: '透過雲端服務遊玩精選遊戲以及在 PC 上暢玩超過 100 款遊戲',
            icon: 'games',
          },
          { title: '高級版本', description: '享用擴充內容、季票與更多精彩內容', icon: 'diamond' },
          { title: '每月獎勵', description: '每個月可獲得全新自訂物品、加強物和遊戲內獎勵', icon: 'reward' },
          { title: '可隨時取消及續訂', description: '無論何時都可以輕鬆續訂', icon: 'replay' },
        ],
      },
      learnMoreAboutUbisoftPlus: '深入瞭解 Ubisoft+',
    },
  },
};
