export default {
  translations: {
    title: 'Porównaj edycje {{gameName}}',
    subscription: {
      title: 'Dostępne w Ubisoft<span class="blue">+</span>.',
      classics: { title: 'Kolekcja Classics', subtitle: 'Ciesz się najbardziej znanymi tytułami Ubisoft' },
      premium: { title: 'Ponad 100 gier na PC', subtitle: 'Zawiera premierowe gry' },
    },
    subscriptionModal: {
      classics: {
        title: 'Ubisoft<span class="blue">+</span> Classics',
        items: [
          { description: 'Graj w ciągle zmieniającą się kolekcję kultowych gier z Ubisoft Connect', icon: 'check' },
          { description: 'Dostęp do edycji standardowych', icon: 'check' },
          { description: '20% rabatu na gry i DLC', icon: 'check' },
        ],
      },
      premium: {
        title: 'Czym jest Ubisoft<span class="blue">+</span>?',
        items: [
          {
            title: 'Nowości i katalog ponad 100 gier na PC',
            description: 'Graj w chmurze w wybrane gry i uzyskaj dostęp do ponad 100 tytułów na PC',
            icon: 'games',
          },
          {
            title: 'Edycje Premium',
            description: 'Uzyskaj dostęp do dodatków, przepustek sezonowych i dodatkowej zawartości',
            icon: 'diamond',
          },
          {
            title: 'Comiesięczne nagrody',
            description: 'Co miesiąc odbieraj nowe kosmetyczne przedmioty, doładowania i inne bonusy w grach',
            icon: 'reward',
          },
          {
            title: 'Łatwa rezygnacja i powrót do usługi w dowolnym momencie',
            description: 'Możesz łatwo ponownie dołączyć do subskrypcji kiedy tylko zechcesz',
            icon: 'replay',
          },
        ],
      },
      learnMoreAboutUbisoftPlus: 'Dowiedz się więcej o Ubisoft+',
    },
  },
};
