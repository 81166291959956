import breakpoints from '../../../../themes/utils/breakpoints';
import styled from 'styled-components';

export const TileAccordionWrapper = styled.div`
  position: relative;
  z-index: 2;
  width: 100%;
  height: auto;
  background: ${({ theme }) => theme.colors.BlackPearl};
  border-radius: 0 0 4px 4px;

  @media screen and (min-width: ${breakpoints.minTablet}) {
    display: none;
  }
`;

export const TileAccordionHeader = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const SeeDetailsButton = styled.button`
  padding: 1rem 0;
  appearance: none;
  background: transparent;
  border-color: transparent;
  color: ${({ theme }) => theme.colors.White};
  text-decoration: underline;
`;
