import { IBreakpoints } from '@pdp-rework-hub/utils-types';
import { ButtonTypes } from '@pdp-rework-hub/ui';

export interface ICTA {
  title: string;
  link: string;
  type?: ButtonTypes;
  tracking?: string;
  target?: '_blank' | '_self' | '_parent' | '_top' | '';
}

export interface ICustomAppProps {
  alignment?: 'left' | 'center' | 'right';
  textcolor?: string;
  textalign?: 'left' | 'center' | 'right';
  imageposition?: 'left' | 'right';
  split?: boolean;
  titlecontent?: string;
  text?: string;
  cta?: ICTA;
  productid?: string;
  categoryid?: string;
  backgroundcolor?: string;
  background?: IBreakpoints;
  image?: IBreakpoints;
  imagealttext?: string;
  nominheight?: boolean;
}

export const CustomAppDefaultProps = {
  alignment: 'center',
  imageposition: 'left',
  split: false,
};

export const CustomAppEventsConfigs = {};
