export default {
  translations: {
    title: {
      classics:
        '登録して<span class="blue-variant">{{gameName}}</span>やラインナップを拡大していくUbisoftの人気ゲームをプレイしよう！',
      premium: 'サブスクリプションで <span class="blue-variant">{{gameName}}</span> と100以上のPCゲームをプレイ',
    },
    cancelAnytime: 'いつでも解約可能',
    learnMore: 'Ubisoft+の詳細はこちら',
    classics: 'CLASSICS',
    premium: 'PREMIUM',
  },
};
