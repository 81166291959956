import React from 'react';
import { useSelector } from 'react-redux';
import { SELECTOR_OPTION_PRODUCT } from '@pdp-rework-hub/utils-constants';
import { RootState } from '../../../../store';

import BuyCartAction from './Buy';
import SubCartAction from './Subscription';

const CartAction = () => {
  const { selectedOption } = useSelector((state: RootState) => ({
    selectedOption: state.products.selectedOption,
  }));

  return selectedOption === SELECTOR_OPTION_PRODUCT ? <BuyCartAction /> : <SubCartAction />;
};

export default CartAction;
