import React, { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { WithTranslation, withTranslation } from 'react-i18next';
import { useTheme } from '@mooncake/ui';
import Container from '@mooncake/ui/lib/components/Container';
import Text from '@mooncake/ui/lib/components/Text';
import { fetchProductData } from '@pdp-rework-hub/slices';

import { ICustomAppProps } from '../../AppProps';
import { BreakpointsBackgroundImages, GlobalStyle, withLazyMotion, AppLoader } from '@pdp-rework-hub/ui';
import EditionsList from '../Editions/EditionsList';
import { RootState, useAppDispatch } from '../../store';
import {
  useCheckUserRestrictions,
  useModelsDependencies,
  useUbiConnectFetched,
  watchLocationPosition,
} from '@pdp-rework-hub/utils';

type IContentProps = ICustomAppProps & WithTranslation;

const Content = ({ backgroundimage, color, t }: IContentProps) => {
  const { productLoaded, productData } = useSelector((state: RootState) => ({
    productLoaded: state.products.loaded,
    productData: state.products.data.product,
  }));
  const dispatch = useAppDispatch();
  const [locationPosition, setLocationPosition] = useState<string | null>(null);
  const appRef = useRef<HTMLElement>(null);

  useModelsDependencies();

  useUbiConnectFetched();
  useCheckUserRestrictions();

  useEffect(() => {
    if (appRef.current) {
      // For tracking purposes, we have to get the position of the block based on an attribute of the parent of the MFE
      watchLocationPosition(appRef).then((value) => {
        setLocationPosition(value);
      });
    }
  }, [appRef]);

  useEffect(() => {
    dispatch(fetchProductData());
  }, [fetchProductData]);

  const theme = useTheme();

  const productName = productData?.details?.productName;
  const sectionTitle = productName && t('title', { gameName: productName });
  const backgroundColor = theme.colors.background.secondary;
  const textColor = theme.colors.text.primary;
  return (
    <Container ref={appRef} backgroundColor={theme.colors.background.primary}>
      <GlobalStyle />
      {!productLoaded || productData?.masters ? (
        <BreakpointsBackgroundImages source={backgroundimage}>
          <Container
            minHeight={'100%'}
            padding={{
              default: '1.875rem 1.875rem 3.75rem',
              tabletLarge: '3.125rem 3.125rem 5.625rem',
            }}
            color={textColor}
          >
            {!productLoaded ? (
              <Container padding={'1rem'}>
                <AppLoader />
              </Container>
            ) : productData?.masters ? (
              <>
                <Text.Heading
                  as="h3"
                  fontSize={{
                    default: '1.43rem',
                    tablet: '2rem',
                    tabletLarge: '2.5rem',
                  }}
                  textAlign={'center'}
                  marginBottom={'1.25rem'}
                  color={color || textColor}
                  fontFamily={`Custom Font, ${theme.fonts.bold}`}
                >
                  {sectionTitle}
                </Text.Heading>
                <EditionsList
                  masters={productData?.masters}
                  textColor={textColor}
                  backgroundColor={backgroundColor}
                  position={locationPosition}
                />
              </>
            ) : null}
          </Container>
        </BreakpointsBackgroundImages>
      ) : null}
    </Container>
  );
};

export default withTranslation()(withLazyMotion(Content));
