const watchLocationPosition = (appRef: React.RefObject<HTMLElement>) => {
  return new Promise<string>((resolve) => {
    const rootNode = appRef.current?.getRootNode() as ShadowRoot;
    const parentNode = rootNode?.host?.closest('.experience-component');

    if (parentNode) {
      if (parentNode.getAttribute('data-order')) {
        resolve(parentNode.getAttribute('data-order') as string);
      }
      const observer = new MutationObserver((mutationList) => {
        const node = mutationList[0].target as HTMLElement;
        const dataOrder = node.getAttribute('data-order');
        if (dataOrder) {
          resolve(dataOrder);
        }
      });
      observer.observe(parentNode, {
        attributes: true,
        attributeFilter: ['data-order'],
        attributeOldValue: false,
        subtree: false,
      });
    }
  });
};

export default watchLocationPosition;
