import React from 'react';
import Container from '@mooncake/ui/lib/components/Container';
import { useSelector } from 'react-redux';
import { RootState } from '../../../store';
import { IMaster } from '@pdp-rework-hub/utils-types';
import { ICustomAppProps } from '../../../AppProps';

import EditionDetails from '../EditionDetails';
import { OfferModal } from '@pdp-rework-hub/ui';
import { getPremiumSubscriptionEdition } from '@pdp-rework-hub/utils';
import { SUBSCRIPTION_PLAN_RECURRENCE } from '@pdp-rework-hub/utils-constants';

interface IEditionsListProps extends ICustomAppProps {
  masters: IMaster[];
  position: string | null;
  textColor: string;
  backgroundColor: string;
}

const EditionsList = ({ backgroundColor, masters, position, textColor }: IEditionsListProps) => {
  const { selectedOffer, productData, availableSortedSubscriptions } = useSelector((state: RootState) => ({
    selectedOffer: state.products.selectedOffer,
    productData: state.products.data.product,
    availableSortedSubscriptions: state.products.availableSortedSubscriptions,
  }));

  if (!productData) {
    return null;
  }

  const availableSortedMonthlySubscriptions =
    availableSortedSubscriptions?.filter(
      (subscription) => subscription.productSubscriptionType === SUBSCRIPTION_PLAN_RECURRENCE.MONTHLY
    ) || null;

  const premiumSubEdition = getPremiumSubscriptionEdition(productData, availableSortedMonthlySubscriptions);

  return (
    <Container
      minHeight="100%"
      display={'flex'}
      flexWrap={'wrap'}
      flexDirection={{
        default: 'column',
        tabletLarge: 'row',
      }}
      alignItems={{
        default: 'center',
        tabletLarge: 'stretch',
      }}
      justifyContent={{
        tabletLarge: 'center',
      }}
    >
      {masters.map((master, index) => (
        <EditionDetails
          key={`product-comparison-table-${master.id}`}
          product={master}
          index={index}
          backgroundColor={backgroundColor}
          textColor={textColor}
          position={position}
          isSubscription={false}
        />
      ))}
      {premiumSubEdition && (
        <EditionDetails
          product={premiumSubEdition}
          index={masters.length}
          backgroundColor={backgroundColor}
          textColor={textColor}
          position={position}
          isSubscription
        />
      )}
      {selectedOffer && (
        <OfferModal
          backgroundColor={backgroundColor}
          textColor={textColor}
          offer={selectedOffer}
          locationPosition={'pop in'}
        />
      )}
    </Container>
  );
};

export default EditionsList;
