export default {
  translations: {
    global: {
      subscription: {
        subscribeNow: 'Оформить подписку',
        reactivate: 'ОБНОВИТЬ ПОДПИСКУ',
        resubscribe: 'Возобновить подписку',
        subscribeUbisoftPlus: 'Оформить подписку Ubisoft+',
        playNowUbisoftPlus: 'Играйте с Ubisoft+',
        goToMyLibrary: 'Перейти в библиотеку',
        changePlan: 'Изменить тариф',
        upgradeNow: 'Upgrade Now',
        includedWithUbisoftPlus: 'Доступно по подписке Ubisoft+',
        dayOne: 'Игра с 1 дня с Ubisoft+',
        service: 'Сервис, доступный по подписке',
      },
      offerEnds: 'Акция завершится {{date}}',
      outOfStock: 'Нет в наличии',
      from: 'От',
      preOrder: 'ПРЕДЗАКАЗ',
      buyNow: 'КУПИТЬ',
      getTheGame: 'ПОЛУЧИТЬ',
      addToCart: 'Добавить в корзину',
      playNow: 'Играть',
      seeMore: 'Узнать больше',
      month: 'месяц',
      months: 'мес.',
      day: 'день',
      days: 'д.',
      year: 'год',
      years: 'годы',
      buyGame: 'Купить игру',
      free: 'бесплатно',
      freeTrial: 'Бесплатный пробный период',
      tryForFree: 'Попробовать бесплатно',
      then: 'далее',
      and: 'или',
      durationTerm: 'за',
      containedOffers: 'входит в это издание',
      gameOwned: 'У вас есть это издание',
      seeDetails: 'подробности',
      hide: 'скрыть',
      subscribed: 'Подписан',
      buyOn: { playstation: 'Купить на Playstation', microsoft: 'Купить на Xbox', nintendo: 'Купить на Nintendo' },
      ageGateRestrictionMessage:
        'К сожалению, вы не можете воспользоваться данным контентом из-за возрастных ограничений. Ознакомьтесь с <0>Положением об использовании персональных данных</0>',
      monthly: 'Ежемесячно',
      yearly: 'Раз в год',
      earlyAccess: 'Ранний доступ',
    },
    platforms: {
      PC: 'PC',
      PS4: 'PS4',
      PS5: 'PS5',
      Xbox1: 'Xbox One',
      XboxX: 'Xbox Series X|S',
      Android: 'Android',
      iOS: 'iOS',
      Switch: 'Switch',
      Stadia: 'Stadia',
      Luna: 'Luna',
    },
    versions: { digital: 'Цифровая версия', physical: 'Физическая версия' },
    subscription: { promoprice: { month: 'далее {{price}} в месяц', year: 'далее {{price}} в год' } },
  },
};
