import styled, { css } from 'styled-components';

type IEditionHeaderWrapperProps = {
  isHorizontal: boolean;
  isSubscription?: boolean;
};

type IsHorizontalProp = {
  isHorizontal: boolean;
};

type IsSubscriptionProp = {
  isSubscription?: boolean;
};

export const EditionHeaderImage = styled.div<IsSubscriptionProp>`
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  z-index: 2;
  height: ${({ isSubscription }) => (isSubscription ? '75%' : '100%')};
  margin-bottom: auto;
  padding: 0.625rem;

  img {
    box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.4);
  }
`;

export const EditionHeaderWrapper = styled.div<IEditionHeaderWrapperProps>`
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  ${({ isHorizontal, isSubscription }) =>
    !isHorizontal &&
    css`
      &::after {
        content: '';
        position: absolute;
        width: 100%;
        height: 100%;
        background: ${isSubscription ? '' : 'rgba(0, 0, 0, 0.7)'};
        top: 0;
        left: 0;
        z-index: 1;
        backdrop-filter: ${isSubscription ? '' : 'blur(4px)' };
      }
    `}

    ${({ isHorizontal, isSubscription, theme }) =>
      !isHorizontal && !isSubscription &&
      css`
        &::after {
          background: ${theme.name === 'Uplay'
            ? 'linear-gradient(180deg, rgba(0, 0, 0, 0.85) 0%, rgba(0, 0, 0, 0.95) 100%)'
            : 'radial-gradient(circle, rgb(199 199 199) 0%, rgb(255 255 255 / 80%) 100%)'};
        }
      `}


  ${EditionHeaderImage} {
    img {
      height: 100%;
      border-radius: 4px;
      border: 1px solid ${({ theme }) => theme.colors.MineShaft};
      box-shadow: 0px 4px 8px 3px rgba(0, 0, 0, 0.25);
    }
  }
`;

export const HeaderBackgroundImage = styled.div<IsHorizontalProp>`
  width: 100%;
  height: 100%;
  background-color: ${({ theme }) => theme.colors.CodGray};
  overflow: hidden;
  z-index: 1;

  ${({ isHorizontal }) =>
    !isHorizontal
      ? css`
          position: absolute;
          top: 0;
          left: 0;
        `
      : css`
          position: relative;
        `};
  img {
    position: absolute;
    left: 0;
    width: 100%;
    height: 100%;
    top: 50%;
    transform: translateY(-50%);
  }
`;
