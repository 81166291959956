export default {
  translations: {
    selectYour: {
      edition: 'Выберите издание',
      version: 'Выберите версию',
      platform: 'Укажите платформу',
      payment: 'Выберите условия оплаты',
    },
    selectPlatform: 'Выбрать платформу',
    buySelector: { subscription: { subscriptionSelection: 'Выбор подписки:' } },
    subscription: {
      title: '{{gameName}} и многое другое с Ubisoft<span class="blue">+</span>',
      alreadySubscribed: 'Вы уже оформили подписку Ubisoft+!',
      learnMoreAbout: 'Узнайте больше о <0>Ubisoft+</0>',
      cancelNotice:
        'Вы можете в любой момент отменять и возобновлять подписку. Ваши сохранения не пострадают. <0>Раздел FAQ</0>',
      gamesSelection: {
        classics: {
          title: 'Легендарные игры Ubisoft',
          description:
            "Насладитесь коллекцией легендарных игр, включающей Assassin's Creed Вальгалла, Rainbow Six Осада и многое другое.",
          link: { notSubscriber: 'просмотреть полный список игр', subscriber: 'Просмотреть игры' },
        },
        premium: {
          title: '100+ игр',
          description:
            "Играйте во все части Far Cry, а также в игры из постоянно пополняемого каталога, в том числе Assassin's Creed Вальгалла, Rainbow Six Осада и многие другие.",
          link: { notSubscriber: 'Полный список игр', subscriber: 'Просмотреть игры' },
        },
      },
      cards: {
        cloudGaming: {
          title: 'Облачные игры',
          description:
            'Играйте с использованием смартфона, планшета или телевизора в любом месте с помощью {{platforms}}. Требуется стабильное интернет-соединение.',
          link: 'Список облачных игр',
        },
      },
      partOfUbisoftPlus: {
        dlc: 'Доступно в комплекте с игрой: {{dlc}}.',
        preOrder: 'Играйте в {{game}} сразу после выхода ({{releaseDate}}).',
        includedContent: 'Доступный контент:',
      },
      firstParty: { legalLine: 'Доступность и актуальные цены проверяйте в розничных магазинах.' },
      learnMore: 'Узнать больше',
      playThisGame: 'Play this game in your <span class="blue">Library+</span>!',
    },
  },
};
