import { ImageContainer } from './styles';

interface ITileTitleBrush {
  color: string;
}

const TileTitleBrush = ({ color }: ITileTitleBrush) => (
  <ImageContainer width="219" height="53" viewBox="0 0 219 53" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M0 52.6207V5.01684H12.8056C25.1091 5.81614 28.9786 6.93515 28.9786 5.01684C28.9786 3.09854 30.4851 1.28681 31.2384 0.620728H53.0833C58.8082 0.620728 70.2831 1.95288 75.3049 2.61896L219 5.01684V52.6207H0Z"
      fill={color}
    />
  </ImageContainer>
);

export default TileTitleBrush;
