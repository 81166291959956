import styled from 'styled-components';

export const Image = styled.img`
  display: block;
  max-width: 100%;
  height: 6.25rem;

  ${({ theme }) => theme.breakpoints.tablet.min.css`
    height: auto;
  `}
`;

export const TextContainer = styled.div`
  p:last-child {
    margin-bottom: 0;
  }
  p:first-child {
    margin-top: 0;
  }
`;

export const Heading = styled.div`
  &,
  h1,
  h2,
  h3,
  p {
    margin-top: 0;
    margin-bottom: 1rem;
    color: ${({ theme }) => theme.colors.Black};
    font-size: 1.25rem;
    font-family: ${({ theme }) => theme.fonts.bold};

    ${({ theme }) => theme.breakpoints.desktop.min.css`
      font-size: 1.875rem;
    `}
  }
`;
