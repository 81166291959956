import React from "react";

interface IGooglePlayIconProps {
  width?: number;
  height?: number;
  fill?: string;
}

const GooglePlayIcon = ({width = 42, height = 49, fill = "#fff"}: IGooglePlayIconProps) => (
<svg width={width} height={height} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 89.61 96" fill="none">
    <path fill={fill} d="M83.6,36.49L22.4,1.99s-.02,0-.02-.01c-.16-.09-.31-.18-.47-.26-.16-.08-.31-.16-.47-.24-.11-.05-.21-.1-.32-.15C19.24.48,17.16,0,14.97,0,6.74,0,.06,6.66.06,14.89l-.03,33.09L0,81.08c0,8.23,6.66,14.91,14.89,14.92,2.19,0,4.26-.47,6.14-1.31.11-.05.22-.1.33-.15.16-.08.32-.16.47-.24.16-.08.31-.17.47-.26,0,0,.01,0,.02,0l61.26-34.41s6.02-3.34,6.02-11.56c0-8.22-6-11.57-6-11.57ZM14.96,8.92c.86,0,1.67.18,2.44.52.03.02.07.03.11.05.08.04.16.08.24.12.06.03.13.07.19.1l.18.1.05.03,36.84,20.77-11.31,11.3-.56-.56L11.39,10.1c1.03-.77,2.27-1.18,3.57-1.18ZM8.94,48l.02-27.83,28.32,28.14-28.37,28.37.03-28.69ZM18.13,86.16l-.05.03-.2.11c-.06.04-.13.07-.19.1-.08.04-.16.08-.24.12-.03.02-.07.03-.1.05-.77.34-1.59.52-2.44.52-1.46,0-2.8-.53-3.84-1.4l31.8-31.79,11.75,11.77-36.48,20.49ZM79.02,51.96l-16.2,9.1-13.55-13.57,12.88-12.86,16.87,9.51s.01,0,.02.01c.12.09.37.3.63.63.69.86,1.03,1.93,1.03,3.27,0,2.44-1.2,3.56-1.67,3.9Z"/>
</svg>
);

export default GooglePlayIcon;
