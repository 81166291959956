import { IBreakpoints } from '@pdp-rework-hub/utils-types';

export interface ICustomAppProps {
  color?: string;
  backgroundimage?: IBreakpoints;
}

export const CustomAppDefaultProps = {};

export const CustomAppEventsConfigs = {};
