import { RootState } from '../../../../store';
import { useSelector } from 'react-redux';
import React from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';
import { WEBDAV_URL } from '@pdp-rework-hub/utils-constants';
import SubscriptionItem from './SubscriptionItem';

interface IIncludedGamesDetails extends WithTranslation {
  type: 'classics' | 'premium';
}

const IncludedGamesDetails = ({ t, type }: IIncludedGamesDetails) => {
  const { productData, activeSubscription } = useSelector((state: RootState) => ({
    productData: state.products.data.product,
    activeSubscription: state.user.activeSubscription,
  }));
  const gameName = productData?.details.productName;

  const hundredPlusGamesLink = {
    title: t(`subscription.gamesSelection.${type}.link.${activeSubscription ? 'subscriber' : 'notSubscriber'}`),
    url: `/ubisoftplus/games?offer=${type}`,
  };

  return (
    <SubscriptionItem
      type={'hundredPlusGames'}
      title={t(`subscription.gamesSelection.${type}.title`)}
      edition={'PC'}
      description={t(`subscription.gamesSelection.${type}.description`, {
        gameName,
      })}
      link={hundredPlusGamesLink}
      trackingAction={'ubisoft plus all games : see products list'}
      image={`${WEBDAV_URL}/Library-Sites-shared-library-web/default/pdp-rework-hub/assets/${
        type === 'classics' ? 'classicsCollection' : 'gamelist'
      }.png`}
    />
  );
};

export default withTranslation()(IncludedGamesDetails);
