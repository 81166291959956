import styled, { css } from 'styled-components';

interface ICustomScrollBarProperties {
  background: string;
  innerColor: string;
  width: number;
}

export const customScrollBar = ({ background, innerColor, width }: ICustomScrollBarProperties) => {
  return css`
    ::-webkit-scrollbar {
      width: ${width + 'px'};
    }

    /* Track */

    ::-webkit-scrollbar-track {
      background: ${background};
    }

    /* Handle */

    ::-webkit-scrollbar-thumb {
      background: ${innerColor};
      border-radius: 20px;
    }

    /* Handle on hover */

    ::-webkit-scrollbar-thumb:hover {
      opacity: 0.8;
    }
  `;
};

interface IScrollableSection {
  expand?: boolean;
}

export const ScrollableSection = styled.div<IScrollableSection>`
  width: ${({ expand }) => (expand ? '100%' : 'auto')};
  overflow-y: auto;
  scroll-behavior: smooth;
  flex: 1;

  ${({ theme }) =>
    customScrollBar({
      background: theme.colors.Silver,
      innerColor: theme.colors.DoveGray,
      width: 10,
    })}
`;
