import storeTheme from './store';
import uplayTheme from './uplay';
import { Themes, ObjectValuesType } from '@pdp-rework-hub/utils-types';

const themes = {
  store: storeTheme,
  uplay: uplayTheme,
};

export type OverrideTheme = ObjectValuesType<Partial<typeof themes>>;

const getAppTheme = (theme: Themes): OverrideTheme => {
  if (!themes[theme]) {
    return themes.store;
  }

  return themes[theme];
};

export default getAppTheme;
