export default {
  translations: {
    title: 'Vergelijk {{gameName}} Edities',
    subscription: {
      title: 'Inbegrepen in Ubisoft<span class="blue">+</span>',
      classics: { title: 'Classics-collectie', subtitle: 'Geniet van iconische Ubisoft-titels' },
      premium: { title: '100+ PC-games', subtitle: 'Inclusief nieuwe release' },
    },
    subscriptionModal: {
      classics: {
        title: 'Ubisoft<span class="blue">+</span> Classics',
        items: [
          { description: 'Speel een steeds wisselend aanbod van iconische games met Ubisoft Connect', icon: 'check' },
          { description: 'Toegang tot standaard edities', icon: 'check' },
          { description: '20% korting op DLC en games', icon: 'check' },
        ],
      },
      premium: {
        title: 'Wat is Ubisoft<span class="blue">+</span>?',
        items: [
          {
            title: 'Nieuwe releases en 100+ PC-games',
            description: 'Speel een selectie aan games vanuit de cloud en een uitgebreide collectie van 100+ PC-games',
            icon: 'games',
          },
          {
            title: 'Premium editions',
            description: 'Toegang tot uitbreidingen, season passes en meer content',
            icon: 'diamond',
          },
          {
            title: 'Maandelijks beloningen',
            description: 'Krijg elke maand nieuwe aanpassingsitems, boosters en in-game beloningen',
            icon: 'reward',
          },
          {
            title: 'Elk moment opzegbaar en kom terug wanneer je wil',
            description: 'Herstart je abonnement op elk gewenst moment',
            icon: 'replay',
          },
        ],
      },
      learnMoreAboutUbisoftPlus: 'Meer info over Ubisoft+',
    },
  },
};
