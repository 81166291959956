// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import colors from '@mooncake/ui/lib/themes/colors';
import globalColors from '../colors';

export default {
  text: {
    primary: colors.Black,
    secondary: colors.White,
  },
  price: {
    previous: globalColors.DoveGray,
    discounted: colors.FlushOrange,
  },
};
