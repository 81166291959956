import PCIcon from './PCIcon';
import PS4Icon from './PS4Icon';
import PS5Icon from './PS5Icon';
import XboxIcon from './XboxIcon';
import XboxOneIcon from './XboxOneIcon';
import XboxxIcon from './XboxxIcon';
import { platformKeys } from '@pdp-rework-hub/utils-constants';
import PSIcon from './PSIcon';
import SwitchIcon from './SwitchIcon';
import AppStoreIcon from './AppStoreIcon';
import GooglePlayIcon from './GooglePlayIcon';

export default function getPlatformIcon(key: string) {
  switch (key) {
    case platformKeys.PC_KEY:
      return <PCIcon />;

    case platformKeys.PS4_KEY:
      return <PS4Icon />;

    case platformKeys.PS5_KEY:
      return <PS5Icon />;

    case platformKeys.PS_KEY:
      return <PSIcon />;

    case platformKeys.XBOX_KEY:
      return <XboxIcon />;

    case platformKeys.XBOXX_KEY:
      return <XboxxIcon />;

    case platformKeys.XBOX1_KEY:
      return <XboxOneIcon />;

    case platformKeys.SWITCH_KEY:
      return <SwitchIcon />;

    case platformKeys.APP_STORE_KEY:
      return <AppStoreIcon />;

    case platformKeys.GOOGLE_PLAY_KEY:
      return <GooglePlayIcon />;

    default:
      return null;
  }
}
