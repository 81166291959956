import styled from 'styled-components';

export const StyledLink = styled.a`
  font-family: unset;
  font-weight: unset;
  text-decoration: underline;
  font-size: unset;
  color: ${({ theme }) => theme.colors.text.primary};

  &:hover {
    color: ${({ theme }) => theme.colors.text.primary};
  }
`;

export const SubscriptionPriceContainer = styled.div`
  font-size: 1rem;
  margin-bottom: 1rem;

  ${({ theme }) => theme.breakpoints.desktop.min.css`
    font-size: 1.375rem;
  `}
`;
