import React from 'react';
import { LoaderContainer, LoaderInner, StyledDot } from './styles';

const AppLoader = () => (
  <LoaderContainer>
    <LoaderInner>
      <StyledDot index={1} />
      <StyledDot index={2} />
      <StyledDot index={3} />
    </LoaderInner>
  </LoaderContainer>
);

export default AppLoader;
