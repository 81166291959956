export default {
  translations: {
    selectYour: {
      edition: 'Selecione sua edição',
      version: 'Selecione sua versão',
      platform: 'Selecione sua plataforma',
      payment: 'Selecionar opção de pagamento',
    },
    selectPlatform: 'Selecionar plataforma',
    releaseDate: 'Data de lançamento:',
    buySelector: { subscription: { subscriptionSelection: 'Seleção da assinatura:' } },
    subscription: {
      title: '{{gameName}} e muito mais com Ubisoft<span class="blue">+</span>',
      alreadySubscribed: 'Você já é assinante do Ubisoft+!',
      learnMoreAbout: 'Saiba mais sobre o <0>Ubisoft+</0>',
      cancelNotice: 'Cancele e volte a qualquer momento. <0>Ver perguntas frequentes</0>',
      gamesSelection: {
        classics: {
          title: 'Jogos icônicos Ubisoft',
          description:
            "Aproveite uma variedade de títulos icônicos, incluinco Assassin's Creed Valhalla, Rainbow Six Siege e muito mais",
          link: { notSubscriber: 'Ver jogos inclusos', subscriber: 'Procure jogos' },
        },
        premium: {
          title: '+ de 100 jogos',
          description:
            "Jogue todos os títulos de Far Cry agora e desfrute de um catálogo de jogos cada vez maior, incluindo Assassin's Creed Valhalla, Rainbow Six Siege e muito mais.",
          link: { notSubscriber: 'Veja todos os jogos inclusos', subscriber: 'Procure jogos' },
        },
      },
      cards: {
        cloudGaming: {
          title: 'Jogos na Nuvem',
          description:
            'Jogue instantaneamente em seu smartphone, tablet, TV ou em qualquer lugar que você quiser via {{platforms}} usando uma boa conexão.',
          link: 'Ver jogos na nuvem',
        },
        consoles: {
          title: 'Consoles',
          description: 'Jogue uma seleção de jogos de seus consoles {{platforms}} com nossa assinatura de jogos.',
          link: 'Veja os jogos de console inclusos',
        },
      },
      partOfUbisoftPlus: {
        released: 'Jogue {{game}}  {{edition}} com Ubisoft+',
        dlc: 'Jogue {{dlc}}, incluso em {{game}}.',
        preOrder: 'Jogue {{game}} no lançamento em {{releaseDate}}.',
        includedContent: 'Conteúdo incluso:',
      },
      firstParty: {
        legalLine:
          'Os preços listados são apenas das versões para PC.<br/>Confira os preços para consoles nas lojas das plataformas de terceiros.',
        pricingInfo: 'Confira o preço na loja da plataforma',
      },
      learnMore: 'Saiba mais',
      playThisGame: 'Play this game in your <span class="blue">Library+</span>!',
    },
  },
};
