export default {
  translations: {
    title: {
      classics:
        'สมัครสมาชิกเพื่อเล่น <span class="blue-variant">{{gameName}}</span> และเกม Ubisoft อันโด่งดังที่คัดสรรมาให้อย่างต่อเนื่อง',
      premium: 'สมัครสมาชิกเพื่อเล่น <span class="blue-variant">{{gameName}}</span> และเกม PC มากกว่า 100 เกม',
    },
    cancelAnytime: 'ยกเลิกได้ตลอดเวลา',
    learnMore: 'รู้จัก Ubisoft+ ให้มากขึ้น',
    classics: 'CLASSICS',
    premium: 'PREMIUM',
  },
};
