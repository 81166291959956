export default {
  translations: {
    global: {
      subscription: {
        subscribeNow: 'JETZT ABONNIEREN',
        reactivate: 'ABO ERNEUERN',
        resubscribe: 'Erneut Abonnieren',
        subscribeUbisoftPlus: 'Ubisoft+ abonnieren',
        playNowUbisoftPlus: 'Spiele jetzt mit Ubisoft+',
        goToMyLibrary: 'Zur Bibliothek gehen',
        changePlan: 'Modell ändern',
        upgradeNow: 'Upgrade Now',
        includedWithUbisoftPlus: 'Enthalten in Ubisoft+',
        dayOne: 'Day 1 mit Ubisoft+',
        service: 'Abo-Service',
      },
      offerEnds: 'Das Angebot endet am {{date}}',
      outOfStock: 'Nicht mehr verfügbar',
      from: 'ab',
      preOrder: 'VORBESTELLUNG',
      buyNow: 'JETZT KAUFEN',
      getTheGame: 'DAS SPIEL HOLEN',
      addToCart: 'In den warenkorb',
      playNow: 'Jetzt spielen',
      seeMore: 'Mehr anzeigen',
      month: 'Monat',
      months: 'Monate',
      day: 'Tag',
      days: 'Tage',
      year: 'Jahr',
      years: 'Jahre',
      buyGame: 'Das Spiel kaufen',
      free: 'kostenlos',
      freeTrial: 'Kostenloser Probezeitraum',
      tryForFree: 'Kostenlos ausprobieren',
      then: 'dann',
      and: 'und',
      durationTerm: 'für',
      containedOffers: 'Artikel in dieser Edition enthalten',
      gameOwned: 'Du besitzt diese Edition',
      seeDetails: 'Details',
      hide: 'Ausblenden',
      subscribed: 'Teilnehmer',
      buyOn: { playstation: 'Für PlayStation kaufen', microsoft: 'Für Xbox kaufen', nintendo: 'Für Nintendo kaufen' },
      ageGateRestrictionMessage:
        'Es tut uns leid, aber wegen der Altersbeschränkung hast du möglicherweise keinen Zugriff auf diesen Inhalt. Bitte sieh dir unsere <0>Datenschutzerklärung</0> an.',
      monthly: 'Monatlich',
      yearly: 'Jährlich',
      earlyAccess: 'Vorabzugang',
    },
    platforms: {
      PC: 'PC',
      PS4: 'PS4',
      PS5: 'PS5',
      Xbox1: 'Xbox One',
      XboxX: 'Xbox Series X|S',
      Android: 'Android',
      iOS: 'iOS',
      Switch: 'Switch',
      Stadia: 'Stadia',
      Luna: 'Luna',
    },
    versions: { digital: 'Digital', physical: 'Physisch' },
    subscription: { promoprice: { month: 'danach {{price}}/Monat', year: 'danach {{price}}/Jahr' } },
  },
};
