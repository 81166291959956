import React from 'react';
import Container from '@mooncake/ui/lib/components/Container';
import { m } from 'framer-motion';

import { appearingAnimationParams, filterImagesList, generateImagesList } from '@pdp-rework-hub/ui';
import { IBreakpoints } from '@pdp-rework-hub/utils-types';

interface IImageProps {
  source?: IBreakpoints;
  fullsize?: boolean;
  alt?: string;
}

const Image = ({ fullsize, source, alt }: IImageProps) => {
  const filteredImagesList = filterImagesList(source);
  if (!filteredImagesList.length) {
    return null;
  }
  const imagesList = generateImagesList(source, fullsize, alt);
  return (
    <Container
      flexBasis={{
        tablet: '50%',
      }}
      flexShrink={{
        tablet: 0,
      }}
      flexGrow={{
        tablet: 1,
      }}
      padding={
        !fullsize
          ? {
              default: '1rem',
              desktop: '1.25rem',
            }
          : null
      }
      width={'100%'}
    >
      {!fullsize ? <m.div {...appearingAnimationParams(0.2)}>{imagesList}</m.div> : imagesList}
    </Container>
  );
};

export default Image;
