import styled, { css } from 'styled-components';
import { IBreakpoints } from "@pdp-rework-hub/utils-types";

interface IBackgroundContainerProps {
  backgrounds?: IBreakpoints;
}

export const BackgroundContainer = styled.div<IBackgroundContainerProps>`
  background-position: 50% 50%;
  background-size: cover;

  ${({ backgrounds, theme }) => {
  const tabletMin = theme._options.breakpoints.tablet;
  const tabletMax = theme._options.breakpoints.tabletLarge - 1;

  return css`
      ${backgrounds?.default ? `background-image: url("${backgrounds.default}");` : ''}
      ${backgrounds?.mobile ? `
        ${theme.breakpoints.tablet.max.media} {
          background-image: url("${backgrounds.mobile}");
        }
      ` : ''}
      ${backgrounds?.tablet ? `
        @media screen and (min-width: ${tabletMin}px) and (max-width: ${tabletMax}px) {
          background-image: url("${backgrounds.tablet}");
        }
    ` : ''}
    `;
}}
`;
