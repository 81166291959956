import styled, { css } from 'styled-components';
import { BreakpointsKeys, IBreakpoints } from "@pdp-rework-hub/utils-types";

interface IImageContainerProps {
  display?: IBreakpoints;
  current: BreakpointsKeys;
}

export const ImageContainer = styled.div<IImageContainerProps>`
  ${({ display, current, theme }) => {
    const currentDefaultRule = display?.default && current === 'default' ? 'block' : 'none';
    const currentMobileRule = display?.mobile && current === 'mobile' ? 'block' : 'none';
    const currentTabletRule = display?.tablet && current === 'tablet' ? 'block' : 'none';

    const tabletMin = theme._options.breakpoints.tablet;
    const tabletMax = theme._options.breakpoints.tabletLarge - 1;

    return css`
      display: ${currentDefaultRule};

      ${(current === 'default' && display?.mobile) || current === 'mobile' ? `
        ${theme.breakpoints.tablet.max.media} {
          display: ${currentMobileRule};
        }
      ` : ''}
        ${(current === 'default' && display?.tablet) || current === 'tablet' ? `
          @media screen and (min-width: ${tabletMin}px) and (max-width: ${tabletMax}px) {
            display: ${currentTabletRule};
          }
      ` : ''}
    `;
  }}
`;

interface IImageElementProps {
  fullsize?: boolean;
}

export const ImageElement = styled.img<IImageElementProps>`
  display: block;
  margin: auto;
  max-width: 100%;
  width: ${(props) => (props.fullsize ? '100%' : 'auto')};
`;
