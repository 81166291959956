import React from 'react';

const XboxOneIcon = () => {
  return (
    <svg version="1.1" x="0px" y="0px" viewBox="0 0 100 79.6">
      <path d="M25.1,79h-4.6l-7.9-10.9L4.6,79H0L10.2,65L0.8,52h4.6l7.1,9.8l7.1-9.8h4.6L14.9,65L25.1,79z M48.9,71.4
	c0,2.4-0.8,4.3-2.4,5.6C45,78.3,42.8,79,39.9,79H27.2V67h-6.1l2.5-3.5h3.5V52h12.1c2.7,0,4.8,0.7,6.3,2c1.5,1.3,2.2,3,2.2,5.1
	c0,2.6-1.2,4.6-3.5,5.9c1.5,0.6,2.6,1.5,3.4,2.5C48.5,68.6,48.9,69.9,48.9,71.4L48.9,71.4z M31.3,63.6h7.7c1.6,0,2.7-0.3,3.5-1.1
	c0.8-0.7,1.2-1.7,1.2-3.1c0-1.2-0.4-2.1-1.2-2.8c-0.8-0.7-2-1-3.5-1h-7.7L31.3,63.6L31.3,63.6z M44.8,71.2c0-1.4-0.4-2.5-1.3-3.2
	c-0.8-0.7-2.1-1.1-3.8-1.1h-8.4v8.5h8.3c1.6,0,2.9-0.4,3.8-1.1C44.3,73.7,44.8,72.6,44.8,71.2L44.8,71.2z M76.7,65.5
	c0,2.1-0.3,4-0.9,5.7c-0.6,1.7-1.5,3.2-2.7,4.5c-1.2,1.3-2.6,2.3-4.2,2.9c-1.6,0.7-3.4,1-5.4,1c-2,0-3.8-0.3-5.4-1
	c-1.6-0.7-3-1.6-4.2-2.9c-1.2-1.3-2.1-2.8-2.7-4.5c-0.6-1.7-0.9-3.6-0.9-5.7c0-2.1,0.3-4,0.9-5.7c0.6-1.7,1.5-3.3,2.7-4.5
	c1.2-1.3,2.6-2.2,4.2-2.9c1.6-0.6,3.4-1,5.4-1s3.8,0.3,5.4,1c1.6,0.6,3,1.6,4.2,2.9c1.2,1.3,2.1,2.9,2.7,4.5S76.7,63.4,76.7,65.5
	L76.7,65.5z M54.6,65.5c0,3.2,0.8,5.7,2.5,7.6s3.8,2.8,6.5,2.8c2.7,0,4.9-1,6.5-2.8c1.6-1.8,2.4-4.4,2.4-7.6c0-3.2-0.8-5.8-2.4-7.6
	c-1.6-1.8-3.8-2.8-6.5-2.8s-4.9,1-6.5,2.8C55.4,59.8,54.6,62.3,54.6,65.5L54.6,65.5z M89.7,65L99.2,52h-4.6l-7.1,9.8L80.3,52h-4.6
	L85.1,65L74.9,79h4.6l7.9-10.9l8,10.9h4.6L89.7,65z M50,18C50,18,50.1,18,50,18c6.8,5.2,18.3,17.8,14.8,21.4
	c-4,3.5-9.1,5.5-14.8,5.5s-10.9-2.1-14.8-5.5C31.7,35.9,43.2,23.2,50,18C49.9,18,50,18,50,18L50,18z M61.7,3.3C58.3,1.2,54.5,0,50,0
	s-8.3,1.2-11.7,3.3c-0.1,0-0.1,0.1-0.1,0.1c0,0.1,0.1,0.1,0.1,0.1c4.4-1,11,2.8,11.6,3.2h0.1c0.6-0.4,7.2-4.1,11.6-3.2
	c0.1,0,0.1,0,0.1-0.1C61.8,3.4,61.8,3.3,61.7,3.3L61.7,3.3z M34.2,6.4c-0.1,0-0.1,0.1-0.1,0.1c-4.1,4.1-6.6,9.8-6.6,16
	c0,5.1,1.7,9.9,4.6,13.6c0,0.1,0.1,0.1,0.1,0.1c0.1,0,0.1-0.1,0-0.1c-1.7-5.3,7.1-18.2,11.6-23.5l0.1-0.1c0-0.1,0-0.1-0.1-0.1
	C36.9,5.5,34.6,6.3,34.2,6.4L34.2,6.4z M56.2,12.3L56.2,12.3c-0.1,0.1-0.1,0.1,0,0.1c4.5,5.4,13.3,18.2,11.6,23.5v0.1
	c0.1,0,0.1,0,0.1-0.1c2.9-3.8,4.6-8.5,4.6-13.6c0-6.2-2.5-11.9-6.7-16c-0.1-0.1-0.1-0.1-0.1-0.1C65.4,6.3,63.1,5.5,56.2,12.3
	L56.2,12.3z" fill="white" />
    </svg>
  );
};

export default XboxOneIcon;
