export default {
  translations: {
    title: 'Compare {{gameName}} Editions',
    subscription: {
      title: 'Included in Ubisoft<span class="blue">+</span>',
      classics: { title: 'Classics collection', subtitle: 'Enjoy Ubisoft iconic titles' },
      premium: { title: '100+ PC games', subtitle: 'Including new releases' },
    },
    subscriptionModal: {
      classics: {
        title: 'Ubisoft<span class="blue">+</span> Classics',
        items: [
          { description: 'Play an evolving selection of iconic games with Ubisoft Connect', icon: 'check' },
          { description: 'Access to standard editions', icon: 'check' },
          { description: '20% discount on DLC and games', icon: 'check' },
        ],
      },
      premium: {
        title: 'What is Ubisoft<span class="blue">+</span>?',
        items: [
          {
            title: 'New releases and 100+ PC games',
            description: 'Play select games from the cloud and an extended catalog of 100+ games on PC',
            icon: 'games',
          },
          {
            title: 'Premium editions',
            description: 'Get access to expansions, season passes, and more content',
            icon: 'diamond',
          },
          {
            title: 'Monthly rewards',
            description: 'Every month, get new customization items, boosters, and in-game rewards',
            icon: 'reward',
          },
          {
            title: 'Cancel and come back anytime',
            description: 'Easily jump back in your subscription whenever you want',
            icon: 'replay',
          },
        ],
      },
      learnMoreAboutUbisoftPlus: 'Learn more about Ubisoft+',
    },
  },
};
