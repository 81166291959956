export default {
  translations: {
    title: '比较 {{gameName}} 不同版本',
    subscription: {
      title: '包含在育碧<span class="blue">+</span>',
      classics: { title: 'Classics 收藏', subtitle: '享受 Ubisoft 經典遊戲' },
      premium: { subtitle: '包括最新游戏' },
    },
    subscriptionModal: {
      classics: {
        items: [
          { description: '在Ubisoft Connect上，游玩一系列不断扩充的标志性游戏' },
          { description: '畅玩标准版游戏' },
          { description: '购买 DLC 和游戏享受 20% 的优惠' },
        ],
      },
      premium: { title: '什麼是 Ubisoft<span class="blue">+</span>？' },
      learnMoreAboutUbisoftPlus: '深入了解 Ubisoft+',
    },
  },
};
