import React from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';
import { ISubPriceComponentProps } from '.';
import Container from '@mooncake/ui/lib/components/Container';
import Text from '@mooncake/ui/lib/components/Text';

type RegularPriceProps = ISubPriceComponentProps & WithTranslation;

const RegularPrice = ({ price, t, subType }: RegularPriceProps) => {
  const paymentOccurenceString = subType === 'monthly' ? t('global.month') : t('global.year');
  return (
    <Container className={'subscription-price-regular'}>
      <Text.Span bold fontSize={'1em'} className="price">
        {price}
      </Text.Span>
      <Text.Span className="month">/{paymentOccurenceString}</Text.Span>
    </Container>
  );
};

export default withTranslation()(RegularPrice);
