import { useSelector } from 'react-redux';
import { RootState } from '../../../../../store';
import { IProductVersion, IVariation, IMaster } from '@pdp-rework-hub/utils-types';
import { getIsPlayProductAction, getVariantAvailabilityData } from '@pdp-rework-hub/utils';

export interface IBuyCartAction {
  firstPartyVersion?: IProductVersion;
  hasRedirectVersion: boolean;
  disabled: boolean;
  isPreOrder: boolean;
  releaseDateToDisplay: string | null;
  selectedMaster: IMaster;
  selectedVariant: IVariation;
  isPlayButton: boolean;
}

export const useBuyCartAction = (): IBuyCartAction | null => {
  const { locale, selectedMaster, selectedVariant, isMinor } = useSelector((state: RootState) => ({
    locale: state.app.locale,
    selectedMaster: state.products.selectedMaster,
    selectedVariant: state.products.selectedVariant,
    isMinor: state.user.isMinor,
  }));

  if (!selectedMaster || !selectedVariant) {
    return null;
  }

  const { hasRedirectVersion, firstPartyVersion, isAvailable } = getVariantAvailabilityData(selectedVariant);
  const disabled = !isAvailable || isMinor;
  const formattedReleaseDate = selectedMaster.productReleaseDateTime && new Date(selectedMaster.productReleaseDateTime);
  const isPreOrder = !!formattedReleaseDate && !selectedMaster.isReleased;
  const releaseDateToDisplay =
    selectedMaster.releaseDateString || (formattedReleaseDate ? formattedReleaseDate.toLocaleString(locale) : null);

  return {
    firstPartyVersion,
    hasRedirectVersion,
    disabled,
    isPreOrder,
    releaseDateToDisplay,
    selectedMaster,
    selectedVariant,
    isPlayButton: getIsPlayProductAction(selectedMaster),
  };
};
