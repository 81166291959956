import React from 'react';

interface IRewardIcon {
  width: string;
  height: string;
  color?: string;
}

const RewardIcon = ({ width, height, color = 'white' }: IRewardIcon) => {
  return (
    <svg width={width} height={height} viewBox="0 0 39 39" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M3.65625 10.9688H30.1641L31.6875 7.3125H0V14.625H3.65625V10.9688Z" fill={color}/>
      <path d="M39 10.9688H30.1641L28.6406 14.625H35.3438V18.2813H27.1172L25.5938 21.9375H31.6875V35.3438H23.1563V18.2813H27.1172L28.6406 14.625H3.65625V39H35.3438V18.2813H39V10.9688ZM19.5 35.3438H17.0625V18.2813H19.5V35.3438ZM7.3125 18.2813H13.4063V35.3438H7.3125V18.2813Z" fill={color}/>
      <path d="M16.8768 9.3776L14.7507 4.6475C14.4921 4.07333 13.9152 3.70229 13.2801 3.70229C12.4933 3.70229 12.0749 4.20875 11.9314 4.42542C11.7878 4.64344 11.4885 5.22844 11.8095 5.94208L12.6762 7.86635L9.25146 9.36948L8.3848 7.44385C7.64407 5.79854 7.79574 3.91625 8.78834 2.40635C9.78094 0.900521 11.4601 0 13.2801 0C15.3953 0 17.3169 1.235 18.1768 3.14573L20.3028 7.87583L16.8768 9.3776Z" fill={color}/>
      <path d="M20.9031 9.3776L17.4771 7.87583L19.6031 3.14573C20.4616 1.235 22.3832 0 24.4998 0C26.3198 0 27.9989 0.900521 28.9915 2.40771C29.9841 3.9176 30.1358 5.7999 29.3951 7.44521L28.5284 9.36948L25.1037 7.86635L25.9704 5.94208C26.2913 5.22844 25.9921 4.64344 25.8485 4.42542C25.705 4.2074 25.2879 3.70229 24.4998 3.70229C23.8647 3.70229 23.2878 4.07333 23.0291 4.6475L20.9031 9.3776Z" fill={color}/>
    </svg>

  );
};

export default RewardIcon;
