import React, { ComponentType } from 'react';
import { domAnimation, LazyMotion } from 'framer-motion';

function withLazyMotion<P>(Component: ComponentType<P>) {
  return (hocProps: Omit<P, 'context'>) => (
    <LazyMotion features={domAnimation}>
      <Component {...(hocProps as P)} />
    </LazyMotion>
  );
}

export default withLazyMotion;
