type Breakpoint = 'mobile' | 'tablet' | 'tabletLarge' | 'desktop' | 'desktopLarge';

export type MediaQueryType = 'min' | 'max';
export type Breakpoints = {
  [key in Breakpoint]: {
    [key in MediaQueryType]: {
      value: string;
    };
  };
};

export const getBreakpointValue =
  (breakpoint: Breakpoint, queryType: MediaQueryType) =>
  ({ theme }: { theme: { breakpoints: Breakpoints } }) => {
    return theme.breakpoints[breakpoint][queryType].value;
  };
