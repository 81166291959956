import React from 'react';
import { useTheme } from 'styled-components';

interface IUbisoftPlusLogoProps {
  color?: string;
}

const UbisoftPlusLogo = ({ color = 'white' }: IUbisoftPlusLogoProps) => {
  const theme = useTheme();
  return (
    <svg width="181" height="30" viewBox="0 0 181 30" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M17.651 17.3986C17.651 19.5258 17.1607 21.1626 16.1799 22.309C15.1992 23.4555 13.7975 24.0287 11.9749 24.0287C10.1522 24.0287 8.75051 23.4555 7.7698 22.309C6.78905 21.1626 6.29867 19.5258 6.29867 17.3986V0.495422H0V17.8957C0 20.2154 0.461262 22.2956 1.38827 24.1317C2.31303 25.97 3.68114 27.4053 5.49036 28.4397C7.29958 29.4765 9.46032 29.9937 11.9749 29.9937C14.4872 29.9937 16.6502 29.4765 18.4594 28.4397C20.2686 27.403 21.6345 25.9677 22.5615 24.1317C23.4862 22.2956 23.9497 20.2154 23.9497 17.8957V0.495422H17.6533V17.3986H17.651Z"
        fill={color}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M42.1751 13.7134C43.3618 13.2163 44.2955 12.4505 44.9718 11.4138C45.648 10.3793 45.9861 9.0739 45.9861 7.49979C45.9861 5.34575 45.1441 3.63955 43.4581 2.38339C41.772 1.12723 39.5217 0.498047 36.7049 0.498047H27.052V29.497H37.0788C39.15 29.497 40.9592 29.132 42.5064 28.3998C44.0537 27.6676 45.2471 26.6466 46.0913 25.3345C46.9332 24.0223 47.3542 22.5378 47.3542 20.8808C47.3542 17.399 45.6278 15.0121 42.1751 13.7134V13.7134ZM33.3479 6.04928H36.6618C37.6561 6.04928 38.4509 6.31798 39.0443 6.85761C39.6376 7.39724 39.9355 8.14959 39.9355 9.1169C39.9355 10.0842 39.6376 10.8365 39.0443 11.3739C38.4509 11.9136 37.6561 12.1823 36.6618 12.1823H33.3479V6.04928ZM39.9608 23.0118C39.2287 23.632 38.1852 23.9433 36.8328 23.9433H33.3532V16.6527H36.8328C38.1852 16.6527 39.2287 16.9639 39.9608 17.5841C40.693 18.2066 41.058 19.1112 41.058 20.298C41.058 21.4847 40.6908 22.3893 39.9608 23.0118Z"
        fill={color}
      />
      <rect x="49.802" y="0.495422" width="6.29643" height="29.0012" fill={color} />
      <path
        d="M71.3027 12.2211C69.0927 11.4486 67.5522 10.7499 66.6834 10.1297C65.8124 9.50723 65.3781 8.784 65.3781 7.9555C65.3781 7.20988 65.6892 6.60308 66.3095 6.13286C66.9297 5.66265 67.7246 5.42754 68.6919 5.42754C70.846 5.42754 72.4201 6.54709 73.4142 8.784L78.4276 5.30438C77.626 3.56458 76.4035 2.24573 74.7622 1.34786C73.1187 0.44993 71.1773 0.00210571 68.9405 0.00210571C67.1178 0.00210571 65.4878 0.353678 64.0525 1.059C62.6172 1.76432 61.4976 2.73611 60.696 3.98104C59.8944 5.22378 59.4936 6.61875 59.4936 8.16598C59.4936 10.4029 60.1631 12.1785 61.5021 13.4906C62.8411 14.8028 64.769 15.9134 67.2813 16.8247C69.2965 17.5435 70.678 18.2197 71.4237 18.8556C72.1693 19.4915 72.5433 20.2506 72.5433 21.135C72.5433 22.0464 72.2118 22.7718 71.5491 23.3092C70.8863 23.8466 70.0286 24.1175 68.9808 24.1175C67.765 24.1175 66.6319 23.7324 65.5841 22.9577C64.5339 22.1852 63.7883 21.1619 63.3472 19.8923L58.3337 23.6205C59.1353 25.6648 60.4475 27.2389 62.2701 28.3428C64.0928 29.4467 66.2737 29.9997 68.8173 29.9997C70.6668 29.9997 72.3529 29.6325 73.871 28.9025C75.3891 28.1704 76.5983 27.1001 77.4962 25.6917C78.394 24.2833 78.8419 22.6129 78.8419 20.6783C78.8419 18.5242 78.2351 16.8046 77.0193 15.5193C75.8056 14.2318 73.8979 13.1346 71.3027 12.2211H71.3027Z"
        fill={color}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M102.979 1.96819C100.728 0.656066 98.1937 0 95.3769 0C93.1108 0 91.0127 0.380659 89.0805 1.13973C87.1457 1.8988 85.6007 2.87282 84.4409 4.06179L85.4351 5.05597C83.9438 6.29869 82.7683 7.83921 81.9129 9.67529C81.0576 11.5136 80.6277 13.6184 80.6277 15.9941C80.6277 18.5355 81.2502 20.8754 82.4929 23.016C83.7357 25.1566 85.4755 26.8561 87.7123 28.1123C89.9492 29.3684 92.5041 29.9976 95.3769 29.9976C98.1937 29.9976 100.728 29.3416 102.979 28.0294C105.229 26.7173 106.984 24.9215 108.241 22.6421C109.497 20.3626 110.126 17.8145 110.126 14.9977C110.126 12.2077 109.497 9.66633 108.241 7.37345C106.984 5.08282 105.229 3.28033 102.979 1.96818V1.96819ZM102.71 19.6596C102.021 21.0277 101.038 22.098 99.768 22.8705C98.4962 23.643 97.034 24.0303 95.3771 24.0303C93.6911 24.0303 92.2132 23.6564 90.9437 22.9108C89.6718 22.1651 88.7 21.1642 88.0216 19.9081C87.3453 18.6519 87.0073 17.2771 87.0073 15.7859C87.0073 14.3215 87.2692 13.1012 87.7954 12.1182C88.3194 11.1374 89.0247 10.2753 89.9091 9.52974L88.915 8.53555C89.6337 7.76308 90.5383 7.14058 91.6289 6.67036C92.7192 6.20015 93.9687 5.96505 95.3771 5.96505C97.0341 5.96505 98.4985 6.35241 99.768 7.12491C101.038 7.89742 102.018 8.96772 102.71 10.3358C103.4 11.7039 103.747 13.2579 103.747 14.9977C103.745 16.7375 103.4 18.2914 102.71 19.6595L102.71 19.6596Z"
        fill={color}
      />
      <path
        d="M112.697 29.4966H118.996V17.9382H131.3V13.1331H118.996V6.46271H132.543V0.495422H112.697L112.697 29.4966Z"
        fill={color}
      />
      <path d="M134.944 6.46271H143.107V29.4966H149.404V6.46271H157.525V0.495422H134.944V6.46271Z" fill={color} />
      <path
        d="M172.047 7.24135V0H167.168V7.24135H165.314H159.927V12.1227H163.948H165.314H167.166H167.168V19.3617H172.047V12.1227H178.073L180.124 7.24135H172.047Z"
        fill={theme.colors.UbisoftPlus}
      />
    </svg>
  );
};

export default UbisoftPlusLogo;
