import { appReducer } from '@mooncake/core';
import { combineReducers } from 'redux';
import { productsReducer } from '@pdp-rework-hub/slices';
import { userReducer } from '@pdp-rework-hub/slices';

export default combineReducers({
  app: appReducer,
  products: productsReducer,
  user: userReducer,
});
