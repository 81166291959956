export default {
  translations: {
    title: {
      classics:
        'Subskrybuj, aby zagrać w <span class="blue-variant">{{gameName}}</span> i ciągle zmieniającą się kolekcję kultowych tytułów Ubisoft!',
      premium: 'Dołącz aby zagrać w <span class="blue-variant">{{gameName}}</span> i ponad 100 gier na PC.',
    },
    cancelAnytime: 'Zrezygnuj w dowolnym momencie',
    learnMore: 'Dowiedz się więcej o Ubisoft+',
    classics: 'CLASSICS',
    premium: 'PREMIUM',
  },
};
