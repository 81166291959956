import { capitalizeFirstLetter } from '@pdp-rework-hub/utils';
import React from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';
import { ISubPriceComponentProps } from '.';
import Container from '@mooncake/ui/lib/components/Container';
import Text from '@mooncake/ui/lib/components/Text';
import { DurationUnit } from '@pdp-rework-hub/utils-types';
import { SUBSCRIPTION_PLAN_RECURRENCE } from '@pdp-rework-hub/utils-constants';

interface IPromoPriceProps extends ISubPriceComponentProps, WithTranslation {
  promotionDuration: string;
  promotionPrice: string;
  durationUnit: DurationUnit;
  isFreeTrial?: boolean;
  customSecondaryColor?: string;
  subType: string;
}

const PromoPrice = ({
  price,
  promotionDuration,
  promotionPrice,
  isFreeTrial = false,
  durationUnit,
  t,
  customSecondaryColor,
  subType,
}: IPromoPriceProps) => {
  return (
    <Container>
      <Container marginBottom={'0.5em'}>
        <Container className={'subscription-price-discount'}>
          <Text.Span bold>{isFreeTrial ? capitalizeFirstLetter(t('global.free')) : promotionPrice}</Text.Span>
          &nbsp;{t('global.durationTerm')} {promotionDuration}{' '}
          {subType === SUBSCRIPTION_PLAN_RECURRENCE.MONTHLY || isFreeTrial
            ? t(`global.${durationUnit}`)
            : t('global.year')}
        </Container>
      </Container>
      <Container className={'subscription-price-regular'} fontSize={'0.75em'} color={customSecondaryColor}>
        {subType === SUBSCRIPTION_PLAN_RECURRENCE.MONTHLY
          ? t('subscription.promoprice.month', { price: price })
          : t('subscription.promoprice.year', { price: price })}
      </Container>
    </Container>
  );
};

export default withTranslation()(PromoPrice);
