import { KeyboardEventHandler, MouseEventHandler } from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { CSSProperties, useTheme } from 'styled-components';
import Pill from '../Pill';
import SelectedIconMotion from '../Icons/SelectedIcon/SelectedIconMotion';
import {
  SelectedTileOuter,
  TileBasePrice,
  TileContent,
  TileCurrentPrice,
  TileImageWrapper,
  TileInner,
  TilePrice,
  TileWrapper,
  OwnedSubscription,
} from './style';
import { SubscriptionPrice, TextResetContainer } from '@pdp-rework-hub/ui';
import { ISubscription } from '@pdp-rework-hub/utils-types';
import { SUBSCRIPTION_PROMOTION_FREE_TRIAL_TYPE, platformKeys } from '@pdp-rework-hub/utils-constants';
import { capitalizeFirstLetter, filterActivePromotionsFromSubscriptions } from '@pdp-rework-hub/utils';
import Text from '@mooncake/ui/lib/components/Text';
import { useSelector } from 'react-redux';
import { RootState } from '../../../store';
import CheckmarkIcon from '../../../assets/checkmarkIcon';
import TileImage from './TileImage';
import ExternalLinkIcon from '../Icons/ExternalLinkIcon/externalLinkIcon';

export interface ITilePriceData {
  isPromo: boolean;
  hasEqualPrices: boolean;
  price: string | null;
  basePrice: string | null;
  discount?: string | number;
}

export type ITileProps = WithTranslation & {
  type?: 'product' | 'subscription';
  masterId?: string;
  background?: string;
  minHeight?: string;
  flexFlow?: CSSProperties['flexFlow'];
  imageSource?: string;
  platform?: string;
  altText: string;
  title: string;
  description?: string | React.ReactElement;
  tileItemSizes: [string, string, string];
  handleClick?: MouseEventHandler<HTMLButtonElement>;
  handleKeyPress?: KeyboardEventHandler<HTMLButtonElement>;
  active?: boolean;
  isFreePlayGame?: boolean;
  priceData?: ITilePriceData;
  available?: boolean;
  checkPosition?: 'top' | 'title';
  subscription?: ISubscription;
  hasImage?: boolean;
};

const Tile: React.FC<ITileProps> = ({
  children,
  t,
  imageSource,
  platform,
  title,
  masterId,
  description,
  background = '#0D0D0D',
  minHeight = '120px',
  flexFlow = 'row',
  tileItemSizes,
  handleClick,
  handleKeyPress,
  active,
  isFreePlayGame = false,
  priceData,
  available = true,
  checkPosition = 'title',
  subscription = null,
  hasImage = true,
}) => {
  const theme = useTheme();
  const { activeSubscription, isActiveSubscriber, isEarlyAccess } = useSelector((state: RootState) => ({
    activeSubscription: state.user.activeSubscription,
    isActiveSubscriber: state.user.isActiveSubscriber,
    isEarlyAccess: !!state.products.data.product?.masters.find((targetedProduct) => {
      if (masterId) {
        return targetedProduct.id === masterId && targetedProduct?.earlyAccessDuration !== null;
      }
      if (subscription) {
        return targetedProduct.masterSubOffersDates?.find((item) => item.offerId === subscription.offerId);
      }
      return false;
    }),
  }));
  let isFreeTrial = false;
  if (subscription) {
    const filteredPromotions = filterActivePromotionsFromSubscriptions([subscription]);
    isFreeTrial =
      filteredPromotions.length > 0 && filteredPromotions[0].type === SUBSCRIPTION_PROMOTION_FREE_TRIAL_TYPE;
  }

  const isSubscribed = !!(
    subscription &&
    isActiveSubscriber &&
    activeSubscription &&
    activeSubscription.offerId === subscription.offerId
  );

  const normalizePrice = (price: string | null) => {    
    if (isFreePlayGame) {  
      return capitalizeFirstLetter(t('global.free'));
    }
    return price?.replace(' ', '&nbsp;');
  };

  return (
    <TileWrapper disabled={!available}>
      {priceData && priceData.isPromo && priceData.discount && (
        <Pill background={theme.BuySelector.Colors.promo} xPosition="right">
          -{priceData.discount}%
        </Pill>
      )}
      {isFreeTrial && (
        <Pill background={theme.BuySelector.Colors.promo} xPosition="right">
          {t('global.freeTrial')}
        </Pill>
      )}
      {active && (
        <SelectedTileOuter
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          available={available}
        />
      )}
      <TileInner
        active={active}
        onKeyPress={handleKeyPress ? handleKeyPress : undefined}
        background={background}
        minHeight={minHeight}
        flexFlow={flexFlow}
        onClick={handleClick}
        available={available}
        role={'checkbox'}
        aria-checked={active}
      >
        {checkPosition === 'top' && available && active}
        {hasImage && !subscription ? (
          <TileImageWrapper size={tileItemSizes[0]} flexFlow={flexFlow} isSubscriptionTile={!!subscription}>
            <TileImage hasImage={hasImage} platform={platform} imageSource={imageSource} />
          </TileImageWrapper>
        ) : null}
        <TileContent size={tileItemSizes[1]} flexFlow={flexFlow}>
          <h4>
            <span className="title">{title}</span>
            {checkPosition === 'title' && available && active && <SelectedIconMotion />}
            {checkPosition === 'top' && platform != platformKeys.PC_KEY && <ExternalLinkIcon width={16} height={16} />}
          </h4>
          {isEarlyAccess && (
            <div className={'earlyAccess'}>
              <span>{t('global.earlyAccess')}</span>
            </div>
          )}
          {description && !subscription && (
            <TextResetContainer
              as="div"
              fontSize={{
                default: '0.75rem',
                desktop: '0.875rem',
              }}
              color={theme.colors.Nobel}
              marginTop={'0.4rem'}
              marginBottom={'0'}
            >
              {description}
            </TextResetContainer>
          )}
        </TileContent>
        {priceData && (
          <TilePrice size={tileItemSizes[2]} flexFlow={flexFlow}>
            {!subscription ? (
              <>
                {available ? (
                  <>
                    <TileCurrentPrice>
                      {!priceData.hasEqualPrices && <span className="from">{t('global.from')} </span>}
                      <Text.Span
                        className="price"
                        bold
                        dangerouslySetInnerHTML={{ __html: normalizePrice(priceData.price) }}
                      />
                    </TileCurrentPrice>
                    {priceData.isPromo && priceData.basePrice && (
                      <TileBasePrice aria-hidden>{priceData.basePrice}</TileBasePrice>
                    )}
                  </>
                ) : (
                  <span className="out-of-stock">{t('global.outOfStock')}</span>
                )}
              </>
            ) : (
              !isSubscribed && (
                <SubscriptionPrice subscriptions={[subscription]} customSecondaryColor={theme.colors.Nobel} />
              )
            )}
          </TilePrice>
        )}
        {isSubscribed && (
          <OwnedSubscription>
            <CheckmarkIcon />
            <p>{t('global.subscribed')}</p>
          </OwnedSubscription>
        )}
      </TileInner>
      {children}
    </TileWrapper>
  );
};

export default withTranslation()(Tile);
