export default {
  translations: {
    title: {
      classics:
        'Оформите подписку, чтобы сыграть в <span class="blue-variant">{{gameName}}</span> и другие знаменитые игры Ubisoft из постоянно пополняемой коллекции!',
    },
    cancelAnytime: 'Отмена в любой момент',
    classics: 'CLASSICS',
    premium: 'PREMIUM',
  },
};
