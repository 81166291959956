import breakpoints from '../../../../themes/utils/breakpoints';
import { m } from 'framer-motion';
import styled, { keyframes } from 'styled-components';
import { TileSkeletonWrapperItem } from '../../../UI/Tiles/style';

const scaling = keyframes`
  0% { transform: scale(0.8); opacity: 0.7; }
  100% { transform: scale(1); opacity: 0.4 }
`;

export const SelectedProductWrapper = styled.div`
  display: flex;
  padding: 2em 10%;
  overflow: hidden;
  height: auto;
  flex-wrap: wrap;

  @media screen and (max-width: ${breakpoints.desktop}) {
    padding: 2em;
  }
`;

export const SelectedProductWrapperInner = styled(m.div)`
  display: flex;
  align-items: center;
  margin: auto;
  min-width: 0px; // prevent media flexbox item and slick track bug
  background: ${({ theme }) => theme.colors.Black};
  border-radius: 4px;
  padding: 1rem;
  width: 100%;
`;

export const SelectedProductMediaSection = styled(m.div)`
  margin-right: 1.5rem;
  flex: 0 0 160px;
  border-radius: 4px;
  overflow: hidden;

  img {
    display: block;
    width: 100%;
  }
`;

export const SelectedProductContentSection = styled(m.div)``;

export const SelectedProductLoaderWrapper = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  position: relative;
  overflow: hidden;
  border-radius: 10px;

  ${TileSkeletonWrapperItem} {
    display: flex;
    text-align: center;
    width: 100%;

    svg {
      margin: auto;
      width: 200px;
      fill: white;
      opacity: 0.4;
      transition: all 0.2s ease;
      animation: ${scaling} 1.2s cubic-bezier(0.39, 0.575, 0.565, 1) infinite alternate both;
    }
  }
`;
