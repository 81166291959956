import React, { useCallback } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '../../store';
import { withTranslation, WithTranslation } from 'react-i18next';
import { useTheme } from 'styled-components';
import { IAdobeEvent } from '@pdp-rework-hub/utils-types';

import Container from '@mooncake/ui/lib/components/Container';
import Text from '@mooncake/ui/lib/components/Text';
import { SubscriptionButton, SubscriptionPrice, UbisoftPlusLogo } from '@pdp-rework-hub/ui';
import { StyledLink, SubscriptionPriceContainer } from './style';
import { SUBSCRIPTION_IDS, SUBSCRIPTIONS, SUBSCRIPTION_PLAN_RECURRENCE } from '@pdp-rework-hub/utils-constants';

interface IPromoDetailsProps extends WithTranslation {
  position: string | null;
}

const PromoDetails = ({ position, t }: IPromoDetailsProps) => {
  const theme = useTheme();
  const { availableSortedSubscriptions, product, isSubscriptionButtonRestricted } = useSelector((state: RootState) => ({
    product: state.products.data.product,
    isSubscriptionButtonRestricted: state.products.isSubscriptionButtonRestricted,
    availableSortedSubscriptions: state.products.availableSortedSubscriptions,
  }));

  const availableSortedMonthlySubscriptions =
    availableSortedSubscriptions?.filter(
      (subscription) => subscription.productSubscriptionType === SUBSCRIPTION_PLAN_RECURRENCE.MONTHLY
    ) || null;

  const clickEventDetails: IAdobeEvent = {
    location: 'ubisoft plus block',
    action: 'ubisoft plus : see more',
    category: 'action',
  };

  if (position) {
    clickEventDetails.locationPosition = position;
  }

  const upcPrefix = window.location.pathname.indexOf('/upc/') > -1 ? '/upc' : '';
  const ubisoftPlusLink = `${upcPrefix}/ubisoftplus`;

  const handleClick = useCallback(() => {
    window.location.href = ubisoftPlusLink;
  }, [ubisoftPlusLink]);

  if (!availableSortedMonthlySubscriptions) {
    return null;
  }

  const currentSubscription = availableSortedMonthlySubscriptions[availableSortedMonthlySubscriptions.length - 1];
  const subscriptionType =
    currentSubscription?.id === SUBSCRIPTION_IDS.BASIC ? SUBSCRIPTIONS.BASIC.TYPE : SUBSCRIPTIONS.PREMIUM.TYPE;

  return currentSubscription ? (
    <Container
      flex={{
        tabletLarge: '0 0 50%',
      }}
    >
      <Container>
        <UbisoftPlusLogo color={theme.colors.text.primary} />
      </Container>
      <Container
        display={'flex'}
        justifyContent={{
          default: 'center',
          tabletLarge: 'flex-start',
        }}
      >
        <Text.Heading
          as={'h3'}
          bold
          uppercase
          marginBottom={{
            default: '1rem',
            desktop: '1.5rem',
          }}
          fontSize={'0.875rem'}
        >
          {currentSubscription.id === SUBSCRIPTION_IDS.PREMIUM_ANYWHERE ? t('premium') : t('classics')}
        </Text.Heading>
      </Container>
      <Text.Heading
        bold
        marginBottom={{
          default: '2rem',
          desktop: '3rem',
        }}
        fontSize={{
          default: '1.25rem',
          desktop: '1.875rem',
        }}
        dangerouslySetInnerHTML={{
          __html: t(`title.${subscriptionType}`, { gameName: product?.details.productName }),
        }}
      />
      <SubscriptionPriceContainer>
        <SubscriptionPrice
          subscriptions={availableSortedMonthlySubscriptions}
          activeSubscription={currentSubscription}
        />
      </SubscriptionPriceContainer>
      <Container
        marginBottom={{
          default: '1.5rem',
          desktop: '2rem',
        }}
      >
        <SubscriptionButton
          trackingEvent={clickEventDetails}
          isPreOrder={product?.availability.isPreOrder}
          isButtonRestricted={isSubscriptionButtonRestricted}
          isModalTrigger
        />
      </Container>
      <Text.Paragraph
        color="unset"
        fontFamily="unset"
        lineHeight="unset"
        fontSize={{
          default: '0.875rem',
          desktop: '1rem',
        }}
      >
        {t('cancelAnytime')} |&nbsp;
        <StyledLink href={ubisoftPlusLink} onClick={() => handleClick()}>
          {t('learnMore')}
        </StyledLink>
      </Text.Paragraph>
    </Container>
  ) : null;
};

export default withTranslation()(PromoDetails);
