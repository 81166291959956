export default {
  translations: {
    title: 'Editionen von {{gameName}} vergleichen',
    subscription: {
      title: 'Enthalten in Ubisoft<span class="blue">+</span>',
      classics: { title: 'Classics-Sammlung', subtitle: 'Genieße Ubisoft-Klassiker' },
      premium: { title: 'Über 100 Spiele', subtitle: 'Inklusive Neuerscheinungen' },
    },
    subscriptionModal: {
      classics: {
        title: 'Ubisoft<span class="blue">+</span> Classics',
        items: [
          { description: 'Spiele eine wachsende Auswahl legendärer Spiele bei Ubisoft Connect', icon: 'check' },
          { description: 'Zugriff auf Standard Editions', icon: 'check' },
          { description: '20 % Rabatt auf DLC und Spiele', icon: 'check' },
        ],
      },
      premium: {
        title: 'Was ist Ubisoft<span class="blue">+</span>?',
        items: [
          {
            title: 'Neuerscheinungen und über 100 PC-Spiele',
            description:
              'Spiele neue Titel am Tag der Veröffentlichung und erhalte Zugang zu unserem stetig wachsenden Katalog.',
            icon: 'games',
          },
          {
            title: 'Premium editions',
            description: 'Erhalte Zugang zu Erweiterungen, Season Pass und vielen weiteren Inhalten.',
            icon: 'diamond',
          },
          {
            title: 'Monatliche Belohnungen',
            description: 'Erhalte jeden Monat neue Anpassungsobjekte, Booster, und in-Game-Belohnungen.',
            icon: 'reward',
          },
          {
            title: 'Kündigen und jederzeit zurückkehren',
            description: 'Du kannst das Abonnement jederzeit kündigen und wieder aufnehmen.',
            icon: 'replay',
          },
        ],
      },
      learnMoreAboutUbisoftPlus: 'Mehr erfahren Über Ubisoft+',
    },
  },
};
