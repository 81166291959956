import styled from 'styled-components';

interface ITitleContainerProps {
  backgroundColor: string;
  textColor: string;
}

export const TitleContainer = styled.div<ITitleContainerProps>`
  display: flex;
  justify-content: center;
  position: relative;
  text-align: center;
  margin-bottom: 1rem;

  &::before {
    content: '';
    position: absolute;
    top: 50%;
    left: 0;
    right: 0;
    height: 1px;
    background-color: ${({ textColor }) => textColor};
    opacity: 0.2;
  }
  > span {
    display: inline-block;
    position: relative;
    background-color: ${({ backgroundColor }) => backgroundColor};
    padding: 0 0.5rem;
    margin: 0;
  }
`;

export const ListContainer = styled.ul`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 0;
  margin: 0;
  list-style: none;
`;
