export default {
  translations: {
    title: 'Compare as edições de {{gameName}}',
    subscription: {
      title: 'Incluso no Ubisoft<span class="blue">+</span>',
      classics: { title: 'Coleção Classics', subtitle: 'Aproveite títulos icônicos da Ubisoft' },
      premium: { title: 'Mais de 100 jogos para PC', subtitle: 'Incluindo novos lançamentos' },
    },
    subscriptionModal: {
      classics: {
        title: 'Ubisoft<span class="blue">+</span> Classics',
        items: [
          { description: 'Jogue uma lista em crescimento de jogos icônicos com Ubisoft Connect', icon: 'check' },
          { description: 'Acesso a edições standard', icon: 'check' },
          { description: '20% de desconto em DLC e jogos', icon: 'check' },
        ],
      },
      premium: {
        title: 'O que é o Ubisoft<span class="blue">+</span>?',
        items: [
          {
            title: 'Novos lançamentos e mais de 100 jogos no computador',
            description: 'Jogue jogos selecionados da nuvem e um catálogo ampliado de mais de 100 jogos no computador',
            icon: 'games',
          },
          {
            title: 'Edições Premium',
            description: 'Tenha acesso a expansões, passes de temporada e muito mais conteúdo',
            icon: 'diamond',
          },
          {
            title: 'Recompensas menais',
            description: 'Receba novos itens de personalização, reforços e recompensas no jogo todos os meses!',
            icon: 'reward',
          },
          {
            title: 'Cancele e volte quando quiser',
            description: 'Cancele sua assinatura sem dificuldades e quando quiser',
            icon: 'replay',
          },
        ],
      },
      learnMoreAboutUbisoftPlus: 'Saiba mais sobre o Ubisoft+',
    },
  },
};
