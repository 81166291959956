export default {
  translations: {
    title: 'Compara las ediciones de {{gameName}}',
    subscription: {
      title: 'Incluido en Ubisoft<span class="blue">+</span>',
      classics: { title: 'Colección Classics', subtitle: 'Disfruta de los títulos más emblemáticos de Ubisoft' },
      premium: { title: '+100 Juegos', subtitle: 'Incluye nuevos lanzamientos' },
    },
    subscriptionModal: {
      classics: {
        title: 'Ubisoft<span class="blue">+</span> Classics',
        items: [
          {
            description:
              'Juega a una selección de juegos icónicos de Ubisoft que se actualiza constantemente con Ubisoft Connect',
            icon: 'check',
          },
          { description: 'Consigue acceso a ediciones estándar', icon: 'check' },
          { description: '20 % de descuento en contenidos descargables y juegos', icon: 'check' },
        ],
      },
      premium: {
        title: '¿Qué incluye Ubisoft<span class="blue">+</span>?',
        items: [
          {
            title: 'Más de 100 juegos para PC',
            description:
              'Juega a nuevos lanzamientos desde el primer día y consigue acceso a un catálogo que no para de crecer',
            icon: 'games',
          },
          {
            title: 'Premium Editions',
            description: 'Consigue acceso a expansiones, Season Pass y mucho más contenido',
            icon: 'diamond',
          },
          {
            title: 'Recompensas mensuales',
            description: 'Consigue cada mes nuevos objetos personalizables, potenciadores y recompensas in-game',
            icon: 'reward',
          },
          {
            title: "Cancela y vuelve cuando quieras'",
            description: 'Regresa fácilmente y cuando quieras a tu suscripción',
            icon: 'replay',
          },
        ],
      },
      learnMoreAboutUbisoftPlus: 'Descubre más acerca de Ubisoft+',
    },
  },
};
