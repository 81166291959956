export const SFCC_PLATFORMS = {
  pcdl: {
    id: 'pcdl',
    displayValue: 'PC',
    description: 'PC (Download)',
  },
  pcph: {
    id: 'pcph',
    displayValue: 'PC DVD',
    description: 'PC DVD',
  },
  ps3: {
    id: 'ps3',
    displayValue: 'PS3',
    description: 'PS3',
  },
  ps4: {
    id: 'ps4',
    displayValue: 'PS4',
    description: 'PS4',
  },
  ps5: {
    id: 'ps5',
    displayValue: 'PS5',
    description: 'PS5',
  },
  xbox1: {
    id: 'xbox1',
    displayValue: 'Xbox',
    description: 'Xbox',
  },
  xbox360: {
    id: 'xbox360',
    displayValue: 'Xbox 360',
    description: 'Xbox 360',
  },
  wii: {
    id: 'wii',
    displayValue: 'Wii',
    description: 'Wii',
  },
  wiiu: {
    id: 'wiiu',
    displayValue: 'Wii U',
    description: 'Wii U',
  },
  nintendo3ds: {
    id: 'nintendo3ds',
    displayValue: 'Nintendo 3DS',
    description: 'Nintendo 3DS',
  },
  vroculus: {
    id: 'vroculus',
    displayValue: 'VR - Oculus Rift',
    description: 'VR - Oculus Rift',
  },
  vrvive: {
    id: 'vrvive',
    displayValue: 'VR - Vive',
    description: 'VR - Vive',
  },
  vrpsvr: {
    id: 'vrpsvr',
    displayValue: 'VR - PSVR',
    description: 'VR - PSVR',
  },
  switch: {
    id: 'switch',
    displayValue: 'Switch',
    description: 'Switch',
  },
  XboxDig: {
    id: 'XboxDig',
    displayValue: 'Xbox',
    description: 'Xbox (Download)',
  },
  ios: {
    id: 'ios',
    displayValue: 'iOS',
    description: 'iOS',
  },
  android: {
    id: 'android',
    displayValue: 'Android',
    description: 'Android',
  },
  SwitchDig: {
    id: 'SwitchDig',
    displayValue: 'Switch',
    description: 'Switch (Download)',
  },
  switchdig: {
    id: 'switchdig',
    displayValue: 'Switch',
    description: 'Switch (Download version)',
  },
  xboxx: {
    id: 'xboxx',
    displayValue: 'Xbox',
    description: 'Xbox',
  },
  ps5dig: {
    id: 'ps5dig',
    displayValue: 'PS5',
    description: 'PS5 (Download)',
  },
  ps4dig: {
    id: 'ps4dig',
    displayValue: 'PS4',
    description: 'PS4 (Download)',
  },
  xboxdig: {
    id: 'xboxdig',
    displayValue: 'Xbox',
    description: 'Xbox (Download)',
  },
  ps4ps5dig: {
    id: 'ps4ps5dig',
    displayValue: 'PlayStation',
    description: 'PS4/PS5 (Digital)',
  },
  google: {
    id: 'google',
    displayValue: 'Google Play',
    description: 'Google Play',
  },
  apple: {
    id: 'apple',
    displayValue: 'App Store',
    description: 'App Store',
  },
} as const;

export const platformKeys = {
  PC_KEY: SFCC_PLATFORMS.pcdl.id,
  PS4_KEY: SFCC_PLATFORMS.ps4dig.id,
  PS5_KEY: SFCC_PLATFORMS.ps5dig.id,
  PS_KEY: SFCC_PLATFORMS.ps4ps5dig.id,
  XBOX_KEY: SFCC_PLATFORMS.xboxdig.id,
  XBOXX_KEY: SFCC_PLATFORMS.xboxx.id,
  XBOX1_KEY: SFCC_PLATFORMS.xbox1.id,
  SWITCH_KEY: SFCC_PLATFORMS.switchdig.id,
  APP_STORE_KEY: SFCC_PLATFORMS.apple.id,
  GOOGLE_PLAY_KEY: SFCC_PLATFORMS.google.id,
};

export const PLAYSTATION_DIGITAL_PLAFORMS_SET = new Set(['ps5dig', 'ps4dig', 'ps4ps5dig']);
export const DIGITAL_PLATFORMS_SET = new Set([
  'pcdl',
  'switchdig', 
  'xboxdig', 
  'apple', 
  'google', 
  ...PLAYSTATION_DIGITAL_PLAFORMS_SET
]);
