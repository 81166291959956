import { buyEditionGraphQLQuery } from './queries';
import { fetchGraphql, FetchDataOptions, QueryVariables } from '@components-it/service-contentful';
import { DocumentNode } from 'graphql/language/ast';
import {
  BuyEditionResponse,
  IContentfulFetchError,
  IFeaturesId,
  ITranslations,
  ProductOfferMap,
} from '@pdp-rework-hub/utils-types';
import { unflatten } from 'flat';
import { CONTENTFUL_CONFIG } from '@pdp-rework-hub/utils-constants';
import { localeMap, LocaleMapItem } from '../localeMap';

type ContentfulServiceGlobalConfig = Omit<FetchDataOptions, 'query' | 'queryVariables'>;

export const fetchCustomerOffers = async (
  ids: string[],
  locale: string
): Promise<ProductOfferMap | IContentfulFetchError> => {
  const mappedLocale = localeMap[locale];

  try {
    if (!mappedLocale || !mappedLocale.contentfulLocale) {
      throw new Error('No contentful locale mapped with SFCC');
    }

    const globalConfig = getContentfulServiceGlobalConfig();
    const buyEditionResponse = await fetchBuyEditionMaster(ids, mappedLocale, globalConfig);

    if (!buyEditionResponse.buyEditionMasterCollection || !buyEditionResponse.buyEditionMasterCollection.items.length) {
      throw new Error('No buyEditionMasterCollection found');
    }

    const { items: editions } = buyEditionResponse.buyEditionMasterCollection;
    const productOfferMap = editions.reduce<ProductOfferMap>((map, edition) => {
      if (
        !edition.customerOffer ||
        (!edition.featuresId?.localizedItems?.translations && !edition.featuresId?.fallbackLocalizedItems?.translations)
      ) {
        return map;
      }

      const formattedFeaturesId: IFeaturesId = {
        localizedItems: {
          translations: unflattenTranslations(edition.featuresId?.localizedItems?.translations),
        },
        fallbackLocalizedItems: {
          translations: unflattenTranslations(edition.featuresId?.fallbackLocalizedItems?.translations),
        },
      };

      return {
        ...map,
        [edition.customerOffer]: {
          ...edition,
          featuresId: formattedFeaturesId,
        },
      };
    }, {});

    return productOfferMap;
  } catch (error) {
    return getContentfulError(error);
  }
};

const fetchContentfulData = async <R>(
  query: DocumentNode,
  queryVariables: QueryVariables & Record<string, unknown>,
  config: ContentfulServiceGlobalConfig
): Promise<R> => {
  const queryString = query.loc?.source.body;

  if (!queryString) {
    throw new Error(`Error: no ${queryString}`);
  }

  const fetchDataOptions: FetchDataOptions = {
    ...config,
    query: queryString,
    queryVariables: queryVariables,
  };

  const { errors, response } = await fetchGraphql(fetchDataOptions);

  if (errors || !response) {
    throw new Error(`Error while fetching ${query.loc?.source.name ?? null}`);
  }

  return response;
};

const fetchBuyEditionMaster = async (ids: string[], locale: LocaleMapItem, config: ContentfulServiceGlobalConfig) => {
  if (!locale.contentfulLocale) {
    throw new Error('No contentful locale mapped with SFCC');
  }

  const buyEditionQueryVariables = {
    locale: locale.contentfulLocale,
    fallbackLocale: locale.fallbackLocale,
    customerOfferIds: ids,
  };

  return await fetchContentfulData<BuyEditionResponse>(buyEditionGraphQLQuery, buyEditionQueryVariables, config);
};

const getContentfulServiceGlobalConfig = () => {
  const { NX_CONTENTFUL_SPACE_ID, NX_CONTENTFUL_ACCESS_TOKEN, NX_UBI_APP_ID } = process.env;
  if (!NX_CONTENTFUL_SPACE_ID || !NX_CONTENTFUL_ACCESS_TOKEN || !NX_UBI_APP_ID) {
    throw new Error('Environment variables missing to build the contentful configuration');
  }

  const config = {
    contentfulSpaceId: NX_CONTENTFUL_SPACE_ID,
    accessToken: NX_CONTENTFUL_ACCESS_TOKEN,
    requestIdentifier: CONTENTFUL_CONFIG.REQUEST_IDENTIFIER,
    contentfulEnvironment: 'master',
    ubiAppId: NX_UBI_APP_ID,
    ubiAppName: CONTENTFUL_CONFIG.REQUEST_IDENTIFIER,
    graphQlBaseUrl: `https://${CONTENTFUL_CONFIG.CONTENTFUL_GRAOHQL_BASE_URL}`,
  };

  return config;
};

const getContentfulError = (error: any): IContentfulFetchError => ({
  error: true,
  message: typeof error.message === 'string' ? error.message : null,
  status: typeof error.status === 'number' ? error.status : null,
});

const unflattenTranslations = (translations?: ITranslations) => {
  if (!translations) {
    return undefined;
  }

  return unflatten<ITranslations, ITranslations>(translations);
};
