// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import colors from '@mooncake/ui/lib/themes/uplay/colors';

export default {
  text: {
    primary: colors.White,
    secondary: colors.BlackPearl,
  },
  price: {
    previous: colors.White,
    discounted: colors.FlushOrange,
  },
  background: {
    primary: colors.BlackRussian,
    secondary: colors.BlackPearl,
  },
};
