export default {
  translations: {
    title: {
      classics:
        'Inscrivez-vous pour jouer à <span class="blue-variant">{{gameName}}</span> ainsi qu\'à une sélection en constante évolution de jeux Ubisoft emblématiques !',
      premium: 'S\'inscrire pour jouer à <span class="blue-variant">{{gameName}}</span> et à plus de 100 jeux PC',
    },
    cancelAnytime: 'Annulez à tout moment',
    learnMore: 'En savoir plus sur Ubisoft+',
    classics: 'CLASSICS',
    premium: 'PREMIUM',
  },
};
