export default {
  translations: {
    selectYour: { edition: '選擇你的版本', version: '選擇你的版本', platform: '選擇你的平臺', payment: '選擇付款選項' },
    selectPlatform: '選擇平臺',
    releaseDate: '發售日期:',
    buySelector: { subscription: { subscriptionSelection: '訂閱選項：' } },
    subscription: {
      title: '{{gameName}} 和更多其他 Ubisoft 遊戲<span class="blue">+</span>',
      alreadySubscribed: '你已經訂閱了 Ubisoft+！',
      learnMoreAbout: '深入瞭解 <0>Ubisoft+</0>',
      cancelNotice: '可隨時取消，也可隨時重新加入，你的存檔資料會保留下來。<0>查看常見問答</0>',
      gamesSelection: {
        classics: {
          title: 'Ubisoft 經典遊戲',
          description: '享受一系列精選經典作品，包含《刺客教條：維京紀元》、《虹彩六號：圍攻行動》等遊戲',
          link: { notSubscriber: '查看方案提供的遊戲', subscriber: '流覽遊戲' },
        },
        premium: {
          title: '超過 100 款遊戲',
          description:
            '立即遊玩所有《極地戰嚎》遊戲並享用陣容日益壯大的眾多遊戲，包括《刺客教條：維京紀元》、《虹彩六號：圍攻行動》等等。',
          link: { notSubscriber: '查看包含的所有遊戲', subscriber: '流覽遊戲' },
        },
      },
      cards: {
        cloudGaming: {
          title: '雲端遊戲服務',
          description:
            '透過 {{platforms}} 服務，只要有良好的網際網路連線，你就可以在智慧手機、平板電腦或電視上立即玩遊戲。',
          link: '查看雲端遊戲',
        },
        consoles: {
          title: '主機',
          description: '訂閱我們的遊戲服務，在你的 {{platforms}} 主機上遊玩精選遊戲。',
          link: '查看可遊玩哪些主機遊戲',
        },
      },
      partOfUbisoftPlus: {
        released: '訂閱 Ubisoft+ 立即遊玩 {{game}} {{edition}}。',
        dlc: '遊玩包含在 {{game}} 裡的 {{dlc}}。',
        preOrder: '在 {{game}} 於 {{releaseDate}} 發售時立即遊玩。',
        includedContent: '包含內容：',
      },
      firstParty: {
        legalLine: '標價僅適用於 PC 版本<br/>請洽第三方平台商店了解遊戲主機版本的相關定價。',
        pricingInfo: '請洽平台商店了解定價。',
      },
      learnMore: '深入瞭解',
      playThisGame: 'Play this game in your <span class="blue">Library+</span>!',
    },
  },
};
