import { IBreakpoints } from '@pdp-rework-hub/utils-types';

export interface ICustomAppProps {
  titlecontent?: string;
  titlecolor?: string;
  textcontent?: string;
  textcolor?: string;
  backgroundimage?: IBreakpoints;
  tilebackgroundcolor?: string;
  tiletitlebrushcolor?: string;
  tiletitle1?: string;
  tiletext1?: string;
  tileimage1?: string;
  tileimagealttext1?: string;
  tiletitle2?: string;
  tiletext2?: string;
  tileimage2?: string;
  tileimagealttext2?: string;
  tiletitle3?: string;
  tiletext3?: string;
  tileimage3?: string;
  tileimagealttext3?: string;
}

export const CustomAppDefaultProps = {};

export const CustomAppEventsConfigs = {};
