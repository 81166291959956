import { addToCart } from '@mooncake/mooncake-gateway-ecom/events/sfcc';
import { IMaster, isPriceRange, IVariation, IProductDisplayPrice, IProductModel } from '@pdp-rework-hub/utils-types';
import { ProductModelFormatter } from './productModel';

export * from './productModel';
export * from './offers';

export const getProductDisplayPrice = (master: IMaster | IVariation): IProductDisplayPrice => {
  let productPrice;

  if (isPriceRange(master.price)) {
    productPrice = {
      price: master.price.min.sales.formatted,
      basePrice: master.price.min.list ? master.price.min.list.formatted : null,
      discount: master.price.min.list ? getDiscount(master.price.min.sales.value, master.price.min.list.value) : null,
      hasEqualPrices: false,
    };

    return productPrice;
  }

  productPrice = {
    price: master.price.sales.formatted,
    basePrice: master.price.list ? master.price.list.formatted : null,
    discount: master.price.list ? getDiscount(master.price.sales.value, master.price.list.value) : null,
    hasEqualPrices: true,
  };

  return productPrice;
};

export const getDiscount = (price: number, base: number) => Math.round(100 - (price * 100) / base);

export const checkPromotionStatus = (variant: IVariation) => {
  return variant.price.list !== null;
};

export const postProductToCart = async (id: string) => {
  const addToCartConfig = {
    productId: id,
    quantity: 1,
    showPopup: true,
    format: 'ajax',
  };

  return new Promise((resolve, reject) => {
    try {
      addToCart(addToCartConfig, ({ resource, error }: any) => {
        if (error) {
          throw new Error(error);
        }
        resolve(resource);
      })();
    } catch (error) {
      console.error(error);
      reject({
        error: true,
        message: error,
      });
    }
  });
};

export const getVariantAvailabilityData = (variant: IVariation) => {
  const versionsExist = !!variant.productsVersions && !!variant.productsVersions.versions;
  const hasRedirectVersion = versionsExist && !!variant.productsVersions?.hasRedirectVersion;
  const firstPartyVersion = variant.productsVersions?.versions?.find(
    (version) => hasRedirectVersion && typeof version.redirectLink !== undefined && !version.isDigitalKey
  );

  const firstPartyVersionAvailable = !!firstPartyVersion && firstPartyVersion.isAvailable;
  const isAvailable = (!firstPartyVersion && variant.availability.addToCartPossible) || firstPartyVersionAvailable;

  return {
    hasRedirectVersion,
    firstPartyVersion,
    isAvailable,
  };
};

export const getFormattedProductModelData = (productModel: IProductModel): IProductModel => {
  const productModelFormatter = new ProductModelFormatter(productModel);
  return productModelFormatter.formatMasters().formattedProductModel;
};

export const getMDMCustomerIdFromVariant = (productMasters: IMaster[]) => {
  const resultSetObject: Set<string> = new Set([]);

  productMasters.forEach((master) => {
    const nonEmptyVariation = master.variations.find((variation) => variation.mdmCustomerID !== '');

    if (nonEmptyVariation && nonEmptyVariation.mdmCustomerID !== undefined) {
      resultSetObject.add(nonEmptyVariation.mdmCustomerID);
    }
  });

  return Array.from(resultSetObject);
};
