export default {
  translations: {
    global: {
      subscription: {
        subscribeNow: 'REJOINDRE MAINTENANT',
        reactivate: 'RENOUVELER SON ABONNEMENT',
        resubscribe: 'Se réinscrire',
        subscribeUbisoftPlus: "S'abonner à Ubisoft+",
        playNowUbisoftPlus: 'Jouer maintenant avec Ubisoft+',
        goToMyLibrary: 'Accéder à la bibliothèque',
        changePlan: 'Changer de formule',
        upgradeNow: 'Upgrade Now',
        includedWithUbisoftPlus: 'Inclus avec Ubisoft+',
        dayOne: 'Day 1 avec Ubisoft+',
        service: "Service d'abonnement",
      },
      offerEnds: "L'offre se termine le {{date}}",
      outOfStock: 'Rupture de stock',
      from: 'à partir de',
      preOrder: 'PRÉCOMMANDER',
      buyNow: 'ACHETER MAINTENANT',
      getTheGame: 'OBTENIR LE JEU',
      addToCart: 'Ajouter au panier',
      playNow: 'Jouer maintenant',
      seeMore: 'Voir plus',
      month: 'mois',
      months: 'mois',
      day: 'jour',
      days: 'jours',
      year: 'an',
      years: 'ans',
      buyGame: 'Acheter le jeu',
      free: 'gratuit',
      freeTrial: 'Essai gratuit',
      tryForFree: 'Essayer gratuitement',
      then: 'puis',
      and: 'et',
      durationTerm: 'pour',
      containedOffers: 'articles inclus dans cette édition',
      gameOwned: 'Vous possédez cette édition',
      seeDetails: 'voir details',
      hide: 'masquer',
      subscribed: 'Abonné',
      buyOn: {
        playstation: 'Acheter sur PlayStation',
        microsoft: 'Acheter sur Xbox',
        nintendo: 'Acheter sur Nintendo',
      },
      ageGateRestrictionMessage:
        "Nous sommes désolés mais il semblerait que vous ne puissiez pas accéder à ce contenu dû à l'âge conseillé. Vous pouvez vous renseigner sur nos <0>Conditions générales</0>",
      monthly: 'Mensuel',
      yearly: 'Annuel',
      earlyAccess: 'Accès anticipé',
    },
    platforms: {
      PC: 'PC',
      PS4: 'PS4',
      PS5: 'PS5',
      Xbox1: 'Xbox One',
      XboxX: 'Xbox Series X|S',
      Android: 'Android',
      iOS: 'iOS',
      Switch: 'Switch',
      Stadia: 'Stadia',
      Luna: 'Luna',
    },
    versions: { digital: 'Digital', physical: 'Physique' },
    subscription: { promoprice: { month: 'puis {{price}} /mois', year: 'puis {{price}} /an' } },
  },
};
