import { m } from 'framer-motion';
import styled from 'styled-components';

export const Overlay = styled(m.div)`
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background: rgba(0, 0, 0, 0.3);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1010;
  opacity: 0;
`;

interface IModalContainerProps {
  offsetY?: string;
  backgroundColor?: string;
  textColor?: string;
  fullscreen?: boolean;
}

export const ModalContent = styled.div`
  position: relative;
  z-index: 1000;
  max-height: 100%;
  display: flex;
`;

export const ModalContainer = styled(m.div)<IModalContainerProps>`
  position: absolute;
  width: ${({ fullscreen }) => (fullscreen ? '100%' : '90%')};
  width: ${({ fullscreen }) => (fullscreen ? '100%' : 'clamp(50%, 700px, 90%)')};
  height: ${({ fullscreen }) => (fullscreen ? '100%' : 'auto')};
  background: ${({ theme, backgroundColor }) => backgroundColor || theme.colors.background.primary};
  color: ${({ theme, textColor }) => textColor || theme.colors.text.primary};
  top: ${({ offsetY, fullscreen }) => (fullscreen ? '0' : `${offsetY}`)};
  z-index: 1011;
  border-radius: 8px;

  @media screen and (min-width: 1080px) {
    width: ${({ fullscreen }) => (fullscreen ? '100%' : '50%')};
    width: ${({ fullscreen }) => (fullscreen ? '100%' : 'clamp(50%, 1660px, 90%)')};
  }
  ${ModalContent} {
    height: ${({ fullscreen }) => (fullscreen ? '100%' : 'auto')};
  }
`;

export const ModalContainerInner = styled.div`
  position: relative;
  height: 100%;
`;

interface IModalCloseButtonProps {
  textColor?: string;
}

export const ModalCloseButton = styled.button<IModalCloseButtonProps>`
  position: absolute;
  width: 2.125rem;
  right: 1.6875rem;
  top: 1.6875rem;
  cursor: pointer;
  background: none;
  border: none;
  transition: all 0.2s ease;
  z-index: 1012;
  padding: 0;

  &:focus-visible {
    svg {
      fill: ${({ theme }) => theme.colors.Black};
    }
  }
  svg {
    width: 100%;
    fill: ${({ theme, textColor }) => textColor || theme.colors.text.primary};
  }
`;
