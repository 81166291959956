import styled from 'styled-components';

export const OfferImage = styled.img`
  width: 100%;
`;

export const OfferModalContainer = styled.div`
  .modal-container-mate {
    max-width: 56.25rem;
  }
`;
