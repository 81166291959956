export default {
  translations: {
    title: {
      classics:
        'Iscriviti per giocare a <span class="blue-variant">{{gameName}}</span> e a una selezione sempre più ricca di indimenticabili giochi Ubisoft!',
      premium: 'Iscriviti per giocare a <span class="blue-variant">{{gameName}}</span> e a oltre 100 giochi per PC',
    },
    cancelAnytime: 'Annulla in qualsiasi momento',
    learnMore: 'Per saperne di più su Ubisoft+',
    classics: 'CLASSICS',
    premium: 'PREMIUM',
  },
};
