import { getURLCountryCode } from '@mooncake/utils';

export const getStoreRelativeURL = (url: string) => {
  const isUPC = window.location.pathname.indexOf('/upc/') > -1;
  const sfccLocale = window.GeoRestrictedResources?.GEORESTRICTED_CURRENTSITE;

  if (!sfccLocale) {
    throw new Error("GEORESTRICTED_CURRENTSITE is undefined");
  }

  return `${isUPC ? '/upc' : ''}/${getURLCountryCode(sfccLocale, isUPC)}/${url}`;
};
