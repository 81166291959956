import React, { KeyboardEvent } from 'react';
import { RootState, useAppDispatch } from '../../../../store';
import { SELECTOR_OPTION_SUBSCRIPTION, SFCC_PLATFORMS } from '@pdp-rework-hub/utils-constants';
import { WithTranslation, withTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import Tile from '../../../UI/Tiles/Tile';
import PlatformVersionSkeleton from '../PlatformVersionSkeleton';
import { PlatformsVersionsWrapper, PlatformsVersionsWrapperInner, PlatformVersionItemWrapper } from '../style';
import { selectPlatform } from '@pdp-rework-hub/slices';
import { IVariation } from '@pdp-rework-hub/utils-types';
import { getAmimatedProperties } from '../utils';
import { getVariantAvailabilityData } from '@pdp-rework-hub/utils';

const Platforms = ({ t }: WithTranslation) => {
  const platformStateSelector = useSelector((state: RootState) => ({
    selectedMaster: state.products.selectedMaster,
    product: state.products.data.product,
    fetching: state.products.fetching,
    selectedOption: state.products.selectedOption,
    selectedPlatform: state.products.selectedPlatform,
  }));
  const { selectedMaster, product, fetching, selectedOption, selectedPlatform } = platformStateSelector;
  const masters = product?.masters;
  const loading = !masters && fetching;
  const dispatch = useAppDispatch();

  const handlePlatformKeypress = (e: KeyboardEvent, variant: IVariation) => {
    if (e.key.toLowerCase() !== 'enter') return;
    dispatch(selectPlatform(variant));
  };

  if (selectedOption === SELECTOR_OPTION_SUBSCRIPTION) {
    return null;
  }

  if (loading) {
    return (
      <PlatformsVersionsWrapper>
        <h2>{t('selectYour.platform')}</h2>
        <PlatformVersionSkeleton />
      </PlatformsVersionsWrapper>
    );
  }

  if (!selectedMaster || !selectedPlatform) return null;

  return (
    <PlatformsVersionsWrapper>
      {selectedMaster.variations.length > 0 && <h2>{t('selectYour.platform')}</h2>}
      <PlatformsVersionsWrapperInner>
        {selectedMaster.variations.map((variant, index) => {
          const { isAvailable } = getVariantAvailabilityData(variant);
          return (
            <PlatformVersionItemWrapper key={`platform_${variant.id}_${index}`} {...getAmimatedProperties(index)}>
              <Tile
                altText={SFCC_PLATFORMS[variant.platform]?.displayValue}
                title={SFCC_PLATFORMS[variant.platform]?.displayValue}
                flexFlow="column"
                tileItemSizes={['60%', 'auto', '0%']}
                platform={variant.platform}
                handleClick={() => dispatch(selectPlatform(variant))}
                handleKeyPress={(e) => handlePlatformKeypress(e, variant)}
                active={variant.platform === selectedPlatform}
                checkPosition="top"
                available={isAvailable}
              />
            </PlatformVersionItemWrapper>
          );
        })}
      </PlatformsVersionsWrapperInner>
    </PlatformsVersionsWrapper>
  );
};

export default withTranslation()(Platforms);
