export default {
  translations: {
    selectYour: {
      edition: 'เลือกเอดิชั่นของคุณ',
      version: 'เลือกเวอร์ชันของคุณ',
      platform: 'เลือกแพลตฟอร์มของคุณ',
      payment: 'เลือกตัวเลือกการชำระเงิน',
    },
    selectPlatform: 'เลือกแพลตฟอร์ม',
    releaseDate: 'วันวางจำหน่าย:',
    buySelector: { subscription: { subscriptionSelection: 'การเลือกสมัครสมาชิก:' } },
    subscription: {
      title: '{{gameName}} และอีกมากมายด้วย Ubisoft<span class="blue">+</span>',
      alreadySubscribed: 'คุณสมัครบริการ Ubisoft+ แล้ว!',
      learnMoreAbout: 'เรียนรู้เพิ่มเติมเกี่ยวกับ <0>Ubisoft+</0>',
      cancelNotice: 'ยกเลิกและกลับมาใหม่ได้ทุกเมื่อ ข้อมูลที่คุณบันทึกไว้จะถูกเก็บไว้ <0>ดูคำถามที่พบบ่อย</0>',
      gamesSelection: {
        classics: {
          title: 'เกมที่โด่งดังของยูบิซอฟต์',
          description:
            "เพลิดเพลินกับเกมที่โด่งดังมากมาย เช่น Assassin's Creed Valhalla, Rainbow Six Siege และอื่น ๆ อีกมากมาย",
          link: { notSubscriber: 'ดูรายชื่อเกมที่รวมอยู่ในนี้', subscriber: 'เลือกดูเกม' },
        },
        premium: {
          title: '100+ เกม',
          description:
            "เล่นเกม Far Cry ทั้งหมดตอนนี้และ แคตตาล็อกเกมที่เพิ่มขึ้นต่อเนื่อง รวมถึง Assassin's Creed Valhalla, Rainbow Six Siege และอีกมากมาย",
          link: { notSubscriber: 'ดูเกมรวมทั้งหมด', subscriber: 'เลือกดูเกม' },
        },
      },
      cards: {
        cloudGaming: {
          title: 'คลาวด์เกมมิ่ง',
          description:
            'เล่นจากสมาร์ทโฟน แท็บเล็ต หรือทีวีได้ทันทีจากที่ที่คุณต้องการด้วยการเชื่อมต่ออินเทอร์เน็ตที่สมบูรณ์ผ่าน {{platforms}}',
          link: 'ดูเกมบนคลาวด์',
        },
        consoles: {
          title: 'คอนโซล',
          description: 'เล่นเกมที่เลือกจากคอนโซล Xbox {{platforms}} ของคุณด้วยการสมัครสมาชิกของเรา',
          link: 'ดูเกมคอนโซลที่รวมอยู่',
        },
      },
      partOfUbisoftPlus: {
        released: 'เล่น {{game}} {{edition}} ด้วย Ubisoft+',
        dlc: 'เล่น {{dlc}} ที่รวมอยู่ใน {{game}}',
        preOrder: 'เล่น {{game}} ได้ทันทีในวันวางจำหน่าย {{releaseDate}}',
        includedContent: 'คอนเทนต์ที่รวมอยู่ในเกม:',
      },
      firstParty: {
        legalLine:
          'ราคาที่แสดงใช้กับเวอร์ชันพีซีเท่านั้น<br/>โปรดตรวจสอบร้านค้าของแพลตฟอร์มที่เกี่ยวข้องเพื่อดูราคาสำหรับคอนโซล',
        pricingInfo: 'ดูราคาบนร้านค้าของแพลตฟอร์ม',
      },
      learnMore: 'เรียนรู้เพิ่มเติม',
      playThisGame: 'Play this game in your <span class="blue">Library+</span>!',
    },
  },
};
