import styled, { css, CSSProperties, keyframes } from 'styled-components';

const loading = keyframes`
  to{transform:translateX(100%)}
`;

export const BaseSkeleton = styled.div<CSSProperties>`
  position: relative;
  overflow: hidden;

  &::after {
    animation: ${loading} 1.5s infinite;
    content: '';
    height: 100%;
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
    transform: translateX(-100%);
    z-index: 1;
  }
`;

export const baseLoadingBackground = css`
  background: rgba(255, 255, 255, 0.2);
  background: linear-gradient(
    90deg,
    rgb(255 255 255 / 0%),
    rgb(255 255 255 / 10%),
    rgb(255 255 255 / 20%),
    rgb(255 255 255 / 0%)
  );
`;
