export default {
  translations: {
    global: {
      subscription: {
        subscribeNow: 'ÚNETE YA',
        reactivate: 'RENOVAR SUSCRIPCIÓN',
        resubscribe: 'Resuscribirme',
        subscribeUbisoftPlus: 'Suscríbete a Ubisoft+',
        playNowUbisoftPlus: 'Jugar ahora con Ubisoft+',
        goToMyLibrary: 'Ir a la biblioteca',
        changePlan: 'Cambiar de plan',
        upgradeNow: 'Upgrade Now',
        includedWithUbisoftPlus: 'Incluido con Ubisoft+',
        dayOne: 'Día 1 con Ubisoft+',
        service: 'Servicio de Suscripción',
      },
      offerEnds: 'La oferta finaliza el {{date}}',
      outOfStock: 'Agotado',
      from: 'de',
      preOrder: 'RESERVA',
      buyNow: 'COMPRA YA',
      getTheGame: 'CONSIGUE EL JUEGO',
      addToCart: 'Añadir al carrito',
      playNow: 'Juega YA',
      seeMore: 'Ver más',
      month: 'mes',
      months: 'meses',
      day: 'dia',
      days: 'dias',
      year: 'año',
      years: 'años',
      buyGame: 'Compra el juego',
      free: 'GRATIS',
      freeTrial: 'Prueba gratuita',
      tryForFree: 'Probar gratis',
      then: 'luego',
      and: 'y',
      durationTerm: 'para',
      containedOffers: 'objetos incluido en esta edición',
      gameOwned: 'Ya tienes esta edición',
      seeDetails: 'ver detalles',
      hide: 'ocultar',
      subscribed: 'Suscrito',
      buyOn: { playstation: 'Comprar en PlayStation', microsoft: 'Comprar en Xbox', nintendo: 'Comprar en Nintendo' },
      ageGateRestrictionMessage:
        'Lamentablemente no tienes acceso a este contenido debido a restricciones de edad. Por favor, revisa nuestra <0>Política de Privacidad</0>',
      monthly: 'Mensual',
      yearly: 'Anual',
      earlyAccess: 'Acceso anticipado',
    },
    platforms: {
      PC: 'PC',
      PS4: 'PS4',
      PS5: 'PS5',
      Xbox1: 'Xbox One',
      XboxX: 'Xbox Series X|S',
      Android: 'Android',
      iOS: 'iOS',
      Switch: 'Switch',
      Stadia: 'Stadia',
      Luna: 'Luna',
    },
    versions: { digital: 'Digital', physical: 'Físico' },
    subscription: { promoprice: { month: 'después, {{price}}/mes', year: 'después, {{price}}/año' } },
  },
};
