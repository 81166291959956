import React from 'react';
import {
  SelectedProductContentSection,
  SelectedProductLoaderWrapper,
  SelectedProductMediaSection,
  SelectedProductWrapper,
  SelectedProductWrapperInner,
} from './style';
import Media from './Media';
import SelectedProductContent from './Content';
import { TileSkeletonWrapperItem } from '../../../UI/Tiles/style';
import UbisoftIcon from '../../../UI/Icons/Ubisoft/UbisoftIcon';
import { useWithProductOffers } from '../../../hook/useWithProductOffers';
import ShowcaseProductOffers from './ShowcaseProductOffers';
import { getMDMCustomerIdFromVariant } from '@pdp-rework-hub/utils';

const SelectedProduct = () => {
  const { selectedMaster, fetching, productOffers, fetchingProductOffer } = useWithProductOffers();
  if (!selectedMaster && fetching) {
    return (
      <SelectedProductLoaderWrapper>
        <TileSkeletonWrapperItem width="100%" height="100%">
          <UbisoftIcon />
        </TileSkeletonWrapperItem>
      </SelectedProductLoaderWrapper>
    );
  }

  if (!selectedMaster) {
    return null;
  }

  const mdmCustomerOfferId = getMDMCustomerIdFromVariant([selectedMaster]);
  const targetProductOffer = productOffers[mdmCustomerOfferId[0]];

  return (
    <SelectedProductWrapper className="selected-product-wrapper">
      <SelectedProductWrapperInner className="selected-product-inner">
        <SelectedProductMediaSection>
          <Media product={selectedMaster} />
        </SelectedProductMediaSection>
        <SelectedProductContentSection>
          <SelectedProductContent product={selectedMaster} />
        </SelectedProductContentSection>
      </SelectedProductWrapperInner>
      <ShowcaseProductOffers
        product={selectedMaster}
        loading={fetchingProductOffer}
        productOffer={targetProductOffer}
      />
    </SelectedProductWrapper>
  );
};

export default SelectedProduct;
