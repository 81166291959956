import React from 'react';
import { useSelector } from 'react-redux';
import {
  ProductOfferFeatureContent,
  ProductOfferWrapper,
  ProductOfferFeatureContentInner,
  ProductOfferImageWrapper,
  ProductOfferFeatureTile,
  ProductOfferFeatureContentItem,
  ProductOfferFeatureText,
} from './style';
import { TileSkeleton } from '../../../../UI/Tiles/TileSkeleton';
import { IMaster, IContain, ICustomerOffer } from '@pdp-rework-hub/utils-types';
import Text from '@mooncake/ui/lib/components/Text';
import { ChevronRightIcon } from '@pdp-rework-hub/ui';
import { setSelectedOffer } from '@pdp-rework-hub/slices';
import { prepareProductEvent, sendAdobeEvent } from '@pdp-rework-hub/utils';
import { RootState, useAppDispatch } from '../../../../../store';
import { WEBDAV_URL } from '@pdp-rework-hub/utils-constants';

interface IShowcaseProductOffersProps {
  productOffer?: ICustomerOffer | null;
  loading: boolean;
  product: IMaster;
}

const ShowcaseProductOffers = ({ productOffer, loading, product }: IShowcaseProductOffersProps) => {
  const dispatch = useAppDispatch();
  const productInfo = useSelector((state: RootState) => state.products.data.product);

  if (loading && !productOffer) {
    return (
      <ProductOfferWrapper>
        {[...Array(3)].map((_, index) => (
          <TileSkeleton type="vertical" key={`product-offer-skeleton-${index}`} />
        ))}
      </ProductOfferWrapper>
    );
  }

  const openOfferModal = (featureContent: IContain) => {
    dispatch(setSelectedOffer(featureContent));
    const { name } = product;
    const baseTrackingEvent = {
      location: 'buy selector',
      locationPosition: 'overlay',
      locationDetail: 'edition detail',
      action: `${name} : see more`,
      category: 'interaction',
    };
    const productTrackingInfo = prepareProductEvent(productInfo, product);
    const trackingEvent = {
      ...baseTrackingEvent,
      ...productTrackingInfo,
    };
    sendAdobeEvent(trackingEvent);
  };

  return (
    <ProductOfferWrapper>
      <ProductOfferFeatureContent>
        {productOffer?.contains.map((contain: IContain, index) => {
          return (
            <ProductOfferFeatureContentItem key={`${contain.title}-${index}`}>
              <ProductOfferFeatureContentInner>
                {contain.title && (
                  <ProductOfferFeatureTile
                    as={contain.shortDescription ? 'button' : 'div'}
                    onClick={() => (contain.shortDescription ? openOfferModal(contain) : null)}
                  >
                    <ProductOfferImageWrapper>
                      <img
                        src={
                          contain.icon?.url
                            ? contain.icon.url
                            : `${WEBDAV_URL}/Library-Sites-shared-library-web/default/pdp-rework-hub/assets/boxshot_fallback.png`
                        }
                        alt={contain.title}
                      />
                    </ProductOfferImageWrapper>

                    <ProductOfferFeatureText>
                      <Text.Heading as={'h3'} bold>
                        {contain.shortTitle} {contain.shortDescription ? <ChevronRightIcon /> : null}
                      </Text.Heading>
                    </ProductOfferFeatureText>
                  </ProductOfferFeatureTile>
                )}
              </ProductOfferFeatureContentInner>
            </ProductOfferFeatureContentItem>
          );
        })}
      </ProductOfferFeatureContent>
    </ProductOfferWrapper>
  );
};

export default ShowcaseProductOffers;
