import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { setSelectedOffer } from '@pdp-rework-hub/slices';
import { sendAdobeEvent } from '@pdp-rework-hub/utils';
import { IAdobeProductImpressionEvent, IContain } from '@pdp-rework-hub/utils-types';

import { OfferImage, OfferModalContainer } from './styles';
import Container from '@mooncake/ui/lib/components/Container';
import Text from '@mooncake/ui/lib/components/Text';
import Modal from '../';

interface IOffersList {
  textColor?: string;
  backgroundColor?: string;
  offer: IContain | null;
  location?: string;
  locationPosition: string | null;
}

const OfferModal = ({
  textColor,
  backgroundColor,
  offer,
  location = 'comparison table',
  locationPosition,
}: IOffersList) => {
  useEffect(() => {
    if (offer) {
      const impressionEvent: IAdobeProductImpressionEvent = {
        location,
        locationDetail: 'edition detail',
        category: 'display',
        action: 'visible',
        productImpression: [offer.ecommerceMasterId],
      };
      if (locationPosition) {
        impressionEvent.locationPosition = locationPosition;
      }
      sendAdobeEvent(impressionEvent, 'impressionevent');
    }
  }, [offer]);

  const dispatch = useDispatch();
  const closeModal = () => {
    dispatch(setSelectedOffer(null));
    const closeClickEvent = {
      location,
      locationPosition: 'pop in',
      locationDetail: 'edition detail',
      action: 'close pop in',
      category: 'interaction',
    };
    sendAdobeEvent(closeClickEvent);
  };

  return (
    offer && (
      <OfferModalContainer>
        <Modal
          offsetY="5vh"
          animated
          isOpen={!!offer}
          handleClose={() => closeModal()}
          backgroundColor={backgroundColor}
          textColor={textColor}
        >
          <Container
            display={'flex'}
            flexDirection={{
              default: 'column',
              tablet: 'row',
            }}
            padding={{
              default: '2.5rem 1.25rem 1.25rem',
              tabletLarge: '2.5rem 4.125rem 2.5rem 2.5rem',
            }}
            width={'100%'}
          >
            {offer.boxshot && offer.boxshot.url && (
              <Container
                flex={{
                  tabletLarge: '0 0 40%',
                }}
                padding={{
                  default: '0 0 0.625rem 0',
                  tablet: '0 1.25rem 0 0',
                  tabletLarge: '0 2.5rem 0 0',
                }}
              >
                <OfferImage src={offer.boxshot.url} alt={''} loading={'lazy'} />
              </Container>
            )}
            <Container flex={'0 0 60%'}>
              <Text.Heading fontSize={'1.875rem'} bold>
                {offer.shortTitle}
              </Text.Heading>
              <Text.Paragraph
                color="unset"
                fontFamily="unset"
                textAlign="unset"
                lineHeight={1.5}
                fontSize={'1.125rem'}
                dangerouslySetInnerHTML={{ __html: offer?.shortDescription.replace(/\\n/g, '<br />') }}
              />
            </Container>
          </Container>
        </Modal>
      </OfferModalContainer>
    )
  );
};

export default OfferModal;
