import React from 'react';

const UbisoftPlusIcon = () => {
  return (
    <svg width="180" height="28" viewBox="0 0 180 28" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g id="Vector">
        <path d="M125.25 6.20567H132.84V27.2432H138.696V6.20567H146.25V0.756715H125.25V6.20567Z" fill="white" />
        <path
          d="M16.5856 16.3673C16.5856 20.2686 14.7152 22.4885 11.25 22.4885C7.78483 22.4885 5.918 20.2686 5.918 16.3673V0.756715H0V16.8256C0 23.216 3.96952 28 11.25 28C18.5305 28 22.5 23.216 22.5 16.8256V0.756715H16.5856V16.3673Z"
          fill="white"
        />
        <path
          d="M88.5009 0C84.1779 0 80.5001 1.66349 78.4888 3.79016L79.399 4.71833C76.7815 6.9225 75 10.2873 75 14.9264C75 22.0832 80.4241 28 88.5009 28C96.5778 28 102 21.966 102 14C102 6.03399 96.3904 0 88.5009 0ZM88.5009 22.431C83.6848 22.431 80.8412 18.8337 80.8412 14.7353C80.8412 11.758 82.0925 10.0945 83.4957 8.89598L82.5855 7.96782C83.798 6.61432 85.8092 5.569 88.5027 5.569C93.1296 5.569 96.1624 9.12848 96.1624 14.0018C96.1624 18.8751 93.1296 22.431 88.5027 22.431H88.5009Z"
          fill="white"
        />
        <path
          d="M39.2739 12.8273C41.4624 11.9562 42.9369 10.1398 42.9369 7.15086C42.9369 3.21668 39.3945 0.756715 34.0215 0.756715H24.75V27.2432H34.3813C40.472 27.2432 44.25 23.9509 44.25 19.3731C44.25 15.818 42.221 13.8871 39.2758 12.8291L39.2739 12.8273ZM30.7981 5.82653H33.9807C35.8502 5.82653 37.1244 6.84755 37.1244 8.62507C37.1244 10.4026 35.8502 11.4254 33.9807 11.4254H30.7981V5.82653ZM34.1402 22.1716H30.7981V15.513H34.1402C36.7256 15.513 38.1982 16.6468 38.1982 18.8423C38.1982 21.0377 36.7256 22.1716 34.1402 22.1716Z"
          fill="white"
        />
        <path
          d="M104.25 27.2432H110.2V16.7244H121.823V12.3371H110.2V6.20567H123V0.756715H104.25V27.2432Z"
          fill="white"
        />
        <path d="M51.75 0.756715H46.5V27.2432H51.75V0.756715Z" fill="white" />
        <path
          d="M66.333 11.4065C62.1969 9.97554 60.6989 9.00772 60.6989 7.42353C60.6989 6.03218 62.0372 5.06617 63.8491 5.06617C66.0154 5.06617 67.4345 6.18718 68.3395 8.19851L73.1053 4.95082C71.5706 1.66349 68.2991 0 64.0841 0C58.7676 0 55.1033 3.28733 55.1033 7.61818C55.1033 11.949 58.2536 14.191 62.509 15.6995C66.4486 17.0927 67.5116 18.0984 67.5116 19.7222C67.5116 21.4596 66.0925 22.5067 64.1227 22.5067C61.8793 22.5067 59.6323 20.9982 58.7676 18.5615L54 22.0435C55.5384 25.9094 59.2009 28 63.9666 28C69.1271 28 73.5 24.9055 73.5 19.2987C73.5 15.121 71.0584 13.034 66.3312 11.4083L66.333 11.4065Z"
          fill="white"
        />
        <path
          d="M180 10.6512H168.908V0.756715H162.182V10.6512H152.25V17.3504H162.182V27.2432H168.908V17.3504H177.181L180 10.6512Z"
          fill="white"
        />
      </g>
    </svg>
  );
};

export default UbisoftPlusIcon;
