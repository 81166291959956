import styled from 'styled-components';
import Container from '@mooncake/ui/lib/components/Container';

export const BlockContainer = styled(Container)`
  p:last-child {
    margin-bottom: 0;
  }
  p:first-child {
    margin-top: 0;
  }
`;
