import React from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '../../store';
import Selector from '../Blocks/Selector';
import Showcase from '../Blocks/Showcase';
import { ICustomAppProps } from '../../AppProps';
import { TileSkeletonWrapperItem } from '../UI/Tiles/style';
import { BuySelectorHeading, BuySelectorInner, BuySelectorWrapper } from './style';

const BuySelector = ({ background }: ICustomAppProps) => {
  const buyStateSelector = useSelector((state: RootState) => ({
    products: state.products.data.product,
    errorData: state.products.errorData,
  }));
  const { products, errorData } = buyStateSelector;

  if (errorData && errorData.error && errorData.message) {
    return (
      <BuySelectorWrapper>
        <p>{errorData.message}</p>
      </BuySelectorWrapper>
    );
  }

  const title = products?.masters?.length && products.masters[0].name;

  return (
    <BuySelectorWrapper>
      <BuySelectorHeading>
        {title ? <h2>{title}</h2> : <TileSkeletonWrapperItem width="160px" height="16px" />}
      </BuySelectorHeading>
      <BuySelectorInner>
        <Showcase background={background} />
        <Selector />
      </BuySelectorInner>
    </BuySelectorWrapper>
  );
};

export default BuySelector;
