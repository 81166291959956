import { Variant, Variants } from 'framer-motion';

export type ModalAnimationType = 'tween' | 'spring' | '';

export const overlayVariants: { [key: string]: Variant } = {
  initial: { opacity: 0 },
  isOpen: { opacity: 1 },
  exit: { opacity: 0 },
};

export const computeModalContainerVariants: (type: ModalAnimationType, duration: number) => Variants = (
  type,
  duration
) => ({
  initial: {
    y: '-20px',
    transform: 'scale(1.1)',
    opacity: 0,
  },
  isOpen: {
    y: '0',
    transform: 'scale(1)',
    opacity: 1,
    transition: {
      type,
      duration,
    },
  },
  exit: {
    transform: 'scale(0.9)',
    y: '20px',
    opacity: 0,
  },
});

export const toggleBodyOverflow = (isOpen: boolean) => {
  const body = document.body;

  if (isOpen) {
    body.style.overflowY = 'hidden';
  } else {
    body.style.overflowY = '';
  }
};
