import styled from 'styled-components';

interface ICloseIconProps {
  fill: string;
}

export const CloseIconWrapper = styled.div<ICloseIconProps>`
  svg {
    fill: ${(props) => props.fill};
  }
`;
