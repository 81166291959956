import { pollWindowVariables } from '@mooncake/mooncake-gateway-core/utils';
import { setIsSubscriptionButtonRestricted, setUserIsMinor } from '@pdp-rework-hub/slices';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { getCookieValue } from './global';

type AgeGateFormInputValidation = {
  isBlocked: boolean;
};

type UserRestrictions = {
  product: boolean;
  subscription: boolean;
};

export const useCheckUserRestrictions = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    checkUserRestrictions().then((value) => {
      dispatch(setUserIsMinor(value.product));
      dispatch(setIsSubscriptionButtonRestricted(value.subscription));
    });
  }, []);
};

export const checkUserRestrictions = async () => {
  return new Promise<UserRestrictions>((resolve) => {
    pollWindowVariables<[typeof window.Agegate, typeof window.subscriptionAgeGateRating]>([
      'Agegate',
      'subscriptionAgeGateRating',
    ]).then((response) => {
      const [ageGate, subscriptionAgeGateRating] = response;
      if (ageGate?.restrictedUserClasses?.includes('underage')) {
        // We know if the user is restricted based on the class
        resolve({
          product: true,
          subscription: true,
        });
      } else if (ageGate?.restrictedUserClasses?.includes('unknown')) {
        // Otherwise, listen to the age gate modal validation event
        initAgeGateWatching().then((value) => {
          resolve({
            product: value,
            subscription: value,
          });
        });
      } else if (
        ageGate?.restrictedUserClasses === '' &&
        subscriptionAgeGateRating?.ageGatingEnable &&
        subscriptionAgeGateRating?.ageGatingVersion === 'v1' &&
        subscriptionAgeGateRating?.restrictedAge
      ) {
        // If the product is not restricted, we'll get the cookie value of age_classification to see if the user is minor and block only subscription CTAs
        const ageClassification = getCookieValue('age_classification');
        resolve({
          product: false,
          subscription: ageClassification === '0',
        });
      } else {
        resolve({
          product: false,
          subscription: false,
        });
      }
    });
  });
};

export const initAgeGateWatching = () => {
  return new Promise<boolean>((resolve) => {
    document.addEventListener('AgeGate:FormInputValidation', ((e: CustomEvent<AgeGateFormInputValidation>) => {
      const { detail } = e;
      resolve(detail.isBlocked as boolean);
    }) as EventListener);
  });
};
