/**
 * Instruction to add language manually: The country/locale code should be in kebab case
 * Example: en-US.json
 * import enUS from "./en-US";
 * export default {
 *   ...
 *   "en-US": enUS
 * }
 */
import en from './en';

export default {
  en: en,
};
