import breakpoints from '../../../../themes/utils/breakpoints';
import styled from 'styled-components';
import { TileSkeletonWrapper, TileWrapper } from '../../../UI/Tiles/style';

export const AccordionDetails = styled.div`
  > div {
    max-width: 100%;
    margin: 0 auto;
    padding: 0 1rem 0.5rem;
    background: none;

    .subscription-title {
      display: none;
    }
  }
`;

export const ProductEditionsWrapper = styled.div`
  h2 {
    margin: 0 0 0.4em;
    font-size: 1.1em;
  }
  ${TileWrapper} {
    margin: 1.3em 0;
  }
`;

export const EditionsContainer = styled.div`
  padding: 0 2rem 2rem;

  @media screen and (max-width: ${breakpoints.mobile}) {
    padding: 0 1rem 1rem;
  }
`;

export const EditionsWrapper = styled.div`
  ${TileSkeletonWrapper} {
    border-radius: 5px;
  }
`;

export const SubPlanTypeWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
  width: 100%;

  > div {
    width: 100%;
  }
`;
