import { m } from 'framer-motion';
import React from 'react';
import { useTheme } from 'styled-components';

interface ISelectedIconProps {
  fill?: string;
}

export const SelectedIcon = ({ fill }: ISelectedIconProps) => {
  const theme = useTheme();
  const color = fill || theme.colors.DodgerBlue;
  return (
    <m.span className="selected-icon-wrapper">
      <svg width="24" height="24" viewBox="0 0 24 23" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M11.8544 21.8571C17.7718 21.8571 22.5687 17.2201 22.5687 11.5C22.5687 5.77987 17.7718 1.14282 11.8544 1.14282C5.93709 1.14282 1.14014 5.77987 1.14014 11.5C1.14014 17.2201 5.93709 21.8571 11.8544 21.8571Z"
          stroke={color}
          strokeWidth="1.5"
        />
        <path
          d="M10.4339 13.9351L16.0905 7.45632C16.0906 7.45624 16.0907 7.45615 16.0907 7.45607C16.1546 7.3825 16.2322 7.32249 16.3195 7.27977C16.4068 7.23703 16.5017 7.21254 16.5986 7.20796C16.6955 7.20339 16.7922 7.21882 16.8831 7.25321L16.83 7.39331L16.8831 7.25321C16.9739 7.28761 17.0569 7.34024 17.1272 7.40775L17.1276 7.40812C17.2693 7.54504 17.3526 7.73279 17.3609 7.93086C17.3693 8.12895 17.302 8.32321 17.1727 8.47202L17.1725 8.47226L10.9988 15.5436C10.9988 15.5436 10.9988 15.5436 10.9988 15.5436C10.933 15.6195 10.8524 15.6809 10.7621 15.7239C10.6718 15.7668 10.5737 15.7903 10.4739 15.7927C10.374 15.7951 10.2749 15.7763 10.1827 15.7377C10.0904 15.6991 10.0072 15.6415 9.93797 15.5688C9.93795 15.5688 9.93793 15.5688 9.93791 15.5687L6.55905 12.0328L6.55884 12.0325C6.42273 11.8895 6.34731 11.6981 6.34731 11.4996C6.34731 11.3012 6.42272 11.1097 6.55882 10.9667L10.4339 13.9351ZM10.4339 13.9351L7.59654 10.9667C7.59649 10.9667 7.59644 10.9666 7.59639 10.9666C7.52923 10.896 7.44887 10.8396 7.35978 10.8011C7.27061 10.7625 7.17463 10.7425 7.07768 10.7425C6.98068 10.7425 6.88487 10.7625 6.79575 10.8011C6.70671 10.8396 6.62613 10.896 6.55884 10.9667L10.4339 13.9351Z"
          fill={color}
          stroke={color}
          strokeWidth="0.3"
        />
      </svg>
    </m.span>
  );
};

export default SelectedIcon;
