export default {
  translations: {
    global: {
      subscription: {
        subscribeNow: '지금 구독하세요',
        reactivate: 'Ubisoft+ 재활성화',
        resubscribe: '다시 구독하기',
        subscribeUbisoftPlus: 'Ubisoft+를 구독',
        playNowUbisoftPlus: 'Ubisoft+에서 지금 플레이하세요',
        goToMyLibrary: '라이브러리로 이동',
        changePlan: '플랜 변경하기',
        upgradeNow: 'Upgrade Now',
        includedWithUbisoftPlus: 'Ubisoft+에 포함',
        dayOne: 'Ubisoft+으로 출시일부터 플레이',
        service: '구독 서비스',
      },
      offerEnds: '혜택은 {{date}}에 종료됩니다.',
      outOfStock: '재고 없음',
      from: 'xx부터',
      preOrder: '예약 구매 하기',
      buyNow: '지금 구매하세요',
      getTheGame: '지금 얻으세요',
      addToCart: '장바구니에 추가',
      playNow: '지금 플레이하세요',
      seeMore: '더 알아보기',
      month: '월',
      months: '월',
      day: '일',
      days: '일',
      year: '년도',
      years: '연령',
      buyGame: '게임 구매',
      free: '무료',
      freeTrial: '무료 체험',
      tryForFree: '무료로 체험하세요',
      then: '그 다음',
      and: '와',
      durationTerm: '에게',
      containedOffers: '해당 에디션에 포함',
      gameOwned: '이 에디션을 이미 보유하고 있습니다',
      seeDetails: '상세 내용 확인',
      hide: '숨기기',
      subscribed: '구독자',
      buyOn: {
        playstation: 'Playstation에서 구입하기',
        microsoft: 'Xbox에서 구입하기',
        nintendo: 'Nintendo에서 구입하기',
      },
      ageGateRestrictionMessage:
        '죄송합니다. 연령 제한으로 인해 해당 컨텐츠를 이용하실 수 없습니다. 우리의 <0>개인정보 보호 정책 </0>을 확인해주세요',
      monthly: '월간',
      yearly: '연간',
      earlyAccess: '사전 액세스',
    },
    platforms: {
      PC: 'PC',
      PS4: 'PS4',
      PS5: 'PS5',
      Xbox1: 'Xbox One',
      XboxX: 'Xbox Series X|S',
      Android: 'Android',
      iOS: 'iOS',
      Switch: 'Switch',
      Stadia: 'Stadia',
      Luna: 'Luna',
    },
    versions: { digital: '디지털', physical: '실물 상품' },
    subscription: { promoprice: { month: '이후 {{price}}/개월', year: '이후 {{price}}/년' } },
  },
};
