export default {
  translations: {
    title: {
      classics:
        'Subscribe to play <span class="blue-variant">{{gameName}}</span> and an evolving selection of iconic Ubisoft games!',
      premium: 'Subscribe to play <span class="blue-variant">{{gameName}}</span> and 100+ PC games',
    },
    cancelAnytime: 'Cancel anytime',
    learnMore: 'Learn more about Ubisoft+',
    classics: 'CLASSICS',
    premium: 'PREMIUM',
  },
};
