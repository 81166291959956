export default {
  translations: {
    title: {
      classics:
        'Abonniere den Dienst, um <span class="blue-variant">{{gameName}}</span> und eine stetig wachsende Auswahl legendärer Ubisoft-Spiele zu spielen!',
      premium: 'Abonniere und spiele <span class="blue-variant">{{gameName}}</span> und über 100 weitere PC-Spiele',
    },
    cancelAnytime: 'Jederzeit kündbar',
    learnMore: 'Mehr erfahren Über Ubisoft+',
    classics: 'CLASSICS',
    premium: 'PREMIUM',
  },
};
