import React from 'react';
import { sendAdobeEvent, formatDate } from '@pdp-rework-hub/utils';
import { WithTranslation, withTranslation } from 'react-i18next';
import {
  PDP_BUYSUB_MODAL_OPEN,
  SUBSCRIPTION_CART_EVENT,
  SELECTOR_OPTION_SUBSCRIPTION,
  SUBSCRIPTION_BUTTON_TYPE,
} from '@pdp-rework-hub/utils-constants';
import { useTheme } from 'styled-components';
import { IAdobeProductClickEvent, IAdobeEvent } from '@pdp-rework-hub/utils-types';
import { useGetSubButtonInfo } from './useGetSubButtonInfo';

import Text from '@mooncake/ui/lib/components/Text';
import AppLoader from '../../AppLoader';
import { Button } from '../../index';
import { ButtonLoadingContent } from './styles';

interface ISubscriptionButtonProps extends WithTranslation {
  isPreOrder?: boolean;
  trackingEvent: IAdobeEvent | IAdobeProductClickEvent;
  isButtonRestricted: boolean;
  isModalTrigger?: boolean;
}

const SubscriptionButton = ({
  isPreOrder = false,
  t,
  trackingEvent,
  isButtonRestricted,
  isModalTrigger = false,
}: ISubscriptionButtonProps) => {
  const theme = useTheme();
  const subscriptionButtonInfo = useGetSubButtonInfo({
    t,
    isPreOrder,
  });

  if (!subscriptionButtonInfo) {
    return (
      <Button buttonType="subscription" disabled>
        <ButtonLoadingContent>
          <AppLoader />
        </ButtonLoadingContent>
      </Button>
    );
  }

  const { content, type, promotions, selectedSubscription } = subscriptionButtonInfo;

  const handleSubscriptionAction = () => {
    if (isButtonRestricted) {
      return;
    }

    sendAdobeEvent(trackingEvent);

    const isUPC = window.location.pathname.indexOf('/upc/') > -1;

    if (type === SUBSCRIPTION_BUTTON_TYPE.GO_TO_LIBRABRY) {
      window.location.href = isUPC ? 'uplay://open/plus' : 'https://plus.ubisoft.com';
      return;
    }

    if (isModalTrigger) {
      const openSubModalEvent = new CustomEvent<{ tab: string }>(PDP_BUYSUB_MODAL_OPEN, {
        detail: {
          tab: SELECTOR_OPTION_SUBSCRIPTION,
        },
      });
      document.dispatchEvent(openSubModalEvent);
      return;
    }

    if (!selectedSubscription) {
      return;
    }

    let CART_EVENT_TYPE;
    switch (type) {
      case SUBSCRIPTION_BUTTON_TYPE.CHANGE:
        CART_EVENT_TYPE = SUBSCRIPTION_CART_EVENT.CHANGE;
        break;
      case SUBSCRIPTION_BUTTON_TYPE.PLAY:
        CART_EVENT_TYPE = SUBSCRIPTION_CART_EVENT.PLAY;
        break;
      default:
        CART_EVENT_TYPE = SUBSCRIPTION_CART_EVENT.SUBSCRIBE;
        break;
    }

    const subscriptionCartActionEvent = new CustomEvent<{ pid: string }>(CART_EVENT_TYPE, {
      detail: {
        pid: selectedSubscription.id,
      },
    });

    document.dispatchEvent(subscriptionCartActionEvent);
  };

  return (
    <>
      <Button buttonType="subscription" handleClick={handleSubscriptionAction} disabled={isButtonRestricted}>
        {content}
      </Button>
      {promotions.length > 0 && promotions[0].endDate && (
        <Text.Paragraph fontSize={'0.8125rem'} marginTop={'0.5rem'} color={theme.colors.SantasGray}>
          {t('global.offerEnds', { date: formatDate(promotions[0].endDate)?.toLocaleDateString() })}
        </Text.Paragraph>
      )}
    </>
  );
};

export default withTranslation()(SubscriptionButton);
