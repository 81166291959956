import React from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../../../store';

interface IOffersListProps {
  productId: string;
}

const OffersList = ({ productId }: IOffersListProps) => {
  const { productOffers } = useSelector((state: RootState) => ({
    productOffers: state.products.productOffers,
  }));

  const currentProductOffersContains = productOffers[productId]?.contains;

  if (!productOffers[productId] || !currentProductOffersContains) {
    return null;
  }

  const currentProductOffersDisplay = currentProductOffersContains
    .map((contain) => {
      return contain.shortTitle;
    })
    .join(' + ');

  return <>{currentProductOffersDisplay}</>;
};

export default OffersList;
