import React from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';

import Container from '@mooncake/ui/lib/components/Container';
import { useSubCartAction } from './useSubCartAction';
import { SubscriptionButton, SubscriptionPrice } from '@pdp-rework-hub/ui';

import { CartActionWrapper, CartButtonWrapper, ReleaseDate } from '../style';

const SubCartAction = ({ t }: WithTranslation) => {
  const cartAction = useSubCartAction();
  
  if (!cartAction) {
    return null;
  }
  
  const {
    baseEventDetails,
    activeSubscription,
    disabled,
    isPreOrder,
    isSubscriptionButtonRestricted,
    releaseDateToDisplay,
    selectedSubscription,
    subscriptionEventDetails,
  } = cartAction;
  
  return (
    <CartActionWrapper isDisabled={disabled}>
      {isPreOrder && releaseDateToDisplay ? (
        <ReleaseDate>
          <span>{t('releaseDate')}</span> {releaseDateToDisplay}
        </ReleaseDate>
      ) : null}
      {!!selectedSubscription && activeSubscription?.offerId !== selectedSubscription.offerId && (
        <Container fontSize={'1.625rem'}>
          <SubscriptionPrice subscriptions={[selectedSubscription]} />
        </Container>
      )}
      <CartButtonWrapper>
        {selectedSubscription ? (
          <SubscriptionButton
            isPreOrder={isPreOrder}
            trackingEvent={subscriptionEventDetails || baseEventDetails}
            isButtonRestricted={isSubscriptionButtonRestricted}
          />
        ) : null}
      </CartButtonWrapper>
    </CartActionWrapper>
  );
};

export default withTranslation()(SubCartAction);
