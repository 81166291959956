import React from 'react';
import { m } from 'framer-motion';
import Container from '@mooncake/ui/lib/components/Container';
import {
  appearingAnimationParams,
  BreakpointsImages,
  GlobalStyle,
  HeadingResetContainer,
  TextResetContainer,
  withLazyMotion,
} from '@pdp-rework-hub/ui';
import { ICustomAppProps } from '../../AppProps';

import { useTheme } from 'styled-components';
import { BlockContainer, ImageContainer } from './styles';

interface IBlockPositionAttributes {
  right?: number;
  bottom?: number;
}

const Content = ({
  backgroundimage,
  blockposition,
  textcontent,
  textcolor,
  titlecontent,
  titlecolor,
}: ICustomAppProps) => {
  const theme = useTheme();
  const blockPositionAttributes: IBlockPositionAttributes = {};
  if (blockposition && blockposition.indexOf('-right') > -1) {
    blockPositionAttributes.right = 0;
  }
  if (blockposition && blockposition.indexOf('Down-') > -1) {
    blockPositionAttributes.bottom = 0;
  }
  return (
    <BlockContainer>
      <GlobalStyle />
      {titlecontent && textcontent ? (
        <Container
          width={'calc(100% - 2.5rem)'}
          maxWidth={{
            tablet: '25rem',
            desktop: '38rem',
          }}
          position={'absolute'}
          blockposition={blockposition}
          padding={{
            default: '1.25rem',
            desktop: '2.5rem',
          }}
          margin={{
            default: '1.25rem',
            desktop: '5rem',
          }}
          color={theme.colors.text.primary}
          backgroundColor={theme.SmallDescription.backgroundColor}
          style={blockPositionAttributes}
        >
          <m.div {...appearingAnimationParams(0.1)}>
            <HeadingResetContainer
              as="h3"
              primary
              dangerouslySetInnerHTML={{ __html: titlecontent }}
              marginBottom={{
                default: '1rem',
              }}
              fontSize={{
                default: '1.5rem',
                desktop: '2.5rem',
              }}
              fontFamily={`Custom Font, ${theme.fonts.bold}`}
              color={titlecolor}
            />
          </m.div>
          <m.div {...appearingAnimationParams(0.2)}>
            <TextResetContainer
              as="div"
              dangerouslySetInnerHTML={{ __html: textcontent }}
              marginBottom={'0'}
              fontSize={{
                default: '1rem',
                desktop: '1.125rem',
              }}
              color={textcolor}
            />
          </m.div>
        </Container>
      ) : null}
      <ImageContainer>
        <BreakpointsImages source={backgroundimage} />
      </ImageContainer>
    </BlockContainer>
  );
};

export default withLazyMotion(Content);
