import breakpoints from '../../../../../../themes/utils/breakpoints';
import styled from 'styled-components';
import { ProductOfferWrapper } from '../../../../Showcase/SelectedProduct/ShowcaseProductOffers/style';
import {
  TileSkeletonWrapper,
  TileSkeletonWrapperInner,
  ProductOfferTileWrapper,
  ProductOfferTileContent,
} from '../../../../../UI/Tiles/style';

export const MobileShowcaseWrapper = styled.div`
  position: relative;
  z-index: 2;
  width: 100%;
  height: auto;
  background: ${({ theme }) => theme.colors.BlackPearl};
  border-radius: 0 0 4px 4px;

  @media screen and (min-width: ${breakpoints.minTablet}) {
    display: none;
  }

  ${ProductOfferWrapper} {
    display: block;
  }

  ${TileSkeletonWrapper} {
    flex-flow: row;

    @media screen and (max-width: ${breakpoints.tablet}) {
      height: 30px;
      overflow: hidden;
      margin: 0.4em 0;
    }
  }

  ${TileSkeletonWrapperInner} {
    padding: 1em;

    &:nth-child(1) {
      width: 30%;
      height: 100%;

      @media screen and (max-width: ${breakpoints.tablet}) {
        width: 100%;
      }
    }

    &:nth-child(2) {
      width: 70%;
      height: 60px;

      @media screen and (max-width: ${breakpoints.tablet}) {
        width: 0%;
      }
    }
  }

  ${ProductOfferTileWrapper} {
    flex-flow: row wrap;
    width: 100%;
    background: none;
    margin: 0.6em 0;

    img {
      width: 30%;
    }

    ${ProductOfferTileContent} {
      width: 70%;
    }
  }
`;

export const MobileShowcaseHeader = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const SeeDetailsButton = styled.button`
  padding: 1rem 0;
  appearance: none;
  background: transparent;
  border-color: transparent;
  color: ${({ theme }) => theme.colors.White};
  text-decoration: underline;
`;
