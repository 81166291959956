export default {
  translations: {
    selectYour: {
      edition: 'Selecteer je editie',
      version: 'Selecteer je versie',
      platform: 'Selecteer je platform',
      payment: 'Kies betalingsoptie',
    },
    selectPlatform: 'Selecteer je platform',
    releaseDate: 'Releasedatum:',
    buySelector: { subscription: { subscriptionSelection: 'Kies het abonnement:' } },
    subscription: {
      title: '{{gameName}} en nog veel meer met Ubisoft<span class="blue">+</span>',
      alreadySubscribed: 'Je bent al geabonneerd op Ubisoft+!',
      learnMoreAbout: 'Lees meer over <0>Ubisoft+</0>',
      cancelNotice: 'Annuleer en kom terug wanneer je wil. <0>Bekijk FAQ</0>',
      gamesSelection: {
        classics: {
          title: 'De beroemde games van Ubisoft',
          description:
            "Geniet van een selectie beroemde games, waaronder Assassin's Creed Valhalla, Rainbow Six Siege, en nog veel meer",
          link: { notSubscriber: 'Inbegrepen games bekijken', subscriber: 'Ontdek games' },
        },
        premium: {
          title: '100+ Games',
          description:
            "Speel alle Far Cry titels nu en geniet van een groeiende catalogus aan games, waaronder Assassin's Creed Valhalla, Rainbow Six Siege en nog veel meer.",
          link: { notSubscriber: 'Bekijk alle inbegrepen games', subscriber: 'Ontdek games' },
        },
      },
      cards: {
        cloudGaming: {
          title: 'Cloud Gaming',
          description:
            'Speel direct vanaf je smartphone, tablet of TV waar je wilt met een goede internetverbinding via {{platforms}}.',
          link: 'Bekijk cloud games',
        },
        consoles: {
          title: 'Consoles',
          description: 'Speel een selectie games van je {{platforms}}-consoles met ons gaming-abonnement.',
          link: 'Bekijk inbegrepen console-games',
        },
      },
      partOfUbisoftPlus: {
        released: 'Speel {{game}} {{edition}} met Ubisoft +',
        dlc: 'Speel de {{dlc}}, inbegrepen met {{game}}.',
        preOrder: 'Speel {{game}} zodra deze wordt uitgebracht {{releaseDate}}.',
        includedContent: 'Inbegrepen content:',
      },
      firstParty: {
        legalLine:
          'De vermelde prijzen gelden alleen voor de pc-versies.<br/>Bekijk de platformwinkels van derden voor de geldende prijzen op consoles.',
        pricingInfo: 'Ga naar de winkel van jouw systeem voor de prijs',
      },
      learnMore: 'Lees meer',
      playThisGame: 'Play this game in your <span class="blue">Library+</span>!',
    },
  },
};
