import styled from 'styled-components';

interface ISubscriptionItemContainer {
  isWide: boolean;
}

export const SubscriptionItemContainer = styled.div<ISubscriptionItemContainer>`
  display: flex;
  flex-direction: ${({ isWide }) => (isWide ? 'row' : 'column')};
  align-items: center;
  background: ${({ theme }) => theme.colors.Black};
  border-radius: 9px;
  backdrop-filter: blur(7px);
  flex: ${({ isWide }) => (isWide ? '0 0 100%' : '1 1 325px')};
  max-width: ${({ isWide }) => (isWide ? '100%' : '325px')};
  padding: 0 1.875rem;
  align-self: stretch;
  min-height: 100%;

  .subscription-content {
    a {
      color: ${({ theme }) => theme.colors.White};
    }
  }

  img {
    display: block;
    margin: auto;
    max-width: ${({ isWide }) => (isWide ? 'initial' : '100%')};
    max-height: 100%;
  }
`;

export const StyledLink = styled.a`
  display: block;
  color: ${({ theme }) => theme.colors.DodgerBlue};
  padding: 1rem 0;
  font-size: 0.875rem;
  text-decoration: underline;
  font-family: unset;
  font-weight: unset;

  &:hover {
    color: ${({ theme }) => theme.colors.DodgerBlue};
  }
`;
