import React from 'react';

const RenewIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="31" height="31" viewBox="0 0 31 31" fill="none">
      <path d="M22.7624 11.7918L12.2665 5.61014L12.2687 8.93618L22.7656 15.1179L22.7624 11.7918Z" fill="white" />
      <path d="M22.7656 11.625L12.1094 5.8125V9.30538L22.7656 15.1179L22.7656 11.625Z" fill="white" />
      <path d="M22.7656 18.4062L12.1094 24.2187V20.7259L22.7656 14.9134V18.4062Z" fill="white" />
      <path d="M22.7624 18.3191L12.2665 24.5008L12.2687 21.1747L22.7656 14.993L22.7624 18.3191Z" fill="white" />
      <path d="M24.7031 22.2812V28.0937L21.7969 25.1875V20.3438L24.7031 22.2812Z" fill="white" />
      <path d="M27.6094 25.1875L21.7969 25.1875L24.7031 28.0937H29.5469L27.6094 25.1875Z" fill="white" />
      <path
        d="M15.0156 28.0937C8.32802 28.0937 2.90625 22.672 2.90625 15.9843C2.90625 12.7595 4.1667 9.82956 6.2226 7.65956L6.21291 7.62619L4.11288 5.66071C1.564 8.35168 0 11.9856 0 15.9843C0 24.2768 6.72312 31 15.0156 31V28.0937Z"
        fill="white"
      />
      <path
        d="M14.5313 0C10.0901 0 6.08486 1.86861 3.25934 4.86205L5.3809 6.84798C7.67683 4.42073 10.9264 2.90625 14.5313 2.90625C21.4869 2.90625 27.125 8.54437 27.125 15.7519C27.125 19.5752 25.4695 22.94 22.8604 25.1875L23.3964 26.2197C23.3964 26.2197 24.4136 27.6772 24.4706 27.6309C27.8634 24.8527 30.0313 20.5935 30.0313 15.7519C30.0313 6.93948 23.0918 0 14.5313 0Z"
        fill="white"
      />
      <path d="M9.20312 19.1404L12.1094 20.7259V9.30538L9.20312 10.8909V19.1404Z" fill="white" />
    </svg>
  );
};

export default RenewIcon;
