import styled, { css } from 'styled-components';
import breakpoints from '../../../../themes/utils/breakpoints';
import { baseLoadingBackground, BaseSkeleton } from '../../../UI/Skeleton/Skeleton';

const priceSelectorDesktopBreakpoint = '1460px';

export const PriceSelectorWrapper = styled.div`
  display: flex;
  background: ${({ theme }) => theme.BuySelector.Colors.background4};
  border-radius: 50px;
  position: relative;
  margin: 0 auto 3rem;
  min-height: 60px;
  width: calc(100% - 4rem);

  @media screen and (max-width: ${breakpoints.mobile}) {
    width: calc(100% - 1rem);
  }
`;

export type IPriceBlockWrapperProps = {
  active?: boolean;
};

export const PriceBlockWrapper = styled.button<IPriceBlockWrapperProps>`
  width: 50%;
  border-radius: 50px;
  color: white;
  text-align: center;
  padding: 0.6em 3em;
  background: ${({ theme }) => theme.BuySelector.Colors.background4};
  position: relative;
  transition: all 0.3s ease-in;
  cursor: pointer;
  font-size: 18px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-color: transparent;

  @media (min-width: ${breakpoints.minTablet}) and (max-width: ${breakpoints.desktop}) {
    padding: 0.6em 2em;
  }

  @media screen and (max-width: ${breakpoints.mobile}) {
    padding: 0.6em 2em;
  }

  ${({ active, theme }) =>
    active &&
    css`
      background: ${theme.BuySelector.Colors.background5};
      z-index: 1;
    `}
`;

export const PriceProductWrapper = styled.div`
  span.from {
    margin-right: 0.2em;
  }

  span.price {
    font-family: ${({ theme }) => theme.mainFontTweaks.bold};
    font-size: 18px;

    @media (min-width: ${breakpoints.minTablet}) and (max-width: ${priceSelectorDesktopBreakpoint}) {
      font-size: 15px;
    }
  }

  @media (min-width: ${breakpoints.minTablet}) and (max-width: ${priceSelectorDesktopBreakpoint}) {
    span.per-month {
      font-family: ${({ theme }) => theme.fontFamily};
      font-size: 12px;
    }
  }

  @media screen and (max-width: ${breakpoints.mobile}) {
    span.per-month {
      font-family: ${({ theme }) => theme.fontFamily};
      font-size: 12px;
    }
  }

  .selected-icon-motion {
    position: absolute;
    right: 0.625rem;
    top: 50%;
    margin-top: -10px;
    width: 20px;
    height: 20px;

    @media (min-width: ${breakpoints.minTablet}) and (max-width: ${breakpoints.desktop}) {
      display: none;
    }

    @media screen and (max-width: ${breakpoints.mobile}) {
      display: none;
    }

    svg {
      width: 100%;
    }
  }
`;

type SelectorCursorProps = {
  right: boolean;
};

export const SelectionCursor = styled.div<SelectorCursorProps>`
  position: absolute;
  border: 2px solid ${(props) => props.theme.colors.DodgerBlue};
  width: 50%;
  height: 100%;
  border-radius: 50px;
  top: 0;
  left: 0;
  z-index: 2;
  pointer-events: none;
  transition: all 0.4s cubic-bezier(0.17, 0.67, 0.2, 0.98);

  ${({ right }) =>
    right &&
    css`
      left: 50%;
    `}
`;

export const PriceSelectorSkeleton = styled(BaseSkeleton)`
  background: ${({ theme }) => theme.BuySelector.Colors.background4};
  border-radius: 50px;
  margin: 1em 0;
  min-height: 60px;

  &::after {
    ${baseLoadingBackground}
  }
`;
