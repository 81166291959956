import { useWithProductOffers } from '../../../../../hook/useWithProductOffers';
import { IMaster } from '@pdp-rework-hub/utils-types';
import React, { useState } from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import Container from '@mooncake/ui/lib/components/Container';
import Text from '@mooncake/ui/lib/components/Text';
import ShowcaseProductOffers from '../../../../Showcase/SelectedProduct/ShowcaseProductOffers';
import { MobileShowcaseHeader, MobileShowcaseWrapper, SeeDetailsButton } from './style';
import { getMDMCustomerIdFromVariant } from '@pdp-rework-hub/utils';

interface IMobileShowcaseProps extends WithTranslation {
  product: IMaster;
}

const MobileShowcase = ({ t, product }: IMobileShowcaseProps) => {
  const [displayItems, setDisplayItems] = useState(false);
  const { selectedMaster, productOffers, fetchingProductOffer } = useWithProductOffers();

  const mdmCustomerOfferId = getMDMCustomerIdFromVariant([product]);
  const targetProductOffer = productOffers[mdmCustomerOfferId[0]];

  if (!selectedMaster) {
    return null;
  }

  return (
    <MobileShowcaseWrapper>
      <MobileShowcaseHeader>
        {targetProductOffer && targetProductOffer.contains && (
          <Container
            display={'flex'}
            justifyContent={'space-between'}
            alignItems={'center'}
            padding={'0 0.75rem'}
            width={'100%'}
          >
            <Container>
              <Text.Span bold>{targetProductOffer.contains.length}&nbsp;</Text.Span>
              {t('global.containedOffers')}
            </Container>
            <SeeDetailsButton onClick={() => setDisplayItems(!displayItems)}>
              {displayItems ? t('global.hide') : t('global.seeDetails')}
            </SeeDetailsButton>
          </Container>
        )}
      </MobileShowcaseHeader>
      {displayItems ? (
        <ShowcaseProductOffers productOffer={targetProductOffer} loading={fetchingProductOffer} product={product} />
      ) : null}
    </MobileShowcaseWrapper>
  );
};

export default withTranslation()(MobileShowcase);
