const Text = {
  sizes: {
    Heading: {
      primary: {
        default: '1.875rem', // 30px,
        desktop: '3.75rem', // 40px
      },
    },
    Paragraph: {
      primary: {
        default: '1rem', // 16px,
        desktop: '1.125rem', // 18px
      },
    },
  },
};

export default Text;
