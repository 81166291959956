import React from 'react';

const CheckmarkIcon = () => {
  return (
    <svg width="16" height="17" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16 8.96094C16 13.3792 12.4183 16.9609 8 16.9609C3.58172 16.9609 0 13.3792 0 8.96094C0 4.54266 3.58172 0.960938 8 0.960938C12.4183 0.960938 16 4.54266 16 8.96094ZM11.9714 7.26568C12.2318 7.00533 12.2318 6.58322 11.9714 6.32287C11.7111 6.06252 11.2889 6.06252 11.0286 6.32287L6.83333 10.5181L4.80474 8.48953C4.54439 8.22918 4.12228 8.22918 3.86193 8.48953C3.60158 8.74988 3.60158 9.17199 3.86193 9.43234L6.36193 11.9323C6.62228 12.1927 7.04439 12.1927 7.30474 11.9323L11.9714 7.26568Z"
        fill="#3BB071"
      />
    </svg>
  );
};

export default CheckmarkIcon;
