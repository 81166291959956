export default {
  translations: {
    global: {
      subscription: {
        subscribeNow: 'JUNTE-SE AGORA',
        reactivate: 'Reativar Ubisoft+',
        resubscribe: 'Assinar novamente',
        subscribeUbisoftPlus: 'Assine o Ubisoft+',
        playNowUbisoftPlus: 'Jogue agora com o Ubisoft+',
        goToMyLibrary: 'Ir para a biblioteca',
        changePlan: 'Alterar plano',
        upgradeNow: 'Upgrade Now',
        includedWithUbisoftPlus: 'Incluso no Ubisoft+',
        dayOne: 'Primeiro dia de lançamento com Ubisoft+',
        service: 'Serviço de assinatura',
      },
      offerEnds: 'A oferta termina em {{date}}',
      outOfStock: 'Fora de estoque',
      from: 'de',
      preOrder: 'RESERVE',
      buyNow: 'COMPRE AGORA',
      getTheGame: 'OBTENHA',
      addToCart: 'Adicionar ao carrinho',
      playNow: 'Jogue agora',
      seeMore: 'Veja mais',
      month: 'mês',
      months: 'meses',
      day: 'dia',
      days: 'dias',
      year: 'ano',
      years: 'anos',
      buyGame: 'Compre o jogo',
      free: 'grátis',
      freeTrial: 'Teste grátis',
      tryForFree: 'Teste gratuitamente',
      then: 'então',
      and: 'e',
      durationTerm: 'para',
      containedOffers: 'objetos incluídos nesta edição',
      gameOwned: 'Você possui esta edição',
      seeDetails: 'ver detalhes',
      hide: 'ocultar',
      subscribed: 'subscrito',
      buyOn: { playstation: 'Comprar no Playstation', microsoft: 'Comprar no Xbox', nintendo: 'Comprar no Nintendo' },
      ageGateRestrictionMessage:
        'Lamentamos, mas você não pode acessar este conteúdo devido às restrições de idade. Consulte nossa <0>Política de Privacidade</0>',
      monthly: 'Mensal',
      yearly: 'Anual',
      earlyAccess: 'Acesso antecipado',
    },
    platforms: {
      PC: 'PC',
      PS4: 'PS4',
      PS5: 'PS5',
      Xbox1: 'Xbox One',
      XboxX: 'Xbox Series X|S',
      Android: 'Android',
      iOS: 'iOS',
      Switch: 'Switch',
      Stadia: 'Stadia',
      Luna: 'Luna',
    },
    versions: { digital: 'Digital', physical: 'Físico' },
    subscription: { promoprice: { month: 'depois {{price}} /mês', year: 'depois {{price}} /ano' } },
  },
};
