import { addToCart } from '@mooncake/mooncake-gateway-ecom/events/sfcc';
import { pollWindowVariable, pollWindowVariables } from '@mooncake/mooncake-gateway-ecom/utils';
import {
  getFormattedProductModelData,
  parseSubscriptionStatus,
  getMDMCustomerIdFromVariant,
  fetchContentServicesCustomerOffer,
} from '@pdp-rework-hub/utils';
import { SUBSCRIPTION_OFFERIDS, SUBSCRIPTION_PLAN_RECURRENCE } from '@pdp-rework-hub/utils-constants';

import {
  AppBaseState,
  AppDispatch,
  IMaster,
  IProductModel,
  ISubscription,
  ISubscriptionModel,
  IVariation,
  SubscriptionStatus,
} from '@pdp-rework-hub/utils-types';
import {
  fetchEnd,
  fetchProductOfferEnd,
  fetchProductOfferStart,
  fetchStart,
  setAvailableSortedSubscriptions,
  setError,
  setProductData,
  setProductOffers,
  setSelectedMaster,
  setSelectedPlatform,
  setSelectedSubscription,
  setSelectedVariant,
  setSubscriptions,
} from '../features/productsSlice';
import {
  setActiveSubscription,
  setUpgradeRequired,
  fetchSubscriptionEnd,
  setIsActiveSubscriber,
  setSubscriptionStatus,
} from '../features/userSlice';

export const selectMasterAndEdition =
  <S extends AppBaseState, D extends AppDispatch>(productId: string | undefined) =>
  (dispatch: D, getState: () => S) => {
    const { data } = getState().products;

    if (!data.product?.masters.length) {
      return;
    }

    let defaultMaster = data.product.masters[0];

    if (productId) {
      const masterFound = data.product.masters.find((master) => {
        // Look for the product id in masters and variations
        return master.id === productId || master.variations.find((variant) => variant.id === productId);
      });
      if (masterFound) {
        defaultMaster = masterFound;
      }
    }

    dispatch(selectEdition(defaultMaster));

    if (data.product.details.productPlatformsOrder.length > 0) {
      const defaultVariant = defaultMaster.variations.find(
        (variant) => variant.platform === data.product!.details.productPlatformsOrder[0]
      );

      if (!defaultVariant) {
        return;
      }

      dispatch(selectPlatform(defaultVariant));
    }
  };

export const selectEdition =
  <S extends AppBaseState, D extends AppDispatch>(product: IMaster) =>
  (dispatch: D, getState: () => S) => {
    const { selectedPlatform, productOffers } = getState().products;
    dispatch(setSelectedMaster(product));

    if (productOffers[product.id] === undefined) {
      dispatch(fetchProductOffers(getMDMCustomerIdFromVariant([product])));
    }

    if (!product.variations.length) {
      dispatch(selectPlatform(null));
      return;
    }

    const selectedVariant = product.variations.find((variant) => variant.platform === selectedPlatform);
    dispatch(selectPlatform(selectedVariant ? selectedVariant : product.variations[0]));
  };

export const selectPlatform = (variant: IVariation | null) => (dispatch: AppDispatch) => {
  if (!variant) {
    dispatch(setSelectedVariant(null));
    return;
  }

  dispatch(setSelectedPlatform(variant.platform));
  dispatch(setSelectedVariant(variant));
};

export const fetchProductData = (productId?: string) => async (dispatch: AppDispatch) => {
  dispatch(fetchStart());

  const [globalProductData, subscriptionModelData, geoRestrictedResourceData, Urls, User] = await pollWindowVariables<
    [IProductModel, ISubscriptionModel, typeof window.GeoRestrictedResources, typeof window.Urls, typeof window.User]
  >(['extendedProductModel', 'subscriptionPricingDetails', 'GeoRestrictedResources', 'Urls', 'User']);

  if (!geoRestrictedResourceData) {
    dispatch(setError('No GeoRestrictedResource found'));
    dispatch(fetchEnd());
    return;
  }

  if (!globalProductData) {
    dispatch(setError('No product model data found'));
    dispatch(fetchEnd());
    return;
  }

  if (!Urls) {
    dispatch(setError('No urls found in window'));
    dispatch(fetchEnd());
    return;
  }

  if (!globalProductData.masters || !globalProductData.masters.length) {
    dispatch(setError('No master found in product model'));
    dispatch(fetchEnd());
    return;
  }

  const productModelData = getFormattedProductModelData(globalProductData);
  const MDMCustomerIDArray = getMDMCustomerIdFromVariant(productModelData.masters);

  dispatch(setProductData(productModelData));
  dispatch(fetchProductOffers(MDMCustomerIDArray));

  const selectedMaster = productId && productModelData.masters.find((master) => master.id === productId);

  dispatch(selectEdition(selectedMaster ? selectedMaster : productModelData.masters[0]));

  if (subscriptionModelData?.subscriptions?.length) {
    dispatch(setSubscriptionData(subscriptionModelData.subscriptions, productModelData, User));
  }

  dispatch(fetchEnd());
};

export const fetchProductOffers =
  (MDMCustomerIDArray: string[]) =>
  async <D extends AppDispatch>(dispatch: D) => {
    dispatch(fetchProductOfferStart());
    const sfccLocale = window.GeoRestrictedResources?.STOREFRONT_CURRENT_LOCALE;

    if (!sfccLocale || !MDMCustomerIDArray) {
      return;
    }

    const offers = await fetchContentServicesCustomerOffer(MDMCustomerIDArray, sfccLocale);

    dispatch(setProductOffers(offers));
    dispatch(fetchProductOfferEnd());
  };

export const addProductToCart = (productId: string) => async (dispatch: AppDispatch) => {
  if (!window.Urls) {
    await pollWindowVariable('Urls');
  }
  dispatch(fetchStart());
  addToCart({ productId }, (res) => {
    if (res?.resource) {
      window.top.location.href = window.Urls.cartShow;
    }
    dispatch(fetchEnd());
    console.error(res);
  })();
};

export const setSubscriptionData =
  (subscriptions: ISubscription[], productModel: IProductModel, userInfo?: typeof window.User) =>
  (dispatch: AppDispatch) => {
    // Handling subscriptions
    // Store the subscriptions in subscriptionPricingDetails prefiltered
    dispatch(setSubscriptions(subscriptions));

    // Store all subscriptions available for all editions of a game
    const availableSubscriptions = getAvailableSubscriptions(productModel, subscriptions);

    dispatch(setAvailableSortedSubscriptions(availableSubscriptions));

    if (availableSubscriptions?.length) {
      const monthlySubs = availableSubscriptions.filter((sub) => {
        return sub.productSubscriptionType === SUBSCRIPTION_PLAN_RECURRENCE.MONTHLY;
      });

      if (monthlySubs?.length) {
        dispatch(setSelectedSubscription(monthlySubs[monthlySubs.length - 1]));
      }
    }

    // Handle user's subscription status in the global subscriptions
    const activeSubscription = availableSubscriptions.find(
      (subscription) => userInfo?.activeSubOfferId?.toString() === subscription.offerId.toString()
    );
    const isActiveSubscriber = !!(
      userInfo?.isLoggedIn &&
      userInfo?.activeSubOfferId &&
      userInfo.subscriptionStatus === SubscriptionStatus.Active
    );

    dispatch(setIsActiveSubscriber(isActiveSubscriber));
    dispatch(setSubscriptionStatus(parseSubscriptionStatus(userInfo?.subscriptionStatus ?? null)));
    dispatch(setActiveSubscription(activeSubscription));
    dispatch(
      setUpgradeRequired(isActiveSubscriber && activeSubscription?.offerId !== SUBSCRIPTION_OFFERIDS.PREMIUM_ANYWHERE)
    );

    dispatch(fetchSubscriptionEnd());
  };

/**
 * @function getAvailableSubscriptions
 * @param  {IProductModel} productModel
 * @param  {ISubscription[]} subscriptions
 * @return {ISubscription[]} Returns all the active subscription data based on the activeSubOffers model property
 */
export const getAvailableSubscriptions = (productModel: IProductModel, subscriptions: ISubscription[]) => {
  if (!productModel.details.activeSubOffers) {
    return [];
  }
  return productModel.details.activeSubOffers.reduce<ISubscription[]>((availableSubscriptions, currentOffer) => {
    const availableSubscription = subscriptions.filter(
      (sub) => sub.offerId.toString() === currentOffer.offerId.toString()
    );

    if (!availableSubscription) {
      return availableSubscriptions;
    }

    return [...availableSubscriptions, ...availableSubscription];
  }, []);
};
