import { useEffect } from 'react';
import { MOONCAKE_DATA_UBICONNECT_FETCHED } from '@mooncake/mooncake-gateway-core/constants';
import { useDispatch } from 'react-redux';
import { setUbiAuthenticated } from '@pdp-rework-hub/slices';

export const useUbiConnectFetched = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    const mooncakeData = document.getElementById('mooncake-data');

    if (mooncakeData) {
      mooncakeData.addEventListener(MOONCAKE_DATA_UBICONNECT_FETCHED, ((event: CustomEvent<{ ticket?: string }>) => {
        dispatch(setUbiAuthenticated(event.detail.ticket != null));
      }) as EventListener);
    }
  }, []);
};
