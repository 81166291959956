import React from 'react';

const ExternalIcon = () => (
  <svg width="13" height="13" viewBox="0 0 13 13" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M9.625 7.35498V11.105C9.625 11.2775 9.55563 11.4331 9.44188 11.5469C9.32812 11.6606 9.1725 11.73 9 11.73H2.125C1.9525 11.73 1.79688 11.6606 1.68313 11.5469C1.56938 11.4331 1.5 11.2775 1.5 11.105V4.22998C1.5 4.05748 1.56938 3.90186 1.68313 3.78811C1.79688 3.67436 1.9525 3.60498 2.125 3.60498H5.875C6.22 3.60498 6.5 3.32498 6.5 2.97998C6.5 2.63498 6.22 2.35498 5.875 2.35498H2.125C1.6075 2.35498 1.1375 2.56561 0.799375 2.90436C0.46125 3.24311 0.25 3.71248 0.25 4.22998V11.105C0.25 11.6225 0.460625 12.0925 0.799375 12.4306C1.13812 12.7687 1.6075 12.98 2.125 12.98H9C9.5175 12.98 9.9875 12.7694 10.3256 12.4306C10.6638 12.0919 10.875 11.6225 10.875 11.105V7.35498C10.875 7.00998 10.595 6.72998 10.25 6.72998C9.905 6.72998 9.625 7.00998 9.625 7.35498ZM5.69187 8.42186L11.5 2.61373V4.85498C11.5 5.19998 11.78 5.47998 12.125 5.47998C12.47 5.47998 12.75 5.19998 12.75 4.85498V1.10498C12.75 1.01998 12.7331 0.939356 12.7025 0.865606C12.6719 0.791856 12.6269 0.723105 12.5675 0.66373C12.5669 0.663105 12.5669 0.663106 12.5662 0.662481C12.5087 0.60498 12.44 0.55873 12.3644 0.52748C12.2906 0.496855 12.21 0.47998 12.125 0.47998H8.375C8.03 0.47998 7.75 0.75998 7.75 1.10498C7.75 1.44998 8.03 1.72998 8.375 1.72998H10.6163L4.80813 7.53811C4.56375 7.78248 4.56375 8.17811 4.80813 8.42186C5.0525 8.66561 5.44812 8.66623 5.69187 8.42186Z"
      fill="white"
    />
  </svg>
);

export default ExternalIcon;
