import React from 'react';

const PS4Icon = () => {
  return (
    <svg width="78" height="18" viewBox="0 0 78 18" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M14.2343 4.21333V9.60154C16.4943 10.6954 18.2701 9.60154 18.2701 6.70487C18.2701 3.74744 17.2208 2.43077 14.1536 1.37744C12.9429 0.972308 10.703 0.28359 9.16943 0V16.0633L12.8016 17.2179V3.74744C12.8016 3.11949 13.0841 2.6941 13.5281 2.8359C14.1132 2.99795 14.2343 3.58538 14.2343 4.21333Z"
        fill="white"
      />
      <path
        d="M21.4178 11.2828C19.9044 10.7157 17.9672 10.5131 16.3933 10.6954C14.8193 10.8575 13.6893 11.2626 13.6893 11.2626L13.4875 11.3436V13.5313L17.3619 12.1539C18.0278 11.9108 18.9964 11.8703 19.5412 12.0323C20.0658 12.2146 19.9649 12.5387 19.3192 12.7818L13.4875 14.8682V16.9749L21.3976 14.1187C21.3976 14.1187 22.4671 13.7136 22.8909 13.1667C23.3348 12.64 23.133 11.8298 21.4178 11.2828Z"
        fill="white"
      />
      <path
        d="M1.27951 12.5388C0.472363 13.1465 0.775044 14.1796 2.5306 14.6657C4.40723 15.2937 6.32421 15.4152 8.12012 15.1114C8.24119 15.0911 8.36227 15.0708 8.48334 15.0506V13.1667L6.68743 13.8149C6.02153 14.058 5.07313 14.1188 4.5283 13.9365C4.00365 13.7542 4.10454 13.4301 4.75026 13.187L8.48334 11.8501V9.72314L3.27721 11.5665C3.27721 11.5665 2.00595 11.9919 1.27951 12.5388Z"
        fill="white"
      />
      <path
        d="M21.6602 15.4961C21.1557 15.4961 20.7925 15.881 20.7925 16.3671C20.7925 16.8533 21.1355 17.2381 21.6602 17.2381C22.1646 17.2381 22.5077 16.8533 22.5077 16.3671C22.5279 15.881 22.1848 15.4961 21.6602 15.4961ZM21.6602 17.0964C21.2162 17.0964 20.9337 16.7723 20.9337 16.3671C20.9337 15.962 21.2162 15.6379 21.6602 15.6379C22.0839 15.6379 22.3866 15.962 22.3866 16.3671C22.3866 16.7723 22.1041 17.0964 21.6602 17.0964Z"
        fill="white"
      />
      <path
        d="M21.8618 16.3673C21.9829 16.3267 22.0434 16.2457 22.0434 16.1242C22.0434 15.9216 21.9022 15.8608 21.7206 15.8608H21.3372V16.8534H21.5188V16.428H21.6398C21.7811 16.428 21.8214 16.4685 21.8416 16.5698C21.8618 16.6306 21.9022 16.7724 21.9223 16.8331H22.1039C22.0838 16.7319 22.0434 16.6306 22.0031 16.5293C21.9627 16.428 21.9223 16.3875 21.8618 16.3673ZM21.66 16.3065H21.4986V15.9824H21.66C21.7609 15.9824 21.8416 16.0026 21.8416 16.1444C21.8618 16.266 21.7811 16.3065 21.66 16.3065Z"
        fill="white"
      />
      <path
        d="M61.0087 4.47668V3.52463C61.0087 3.48412 60.9683 3.4436 60.928 3.4436H54.1882C52.2309 3.4436 51.2825 4.65899 51.2825 6.21873V10.9992C51.2825 11.9716 50.8184 12.6805 49.8498 12.6805H43.5338C43.4935 12.6805 43.4531 12.721 43.4531 12.7616V13.7136C43.4531 13.7541 43.4935 13.7946 43.5338 13.7946H50.5964C52.5538 13.7946 53.5022 12.5995 53.5022 11.0195V6.21873C53.5022 5.24642 53.9663 4.53745 54.9349 4.53745H60.928C60.9885 4.55771 61.0087 4.51719 61.0087 4.47668Z"
        fill="white"
      />
      <path
        d="M41.0517 3.44482H28.8839C28.8436 3.44482 28.8032 3.48534 28.8032 3.52585V4.4779C28.8032 4.51841 28.8436 4.55893 28.8839 4.55893H40.3253C41.2939 4.55893 41.758 5.2679 41.758 6.24021C41.758 7.21252 41.2939 7.92149 40.3253 7.92149H31.265C29.8525 7.92149 28.8032 9.11662 28.8032 10.6764V13.7351C28.8032 13.7756 28.8436 13.8161 28.8839 13.8161H30.9422C30.9825 13.8161 31.0229 13.7756 31.0229 13.7351V10.6561C31.0229 9.6838 31.5879 8.99508 32.3749 8.99508H41.0517C43.0091 8.99508 43.9575 7.79995 43.9575 6.21995C43.9575 4.63995 43.0091 3.44482 41.0517 3.44482Z"
        fill="white"
      />
      <path
        d="M76.9093 11.5665H74.8511C74.8107 11.5665 74.7704 11.5259 74.7704 11.4854V4.53748C74.7704 3.46389 74.0439 3.11953 72.9946 3.76774L60.5847 11.4247C60.1004 11.7285 59.9389 12.0729 60.06 12.3159C60.1609 12.5388 60.4232 12.6806 60.9681 12.6806H72.4902C72.5305 12.6806 72.5507 12.7008 72.5507 12.7413V13.7136C72.5507 13.7541 72.591 13.7947 72.6314 13.7947H74.7098C74.7502 13.7947 74.7905 13.7541 74.7905 13.7136V12.7616C74.7905 12.7211 74.8309 12.6806 74.8712 12.6806H76.9295C76.9698 12.6806 77.0102 12.64 77.0102 12.5995V11.6475C76.99 11.607 76.9497 11.5665 76.9093 11.5665ZM72.5709 11.4854C72.5709 11.5259 72.5305 11.5665 72.4902 11.5665H62.8245C62.7438 11.5665 62.7034 11.5462 62.7034 11.5259C62.6833 11.4854 62.7236 11.4449 62.7841 11.4044L72.3893 5.46928C72.4498 5.42876 72.5709 5.42876 72.5709 5.57056V11.4854Z"
        fill="white"
      />
    </svg>
  );
};

export default PS4Icon;
