import styled, { css } from "styled-components";
import { Text } from "@mooncake/ui";

export const reset = css`
  p {
    color: unset;
    text-align: unset;
    font-family: unset;
    line-height: unset;
    font-size: unset;
    margin: 1em 0;
  }

  p:last-child {
    margin-bottom: 0;
  }
  p:first-child {
    margin-top: 0;
  }
  i {
    font-style: normal;
  }
  strong {
    font-weight: normal;
    font-family: ${({ theme }) => theme.fonts.bold};
  }

  a {
    font-size: unset;
    color: unset;
    font-family: unset;
    font-weight: unset;
    text-decoration: underline;

    &:hover {
      color: unset;
    }
  }
`;

export const HeadingResetContainer = styled(Text.Heading)`
  ${reset};
`;
export const TextResetContainer = styled(Text.Paragraph)`
  ${reset};
`;
