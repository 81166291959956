export default {
  translations: {
    selectYour: {
      edition: 'Selecciona tu edición',
      version: 'Selecciona tu versión',
      platform: 'Selecciona tu plataforma',
      payment: 'Seleccionar opción de pago',
    },
    selectPlatform: 'Selecciona la plataforma',
    releaseDate: 'Lanzamiento:',
    buySelector: { subscription: { subscriptionSelection: 'Selecciona la suscripción' } },
    subscription: {
      title: 'Consigue {{gameName}} y mucho más con Ubisoft<span class="blue">+</span>',
      alreadySubscribed: '¡Ya estás suscrito a Ubisoft+!',
      learnMoreAbout: 'Descubre más acerca de <0>Ubisoft+</0>',
      cancelNotice: 'Cancela y vuelve más adelante. <0>Ver preguntas frecuentes</0>',
      gamesSelection: {
        classics: {
          title: 'Juegos emblemáticos de Ubisoft',
          description:
            "Disfruta de una selección de títulos emblemáticos como Assassin's Creed Valhalla, Rainbow Six Siege y más",
          link: { notSubscriber: 'Ver juegos incluidos', subscriber: 'Explorar juegos' },
        },
        premium: {
          title: '+100 Juegos',
          description:
            "Juega al instante a los títulos más populares de Ubisoft, y disfruta de un catálogo en constante crecimiento, que incluye juegos como Assassin's Creed Valhalla y Rainbow Six Siege.",
          link: { notSubscriber: 'Revisa todos los juegos incluidos', subscriber: 'Explorar juegos' },
        },
      },
      cards: {
        cloudGaming: {
          title: 'Cloud Gaming',
          description:
            'Juega directamente en tu smartphone, en tu tablet o en tu TV, desde donde quieras, con solo una buena conexión a internet, gracias a {{platforms}}.',
          link: 'Ver los juegos en la nube',
        },
        consoles: {
          title: 'Consolas',
          description:
            'Juega a una selección de juegos de tus consolas {{platforms}} con nuestra suscripción de juegos.',
          link: 'Ver los juegos de consola incluidos',
        },
      },
      partOfUbisoftPlus: {
        released: 'Juega a {{game}} {{edition}} con Ubisoft+',
        dlc: 'Juega al {{dlc}}, incluido con {{game}}.',
        preOrder: 'Juega a {{game}} {{edition}} tan pronto como se lance el {{releaseDate}} con Ubisoft+.',
        includedContent: 'Contenido incluido:',
      },
      firstParty: {
        legalLine:
          'Los precios incluidos solo aplican a la versión de PC.<br/>Visita las tiendas de las plataformas de terceros para ver el precio en consolas.',
        pricingInfo: 'Visita la tienda de la plataforma para consultar el precio',
      },
      learnMore: 'Descubre más',
      playThisGame: 'Play this game in your <span class="blue">Library+</span>!',
    },
  },
};
