import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { ISubscription, IUserState, TSubscriptionStatus } from '@pdp-rework-hub/utils-types';

const userSlice = createSlice({
  name: 'user',
  initialState: {
    subscriptionDataLoaded: false,
    ubiAuthenticated: false,
    ubiAuthenticationLoaded: false,
    isActiveSubscriber: false,
    subscriptionStatus: null,
    activeSubscription: undefined,
    isMinor: false,
    upgradeRequired: false,
  } as IUserState,
  reducers: {
    fetchSubscriptionEnd(state) {
      state.subscriptionDataLoaded = true;
    },
    setUbiAuthenticated(state, action: PayloadAction<boolean>) {
      state.ubiAuthenticated = action.payload;
      state.ubiAuthenticationLoaded = true;
    },
    setIsActiveSubscriber(state, action: PayloadAction<boolean>) {
      state.isActiveSubscriber = action.payload;
    },
    setSubscriptionStatus(state, action: PayloadAction<TSubscriptionStatus>) {
      state.subscriptionStatus = action.payload;
    },
    setActiveSubscription(state, action: PayloadAction<ISubscription | undefined>) {
      state.activeSubscription = action.payload;
    },
    setUserIsMinor(state, action: PayloadAction<boolean>) {
      state.isMinor = action.payload;
    },
    setUpgradeRequired(state, action: PayloadAction<boolean>) {
      state.upgradeRequired = action.payload;
    },
  },
});

export const {
  fetchSubscriptionEnd,
  setUbiAuthenticated,
  setIsActiveSubscriber,
  setSubscriptionStatus,
  setActiveSubscription,
  setUserIsMinor,
  setUpgradeRequired,
} = userSlice.actions;

export const userReducer = userSlice.reducer;
