import {
  SUBSCRIPTION_PROMOTION_DISCOUNT_TYPE,
  SUBSCRIPTION_PROMOTION_FREE_TRIAL_TYPE,
} from '@pdp-rework-hub/utils-constants';
import { filterActivePromotionsFromSubscriptions } from '@pdp-rework-hub/utils';
import React from 'react';
import uniq from 'lodash/uniq';
import PromoPrice from './PromoPrice';
import RegularPrice from './RegularPrice';
import { ISubscription, DurationUnit, SubscriptionPromotion } from '@pdp-rework-hub/utils-types';

export interface ISubPriceComponentProps {
  price: string;
  differentPrices: boolean;
  subType: string;
}

interface ICartSubscriptionPrice {
  activeSubscription?: ISubscription;
  customSecondaryColor?: string;
  subscriptions: ISubscription[];
}

const SubscriptionPrice = ({ activeSubscription, subscriptions, customSecondaryColor }: ICartSubscriptionPrice) => {
  const subscriptionToUse = activeSubscription || subscriptions[0];
  const { basePriceFormatted, discountedPriceFormatted, productSubscriptionType } = subscriptionToUse;
  const filteredPromotions = filterActivePromotionsFromSubscriptions([subscriptionToUse]);
  const filteredFreeTrialPromotions = filteredPromotions.length
    ? filteredPromotions.filter(
        (promotion: SubscriptionPromotion) => promotion.type === SUBSCRIPTION_PROMOTION_FREE_TRIAL_TYPE
      )
    : [];

  const filteredDiscountPromotions = filteredPromotions.length
    ? filteredPromotions.filter(
        (promotion: SubscriptionPromotion) => promotion.type === SUBSCRIPTION_PROMOTION_DISCOUNT_TYPE
      )
    : [];

  // Check if prices are different
  const differentPrices =
    uniq(subscriptions?.map((subscription) => subscription?.discountedPrice || subscription?.basePrice)).length > 1;

  if (filteredFreeTrialPromotions.length || filteredDiscountPromotions.length) {
    const { duration, durationUnit } = filteredFreeTrialPromotions.length
      ? filteredFreeTrialPromotions[0]
      : filteredDiscountPromotions[0];
    return (
      <PromoPrice
        price={basePriceFormatted}
        promotionPrice={discountedPriceFormatted}
        promotionDuration={duration}
        durationUnit={durationUnit as DurationUnit}
        isFreeTrial={!!filteredFreeTrialPromotions.length}
        differentPrices={differentPrices}
        customSecondaryColor={customSecondaryColor}
        subType={productSubscriptionType}
      />
    );
  }

  return (
    <RegularPrice price={basePriceFormatted} differentPrices={differentPrices} subType={productSubscriptionType} />
  );
};

export default SubscriptionPrice;
