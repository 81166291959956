import getPlatformIcon from '../Icons/Platforms';
import { PackshotContainer } from './style';
import { PackshotSkeleton } from './TileSkeleton';

type TileImageProps = {
  hasImage: boolean;
  imageSource?: string;
  platform?: string;
};

const TileImage = ({ hasImage, imageSource, platform }: TileImageProps) => {
  if (imageSource) {
    return (
      <PackshotContainer>
        <PackshotSkeleton />
        <img src={imageSource} alt={''} loading={'lazy'} />
      </PackshotContainer>
    );
  } else if (!imageSource && platform) {
    return getPlatformIcon(platform);
  } else if (hasImage) {
    return (
      <PackshotContainer>
        <PackshotSkeleton />
      </PackshotContainer>
    );
  }
  return null;
};

export default TileImage;
