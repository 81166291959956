const XboxIcon = () => (
  <svg width="51" height="50" viewBox="0 0 51 50" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M25.5596 20.0358C25.5596 20.0358 25.6336 20.0358 25.6336 20.1097C33.1008 25.7286 45.8172 39.7759 41.9727 43.7683C37.6107 47.6128 31.8439 49.9047 25.5596 49.9047C19.2753 49.9047 13.5086 47.6128 9.14653 43.7683C5.22808 39.7759 18.0185 25.7286 25.4857 20.1097C25.4857 20.0358 25.4857 20.0358 25.5596 20.0358ZM38.4979 3.62271C34.7273 1.40472 30.5871 0 25.5596 0C20.5322 0 16.318 1.40472 12.5474 3.62271C12.4735 3.62271 12.4735 3.69664 12.4735 3.77058C12.4735 3.84451 12.5474 3.84451 12.6214 3.84451C17.427 2.80945 24.8203 6.94969 25.4857 7.39329C25.4857 7.39329 25.4857 7.39329 25.5596 7.39329C25.5596 7.39329 25.5596 7.39329 25.6336 7.39329C26.299 6.94969 33.6922 2.80945 38.4979 3.84451C38.5718 3.84451 38.5718 3.84451 38.6457 3.77058C38.5718 3.69664 38.5718 3.69664 38.4979 3.62271ZM8.03753 7.17149C7.9636 7.17149 7.9636 7.24542 7.9636 7.24542C3.37976 11.7553 0.570312 18.0396 0.570312 24.9893C0.570312 30.6821 2.49257 35.9314 5.67168 40.0716C5.67168 40.1456 5.74561 40.1456 5.81955 40.1456C5.89348 40.1456 5.89348 40.0716 5.81955 39.9977C3.89729 34.0831 13.6564 19.8879 18.6839 13.8994V13.8254C18.6839 13.8254 18.6839 13.7515 18.6099 13.7515C11.0688 6.13643 8.48113 6.94969 8.03753 7.17149ZM32.3614 13.6776L32.2875 13.7515V13.8254C37.315 19.814 47.0002 34.0831 45.1518 39.9238C45.1518 39.9977 45.1518 39.9977 45.1518 40.0716C45.2258 40.0716 45.2258 40.0716 45.2997 39.9977C48.4788 35.7835 50.4011 30.5343 50.4011 24.9154C50.4011 17.9657 47.5916 11.7553 43.0078 7.17149L42.9338 7.09756C42.5642 6.94969 39.9765 6.13643 32.3614 13.6776Z"
      fill="white"
    />
  </svg>
);

export default XboxIcon;
