import gql from 'graphql-tag';

export const buyEditionGraphQLQuery = gql`
  query BuyEditionMaster($customerOfferIds: [String], $locale: String, $fallbackLocale: String) {
    buyEditionMasterCollection(where: { customerOffer_in: $customerOfferIds }, limit: 15) {
      items {
        sys {
          id
        }
        tags
        customerOffer
        featuresId {
          ... on TranslationsMaster {
            localizedItems(locale: $locale) {
              translations
            }
            fallbackLocalizedItems: localizedItems(locale: $fallbackLocale) {
              translations
            }
          }
        }
        title(locale: $locale) {
          ... on AttachableContentMaster {
            item: localizedItems(locale: $locale) {
              ... on AttachableContent {
                content
              }
            }
          }
        }
        key
        features
        item: localizedItems(locale: $locale) {
          ... on BuyEdition {
            mediaCollection(limit: 10, locale: $locale) {
              items {
                sys {
                  id
                }
                url
                description
              }
            }
            fallbackMediaCollection: mediaCollection(limit: 10, locale: $fallbackLocale) {
              items {
                sys {
                  id
                }
                url
                description
              }
            }
          }
        }
      }
    }
  }
`;

export const translationGraphQLQuery = gql`
  query Translations($tags: [String], $locale: String) {
    translationsMasterCollection(where: { tags_in: $tags }) {
      items {
        sys {
          id
        }
        tags
        localizedItems(locale: $locale) {
          translations
        }
      }
    }
  }
`;
