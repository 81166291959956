import styled, { css } from 'styled-components';
import { LoaderContainer } from '../AppLoader/styles';
import { IButtonProps } from './index';

interface IButtonWrapperProps {
  buttonType: IButtonProps['buttonType'];
  disabled: boolean;
}

export const HiddenTextContainer = styled.span`
  content: attr(data-alt-text);
  opacity: 0;
  left: 0;
  right: 0;
  position: absolute;
  transform: translate3d(0, 56px, 0);
  display: block;
  transition: all 0.12s ease-in-out;
  padding: 0 2rem;
`;

export const ShowedTextContainer = styled.span`
  transition: all 0.12s ease-in-out;
  display: block;
  opacity: 1;
  font-family: ${({ theme }) => theme.mainFontTweaks.bold};
  text-decoration: none;
`;

export const ButtonWrapper = styled.a<IButtonWrapperProps>`
  position: relative;
  padding: 0.6rem 2rem;
  text-transform: uppercase;
  color: white;
  letter-spacing: 0.04em;
  border-radius: 100px;
  overflow-y: hidden;
  display: inline-block;
  border: 2px solid;
  font-size: 1rem;
  vertical-align: top;
  line-height: 1;
  cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};
  text-decoration: none;
  font-family: ${({ theme }) => theme.mainFontTweaks.bold};
  text-align: center;

  svg {
    margin-left: 0.625rem;
  }
  &:hover {
    color: white;
  }
  &:focus-visible {
    border-color: ${({ theme }) => theme.colors.SchoolBusYellow} !important;
  }
  ${({ disabled }) => {
    if (!disabled) {
      return css`
        cursor: pointer;

        &:hover {
          ${ShowedTextContainer} {
            transform: translate3d(0, -56px, 0);
          }

          ${HiddenTextContainer} {
            opacity: 1;
            transform: translate3d(0, 0, 0);
          }
        }
      `;
    }
    return css`
      opacity: 0.4;
    `;
  }}

  ${({ buttonType, theme }) => {
    switch (buttonType) {
      case 'primary':
        return css`
          background-color: ${theme.colors.DeepCerulean};
          border-color: ${theme.colors.DeepCerulean};
        `;
      case 'invertedPrimary':
        return css`
          color: ${theme.colors.DeepCerulean};
          background-color: ${theme.colors.White};
          border-color: ${theme.colors.White};

          &:hover {
            color: ${theme.colors.DeepCerulean};
          }
        `;
      case 'secondary':
      case 'buy':
      case 'mainGreen':
        return css`
          background-color: ${theme.colors.Jade};
          border-color: ${theme.colors.Jade};
        `;
      case 'invertedSecondary':
        return css`
          color: ${theme.colors.Jade};
          background-color: ${theme.colors.White};
          border-color: ${theme.colors.White};

          &:hover {
            color: ${theme.colors.Jade};
          }
        `;
      case 'subscription':
      case 'uplayPlusBlue':
      case 'mainBlue':
        return css`
          background-color: ${theme.colors.UbisoftPlus};
          border-color: ${theme.colors.UbisoftPlus};
        `;
      case 'uplayPlusWhite':
        return css`
          color: ${theme.colors.UbisoftPlus};
          background-color: ${theme.colors.White};
          border-color: ${theme.colors.White};

          &:hover {
            color: ${theme.colors.UbisoftPlus};
          }
        `;
      case 'disabled':
      case 'neutral':
        return css`
          background-color: ${theme.colors.BaliHai};
          border-color: ${theme.colors.BaliHai};
        `;
      case 'preorder':
        return css`
          background-color: ${theme.colors.CornflowerBlue};
          border-color: ${theme.colors.CornflowerBlue};
        `;
      case 'secondaryOutlineBlack':
        return css`
          background-color: black;
          border-color: ${theme.colors.Concrete};
          color: ${theme.colors.Concrete};

          &:hover {
            color: ${theme.colors.Concrete};
          }

          path {
            fill: ${theme.colors.Concrete};
          }
        `;
      case 'secondaryOutlineWhite':
        return css`
          background-color: white;
          border-color: ${theme.colors.MineShaftDark};
          color: ${theme.colors.MineShaftDark};

          &:hover {
            color: ${theme.colors.MineShaftDark};
          }

          path {
            fill: ${theme.colors.MineShaftDark};
          }
        `;
      case 'secondaryQuietWhite':
        return css`
          background-color: ${theme.colors.Mercury};
          border-color: ${theme.colors.Mercury};
          color: ${theme.colors.MineShaftDark};

          &:hover {
            color: ${theme.colors.MineShaftDark};
          }

          path {
            fill: ${theme.colors.MineShaftDark};
          }
        `;
      case 'secondaryQuietBlack':
      case 'secondaryQuietRedirection':
        return css`
          background-color: ${theme.colors.MineShaftDark};
          border-color: ${theme.colors.MineShaftDark};
          color: ${theme.colors.Mercury};

          &:hover {
            color: ${theme.colors.Mercury};
          }

          path {
            fill: ${theme.colors.Mercury};
          }
        `;
    }
    return null;
  }}

  ${LoaderContainer} {
    width: 100px;
  }
`;
