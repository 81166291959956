import { m } from 'framer-motion';
import React from 'react';
import styled, { css } from 'styled-components';
import SelectedIcon from './SelectedIcon';

interface ISelectedIconMotionWrapperProps {
  top?: boolean;
}

export const SelectedIconMotionWrapper = styled(m.div)<ISelectedIconMotionWrapperProps>`
  width: 1rem;
  height: 1rem;

  ${({ top }) =>
    !top
      ? css`
          position: absolute;
          top: 50%;
          right: 0;
          display: block;
          margin-top: -0.5rem;
        `
      : css`
          display: inline-block;
          position: relative;
          top: 2px;
        `}
  .selected-icon-wrapper {
    width: 1rem;
    height: 1rem;

    svg {
      width: 100%;
      height: 100%;
    }
  }
`;

interface ISelectedIconMotionProps {
  top?: boolean;
}

const SelectedIconMotion = ({ top }: ISelectedIconMotionProps) => {
  return (
    <SelectedIconMotionWrapper
      initial={{
        opacity: 0,
        x: -20,
      }}
      animate={{
        opacity: 1,
        x: 0,
      }}
      exit={{
        opacity: 0,
        x: 10,
      }}
      transition={{
        type: 'spring',
      }}
      className="selected-icon-motion"
      top={top}
    >
      <SelectedIcon />
    </SelectedIconMotionWrapper>
  );
};

export default SelectedIconMotion;
