import styled from 'styled-components';
import { m } from 'framer-motion';
import { getBreakpointValue } from '@pdp-rework-hub/utils';

interface IEditionDetailsContainer {
  backgroundColor: string;
  isSubscription: boolean;
}

export const EditionDetailsContainer = styled(m.div)<IEditionDetailsContainer>`
  background-color: ${({ backgroundColor }) => backgroundColor};
  border-radius: 8px;
  width: 100%;
  max-width: 18.75rem; // 300px
  margin: 0.75rem 0; // 12px

  @media screen and (min-width: ${getBreakpointValue('tabletLarge', 'min')}) {
    flex: 1 1 18.75rem; // 300px
    margin: 0.75rem; // 12px
  }
`;

export const PreviousPrice = styled.span`
  display: block;
  font-size: 0.875rem;
  color: ${({ theme }) => theme.colors.price.previous};
  text-decoration: line-through;
`;

export const NewPrice = styled.span`
  display: block;
  font-size: 1.5rem;
  color: ${({ theme }) => theme.colors.price.discounted};
`;

export const SubscriptionPriceContainer = styled.div`
  .subscription-price-discount {
    font-size: 0.875rem;
    color: ${({ theme }) => theme.colors.price.discounted};

    span {
      font-size: 1.5rem;
      font-family: ${({ theme }) => theme.fonts.regular};
    }
  }
  .subscription-price-regular {
    font-size: 0.875rem;

    span {
      font-size: 1.5rem;
      font-family: ${({ theme }) => theme.fonts.regular};
    }
  }
`;
