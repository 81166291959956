import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { SFCC_PLATFORMS } from '@pdp-rework-hub/utils-constants';
import {
  IProductModel,
  IVariation,
  IProductsState,
  IMaster,
  ISubscription,
  SelectionOptions,
  CustomerOfferMap,
  IContain,
  PlatformValue,
} from '@pdp-rework-hub/utils-types';

const productsSlice = createSlice({
  name: 'products',
  initialState: {
    initialized: false,
    loaded: false,
    fetching: false,
    fetchingProductOffer: false,
    errorData: null,
    data: {
      product: null,
      subscriptions: [],
    },
    isSubscriptionButtonRestricted: false,
    selectedOffer: null,
    selectedMaster: null,
    selectedVariant: null,
    selectedPlatform: SFCC_PLATFORMS.pcdl.id,
    selectedSubscription: null,
    selectedOption: null,
    productOffers: {},
    availableSortedSubscriptions: null,
  } as IProductsState,
  reducers: {
    fetchStart(state) {
      state.initialized = true;
      state.fetching = true;
    },
    fetchProductOfferStart(state) {
      state.fetchingProductOffer = true;
    },
    setIsSubscriptionButtonRestricted(state, action: PayloadAction<boolean>) {
      state.isSubscriptionButtonRestricted = action.payload;
    },
    setProductData(state, action: PayloadAction<IProductModel>) {
      state.data.product = action.payload;
    },
    setSubscriptions(state, action: PayloadAction<ISubscription[]>) {
      state.data.subscriptions = action.payload;
    },
    setSelectedMaster(state, action: PayloadAction<IMaster | null>) {
      state.selectedMaster = action.payload;
    },
    setSelectedSubscription(state, action: PayloadAction<ISubscription>) {
      state.selectedSubscription = action.payload;
    },
    setSelectedVariant(state, action: PayloadAction<IVariation | null>) {
      state.selectedVariant = action.payload;
    },
    setSelectedPlatform(state, action: PayloadAction<PlatformValue>) {
      state.selectedPlatform = action.payload;
    },
    setSelectedOption(state, action: PayloadAction<SelectionOptions>) {
      state.selectedOption = action.payload;
    },
    setSelectedOffer(state, action: PayloadAction<IContain | null>) {
      state.selectedOffer = action.payload;
    },
    setProductOffers(state, action: PayloadAction<CustomerOfferMap>) {
      state.productOffers = {
        ...state.productOffers,
        ...action.payload,
      };
    },
    setError(state, action: PayloadAction<string>) {
      state.errorData = {
        error: true,
        message: action.payload,
      };
    },
    setAvailableSortedSubscriptions(state, action: PayloadAction<ISubscription[]>) {
      state.availableSortedSubscriptions = action.payload;
    },
    fetchEnd(state) {
      state.fetching = false;
      state.loaded = true;
    },
    fetchProductOfferEnd(state) {
      state.fetchingProductOffer = false;
    },
  },
});

export const {
  fetchStart,
  fetchProductOfferStart,
  setIsSubscriptionButtonRestricted,
  setProductData,
  fetchEnd,
  setError,
  setSelectedOffer,
  setSelectedMaster,
  setSelectedVariant,
  setSelectedPlatform,
  setSubscriptions,
  setSelectedSubscription,
  setSelectedOption,
  setProductOffers,
  fetchProductOfferEnd,
  setAvailableSortedSubscriptions,
} = productsSlice.actions;

export const productsReducer = productsSlice.reducer;
