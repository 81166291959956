import styled, { keyframes } from 'styled-components';

export const pulse = keyframes`
  0% {
    transform: scale(0.5);
  }
  50% {
    transform: scale(1);
  }
  100% {
    transform: scale(0.5);
  }
`;

export const LoaderContainer = styled.div`
  position: relative;
  height: 100%;
  min-height: 2rem;
`;

export const LoaderInner = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${({ theme }) => theme.Loader.backgroundcolor};
  z-index: 11;
`;

interface IStyledDot {
  index: number;
}

export const StyledDot = styled.span<IStyledDot>`
  content: '';
  display: block;
  width: 10px;
  height: 10px;
  margin: 0 5px;
  transform: scale(0.5);
  animation: 1s ${pulse} infinite ease-in-out;
  background-color: ${({ theme }) => theme.colors.text.primary};
  border-radius: 5px;
  animation-delay: ${({ index, theme }) => Math.round(index * theme.Loader.Dot.delay * 10) / 10}s;
`;
