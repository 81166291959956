import React from 'react';
import { filterImagesList } from '../utils';
import { IBreakpoints } from '@pdp-rework-hub/utils-types';

import { BackgroundContainer } from './style';

interface IBreakpointsBackgroundImagesProps {
  source?: IBreakpoints;
  children?: React.ReactNode;
}

export const BreakpointsBackgroundImages = ({ source, children }: IBreakpointsBackgroundImagesProps) => {
  const imagesList = filterImagesList(source);
  return imagesList.length ? (
    <>
      <BackgroundContainer backgrounds={source}>{children}</BackgroundContainer>
    </>
  ) : (
    <>{children}</>
  );
};

export default BreakpointsBackgroundImages;
