import {
  DIGITAL_PLATFORMS_SET,
  platformKeys,
  PLAYSTATION_DIGITAL_PLAFORMS_SET,
  SFCC_PLATFORMS,
} from '@pdp-rework-hub/utils-constants';
import { IProductModel, IVariation, PlatformValue } from '@pdp-rework-hub/utils-types';
import cloneDeep from 'lodash/cloneDeep';

export class ProductModelFormatter {
  private _productModel: IProductModel;
  outputProductModel: IProductModel;

  constructor(productModel: IProductModel) {
    this._productModel = cloneDeep(productModel);
    this.outputProductModel = this._productModel;
  }

  formatMasters(): this {
    this.outputProductModel.masters = this.outputProductModel.masters.map((master) => {
      const variationFormatter = new VariationsFormatter(
        master.variations,
        this._productModel.details.productPlatformsOrder
      )
        .extractDigitalVariations()
        .removeDuplicatePlaystation()
        .orderVariations();

      return {
        ...master,
        variations: variationFormatter.formattedVariations,
      };
    });

    return this;
  }

  get formattedProductModel(): IProductModel {
    return this.outputProductModel;
  }
}

export class VariationsFormatter {
  private _variations: IVariation[];
  outputVariations: IVariation[];
  productPlatformsOrder: string[];

  constructor(variations: IVariation[], productPlatformsOrder: string[]) {
    this._variations = variations;
    this.outputVariations = this._variations;
    this.productPlatformsOrder = productPlatformsOrder;
  }

  orderVariations(): this {
    if (this.productPlatformsOrder?.length > 0) {
      this.outputVariations = this.outputVariations.sort((a, b) => {
        const indexA = this.productPlatformsOrder?.indexOf(a.platform);
        const indexB = this.productPlatformsOrder?.indexOf(b.platform);

        if (indexA === -1 && indexB === -1) {
          return 0;
        }

        if (indexA === -1) {
          return 1;
        }

        if (indexB === -1) {
          return -1;
        }

        return indexA - indexB;
      });
      return this;
    }

    const PCIndex = this.outputVariations.findIndex((variation) => variation.platform === 'pcdl');

    if (PCIndex <= 0) {
      return this;
    }

    const PCVariation = this.outputVariations.splice(PCIndex, 1)[0];
    this.outputVariations.unshift(PCVariation);

    return this;
  }

  extractDigitalVariations(): this {
    const uniquePlatforms = new Set<PlatformValue>();

    this.outputVariations = this.outputVariations.filter((variant) => {
      const isDigital = DIGITAL_PLATFORMS_SET.has(variant.platform);
      const isValidFirstParty = isDigital && this.checkFirstParty(variant);
      const isValidPlatform = !uniquePlatforms.has(variant.platform) && isDigital && isValidFirstParty;
      uniquePlatforms.add(variant.platform);

      return isValidPlatform;
    });

    return this;
  }

  /**
   * This method should be removed after the contribution is clean
   */
  removeDuplicatePlaystation(): this {
    let ps4ps5digIndex: number | null = null;

    const playstationVariants = this.outputVariations.filter((variant, index) => {
      if (variant.platform === SFCC_PLATFORMS.ps4ps5dig.id) {
        ps4ps5digIndex = index;
      }

      return PLAYSTATION_DIGITAL_PLAFORMS_SET.has(variant.platform);
    });

    if (!playstationVariants.length) {
      return this;
    }

    const playstationVariant = ps4ps5digIndex != null ? this.outputVariations[ps4ps5digIndex] : playstationVariants[0];

    if (!playstationVariant || !playstationVariant.productsVersions?.hasRedirectVersion) {
      return this;
    }

    this.outputVariations = [
      ...this.outputVariations.filter((variation) => !PLAYSTATION_DIGITAL_PLAFORMS_SET.has(variation.platform)),
      playstationVariant,
    ];

    return this;
  }

  private checkFirstParty(variant: IVariation) {
    if (variant.platform === platformKeys.PC_KEY) {
      return true;
    }

    return !!variant.productsVersions?.hasRedirectVersion;
  }

  get formattedVariations(): IVariation[] {
    return this.outputVariations;
  }
}
