export const SHOP_URL = process.env.NODE_ENV === 'development' ? 'https://store-dev.ubi.com' : '';
export const WEBDAV_URL = `${SHOP_URL}/on/demandware.static/-`;
export const SELECTOR_OPTION_PRODUCT = 'SELECTOR_OPTION_PRODUCT';
export const WEBDAV_ASSETS_URL = `${WEBDAV_URL}/Library-Sites-shared-library-web/default/pdp-rework-hub/assets`;

export const externalStores = [
  {
    key: 'playstation',
    patterns: ['ps4', 'ps5'],
  },
  {
    key: 'microsoft',
    patterns: ['xbox'],
  },
  {
    key: 'nintendo',
    patterns: ['switch'],
  },
  {
    key: 'apple',
    patterns: ['appstore'],
  },
  {
    key: 'google',
    patterns: ['googleplay'],
  },
];
