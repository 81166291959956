export const getCookieValue = (cookieName: string) => {
  const value = `; ${document.cookie}`;
  const parts = value.split(`; ${cookieName}=`);
  if (parts && parts.length === 2) {
    return parts.pop()?.split(';').shift();
  }
  return null;
};

export const capitalizeFirstLetter = (value: string) => value.charAt(0).toUpperCase() + value.slice(1);

export const formatDate = (date: string): Date | null => {
  const dateRegex = /^(\d{2})\/(\d{2})\/(\d{4})\s(\d{2}):(\d{2}):(\d{2})$/;
  const dateArray = date.match(dateRegex);

  if (!dateArray) {
    return null;
  }

  const [, startDay, startMonth, startYear, startHour, startMin, startSec] = dateArray;
  if (startHour) {
    return new Date(`${startYear}-${startMonth}-${startDay} ${startHour}:${startMin}:${startSec}`);
  }

  return new Date(`${startYear}-${startMonth}-${startDay}`);
};

export const isMobile = () =>
  /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
