export default {
  translations: {
    title: {
      classics:
        '¡Suscríbete para jugar a <span class="blue-variant">{{gameName}}</span> y a una selección de juegos icónicos de Ubisoft que se actualiza constantemente!',
      premium: 'Suscríbete para jugar <span class="blue-variant">{{gameName}}</span> y +100 juegos de PC',
    },
    cancelAnytime: 'Cancela cuando quieras',
    learnMore: 'Descubre más acerca de Ubisoft+',
    classics: 'CLASSICS',
    premium: 'PREMIUM',
  },
};
