import en from './en';
import de from './de';
import fr from './fr';
import es from './es';
import it from './it';
import ru from './ru';
import nl from './nl';
import ja from './ja';
import ko from './ko';
import pl from './pl';
import th from './th';
import zh from './zh';
import pt from './pt';
import zhCn from './zh-CN';

export default {
  en: en,
  de: de,
  fr: fr,
  es: es,
  it: it,
  ru: ru,
  nl: nl,
  ja: ja,
  ko: ko,
  pl: pl,
  th: th,
  zh: zh,
  pt: pt,
  'zh-CN': zhCn,
};
