export default {
  translations: {
    selectYour: {
      edition: 'Seleziona la tua edizione',
      version: 'Seleziona la tua versione',
      platform: 'Seleziona la tua piattaforma',
      payment: "Seleziona un'opzione di pagamento",
    },
    selectPlatform: 'Seleziona la piattaforma',
    releaseDate: 'Data di uscita:',
    buySelector: { subscription: { subscriptionSelection: "Scelta dell'abbonamento" } },
    subscription: {
      title: '{{gameName}} e molto altro ancora con Ubisoft<span class="blue">+</span>',
      alreadySubscribed: 'Sei già abbonato a Ubisoft+!',
      learnMoreAbout: 'Scopri di più su <0>Ubisoft+</0>',
      cancelNotice: 'Annulla e ritorna in qualsiasi momento. <0>Vedi le FAQ</0>',
      gamesSelection: {
        classics: {
          title: 'I titoli più famosi di Ubisoft',
          description:
            "Divertiti con i titoli Ubisoft più famosi, inclusi Assassin's Creed Valhalla, Rainbow Six Siege e altro ancora",
          link: { notSubscriber: 'Vedi i giochi inclusi', subscriber: 'Sfoglia i giochi' },
        },
        premium: {
          title: 'Oltre 100 giochi',
          description:
            "Gioca tutti i titoli Far Cry e goditi un catalogo sempre più vasto che include Assassin's Creed Valhalla, Rainbow Six Siege, e molto altro ancora.",
          link: { notSubscriber: "Dai un'occhiata ai giochi inclusi", subscriber: 'Sfoglia i giochi' },
        },
      },
      cards: {
        cloudGaming: {
          title: 'Cloud Gaming',
          description:
            'Gioca istantaneamente con il tuo smartphone, tablet o TV ovunque tu voglia tramite {{platforms}} utilizzando una buona connessione.',
          link: 'Visualizza i giochi sul cloud',
        },
        consoles: {
          title: 'Console',
          description:
            'Gioca a una selezione di giochi della tua console {{platforms}} con il nostro abbonamento di gioco.',
          link: 'Visualizza i giochi per console inclusi',
        },
      },
      partOfUbisoftPlus: {
        released: 'Gioca a {{game}} {{edition}} con Ubisoft+',
        dlc: 'Gioca il {{dlc}}, incluso nel {{game}}.',
        preOrder: 'Gioca a {{game}} non appena esce {{releaseDate}}.',
        includedContent: 'Contenuti inclusi:',
      },
      firstParty: {
        legalLine:
          'I prezzi si riferiscono unicamente alle edizioni per PC.<br/>Consulta gli store digitali delle terze parti per conoscere i prezzi delle edizioni per console.',
        pricingInfo: 'Consulta gli store digitali della piattaforma per conoscere il prezzo',
      },
      learnMore: 'Scopri di più',
      playThisGame: 'Play this game in your <span class="blue">Library+</span>!',
    },
  },
};
