import { filterActivePromotionsFromSubscriptions } from '@pdp-rework-hub/utils';
import {
  SUBSCRIPTION_BUTTON_TYPE,
  SUBSCRIPTION_IDS,
  SUBSCRIPTION_PROMOTION_FREE_TRIAL_TYPE,
} from '@pdp-rework-hub/utils-constants';
import {
  AppBaseState,
  ISubscription,
  SubscriptionButtonType,
  SubscriptionPromotion,
  SubscriptionStatus,
} from '@pdp-rework-hub/utils-types';
import { useMemo } from 'react';
import { TFunction } from 'react-i18next';
import { useSelector } from 'react-redux';

type TUseGetSubButtonInfoParams = {
  t: TFunction<'translation', undefined>;
  isPreOrder: boolean;
  selectedSubscription?: ISubscription;
};

type SubscriptionButtonInfo = {
  type: SubscriptionButtonType;
  content: string;
  promotions: SubscriptionPromotion[];
  selectedSubscription?: ISubscription | null;
};

export const useGetSubButtonInfo = ({ t, isPreOrder }: TUseGetSubButtonInfoParams): SubscriptionButtonInfo | null => {
  const { subscriptions, user, isActiveSubscriber, selectedSubscription, userUpgradeRequired, subscriptionStatus } =
    useSelector((state: AppBaseState) => ({
      subscriptions: state.products.data.subscriptions,
      selectedSubscription: state.products.selectedSubscription,
      user: state.user,
      isActiveSubscriber: state.user.isActiveSubscriber,
      availableSortedSubscriptions: state.products.availableSortedSubscriptions,
      userUpgradeRequired: state.user.upgradeRequired,
      subscriptionStatus: state.user.subscriptionStatus,
    }));

  const arrayOfSubscriptions = selectedSubscription ? [selectedSubscription] : subscriptions;
  const promotions = useMemo(
    () => filterActivePromotionsFromSubscriptions(arrayOfSubscriptions),
    [arrayOfSubscriptions]
  );
  const isFreeTrial = useMemo(
    () =>
      promotions.length > 0 &&
      promotions.filter((promotion) => promotion.type === SUBSCRIPTION_PROMOTION_FREE_TRIAL_TYPE).length,
    [promotions]
  );

  if (!user.ubiAuthenticationLoaded) {
    return null;
  }

  const subscriptionButtonInfo: SubscriptionButtonInfo = {
    type: SUBSCRIPTION_BUTTON_TYPE.SUBSCRIBE,
    content: t('global.subscription.subscribeNow'),
    promotions,
    selectedSubscription,
  };

  if (isFreeTrial) {
    subscriptionButtonInfo.type = SUBSCRIPTION_BUTTON_TYPE.FREE_TRIAL;
    subscriptionButtonInfo.content = t('global.tryForFree');
  }

  //Check if user already had a subscription and push to re-activate
  if (subscriptionStatus === SubscriptionStatus.Inactive) {
    subscriptionButtonInfo.content = t('global.subscription.reactivate');
  }

  // Check if the current user has an active subscription and push the playNow
  if (isActiveSubscriber) {
    subscriptionButtonInfo.type = isPreOrder ? SUBSCRIPTION_BUTTON_TYPE.GO_TO_LIBRABRY : SUBSCRIPTION_BUTTON_TYPE.PLAY;
    subscriptionButtonInfo.content = isPreOrder ? t('global.subscription.goToMyLibrary') : t('global.playNow');
  }

  // We propose to upgrade the plan of the user if he has an active subscription that is not Premium
  if (isActiveSubscriber && userUpgradeRequired && selectedSubscription?.id === SUBSCRIPTION_IDS.PREMIUM_ANYWHERE) {
    subscriptionButtonInfo.type = SUBSCRIPTION_BUTTON_TYPE.CHANGE;
    subscriptionButtonInfo.content = t('global.subscription.changePlan');
  }
  return subscriptionButtonInfo;
};
