export default {
  translations: {
    title: {
      classics: '订阅后即可游玩<span class="blue-variant">{{gameName}}</span>以及一系列不断扩充的经典Ubisoft游戏！',
      premium: '訂閱即可暢玩<span class="blue-variant">{{gameName}}</span>與超過 100 款遊戲',
    },
    cancelAnytime: '随时取消',
    learnMore: '深入了解 Ubisoft+',
  },
};
