import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { fetchProductData, setSelectedOption, selectMasterAndEdition } from '@pdp-rework-hub/slices';
import { RootState, useAppDispatch } from '../../store';
import BuySelector from '../BuySelector';
import { IAdobeProductImpressionEvent, ProductEventPayload } from '@pdp-rework-hub/utils-types';
import { GlobalStyle, Modal, OfferModal, withLazyMotion } from '@pdp-rework-hub/ui';
import { ICustomAppProps } from '../../AppProps';
import { useTheme } from 'styled-components';
import { SELECTOR_OPTION_PRODUCT, SELECTOR_OPTION_SUBSCRIPTION } from '@pdp-rework-hub/utils-constants';
import {
  sendAdobeEvent,
  useCheckUserRestrictions,
  useModelsDependencies,
  useUbiConnectFetched,
} from '@pdp-rework-hub/utils';
import { sendProductEditionEvent, sendSubscriptionEvent, sendSubscriptionPlanEvent } from '../../utils';

import { ModalBuySelector } from './styles';
import { withModalProvider, useModalContext } from '../../context/ModalContext';

const Content = (props: ICustomAppProps) => {
  const theme = useTheme();
  const dispatch = useAppDispatch();
  const {
    productData,
    selectedMaster,
    selectedOffer,
    selectedOption,
    selectedPlatform,
    selectedSubscription,
    selectedVariant,
    availableSortedSubscriptions,
  } = useSelector((state: RootState) => ({
    productData: state.products.data.product,
    selectedMaster: state.products.selectedMaster,
    selectedOffer: state.products.selectedOffer,
    selectedOption: state.products.selectedOption,
    selectedPlatform: state.products.selectedPlatform,
    selectedSubscription: state.products.selectedSubscription,
    selectedVariant: state.products.selectedVariant,
    availableSortedSubscriptions: state.products.availableSortedSubscriptions,
  }));
  const { modalOpened, setModalOpened, handleCloseModal } = useModalContext();
  const handleModalOpen = (e: CustomEvent<ProductEventPayload>) => {
    const productId = e.detail && e.detail.productId;
    const tab = e.detail && e.detail.tab;
    const selectedOption = tab || SELECTOR_OPTION_PRODUCT;

    if (selectedOption === SELECTOR_OPTION_PRODUCT) {
      dispatch(selectMasterAndEdition(productId));
    }

    setModalOpened(true);
    dispatch(setSelectedOption(selectedOption));
  };

  useModelsDependencies();

  useUbiConnectFetched();
  useCheckUserRestrictions();

  useEffect(() => {
    dispatch(fetchProductData());

    document.addEventListener('PDP_BUYSUB_MODAL_OPEN', handleModalOpen as EventListener);

    return () => {
      document.removeEventListener('PDP_BUYSUB_MODAL_OPEN', handleModalOpen as EventListener);
    };
  }, [fetchProductData]);

  // Trigger an event whenever we change the tab
  useEffect(() => {
    if (selectedOption === SELECTOR_OPTION_PRODUCT && !selectedMaster && !selectedVariant) {
      dispatch(selectMasterAndEdition(undefined));
    }
    const impressionTrackingEvent: IAdobeProductImpressionEvent = {
      location: 'buy selector',
      locationDetail: selectedOption === SELECTOR_OPTION_PRODUCT ? 'buy' : 'subscribe',
      action: 'visible',
      category: 'display',
      locationPosition: 'overlay',
      productImpression: [],
    };
    if (
      selectedOption === SELECTOR_OPTION_PRODUCT &&
      productData?.masters.length &&
      selectedMaster &&
      selectedPlatform &&
      selectedVariant
    ) {
      impressionTrackingEvent.productImpression = productData?.masters.map((master) => master.id);
      sendAdobeEvent(impressionTrackingEvent, 'impressionevent');
      sendProductEditionEvent(selectedMaster, selectedVariant, selectedPlatform, productData, 'selected');
    } else if (
      selectedOption === SELECTOR_OPTION_SUBSCRIPTION &&
      selectedSubscription &&
      availableSortedSubscriptions
    ) {
      impressionTrackingEvent.productImpression = availableSortedSubscriptions.map((subscription) => subscription.id);
      sendAdobeEvent(impressionTrackingEvent, 'impressionevent');
      sendSubscriptionEvent(selectedSubscription, 'selected');
    }

    if (selectedOption === SELECTOR_OPTION_SUBSCRIPTION) {
      if (selectedSubscription) {
        sendSubscriptionPlanEvent(selectedSubscription);
      }
    }
  }, [selectedOption]);

  // Trigger an event whenever the selected variant changes
  useEffect(() => {
    if (
      selectedOption === SELECTOR_OPTION_PRODUCT &&
      productData?.masters.length &&
      selectedMaster &&
      selectedPlatform &&
      selectedVariant
    ) {
      sendProductEditionEvent(selectedMaster, selectedVariant, selectedPlatform, productData);
    }
  }, [selectedVariant]);

  return (
    <div className="main">
      <GlobalStyle />
      {modalOpened && (
        <ModalBuySelector>
          <Modal
            offsetY="5vh"
            animated
            isOpen={modalOpened}
            handleClose={() => handleCloseModal()}
            textColor={theme.colors.White}
            fullscreen
          >
            <BuySelector background={props.background as string} />
          </Modal>
        </ModalBuySelector>
      )}
      <OfferModal
        backgroundColor={theme.colors.BlackPearl}
        textColor={theme.colors.White}
        offer={selectedOffer}
        location={'buy selector'}
        locationPosition={'overlay'}
      />
    </div>
  );
};

export default withTranslation()(withLazyMotion(withModalProvider(Content)));
