export default {
  translations: {
    title: '{{gameName}} 에디션 비교하기',
    subscription: {
      title: 'Ubisoft에 포함<span class="blue">+</span>',
      classics: { title: '클래식 컬렉션', subtitle: '유비소프트 대표 타이틀을 즐겨보세요' },
      premium: { title: '100개 이상의 PC 게임', subtitle: '신규 출시 게임 포함' },
    },
    subscriptionModal: {
      classics: {
        title: 'Ubisoft<span class="blue">+</span> Classics',
        items: [
          { description: 'Ubisoft Connect에서 계속 추가되는 상징적인 게임 모음집을 즐겨 보세요', icon: 'check' },
          { description: '스탠다드 에디션 이용하기', icon: 'check' },
          { description: 'DLC 및 게임 20% 할인', icon: 'check' },
        ],
      },
      premium: {
        title: 'Ubisoft<span class="blue">+</span>란 무엇인가요?',
        items: [
          {
            title: '신작 타이틀과 100개 이상의 PC 게임',
            description: '클라우드에서 엄선한 게임과 100개 이상의 PC 게임 플레이 가능',
            icon: 'games',
          },
          { title: '프리미엄 에디션', description: '확장팩, 시즌 패스 등 이용 가능', icon: 'diamond' },
          { title: '월간 보상', description: '매달 새로운 꾸미기 아이템, 부스터 및 게임 내 보상 획득', icon: 'reward' },
          { title: '취소 후 언제든 재이용 가능', description: '언제든 손쉽게 구독 상태 변경', icon: 'replay' },
        ],
      },
      learnMoreAboutUbisoftPlus: 'Ubisoft+에 대해 더 알아보기',
    },
  },
};
