import React from 'react';
import { PillInner, PillWrapper } from './style';

interface IPillProps {
  background: string;
  xPosition?: 'left' | 'right';
}

const Pill: React.FC<IPillProps> = ({ xPosition = 'right', background, children }) => {
  return (
    <PillWrapper xPosition={xPosition} background={background}>
      <PillInner>{children}</PillInner>
    </PillWrapper>
  );
};

export default Pill;
