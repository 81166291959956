import React from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';

import SubscriptionItem from './SubscriptionItem';
import { WEBDAV_URL } from '@pdp-rework-hub/utils-constants';
import { PremiumAnywhereTypes } from '@pdp-rework-hub/utils-types';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../store';

interface IPremiumDetails extends WithTranslation {
  type: PremiumAnywhereTypes;
}

const PremiumDetails = ({ t, type }: IPremiumDetails) => {
  const { subscriptions } = useSelector((state: RootState) => ({
    subscriptions: state.products.data.subscriptions,
  }));

  const premiumSubscription = subscriptions.find((subscription) => subscription.anywhereAvailability);

  if (!premiumSubscription) {
    return null;
  }

  const ubisoftPlusURL = 'https://store.ubi.com/ubisoftplus';

  const cardLink = {
    title: t(`subscription.cards.${type}.link`),
    url: `${ubisoftPlusURL}/games?offer=premium&access=${type === 'cloudGaming' ? 'luna' : 'xbox'}`,
  };

  let platforms = '';
  if (type === 'cloudGaming' && premiumSubscription?.anywhereAvailability?.luna) {
    platforms = t('platforms.Luna');
  }
  if (type === 'consoles' && premiumSubscription?.anywhereAvailability?.xbox) {
    platforms = `${t('platforms.XboxX')} ${t('global.and')} ${t('platforms.Xbox1')}`;
  }

  const cardDescription = (
    <>
      {t(`subscription.cards.${type}.description`, { platforms })}
      &nbsp;
      <a href={`${ubisoftPlusURL}/cloud`} target={'_blank'}>
        {t('subscription.learnMore')}
      </a>
    </>
  );

  const trackingDetail = type === 'cloudGaming' ? 'ubisoft plus cloud games' : 'ubisoft plus console games';

  return (
    <SubscriptionItem
      type={type}
      subscription={premiumSubscription}
      title={t(`subscription.cards.${type}.title`)}
      description={cardDescription}
      trackingAction={`${trackingDetail} : see products list`}
      link={cardLink}
      image={`${WEBDAV_URL}/Library-Sites-shared-library-web/default/pdp-rework-hub/assets/${type}.png`}
    />
  );
};

export default withTranslation()(PremiumDetails);
