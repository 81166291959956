import styled from 'styled-components';

export const ImageContainer = styled.div`
  margin: 0.5rem;

  ${({ theme }) => theme.breakpoints.tabletLarge.min.css`
    margin: 1rem;
  `}
  img {
    max-width: 100%;
    max-height: 320px;
  }
`;

export const EditionPackshot = styled.img`
  display: block;
  border-radius: 8px;
  box-shadow: 0px 4px 8px 3px rgba(0, 0, 0, 0.25);
`;
