import React from 'react';
import { Store } from 'redux';
import { CoreApp, CoreAppBaseDefaultProps, ICoreAppBaseProps } from '@mooncake/core';
import { CustomAppDefaultProps, CustomAppEventsConfigs, ICustomAppProps } from './AppProps';
import store from './store';
import i18n from './i18n';
import { AppLoader } from '@pdp-rework-hub/ui';
import Content from './components/Content';
import theme from './theme';

interface IApp extends ICoreAppBaseProps, ICustomAppProps {
  appLoader?: React.ReactNode;
  content?: React.ReactNode;
  store?: Store;
}

const App = (props: IApp) => {
  const themeName = props.theme === 'dark' ? 'uplay' : 'store';
  return <CoreApp store={store} i18n={i18n} computedTheme={theme(themeName)} {...props} />;
};

export const AppProps = {
  ...CoreAppBaseDefaultProps,
  ...CustomAppDefaultProps,
  appLoader: <AppLoader />,
  content: <Content />,
};

export const AppEventsConfigs = CustomAppEventsConfigs;

App.defaultProps = AppProps;

export default App;
