import React, { useCallback } from 'react';
import { useTheme } from 'styled-components';
import { ICustomAppProps } from '../../AppProps';

import Container from '@mooncake/ui/lib/components/Container';
import { Button, HeadingResetContainer, TextResetContainer } from '@pdp-rework-hub/ui';
import { ContentContainer } from './styles';
import { sendAdobeEvent, getStoreRelativeURL } from '@pdp-rework-hub/utils';
import { IAdobeEvent, IAdobeProductClickEvent } from '@pdp-rework-hub/utils-types';
import { getPageUrl } from '@mooncake/utils';

type IContentProps = ICustomAppProps & {
  position: string | null;
};

const Content = ({ cta, split, text, titlecontent, textcolor, position, productid, categoryid }: IContentProps) => {
  const ctaType = cta && cta.type ? cta.type : 'default';
  const theme = useTheme();

  let buttonLink: string | false | undefined = cta?.link;
  let eventAction = cta?.tracking;
  if (productid) {
    buttonLink = getPageUrl('product', productid);
    eventAction = 'product : see more';
  } else if (categoryid) {
    buttonLink = getPageUrl('category', categoryid);
    eventAction = `${categoryid} : see products list`;
  }

  if (cta?.link.startsWith("/")) {
    buttonLink = getStoreRelativeURL(cta?.link.replace(/^\//, ''));
  }

  const followLink = useCallback(
    (e: React.MouseEvent<HTMLElement>) => {
      if (!buttonLink) {
        return;
      }

      // prepare and send the event
      if (eventAction) {
        e.preventDefault();
        const baseTrackingEvent: IAdobeEvent = {
          location: `content block${split ? ' 50/50' : ''}`,
          locationDetail: titlecontent,
          action: eventAction,
          category: 'action',
        };
        if (position) {
          baseTrackingEvent.locationPosition = position;
        }
        if (productid) {
          const trackingEventProduct: IAdobeProductClickEvent = {
            ...baseTrackingEvent,
            productId: productid,
          };
          sendAdobeEvent(trackingEventProduct);
        } else {
          sendAdobeEvent(baseTrackingEvent);
        }
      }

      // open the link
      if (cta?.target) {
        window.open(buttonLink, cta.target);
      } else {
        window.location.href = buttonLink;
      }
    },
    [cta, split, titlecontent, position]
  );

  return (
    <Container
      display={'flex'}
      justifyContent={'center'}
      flexBasis={{
        tablet: '50%',
      }}
      flexShrink={{
        tablet: 0,
      }}
      flexGrow={{
        tablet: 1,
      }}
      maxWidth={{
        tablet: '50%',
      }}
      padding={
        split
          ? {
              default: '2rem',
              desktop: '2.5rem',
            }
          : {
              default: '1rem',
              desktop: '1.25rem',
            }
      }
    >
      <Container
        maxWidth={{
          desktop: '34.375rem',
        }}
      >
        <ContentContainer>
          <HeadingResetContainer
            as="h2"
            primary
            color={textcolor || theme.colors.text.primary}
            dangerouslySetInnerHTML={{ __html: titlecontent }}
            marginBottom={{
              default: '1rem',
            }}
            fontSize={{
              default: '1.5rem',
              desktop: '2.5rem',
            }}
            fontFamily={`Custom Font, ${theme.fonts.bold}`}
          />
          <TextResetContainer
            as="div"
            primary
            color={textcolor || theme.colors.text.primary}
            dangerouslySetInnerHTML={{ __html: text }}
            marginBottom={{
              default: '2.5rem',
            }}
            lineHeight={{
              default: '1.5rem',
              desktop: '1.625rem',
            }}
            fontSize={{
              default: '1rem',
              desktop: '1.125rem',
            }}
          />
          {cta?.title && buttonLink && (
            <Container marginTop={'1.5rem'}>
              <Button Tag={'button'} buttonType={ctaType} handleClick={(e) => followLink(e)}>
                {cta.title}
              </Button>
            </Container>
          )}
        </ContentContainer>
      </Container>
    </Container>
  );
};

export default Content;
