import React, { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { m } from 'framer-motion';
import { RootState, useAppDispatch } from '../../store';
import { fetchProductData } from '@pdp-rework-hub/slices';
import { hasPackshot } from '@pdp-rework-hub/utils-types';
import {
  getPremiumSubscriptionEdition,
  useCheckUserRestrictions,
  useModelsDependencies,
  useUbiConnectFetched,
  watchLocationPosition,
} from '@pdp-rework-hub/utils';
import { ICustomAppProps } from '../../AppProps';

import { useTheme } from 'styled-components';
import Container from '@mooncake/ui/lib/components/Container';
import {
  BreakpointsBackgroundImages,
  PlusUbisoftPlusIcon,
  appearingAnimationParams,
  GlobalStyle,
  withLazyMotion,
  AppLoader,
  getSubscriptionPackshot,
} from '@pdp-rework-hub/ui';
import { ImageContainer, EditionPackshot } from './style';
import PromoDetails from '../PromoDetails';
import { SHOP_URL, SUBSCRIPTION_PLAN_RECURRENCE } from '@pdp-rework-hub/utils-constants';

const Content = ({ backgroundimage, gameimage }: ICustomAppProps) => {
  const theme = useTheme();
  const dispatch = useAppDispatch();
  const { productData, loaded, availableSortedSubscriptions } = useSelector((state: RootState) => ({
    productData: state.products.data.product,
    loaded: state.products.loaded,
    availableSortedSubscriptions: state.products.availableSortedSubscriptions,
  }));
  const [locationPosition, setLocationPosition] = useState<string | null>(null);
  const appRef = useRef<HTMLElement>(null);
  useModelsDependencies();

  useUbiConnectFetched();
  useCheckUserRestrictions();

  useEffect(() => {
    dispatch(fetchProductData());

    // For tracking purposes, we have to get the position of the block based on an attribute of the parent of the MFE
    watchLocationPosition(appRef).then((value) => {
      setLocationPosition(value);
    });
  }, [fetchProductData]);

  const availableSortedMonthlySubscriptions =
    availableSortedSubscriptions?.filter(
      (subscription) => subscription.productSubscriptionType === SUBSCRIPTION_PLAN_RECURRENCE.MONTHLY
    ) || null;

  const subscription =
    availableSortedMonthlySubscriptions &&
    availableSortedMonthlySubscriptions[availableSortedMonthlySubscriptions.length - 1];
  if (!productData || !subscription) {
    return null;
  }

  const premiumSubscriptionEdition = getPremiumSubscriptionEdition(productData, availableSortedMonthlySubscriptions);
  const packshotMediaFromProduct =
    premiumSubscriptionEdition && hasPackshot(premiumSubscriptionEdition.media)
      ? SHOP_URL + premiumSubscriptionEdition.media.edition_packshot[0].url
      : null;
  const packshotMedia = gameimage || packshotMediaFromProduct;

  return (
    <Container ref={appRef} backgroundColor={theme.colors.background.primary}>
      <GlobalStyle />
      <BreakpointsBackgroundImages source={backgroundimage}>
        <Container
          minHeight={'100%'}
          color={theme.colors.text.primary}
          padding={{
            default: '1rem',
            tabletLarge: '5rem 3.125rem',
          }}
          textAlign={{
            default: 'center',
            tabletLarge: 'left',
          }}
          margin={'auto'}
        >
          {!loaded ? (
            <Container padding={'1rem'}>
              <AppLoader />
            </Container>
          ) : (
            <Container
              width={'100%'}
              maxWidth={'80rem'}
              margin={'auto'}
              display={{
                tabletLarge: 'flex',
              }}
              alignItems={{
                tabletLarge: 'center',
              }}
            >
              <Container
                display={'flex'}
                justifyContent={'center'}
                alignItems={'center'}
                flex={{
                  tabletLarge: '0 0 50%',
                }}
                paddingRight={{
                  tabletLarge: '2rem',
                  desktop: '3rem',
                }}
                marginBottom={{
                  default: '2rem',
                  tabletLarge: '0',
                }}
              >
                {packshotMedia ? (
                  <>
                    <ImageContainer>
                      <m.div {...appearingAnimationParams}>
                        <EditionPackshot src={packshotMedia} alt={productData.details.productName} loading={'lazy'} />
                      </m.div>
                    </ImageContainer>
                    <ImageContainer>
                      <m.div {...appearingAnimationParams(0.1)}>
                        <PlusUbisoftPlusIcon color={theme.colors.text.primary} />
                      </m.div>
                    </ImageContainer>
                  </>
                ) : null}
                <ImageContainer>
                  <m.div {...appearingAnimationParams(packshotMedia ? 0.2 : 0)}>
                    {getSubscriptionPackshot(subscription)}
                  </m.div>
                </ImageContainer>
              </Container>
              <PromoDetails position={locationPosition} />
            </Container>
          )}
        </Container>
      </BreakpointsBackgroundImages>
    </Container>
  );
};

export default withLazyMotion(Content);
