import { RootState } from '../../../../store';
import { SELECTOR_OPTION_PRODUCT } from '@pdp-rework-hub/utils-constants';
import React from 'react';
import { useSelector } from 'react-redux';
import ProductEditions from './ProductEditions';
import { EditionsContainer } from './style';
import SubscriptionPlatforms from './SubscriptionPlatforms';
import { IWithHandleSelectorScroll } from '..';

const Editions = ({ handleScrollEditionClick }: IWithHandleSelectorScroll) => {
  const selectedOption = useSelector((state: RootState) => state.products.selectedOption);
  return (
    <EditionsContainer>
      {selectedOption === SELECTOR_OPTION_PRODUCT ? (
        <ProductEditions skeletonLength={3} handleScrollEditionClick={handleScrollEditionClick} />
      ) : (
        <SubscriptionPlatforms />
      )}
    </EditionsContainer>
  );
};

export default Editions;
