import React from 'react';
import { MediaMainImage, MediaWrapper } from './style';
import { IMaster } from '@pdp-rework-hub/utils-types';
import { hasPackshot } from '@pdp-rework-hub/utils-types';
import { SHOP_URL } from '@pdp-rework-hub/utils-constants';

interface IMediaProps {
  product: IMaster;
}

const Media = ({ product }: IMediaProps) => {
  if (!hasPackshot(product.media)) {
    return null;
  }

  return (
    <MediaWrapper>
      <MediaMainImage>
        <img src={SHOP_URL + product.media.edition_packshot[0].url} alt={product.edition} loading={'lazy'} />
      </MediaMainImage>
    </MediaWrapper>
  );
};

export default Media;
