import React from 'react';

interface IDiamondIcon {
  width: string;
  height: string;
  color?: string;
}

const DiamondIcon = ({ width, height, color = 'white' }: IDiamondIcon) => {
  return (
    <svg width={width} height={height} viewBox="0 0 39 40" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M34.4698 0H4.53714L0 10.8891L12.0182 39.8991H26.9804L39 10.8864L34.4698 0ZM29.3965 13.7153H33.7785L24.4812 36.1586H20.0992L29.3965 13.7153ZM29.5974 9.97478L27.015 3.74054H31.9692L34.5668 9.97478H29.5974ZM22.9572 3.74054L25.5396 9.97478H18.772L19.2887 11.2216L20.3222 13.7153H25.3484L19.8235 27.051L13.2651 11.2216H19.2887L17.7385 7.48108H5.47227L7.03083 3.74054H22.9572ZM4.18664 11.2216H9.21558L17.7981 31.9387L16.0497 36.1586H14.5175L4.18664 11.2216Z" fill={color}/>
    </svg>
  );
};

export default DiamondIcon;
