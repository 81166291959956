import React from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { Button } from '@pdp-rework-hub/ui';
import { externalStores } from '@pdp-rework-hub/utils-constants';

import { LegalLine } from './style';
import { prepareProductEvent, sendAdobeEvent } from '@pdp-rework-hub/utils';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../../../store';
import { IMaster } from '@pdp-rework-hub/utils-types';
interface IExternalStoreButton extends WithTranslation {
  isDisabled: boolean;
  externalLink: string;
  type?: string;
}

const ExternalStoreButton = ({ isDisabled, externalLink, type, t }: IExternalStoreButton) => {
  const { selectedMaster, selectedVariant, product } = useSelector((state: RootState) => ({
    product: state.products.data.product,
    selectedVariant: state.products.selectedVariant,
    selectedMaster: state.products.selectedMaster,
  }));
  const storeFound = externalStores.find(
    (item) => type && item.patterns.some((pattern) => type.toLowerCase().includes(pattern))
  );

  const handleClick = () => {
    const storeName = storeFound ? storeFound.key : 'external store';
    const baseTrackingEvent = {
      location: 'buy selector',
      locationDetail: 'buy',
      action: `product : go to ${storeName}`,
      category: 'external',
      locationPosition: 'overlay',
      slotNumber: 5,
    };
    if (selectedVariant) {
      const productTrackingDetails = prepareProductEvent(product, selectedMaster as IMaster, selectedVariant);
      const trackingEvent = {
        ...baseTrackingEvent,
        ...productTrackingDetails,
      };
      sendAdobeEvent(trackingEvent);
    } else {
      sendAdobeEvent(baseTrackingEvent);
    }
    window.open(externalLink, '_blank');
  };

  return (
    <>
      <Button buttonType={'subscription'} disabled={isDisabled} handleClick={handleClick} external>
        {storeFound ? t(`global.buyOn.${storeFound.key}`) : t('global.getTheGame')}
      </Button>
      <LegalLine dangerouslySetInnerHTML={{ __html: t('subscription.firstParty.legalLine') }} />
    </>
  );
};

export default withTranslation()(ExternalStoreButton);
