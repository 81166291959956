import React from 'react';
import { useTheme } from 'styled-components';
import {
  BreakpointsBackgroundImages,
  GlobalStyle,
  HeadingResetContainer,
  TextResetContainer,
  withLazyMotion,
} from '@pdp-rework-hub/ui';
import Container from '@mooncake/ui/lib/components/Container';
import { ICustomAppProps } from '../../AppProps';
import Tile from '../Tile';
import uniqueId from 'lodash/uniqueId';

import { BlockContainer } from './styles';

const Content = ({
  backgroundimage,
  titlecontent,
  titlecolor,
  textcontent,
  tilebackgroundcolor,
  tiletitlebrushcolor,
  textcolor,
  tiletitle1,
  tiletext1,
  tileimage1,
  tileimagealttext1,
  tiletitle2,
  tiletext2,
  tileimage2,
  tileimagealttext2,
  tiletitle3,
  tiletext3,
  tileimage3,
  tileimagealttext3,
}: ICustomAppProps) => {
  const theme = useTheme();

  const tilesObj = [];
  if (tiletitle1 && tiletext1 && tileimage1) {
    tilesObj.push({
      title: tiletitle1,
      description: tiletext1,
      image: tileimage1,
      alt: tileimagealttext1,
    });
  }
  if (tiletitle2 && tiletext2 && tileimage2) {
    tilesObj.push({
      title: tiletitle2,
      description: tiletext2,
      image: tileimage2,
      alt: tileimagealttext2,
    });
  }
  if (tiletitle3 && tiletext3 && tileimage3) {
    tilesObj.push({
      title: tiletitle3,
      description: tiletext3,
      image: tileimage3,
      alt: tileimagealttext3,
    });
  }

  const tiles = tilesObj.map((tileObj, index) => (
    <Tile
      key={uniqueId()}
      {...tileObj}
      backgroundColor={tilebackgroundcolor}
      brushColor={tiletitlebrushcolor}
      index={index}
    />
  ));

  return (
    <BreakpointsBackgroundImages source={backgroundimage}>
      <GlobalStyle />
      <BlockContainer
        padding={{
          default: '1rem',
          tabletLarge: '5rem 3.125rem',
        }}
        color={theme.colors.text.primary}
      >
        <Container textAlign={'center'} maxWidth={'50rem'} margin={'auto'}>
          <HeadingResetContainer
            primary
            dangerouslySetInnerHTML={{ __html: titlecontent }}
            marginBottom={'1rem'}
            fontSize={{
              default: '1.5rem',
              tabletLarge: '2.5rem',
            }}
            fontFamily={`Custom Font, ${theme.fonts.bold}`}
            lineHeight={'120%'}
            color={titlecolor}
          />
          <TextResetContainer
            as="div"
            dangerouslySetInnerHTML={{ __html: textcontent }}
            fontSize={{
              default: '1rem',
              tabletLarge: '1.125rem',
            }}
            lineHeight={'150%'}
            color={textcolor}
            marginBottom={'1rem'}
          />
        </Container>
        <Container
          width={'100%'}
          maxWidth={'80rem'}
          display={'flex'}
          alignItems={'stretch'}
          flexDirection={{
            default: 'column',
            tablet: 'row',
          }}
          margin={'auto'}
        >
          {tiles}
        </Container>
      </BlockContainer>
    </BreakpointsBackgroundImages>
  );
};

export default withLazyMotion(Content);
