import React, { useState } from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import Container from '@mooncake/ui/lib/components/Container';
import { SeeDetailsButton, TileAccordionHeader, TileAccordionWrapper } from './style';

interface ITileAccordionProps extends WithTranslation {
  children?: React.ReactNode;
  title: string | React.ReactNode;
}

const TileAccordion = ({ children, title, t }: ITileAccordionProps) => {
  const [displayItems, setDisplayItems] = useState(false);

  return (
    <TileAccordionWrapper>
      <TileAccordionHeader>
        <Container
          display={'flex'}
          justifyContent={'space-between'}
          alignItems={'center'}
          padding={'0 0.75rem'}
          width={'100%'}
        >
          <Container>{title}</Container>
          <SeeDetailsButton onClick={() => setDisplayItems(!displayItems)}>
            {displayItems ? t('global.hide') : t('global.seeDetails')}
          </SeeDetailsButton>
        </Container>
      </TileAccordionHeader>
      {displayItems ? children : null}
    </TileAccordionWrapper>
  );
};

export default withTranslation()(TileAccordion);
