import React from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { SELECTOR_OPTION_PRODUCT, SELECTOR_OPTION_SUBSCRIPTION, SUBSCRIPTION_PROMOTION_FREE_TRIAL_TYPE } from '@pdp-rework-hub/utils-constants';
import SelectedIconMotion from '../../../UI/Icons/SelectedIcon/SelectedIconMotion';
import { PriceBlockWrapper, PriceProductWrapper, PriceSelectorWrapper, SelectionCursor } from './style';
import { usePriceSelectorData } from './usePriceSelectorData';

const PriceSelector = ({ t }: WithTranslation) => {
  const priceSelectorData = usePriceSelectorData();

  if (!priceSelectorData) {
    return null;
  }

  const {
    selectedSubscription,
    selectedOption,
    handlePriceSelectorClick,
    upgradeRequired,
    freeTrialPromotion,
    isActiveSubscriber,
  } = priceSelectorData;

  const isFreeTrialPromotionSelected = () => {
    return !!selectedSubscription?.promotions?.some(promotion => promotion.type === SUBSCRIPTION_PROMOTION_FREE_TRIAL_TYPE);
  }

  return (
    <PriceSelectorWrapper>
      <PriceBlockWrapper
        className="price-block"
        active={selectedOption === SELECTOR_OPTION_PRODUCT}
        onClick={() => handlePriceSelectorClick(SELECTOR_OPTION_PRODUCT)}
      >
        <PriceProductWrapper>
          <div className="description">{t('global.buyGame')}</div>
          {selectedOption === SELECTOR_OPTION_PRODUCT && <SelectedIconMotion />}
        </PriceProductWrapper>
      </PriceBlockWrapper>
      <PriceBlockWrapper
        className="price-block"
        onClick={() => handlePriceSelectorClick(SELECTOR_OPTION_SUBSCRIPTION)}
        active={selectedOption === SELECTOR_OPTION_SUBSCRIPTION}
      >
        <PriceProductWrapper>
          {isActiveSubscriber && !upgradeRequired ? (
            <div className="font-bold">{t('global.subscription.playNowUbisoftPlus')}</div>
          ) : (
            <>
              {freeTrialPromotion && isFreeTrialPromotionSelected() ? (
                <>
                  <span className="from">
                    {t('global.durationTerm')}
                    &nbsp;
                    {freeTrialPromotion.duration}
                    &nbsp;
                    {t(`global.${freeTrialPromotion.durationUnit}`)}
                  </span>
                </>
              ) : null}
              <div className="description">{t('global.subscription.subscribeUbisoftPlus')}</div>
            </>
          )}
          {selectedOption === SELECTOR_OPTION_SUBSCRIPTION && <SelectedIconMotion />}
        </PriceProductWrapper>
      </PriceBlockWrapper>
      <SelectionCursor right={selectedOption === SELECTOR_OPTION_SUBSCRIPTION} />
    </PriceSelectorWrapper>
  );
};

export default withTranslation()(PriceSelector);
