import { IBreakpoints } from "@pdp-rework-hub/utils-types";

export const filterImagesList = (source: IBreakpoints | undefined | null) => {
  if (source) {
    const breakpointsKeys = Object.keys(source) as [keyof IBreakpoints];
    return breakpointsKeys
      .filter((currentBreakpoint) => source[currentBreakpoint]);
  }
  return [];
};
