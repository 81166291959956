import { customScrollBar } from '../../../../../utils/style';
import styled from 'styled-components';

export const MediaWrapper = styled.div`
  img {
    width: 100%;
  }
`;

export const MediaSliderWrapper = styled.div`
  width: 100%;
  overflow: hidden;
  margin-top: 1em;

  .slick-slide {
    padding: 0.4em;
    box-sizing: border-box;
  }
`;

export const MediaMainImage = styled.div`
  width: 100%;
`;

export const MediaBottomSlider = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;

  img {
    width: 23%;
    width: calc(25% - 0.4em);
    margin: 0 0.2em;
  }

  ${({ theme }) =>
    customScrollBar({
      background: theme.BuySelector.Colors.background3,
      innerColor: theme.BuySelector.Colors.background5,
      width: 10,
    })}
`;

export const MediaBottomItem = styled.div`
  background: ${({ theme }) => theme.BuySelector.Colors.background4};
  padding: 0.8em 0.4em;
  text-align: center;
  line-height: 0;

  svg {
    width: 16px;
    height: 16px;
  }
`;
