export default {
  translations: {
    selectYour: {
      edition: '에디션을 선택하세요',
      version: '버전을 선택하세요',
      platform: '플랫폼을 선택하세요',
      payment: '결제 설정을 선택하세요',
    },
    selectPlatform: '플랫폼을 선택하세요',
    releaseDate: '출시일:',
    buySelector: { subscription: { subscriptionSelection: '구독 선택:' } },
    subscription: {
      title: '{{gameName}} 그리고 더 많은 유비소프트 <span class="blue">+</span>',
      alreadySubscribed: '이미 Ubisoft+를 구독 중입니다!',
      learnMoreAbout: '<0>Ubisoft+</0> 에 대해서 더 알아보세요',
      cancelNotice: '언제든 취소하고 다시 돌아오세요. 진행 내역은 저장됩니다. <0>View FAQ</0>',
      gamesSelection: {
        classics: {
          title: '유비소프트의 상징적인 게임',
          description: "어쌔신 크리드 발할라', '레인보우 식스 시즈' 등과 같은 상징적인 게임 모음집을 즐겨 보세요",
          link: { notSubscriber: '이용 가능한 게임 보기', subscriber: '게임 찾아보기' },
        },
        premium: {
          title: '100개 이상의 게임',
          description:
            '모든 파 크라이 타이틀 그리고 어쌔신 크리드 발할라, 레인보우 식스 시즈 등  계속해서 추가되는 게임들을 모두 즐기세요.',
          link: { notSubscriber: '포함된 모든 게임 살펴보기', subscriber: '게임 찾아보기' },
        },
      },
      cards: {
        cloudGaming: {
          title: '클라우드 게이밍',
          description:
            '{{platforms}}를 통해 인터넷 연결만 있다면 스마트폰, 태블릿 또는 TV 등 원하는 어디에서든 바로 게임을 플레이하세요.',
          link: '클라우드 게임 살펴보기',
        },
        consoles: {
          title: '콘솔',
          description: '게임 구독 서비스로 엄선된 게임들을 {{platforms}} 콘솔로 플레이하세요.',
          link: '포함된 콘솔 게임 보기',
        },
      },
      partOfUbisoftPlus: {
        released: 'Ubisoft+로 {{game}} {{edition}}을 즐겨보세요.',
        dlc: '{{game}}에 포함된 {{dlc}}을 플레이하세요.',
        preOrder: '{{game}}이 {{releaseDate}}에 출시되자마자 플레이 하세요.',
        includedContent: '포함된 콘텐츠:',
      },
      firstParty: {
        legalLine:
          '표시된 가격은 PC 버전에만 적용됩니다.<br/>콘솔에 적용 가능한 가격을 확인하려면 서드 파티 플랫폼 상점을 확인하세요.',
        pricingInfo: '플랫폼 상점에서 가격을 확인 가능',
      },
      learnMore: '더 알아보기',
      playThisGame: 'Play this game in your <span class="blue">Library+</span>!',
    },
  },
};
