import React from 'react';
import Container from '@mooncake/ui/lib/components/Container';

import Content from '../Content';
import Image from '../Image';
import { ICustomAppProps } from '../../AppProps';

type IAppContent = {
  position: string | null;
} & ICustomAppProps;

const AppContent = (props: IAppContent) => {
  const { alignment, image, imagealttext, imageposition, split, textalign, nominheight } = props;
  const containerAlignment = alignment === 'left' ? 'flex-start' : alignment === 'right' ? 'flex-end' : 'center';

  return (
    <Container
      display="flex"
      justifyContent={containerAlignment}
      minHeight={
        !split && !nominheight
          ? {
              mobile: '640px',
              tablet: '775px',
              desktop: '775px',
            }
          : undefined
      }
      maxHeight={
        !split && !nominheight
          ? {
              default: '776px',
            }
          : undefined
      }
    >
      <Container
        width={'100%'}
        maxWidth={!split ? '90rem' : null}
        margin={'auto'}
        display={'flex'}
        textAlign={textalign}
        padding={
          !split
            ? {
                default: '2rem',
                tablet: '2.5rem',
                desktop: '5rem',
              }
            : null
        }
        flexDirection={{
          default: imageposition === 'right' && split ? 'column-reverse' : 'column',
          tablet: imageposition === 'right' ? 'row-reverse' : imageposition === 'left' ? 'row' : 'column',
        }}
        alignItems={'center'}
        justifyContent={'center'}
      >
        {image && <Image source={image} fullsize={split} alt={imagealttext} />}
        <Content {...props} />
      </Container>
    </Container>
  );
};

export default AppContent;
