import { MicroElement as BaseMicroElement, SfccMixin } from '@mooncake/core';
import kebabCase from 'lodash/kebabCase';
import { projectName } from '../mooncake.json';
import App, { AppProps, AppEventsConfigs } from './App';
import storeConfig from './store';
import { defaultApiEnv } from '../../../mooncake.json';
import Api from '@mooncake/mooncake-gateway-ecom/api';
import { EnvironmentValue } from '@mooncake/mooncake-gateway-core/types';

class MicroElement extends SfccMixin(BaseMicroElement) {
  constructor() {
    super(projectName, App, AppProps, AppEventsConfigs, storeConfig);
    Api.environment = (window.MFE_API_ENV as EnvironmentValue) || defaultApiEnv;
  }
}

const elementName = kebabCase(projectName);
if (!customElements.get(elementName)) {
  customElements.define(elementName, MicroElement);
} else {
  console.error(
    `A custom element with the name "${elementName}" already exists in the page. We can't define it again.`
  );
}
