export default {
  translations: {
    global: {
      subscription: {
        subscribeNow: 'DOŁĄCZ TERAZ',
        reactivate: 'Odnów subskrypcję Ubisoft+',
        resubscribe: 'Dołącz ponownie',
        subscribeUbisoftPlus: 'Dołącz do Ubisoft+',
        playNowUbisoftPlus: 'Zagraj teraz z Ubisoft+',
        goToMyLibrary: 'Przejdź do biblioteki',
        changePlan: 'Zmień plan',
        upgradeNow: 'Upgrade Now',
        includedWithUbisoftPlus: 'Zawarte w Ubisoft+',
        dayOne: 'Od dnia premiery w Ubisoft+',
        service: 'Usługa subskrypcji',
      },
      offerEnds: 'Oferta ważna do {{date}}',
      outOfStock: 'Nakład wyczerpany',
      from: 'od',
      preOrder: 'ZAMÓW TERAZ',
      buyNow: 'KUP TERAZ',
      getTheGame: 'POBIERZ',
      addToCart: 'Dodaj do koszyka',
      playNow: 'Zagraj teraz',
      seeMore: 'Zobacz więcej',
      month: 'miesiąc',
      months: 'miesiące',
      day: 'dzień',
      days: 'dni',
      year: 'rok',
      years: 'lata',
      buyGame: 'Kup grę',
      free: 'za darmo',
      freeTrial: 'Darmowa wersja próbna',
      tryForFree: 'Wypróbuj za darmo',
      then: 'następnie',
      and: 'i',
      durationTerm: 'za',
      containedOffers: 'zawarty w tej edycji',
      gameOwned: 'Posiadasz już tą edycję',
      seeDetails: 'zobacz szczegóły',
      hide: 'ukryj',
      subscribed: 'Abonent',
      buyOn: {
        playstation: 'Kup na konsolę PlayStation',
        microsoft: 'Kup na konsolę Xbox',
        nintendo: 'Kup na konsolę Nintendo',
      },
      ageGateRestrictionMessage:
        'Przykro nam, ale nie możesz uzyskać dostępu do tej zawartości ze względu na ograniczenie wiekowe. Prosimy o zapoznanie się z naszą <0>Polityką prywatności</0>.',
      monthly: 'Miesięcznie',
      yearly: 'Rocznie',
      earlyAccess: 'Wczesny dostęp',
    },
    platforms: {
      PC: 'PC',
      PS4: 'PS4',
      PS5: 'PS5',
      Xbox1: 'Xbox One',
      XboxX: 'Xbox Series X|S',
      Android: 'Android',
      iOS: 'iOS',
      Switch: 'Switch',
      Stadia: 'Stadia',
      Luna: 'Luna',
    },
    versions: { digital: 'Cyfrowa', physical: 'Pudełkowa' },
    subscription: { promoprice: { month: 'potem {{price}}/miesiąc', year: 'potem {{price}}/rok' } },
  },
};
