import React, { CSSProperties } from 'react';
import { TileSkeletonWrapper, TileSkeletonWrapperInner, TileSkeletonWrapperItem } from './style';

interface ITileSkeletonProps extends CSSProperties {
  type: 'horizontal' | 'vertical';
}

export const TileSkeleton = ({ type, height }: ITileSkeletonProps) => {
  if (type === 'vertical') {
    return <VerticalTileSkeleton height={height} />;
  }

  return <HorizontalTileSkeleton height={height} />;
};

export const PackshotSkeleton = () => (
  <TileSkeletonWrapper width={'100%'} height={'100%'}>
    <TileSkeletonWrapperInner width="100%" height="100%">
      <TileSkeletonWrapperItem width="100%" height="100%" borderRadius="5px" />
    </TileSkeletonWrapperInner>
  </TileSkeletonWrapper>
);

export const HorizontalTileSkeleton = ({ height }: CSSProperties) => (
  <TileSkeletonWrapper height={height}>
    <TileSkeletonWrapperInner width="30%" height="100%" padding="0.8em">
      <TileSkeletonWrapperItem width="100%" height="100%" borderRadius="5px" />
    </TileSkeletonWrapperInner>
    <TileSkeletonWrapperInner width="70%" height="100%" padding="1em">
      <TileSkeletonWrapperItem width="50%" height="20px" margin="1em 0" borderRadius="5px" />
      <TileSkeletonWrapperItem width="70%" height="20px" margin="1em 0" borderRadius="5px" />
    </TileSkeletonWrapperInner>
  </TileSkeletonWrapper>
);

export const VerticalTileSkeleton = ({ height }: CSSProperties) => (
  <TileSkeletonWrapper height={height} flexFlow="column">
    <TileSkeletonWrapperInner width="100%" height="50%" padding="0.4em 0.8em">
      <TileSkeletonWrapperItem width="100%" height="60px" borderRadius="5px" />
    </TileSkeletonWrapperInner>
    <TileSkeletonWrapperInner width="100%" height="50%" padding="0.4em">
      <TileSkeletonWrapperItem width="100%" height="20px" margin="0.4em 0" borderRadius="5px" />
    </TileSkeletonWrapperInner>
  </TileSkeletonWrapper>
);

export default TileSkeleton;
