import { customScrollBar } from '../../../utils/style';
import styled, { css } from 'styled-components';
import breakpoints from '../../../themes/utils/breakpoints';
import { TileContent, TileImageWrapper, TileInner, TileWrapper } from '../../UI/Tiles/style';
import { SelectedIconMotionWrapper } from '../../UI/Icons/SelectedIcon/SelectedIconMotion';
import { motion } from 'framer-motion';
import { reset } from "@pdp-rework-hub/ui";

export const SelectorWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: stretch;
  width: 33.33%;
  background: ${(props) => props.theme.BuySelector.Colors.background3};
  color: white;
  padding: 3rem 0 0;
  height: 100%;
  position: relative;

  @media screen and (max-width: ${breakpoints.tablet}) {
    width: 100%;
    padding: 1rem 0 0;
  }
`;

export const ScrollableSection = styled.div`
  overflow-y: scroll;
  scroll-behavior: smooth;
  flex: 1;
  height: 100%;

  ${({ theme }) =>
    customScrollBar({
      background: theme.BuySelector.Colors.background3,
      innerColor: theme.BuySelector.Colors.background5,
      width: 10,
    })}

  @media screen and (min-width: ${breakpoints.tablet}) {
  .sticky-cart-action-section{
    position: sticky;
    width: 100%;
    bottom: 0;
    z-index: 10;
    backdrop-filter: blur(25px);
    }
  }
`;

export const SubscriptionPlatformSelectionHeader = styled.div`
  ${reset};
  text-align: center;
  margin: 2em 0 1em;
  padding: 1em;

  a {
    color: white;
  }
`;

export const PlatformsVersionsWrapper = styled.div`
  padding: 0 2rem 2rem;

  @media screen and (max-width: ${breakpoints.mobile}) {
    padding: 0 1rem 1rem;
  }
  h2 {
    margin: 0 0 0.4em;
    font-size: 1.1em;
  }
`;

interface IPlatformsVersionsWrapperInner {
  isVersion?: boolean;
}

export const PlatformsVersionsWrapperInner = styled.div<IPlatformsVersionsWrapperInner>`
  display: flex;
  flex-wrap: wrap;
  margin: 0 -0.5rem;

  ${TileWrapper} {
    width: 23%;
    width: calc(25% - 1em);
    margin: 0.8em 0.5em;
  }

  ${TileImageWrapper} {
    text-align: center;
    padding: 1em 0.2em 0.2em;
  }

  ${TileContent} {
    margin-top: ${({ isVersion }) => (isVersion ? '0' : 'auto')};
    width: 100%;
    text-align: center;

    h4 {
      font-size: 0.8em;
      font-family: ${({ theme }) => theme.fontFamily};
      font-weight: 400;
      ${({ isVersion }) =>
        isVersion
          ? css`
              margin-top: 0 !important;
            `
          : css``};
    }
  }

  ${SelectedIconMotionWrapper} {
    position: absolute;
    top: 0.4em;
    right: 0.4em;
  }
`;

export const PlatformVersionItemWrapper = styled(motion.div)`
  width: 25%;

  @media screen and (max-width: ${breakpoints.mobile}) {
    width: 33%;
  }
  
  padding: 1rem 0.5rem;
  flex-wrap: wrap;

  ${TileWrapper} {
    width: 100%;
    height: 100%;
    margin: 0;
  }
  ${TileInner} {
    height: 100%;
  }
  svg {
    max-width: 80%;
  }
`;
