export default {
  translations: {
    global: {
      subscription: {
        subscribeNow: '今すぐ加入',
        reactivate: 'Ubisoft+を再開',
        resubscribe: '再登録',
        subscribeUbisoftPlus: 'Ubisoft+に申し込む',
        playNowUbisoftPlus: 'Ubisoft+で今すぐプレイ',
        goToMyLibrary: 'ライブラリに移動',
        changePlan: 'プランを変更',
        upgradeNow: 'Upgrade Now',
        includedWithUbisoftPlus: 'Ubisoft+に含む',
        dayOne: 'Ubisoft+発売日',
        service: 'サブスクリプションサービス',
      },
      offerEnds: 'キャンペーンは{{date}}で終了します',
      outOfStock: '在庫なし',
      from: 'から',
      preOrder: '予約',
      buyNow: '今すぐ購入',
      getTheGame: '購入',
      addToCart: 'カートに追加',
      playNow: '今すぐプレイ',
      seeMore: '詳しく見る',
      month: '月',
      months: '月',
      day: '日',
      days: '日',
      year: '年',
      years: '年',
      buyGame: '購入する',
      free: '無料',
      freeTrial: '無料トライアル',
      tryForFree: '無料で試そう',
      then: 'それでは',
      and: 'と',
      durationTerm: 'にとって',
      containedOffers: '本編に含まれる',
      gameOwned: 'このエディションをお持ちの方',
      seeDetails: '詳細をみる',
      hide: '隠す',
      subscribed: '購読済み',
      buyOn: { playstation: 'PlayStationで購入', microsoft: 'Xboxで購入', nintendo: 'Nintendoで購入' },
      ageGateRestrictionMessage:
        '申し訳ございませんが、年齢制限のためこのコンテンツにアクセスすることはできません。<0>プライバシーポリシー</0>をご確認ください',
      monthly: '月間',
      yearly: '年間',
      earlyAccess: 'アーリーアクセス',
    },
    platforms: {
      PC: 'PC',
      PS4: 'PS4',
      PS5: 'PS5',
      Xbox1: 'Xbox One',
      XboxX: 'Xbox Series X|S',
      Android: 'Android',
      iOS: 'iOS',
      Switch: 'Switch',
      Stadia: 'Stadia',
      Luna: 'Luna',
    },
    versions: { digital: 'デジタル', physical: '物理的な商品' },
    subscription: { promoprice: { month: 'そのあと{{price}}/月', year: 'そのあと{{price}}/年' } },
  },
};
