export default {
  translations: {
    selectYour: {
      edition: 'Wybierz edycję',
      version: 'Wybierz wersję',
      platform: 'Wybierz platformę',
      payment: 'Wybierz opcję płatności',
    },
    selectPlatform: 'Wybierz platformę',
    releaseDate: 'Data premiery:',
    buySelector: { subscription: { subscriptionSelection: 'Wybór planu subskrypcji:' } },
    subscription: {
      title: '{{gameName}} i wiele więcej z Ubisoft<span class="blue">+</span>',
      alreadySubscribed: 'Jesteś już zapisany do Ubisoft+!',
      learnMoreAbout: 'Dowiedz się więcej o <0>Ubisoft+</0>',
      cancelNotice:
        'Możesz zrezygnować i powrócić w dowolnym momencie, twoje postępy zostaną zachowane. <0>View FAQ</0>',
      gamesSelection: {
        classics: {
          title: 'Kultowe gry Ubisoft',
          description:
            "Ciesz się wyborem kultowych tytułów, w tym Assassin's Creed Valhalla, Rainbow Six Siege i nie tylko",
          link: { notSubscriber: 'Zobacz dostępne gry', subscriber: 'Przeglądaj gry' },
        },
        premium: {
          title: 'Ponad 100 gier',
          description:
            "Zagraj w całą serię Far Cry oraz stale powiększany katalog gier, zawierający Assassin's Creed Valhalla, Rainbow Six Siege i wiele więcej.",
          link: { notSubscriber: 'Zabacz wszystkie gry', subscriber: 'Przeglądaj gry' },
        },
      },
      cards: {
        cloudGaming: {
          title: 'Gra w chmurze',
          description:
            'Graj na swoim smartfonie, tablecie lub telewizorze, gdzie tylko chcesz, korzystając z połączenia internetowego za pośrednictwem usług {{platforms}}.',
          link: 'Zobacz gry w chmurze',
        },
        consoles: {
          title: 'Konsole',
          description: 'Graj w gry dostępne na konsole {{platforms}} z naszą usługą abonamentową',
          link: 'Sprawdź gry dostępne na konsole',
        },
      },
      partOfUbisoftPlus: {
        released: 'Zagraj w {{game}} {{edition}} z Ubisoft+.',
        dlc: 'Zagraj w dodatek  {{dlc}}, do {{game}}.',
        preOrder: 'Zagraj w {{game}} od dnia premiery: {{releaseDate}}.',
        includedContent: 'Zawiera:',
      },
      firstParty: {
        legalLine:
          'Widoczne ceny dotyczą wyłącznie wersji na PC.<br/>Sprawdź sklepy platform zewnętrznych, aby poznać ceny na konsolach.',
        pricingInfo: 'Sprawdź sklep platformy, aby poznać ceny',
      },
      learnMore: 'Dowiedz się więcej',
      playThisGame: 'Play this game in your <span class="blue">Library+</span>!',
    },
  },
};
