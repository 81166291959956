export default {
  translations: {
    global: {
      subscription: {
        subscribeNow: '立即加入',
        reactivate: '重新啟用 Ubisoft+',
        resubscribe: '重新訂閱',
        subscribeUbisoftPlus: '訂閱 Ubisoft+',
        playNowUbisoftPlus: '立即訂閱 Ubisoft+ 遊玩',
        goToMyLibrary: '前往遊戲庫',
        changePlan: '更改计划',
        upgradeNow: 'Upgrade Now',
        includedWithUbisoftPlus: '內含於 Ubisoft+',
        dayOne: '訂閱 Ubisoft+ 於首發日遊玩',
        service: '訂閱服務',
      },
      offerEnds: '優惠至 {{date}} 止。',
      outOfStock: '缺貨',
      from: '從',
      preOrder: '預購',
      buyNow: '立即購買',
      getTheGame: '馬上入手',
      addToCart: '放進購物車',
      playNow: '立即遊玩',
      seeMore: '查看更多',
      month: '個月',
      months: '個月',
      day: '天',
      days: '天',
      year: '年',
      years: '年',
      buyGame: '購買遊戲',
      free: '免費',
      freeTrial: '免費試用',
      tryForFree: '試試不用錢',
      then: '之後',
      and: '和',
      durationTerm: '為',
      containedOffers: '包含在此版本中',
      gameOwned: '你擁有此版本',
      seeDetails: '查看詳情',
      hide: '隱藏',
      subscribed: '訂戶',
      buyOn: {
        playstation: '在 PlayStation 平台上購買',
        microsoft: '在 Xbox 平台上購買',
        nintendo: '在 Nintendo 平台上購買',
      },
      ageGateRestrictionMessage: '很抱歉，基於年齡限制你無法取用此內容。請查看我們的<0>隱私權政策</0>',
      monthly: '每月',
      yearly: '每年',
      earlyAccess: '搶先體驗',
    },
    platforms: {
      PC: 'PC',
      PS4: 'PS4',
      PS5: 'PS5',
      Xbox1: 'Xbox One',
      XboxX: 'Xbox Series X|S',
      Android: 'Android',
      iOS: 'iOS',
      Switch: 'Switch',
      Stadia: 'Stadia',
      Luna: 'Luna',
    },
    versions: { digital: '數位', physical: '實體' },
    subscription: { promoprice: { month: '後續費用為 {{price}} / 月', year: '後續費用為 {{price}} / 年' } },
  },
};
