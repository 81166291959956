import Container from '@mooncake/ui/lib/components/Container';
import Text from '@mooncake/ui/lib/components/Text';
import { RenewIcon } from '@pdp-rework-hub/ui';
import { CancelNoticeWrapper } from './style';
import { sendAdobeEvent } from '@pdp-rework-hub/utils';
import React from 'react';
import { Trans } from 'react-i18next';
import { IAdobeEvent } from '@pdp-rework-hub/utils-types';

const CancelNotice = () => {
  const trackingFAQLinkEvent: IAdobeEvent = {
    location: 'buy selector',
    locationDetail: 'subscribe',
    category: 'action',
    locationPosition: 'overlay',
    action: 'view faq',
  };
  return (
    <CancelNoticeWrapper>
      <Container marginRight={'1rem'}>
        <RenewIcon />
      </Container>
      <Container>
        <Text.Span fontSize={'0.875rem'}>
          <Trans i18nKey={'subscription.cancelNotice'}>
            <a
              href={'https://www.ubisoft.com/help?article=000065286'}
              target={'_blank'}
              onClick={() => sendAdobeEvent(trackingFAQLinkEvent)}
            >
              _view-faq_
            </a>
          </Trans>
        </Text.Span>
      </Container>
    </CancelNoticeWrapper>
  );
};

export default CancelNotice;
