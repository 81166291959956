export type LocaleMapItem = Partial<{
  contentfulLocale: string;
  fallbackLocale: string;
}>;

export const localeMap: Record<string, LocaleMapItem | undefined> = {
  default: {
    contentfulLocale: 'en-US',
  },
  zh: {
    contentfulLocale: 'zh-CN',
  },
  zh_CN: {
    contentfulLocale: 'zh-CN',
  },
  zh_HK: {
    contentfulLocale: 'zh-TW',
  },
  zh_TW: {
    contentfulLocale: 'zh-TW',
  },
  nl: {
    contentfulLocale: 'nl-NL',
  },
  nl_NL: {
    contentfulLocale: 'nl-NL',
  },
  en: {
    contentfulLocale: 'en-US',
  },
  en_AU: {
    contentfulLocale: 'en-AU',
  },
  en_CA: {
    contentfulLocale: 'en-CA',
  },
  en_DK: {
    contentfulLocale: 'en-GB',
  },
  en_IN: {
    contentfulLocale: 'en-GB',
  },
  en_ID: {
    contentfulLocale: 'en-GB',
  },
  en_MY: {
    contentfulLocale: 'en-GB',
  },
  en_MX: {
    contentfulLocale: 'en-GB',
  },
  en_BR: {
    contentfulLocale: 'en-GB',
  },
  en_NZ: {
    contentfulLocale: 'en-AU',
  },
  en_NO: {
    contentfulLocale: 'en-GB',
  },
  en_PH: {
    contentfulLocale: 'en-GB',
  },
  en_PL: {
    contentfulLocale: 'en-GB',
  },
  en_SG: {
    contentfulLocale: 'en-GB',
  },
  en_SK: {
    contentfulLocale: 'en-GB',
  },
  en_SE: {
    contentfulLocale: 'en-GB',
  },
  en_TR: {
    contentfulLocale: 'en-GB',
  },
  en_GB: {
    contentfulLocale: 'en-GB',
  },
  en_US: {
    contentfulLocale: 'en-US',
  },
  en_ZW: {
    contentfulLocale: 'en-GB',
  },
  fr: {
    contentfulLocale: 'fr-FR',
  },
  fr_CA: {
    contentfulLocale: 'fr-CA',
  },
  fr_FR: {
    contentfulLocale: 'fr-FR',
  },
  de: {
    contentfulLocale: 'de-DE',
  },
  de_AT: {
    contentfulLocale: 'de-DE',
  },
  de_DE: {
    contentfulLocale: 'de-DE',
  },
  ia: {
    contentfulLocale: 'en-US',
  },
  ia_AG: {
    contentfulLocale: 'en-US',
  },
  ia_AR: {
    contentfulLocale: 'en-US',
  },
  ia_BS: {
    contentfulLocale: 'en-US',
  },
  ia_BB: {
    contentfulLocale: 'en-US',
  },
  ia_BZ: {
    contentfulLocale: 'en-US',
  },
  ia_BM: {
    contentfulLocale: 'en-US',
  },
  ia_BO: {
    contentfulLocale: 'en-US',
  },
  ia_BR: {
    contentfulLocale: 'en-US',
  },
  ia_CL: {
    contentfulLocale: 'en-US',
  },
  ia_CO: {
    contentfulLocale: 'en-US',
  },
  ia_CR: {
    contentfulLocale: 'en-US',
  },
  ia_DM: {
    contentfulLocale: 'en-US',
  },
  ia_DO: {
    contentfulLocale: 'en-US',
  },
  ia_EC: {
    contentfulLocale: 'en-US',
  },
  ia_SV: {
    contentfulLocale: 'en-US',
  },
  ia_GD: {
    contentfulLocale: 'en-US',
  },
  ia_GT: {
    contentfulLocale: 'en-US',
  },
  ia_GY: {
    contentfulLocale: 'en-US',
  },
  ia_HT: {
    contentfulLocale: 'en-US',
  },
  ia_HN: {
    contentfulLocale: 'en-US',
  },
  ia_JM: {
    contentfulLocale: 'en-US',
  },
  ia_MX: {
    contentfulLocale: 'en-US',
  },
  ia_NI: {
    contentfulLocale: 'en-US',
  },
  ia_PA: {
    contentfulLocale: 'en-US',
  },
  ia_PY: {
    contentfulLocale: 'en-US',
  },
  ia_PE: {
    contentfulLocale: 'en-US',
  },
  ia_KN: {
    contentfulLocale: 'en-US',
  },
  ia_LC: {
    contentfulLocale: 'en-US',
  },
  ia_VC: {
    contentfulLocale: 'en-US',
  },
  ia_SR: {
    contentfulLocale: 'en-US',
  },
  ia_TT: {
    contentfulLocale: 'en-US',
  },
  ia_UY: {
    contentfulLocale: 'en-US',
  },
  ia_VE: {
    contentfulLocale: 'en-US',
  },
  it: {
    contentfulLocale: 'it-IT',
  },
  it_IT: {
    contentfulLocale: 'it-IT',
  },
  ja: {
    contentfulLocale: 'ja-JP',
  },
  ja_JP: {
    contentfulLocale: 'ja-JP',
  },
  ko: {
    contentfulLocale: 'ko-KR',
  },
  ko_KR: {
    contentfulLocale: 'ko-KR',
  },
  la: {
    contentfulLocale: 'es-MX',
  },
  la_AG: {
    contentfulLocale: 'es-MX',
  },
  la_AR: {
    contentfulLocale: 'es-MX',
  },
  la_BS: {
    contentfulLocale: 'es-MX',
  },
  la_BB: {
    contentfulLocale: 'es-MX',
  },
  la_BZ: {
    contentfulLocale: 'es-MX',
  },
  la_BM: {
    contentfulLocale: 'es-MX',
  },
  la_BO: {
    contentfulLocale: 'es-MX',
  },
  la_BR: {
    contentfulLocale: 'es-MX',
  },
  la_CL: {
    contentfulLocale: 'es-MX',
  },
  la_CO: {
    contentfulLocale: 'es-MX',
  },
  la_CR: {
    contentfulLocale: 'es-MX',
  },
  la_DM: {
    contentfulLocale: 'es-MX',
  },
  la_DO: {
    contentfulLocale: 'es-MX',
  },
  la_EC: {
    contentfulLocale: 'es-MX',
  },
  la_SV: {
    contentfulLocale: 'es-MX',
  },
  la_GD: {
    contentfulLocale: 'es-MX',
  },
  la_GT: {
    contentfulLocale: 'es-MX',
  },
  la_GY: {
    contentfulLocale: 'es-MX',
  },
  la_HT: {
    contentfulLocale: 'es-MX',
  },
  la_HN: {
    contentfulLocale: 'es-MX',
  },
  la_JM: {
    contentfulLocale: 'es-MX',
  },
  la_MX: {
    contentfulLocale: 'es-MX',
  },
  la_NI: {
    contentfulLocale: 'es-MX',
  },
  la_PA: {
    contentfulLocale: 'es-MX',
  },
  la_PY: {
    contentfulLocale: 'es-MX',
  },
  la_PE: {
    contentfulLocale: 'es-MX',
  },
  la_KN: {
    contentfulLocale: 'es-MX',
  },
  la_LC: {
    contentfulLocale: 'es-MX',
  },
  la_VC: {
    contentfulLocale: 'es-MX',
  },
  la_SR: {
    contentfulLocale: 'es-MX',
  },
  la_TT: {
    contentfulLocale: 'es-MX',
  },
  la_UY: {
    contentfulLocale: 'es-MX',
  },
  la_VE: {
    contentfulLocale: 'es-MX',
  },
  pl_PL: {
    contentfulLocale: 'pl-PL',
  },
  pt: {
    contentfulLocale: 'pt-BR',
  },
  pt_AG: {
    contentfulLocale: 'pt-BR',
  },
  pt_AR: {
    contentfulLocale: 'pt-BR',
  },
  pt_BS: {
    contentfulLocale: 'pt-BR',
  },
  pt_BB: {
    contentfulLocale: 'pt-BR',
  },
  pt_BZ: {
    contentfulLocale: 'pt-BR',
  },
  pt_BM: {
    contentfulLocale: 'pt-BR',
  },
  pt_BO: {
    contentfulLocale: 'pt-BR',
  },
  pt_BR: {
    contentfulLocale: 'pt-BR',
  },
  pt_CL: {
    contentfulLocale: 'pt-BR',
  },
  pt_CO: {
    contentfulLocale: 'pt-BR',
  },
  pt_CR: {
    contentfulLocale: 'pt-BR',
  },
  pt_DM: {
    contentfulLocale: 'pt-BR',
  },
  pt_DO: {
    contentfulLocale: 'pt-BR',
  },
  pt_EC: {
    contentfulLocale: 'pt-BR',
  },
  pt_SV: {
    contentfulLocale: 'pt-BR',
  },
  pt_GD: {
    contentfulLocale: 'pt-BR',
  },
  pt_GT: {
    contentfulLocale: 'pt-BR',
  },
  pt_GY: {
    contentfulLocale: 'pt-BR',
  },
  pt_HT: {
    contentfulLocale: 'pt-BR',
  },
  pt_HN: {
    contentfulLocale: 'pt-BR',
  },
  pt_JM: {
    contentfulLocale: 'pt-BR',
  },
  pt_MX: {
    contentfulLocale: 'pt-BR',
  },
  pt_NI: {
    contentfulLocale: 'pt-BR',
  },
  pt_PA: {
    contentfulLocale: 'pt-BR',
  },
  pt_PY: {
    contentfulLocale: 'pt-BR',
  },
  pt_PE: {
    contentfulLocale: 'pt-BR',
  },
  pt_KN: {
    contentfulLocale: 'pt-BR',
  },
  pt_LC: {
    contentfulLocale: 'pt-BR',
  },
  pt_VC: {
    contentfulLocale: 'pt-BR',
  },
  pt_SR: {
    contentfulLocale: 'pt-BR',
  },
  pt_TT: {
    contentfulLocale: 'pt-BR',
  },
  pt_UY: {
    contentfulLocale: 'pt-BR',
  },
  pt_VE: {
    contentfulLocale: 'pt-BR',
  },
  ru: {
    contentfulLocale: 'ru-RU',
  },
  ru_RU: {
    contentfulLocale: 'ru-RU',
  },
  es: {
    contentfulLocale: 'es-ES',
  },
  es_ES: {
    contentfulLocale: 'es-ES',
  },
  es_US: {
    contentfulLocale: 'es-MX',
  },
  es_MX: {
    contentfulLocale: 'es-MX',
  },
  es_BR: {
    contentfulLocale: 'es-MX',
  },
  th_TH: {
    contentfulLocale: 'th-TH',
  },
  uk_UA: {
    contentfulLocale: 'en-GB',
  },
};