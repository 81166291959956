import breakpoints from '../../../themes/utils/breakpoints';
import React, { useCallback, useRef } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '../../../store';
import CartAction from './CartAction';
import Editions from './Editions';
import Platforms from './Platforms';
import PriceSelector from './PriceSelector';
import { SelectorWrapper, SubscriptionPlatformSelectionHeader, ScrollableSection } from './style';
import { SELECTOR_OPTION_SUBSCRIPTION } from '@pdp-rework-hub/utils-constants';
import { Trans, withTranslation, WithTranslation } from 'react-i18next';
import CancelNotice from './CancelNotice';
import UbisoftPlusIcon from '../../UI/Icons/UbisoftPlus/UbisoftPlusIcon';
import { sendAdobeEvent } from '@pdp-rework-hub/utils';
import { IMaster } from '@pdp-rework-hub/utils-types';
export interface IWithHandleSelectorScroll {
  handleScrollEditionClick: (product: IMaster) => void;
}

const Selector = ({ t }: WithTranslation) => {
  const { selectedOption, availableSubscriptions } = useSelector((state: RootState) => ({
    selectedOption: state.products.selectedOption,
    availableSubscriptions: state.products.availableSortedSubscriptions,
  }));

  const platformSectionRef = useRef<HTMLDivElement>(null);
  const selectorParentRef = useRef<HTMLDivElement>(null);

  const handleScrollEditionClick = (product: IMaster) => {
    const isMobile = window.matchMedia(`(max-width: ${breakpoints.tablet})`);
    const available = product.variations.some((variant) => variant.availability.addToCartPossible);

    if (isMobile.matches || !available) {
      return;
    }

    const { current: platformSectionElement } = platformSectionRef;
    const { current: selectorParentElement } = selectorParentRef;

    if (!platformSectionElement || !selectorParentElement) {
      return;
    }
  };

  const sendUbisoftPlusTracking = useCallback(() => {
    const trackingEvent = {
      location: 'buy selector',
      locationDetail: 'subscribe',
      action: `learn more about ubisoft plus`,
      category: 'action',
      locationPosition: 'overlay',
    };
    sendAdobeEvent(trackingEvent);
  }, []);

  const upcPrefix = window.location.pathname.indexOf('/upc/') > -1 ? '/upc' : '';
  const ubisoftPlusLink = `${upcPrefix}/ubisoftplus`;

  return (
    <SelectorWrapper>
      <ScrollableSection ref={selectorParentRef} tabIndex={-1}>
        {!!availableSubscriptions?.length && <PriceSelector />}
        {selectedOption === SELECTOR_OPTION_SUBSCRIPTION && (
          <SubscriptionPlatformSelectionHeader>
            <UbisoftPlusIcon />
            <p>
              {t('global.subscription.service')}
              &nbsp;|{' '}
              <Trans i18nKey={'subscription.learnMoreAbout'}>
                <a href={ubisoftPlusLink} onClick={() => sendUbisoftPlusTracking()}>
                  Ubisoft+
                </a>
              </Trans>
            </p>
          </SubscriptionPlatformSelectionHeader>
        )}
        <Editions handleScrollEditionClick={handleScrollEditionClick} />
        <div className="selector-platform-section" ref={platformSectionRef}>
          <Platforms />
        </div>
        <div className="sticky-cart-action-section">
          {selectedOption === SELECTOR_OPTION_SUBSCRIPTION && <CancelNotice />}
          <CartAction />
        </div>
      </ScrollableSection>
    </SelectorWrapper>
  );
};

export default withTranslation()(Selector);
