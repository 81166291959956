import axios from 'axios';
import { ICustomerOffer, CustomerOfferMap } from '@pdp-rework-hub/utils-types';
import { localeMap } from './locale-map';

export const fetchContentServicesCustomerOffer = async (MDMCustomerIDArray: string[], sfccLocale: string) => {
  try {
    const getAPIPrefix = () => {
      if (window.MFE_API_ENV === 'prod') {
        return '';
      } else if (window.MFE_API_ENV === 'cert') {
        return 'uat-';
      } else {
        return 'dev-';
      }
    };

    const offerResponse = await axios.get<{ products: ICustomerOffer[] }>(
      `https://${getAPIPrefix()}public-ubiservices.ubi.com/v1/applications/global/itcontent/content/api/products?CustomerOfferIds=${MDMCustomerIDArray.join(
        ','
      )}`,
      {
        headers: {
          'Ubi-AppId': process.env.NX_ECOM_APP_ID,
          'Ubi-LocaleCode': localeMap[sfccLocale]?.contentfulLocale,
        },
      }
    );

    const mappedObject = offerResponse.data.products.reduce<CustomerOfferMap>(
      (productMap: Record<string, ICustomerOffer>, product: ICustomerOffer) => {
        if (product.customerOfferMdmId) {
          return {
            ...productMap,
            [product.customerOfferMdmId]: product,
          };
        }

        return productMap;
      },
      {}
    );

    return mappedObject;
  } catch (error) {
    console.error('OFFER ERROR: ', error);
    throw new TypeError(`OFFER ERROR: ${error}`);
  }
};
