import styled from 'styled-components';
import { LoaderContainer } from '../../AppLoader/styles';

export const ButtonLoadingContent = styled.span`
  display: flex;
  justify-content: center;

  ${LoaderContainer} {
    min-height: 1rem;
  }
`;
