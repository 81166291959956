import React from 'react';
import TileSkeleton from '../../UI/Tiles/TileSkeleton';
import styled from 'styled-components';
import { TileSkeletonWrapper } from '../../UI/Tiles/style';

export const PlatformSkeletonWrapper = styled.div`
  ${TileSkeletonWrapper} {
    border-radius: 5px;
    width: 23%;
    height: calc(25% - 1em);
    margin: 0 0.5em;
  }
`;

const PlatformVersionSkeleton = () => {
  return (
    <PlatformSkeletonWrapper>
      <TileSkeleton type="vertical" />
    </PlatformSkeletonWrapper>
  );
};

export default PlatformVersionSkeleton;
