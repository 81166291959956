import React from 'react';

interface IReplayIcon {
  width: string;
  height: string;
  color?: string;
}

const ReplayIcon = ({ width, height, color = 'white' }: IReplayIcon) => {
  return (
    <svg width={width} height={height} viewBox="0 0 38 39" fill={color} xmlns="http://www.w3.org/2000/svg">
      <path d="M28.8023 14.8349L15.5214 7.05793L15.5241 11.2423L28.8064 19.0193L28.8023 14.8349Z" fill={color}/>
      <path d="M28.8064 14.625L15.3226 7.31251V11.7068L28.8064 19.0193L28.8064 14.625Z" fill={color}/>
      <path d="M28.8064 23.1563L15.3226 30.4688V26.0745L28.8064 18.762V23.1563Z" fill={color}/>
      <path d="M28.8023 23.0466L15.5214 30.8236L15.5241 26.6392L28.8064 18.8622L28.8023 23.0466Z" fill={color}/>
      <path d="M31.258 28.0313V35.3438L27.5806 31.6875V25.5938L31.258 28.0313Z" fill={color}/>
      <path d="M34.9355 31.6875L27.5806 31.6875L31.258 35.3438H37.3871L34.9355 31.6875Z" fill={color}/>
      <path d="M19 35.3437C10.5378 35.3437 3.67742 28.5228 3.67742 20.1094C3.67742 16.0523 5.27232 12.3662 7.87376 9.63623L7.86149 9.59425L5.20423 7.12154C1.979 10.507 0 15.0786 0 20.1094C0 30.5419 8.50709 39 19 39V35.3437Z" fill={color}/>
      <path d="M18.3871 0C12.7675 0 7.69946 2.35083 4.1242 6.11677L6.8087 8.61521C9.71386 5.56157 13.8258 3.65625 18.3871 3.65625C27.1884 3.65625 34.3226 10.7494 34.3226 19.8169C34.3226 24.6269 32.2278 28.86 28.9263 31.6875L29.6046 32.9862C29.6046 32.9862 30.8917 34.8197 30.9639 34.7615C35.2569 31.2664 38 25.9079 38 19.8169C38 8.73032 29.2191 0 18.3871 0Z" fill={color}/>
      <path d="M11.6452 24.0798L15.3226 26.0745V11.7068L11.6452 13.7015V24.0798Z" fill={color}/>
    </svg>

  );
};

export default ReplayIcon;
