import styled, { css } from 'styled-components';

interface IPillWrapperProps {
  background: string;
  xPosition?: 'left' | 'right';
}

export const PillWrapper = styled.div<IPillWrapperProps>`
  position: absolute;
  background: ${({ background }) => background};
  padding: 0.2em 0.6em;
  font-family: ${({ theme }) => theme.mainFontTweaks.bold};
  color: white;
  top: -0.6em;
  border-radius: 20px;
  z-index: 9;
  ${({ xPosition }) => css`
    ${xPosition === 'left' ? 'left' : 'right'}: 1em;
  `}
`;

export const PillInner = styled.div`
  position: relative;
  font-size: 12px;
`;
