import React from 'react';

interface IExternalLinkIconProps {
    width: number;
    height: number;
}

const ExternalLinkIcon = (props: IExternalLinkIconProps) => (
    <svg width={props.width} height={props.height} viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M9.56249 1.43262C9.04473 1.43262 8.62499 1.85235 8.62499 2.37012C8.62499 2.88788 9.04473 3.30762 9.56249 3.30762H11.3618L6.71209 7.95719C6.34597 8.3233 6.34596 8.91689 6.71207 9.28302C7.07818 9.64914 7.67177 9.64915 8.03789 9.28304L12.6877 4.63338V6.43261C12.6877 6.95037 13.1075 7.37011 13.6252 7.37011C14.143 7.37011 14.5627 6.95037 14.5627 6.43261V2.37012C14.5627 1.85235 14.143 1.43262 13.6252 1.43262H9.56249Z" fill="#3DA2FF"/>
        <path d="M1.4375 4.87012C1.4375 2.97165 2.97652 1.43262 4.875 1.43262H6.125V3.30762H4.875C4.01206 3.30762 3.3125 4.00718 3.3125 4.87012V11.1201C3.3125 11.9831 4.01205 12.6826 4.875 12.6826H11.125C11.9879 12.6826 12.6875 11.9831 12.6875 11.1201V9.87012H14.5625V11.1201C14.5625 13.0186 13.0235 14.5576 11.125 14.5576H4.875C2.97652 14.5576 1.4375 13.0186 1.4375 11.1201V4.87012Z" fill="#3DA2FF"/>
    </svg>
);

export default ExternalLinkIcon;
