export default {
  translations: {
    title: 'Сравнить издания {{gameName}}',
    subscription: {
      title: 'Входит в Ubisoft<span class="blue">+</span>',
      classics: { title: 'Коллекция Classics', subtitle: 'Погрузитесь в миры легендарных игр Ubisoft' },
      premium: { subtitle: 'Включает новинки' },
    },
    subscriptionModal: {
      classics: {
        title: 'Ubisoft<span class="blue">+</span> Classics',
        items: [
          {
            description: 'Играйте в знаменитые игры из постоянно пополняемой коллекции в Ubisoft Connect',
            icon: 'check',
          },
          { description: 'Доступ к стандартным изданиям', icon: 'check' },
          { description: 'Скидка 20% на игры и DLC', icon: 'check' },
        ],
      },
      premium: { items: [{ icon: 'games' }, { icon: 'diamond' }, { icon: 'reward' }, { icon: 'replay' }] },
    },
  },
};
