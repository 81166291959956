import React from "react";

interface IAppStoreIconProps {
  width?: number;
  height?: number;
}

const AppStoreIcon = ({width = 51, height = 49}: IAppStoreIconProps ) => (
  <svg width={width} height={height} viewBox="0 0 51 49" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clip-path="url(#clip0_29302_8521)">
      <path d="M38.9316 26.2256C38.9316 20.0545 43.9976 17.0753 44.4382 17.0753C41.5747 12.8194 36.9492 12.3938 35.1871 12.3938C31.4426 11.9682 27.6981 14.5218 25.7157 14.5218C23.7333 14.5218 20.6496 12.3938 17.3457 12.3938C13.1607 12.6066 9.19589 14.9474 7.21351 18.5649C2.80823 26.0128 6.11219 36.8655 10.2972 42.8239C12.4999 45.803 14.9228 48.995 18.2267 48.995C21.3104 48.7822 22.632 47.0798 26.3765 47.0798C30.121 47.0798 31.2223 48.995 34.5263 48.995C37.8303 48.995 40.0329 46.0158 42.2355 43.0367C44.6584 39.6319 45.7598 36.4399 45.7598 36.2271C45.5395 36.0143 38.9316 33.6736 38.9316 26.2256Z" fill="white"/>
      <path d="M32.5438 8.35066C34.306 6.22268 35.4073 3.45631 35.187 0.689941C32.7641 0.689941 29.6804 2.39232 27.9183 4.3075C26.3764 6.22268 24.8346 8.98905 25.2751 11.7554C27.9183 11.9682 30.7817 10.4786 32.5438 8.35066Z" fill="white"/>
    </g>
    <defs>
      <clipPath id="clip0_29302_8521">
        <rect width="50" height="48.3051" fill="white" transform="translate(0.5 0.689941)"/>
      </clipPath>
    </defs>
  </svg>
);

export default AppStoreIcon;
