const colors = {
  background1: '#FFFFFF',
  background2: '#E5E8F1',
  background3: '#0D0D0D',
  background4: '#1B2025',
  background5: '#2C3237',
  background6: '#3A3A3A',
  primary: '#2D8656',
  secondary: '#CC2828',
  connect: '#006EF5',
  buy: '#00C248',
  owned: '#3bb071',
  subscription: '#428EE0',
  preorder: '#735BF8',
  promo: '#FF8300',
  textGrey: '#676767',
  dodgerblue: '#3da2ff',
};

export default colors;
