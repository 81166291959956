import React from 'react';

import { ImageContainer, ImageElement } from './styles';
import { BreakpointsKeys, IBreakpoints, ISubscription } from '@pdp-rework-hub/utils-types';

import { filterImagesList } from '../utils';
import { SUBSCRIPTION_IDS, WEBDAV_URL } from '@pdp-rework-hub/utils-constants';

export const generateImagesList = (images?: IBreakpoints, fullsize?: boolean, alt = '') => {
  if (!images) {
    return null;
  }
  const breakpointsKeys = Object.keys(images) as BreakpointsKeys[];
  return breakpointsKeys.map((breakpointKey, index) => {
    const imageSource = images[breakpointKey];
    if (!imageSource) {
      return null;
    }
    return (
      <ImageContainer key={`breakpoint-image${index}`} display={images} current={breakpointKey}>
        <ImageElement src={imageSource} alt={alt} fullsize={fullsize} loading={'lazy'} />
      </ImageContainer>
    );
  });
};

interface IBreakpointsImagesProps {
  source?: IBreakpoints;
  fullsize?: boolean;
}

export const BreakpointsImages = ({ source, fullsize = false }: IBreakpointsImagesProps) => {
  const imagesList = filterImagesList(source);
  return imagesList.length ? <>{generateImagesList(source, fullsize)}</> : null;
};

export const getSubscriptionPackshot = (currentSubscription: ISubscription | undefined) => {
  const imagePath = `${WEBDAV_URL}/Library-Sites-shared-library-web/default/pdp-rework-hub/assets/${
    currentSubscription?.id === SUBSCRIPTION_IDS.BASIC ? 'classicsCollection' : 'gamelist'
  }.png`;
  return <>{generateImagesList({ default: imagePath, tablet: '', mobile: '' })}</>;
};

export default BreakpointsImages;
