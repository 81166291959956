import { SubscriptionPrice } from '@pdp-rework-hub/ui';
import { IPrice, isPriceRange, ISubscription, MasterPrice } from '@pdp-rework-hub/utils-types';
import React from 'react';
import { NewPrice, PreviousPrice, SubscriptionPriceContainer } from '../style';
import Container from '@mooncake/ui/lib/components/Container';
import Text from '@mooncake/ui/lib/components/Text';
import { WithTranslation, withTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../store';
import { SUBSCRIPTION_IDS } from '@pdp-rework-hub/utils-constants';

interface IEditionPriceProps extends WithTranslation {
  isSubscription: boolean;
  isOnSale: boolean | null;
  price: MasterPrice;
  minPrice: IPrice;
  currentSubscription?: ISubscription | null;
}

const EditionPrice = ({ currentSubscription, isSubscription, isOnSale, price, minPrice, t }: IEditionPriceProps) => {
  const { activeSubscription, availableSortedSubscriptions } = useSelector((state: RootState) => ({
    activeSubscription: state.user.activeSubscription,
    availableSortedSubscriptions: state.products.availableSortedSubscriptions,
  }));

  const isGamePartOfUserSubscription =
    activeSubscription?.id === currentSubscription?.id && activeSubscription?.id === SUBSCRIPTION_IDS.BASIC;

  if (isSubscription && currentSubscription && availableSortedSubscriptions) {
    return (
      <SubscriptionPriceContainer>
        {isGamePartOfUserSubscription ? (
          t('global.subscription.playNowUbisoftPlus')
        ) : (
          <SubscriptionPrice subscriptions={availableSortedSubscriptions} activeSubscription={currentSubscription} />
        )}
      </SubscriptionPriceContainer>
    );
  } else if (isOnSale) {
    return (
      <Container>
        <PreviousPrice>
          {isPriceRange(price) && <Text.Span>{t('global.from')} </Text.Span>}
          {minPrice.list?.formatted}
        </PreviousPrice>
        <NewPrice>
          {isPriceRange(price) && <Text.Span fontSize={'0.875rem'}>{t('global.from')} </Text.Span>}
          {minPrice.sales?.formatted}
        </NewPrice>
      </Container>
    );
  } else {
    return (
      <>
        {isPriceRange(price) && <Text.Span fontSize={'0.875rem'}>{t('global.from')} </Text.Span>}
        <Text.Span fontSize={'1.5rem'}>{minPrice.sales?.formatted}</Text.Span>
      </>
    );
  }
};

export default withTranslation()(EditionPrice);
