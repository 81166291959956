import React from 'react';

const PS5Icon = () => {
  return (
    <svg width="79" height="17" viewBox="0 0 79 17" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M8.27344 0V15.86L11.8881 17V3.7C11.8881 3.08 12.1692 2.66 12.611 2.8C13.1934 2.96 13.3139 3.54 13.3139 4.16V9.48C15.563 10.56 17.3301 9.48 17.3301 6.62C17.3301 3.7 16.2859 2.4 13.2335 1.36C12.0287 0.96 9.79962 0.28 8.27344 0Z"
        fill="white"
      />
      <path
        d="M12.5713 14.6798L18.3748 12.6198C19.0375 12.3798 19.1379 12.0398 18.5957 11.8798C18.0535 11.6998 17.0896 11.7598 16.4269 11.9998L12.5713 13.3598V11.1998L12.7922 11.1198C12.7922 11.1198 13.9167 10.7198 15.4831 10.5598C17.0494 10.3798 18.9773 10.5798 20.4834 11.1398C22.1903 11.6798 22.371 12.4598 21.9493 13.0198C21.5075 13.5598 20.4633 13.9598 20.4633 13.9598L12.5713 16.7598V14.6798Z"
        fill="white"
      />
      <path
        d="M1.66691 14.4601C-0.080168 13.9801 -0.361307 12.9601 0.421865 12.3601C1.16488 11.8201 2.40992 11.4201 2.40992 11.4201L7.57082 9.6001V11.6801L3.85578 13.0001C3.19309 13.2401 3.09268 13.5601 3.63488 13.7401C4.17708 13.9201 5.14098 13.8801 5.78358 13.6201L7.57082 12.9801V14.8401C7.45034 14.8601 7.32985 14.8801 7.20936 14.9001C5.42212 15.2001 3.53447 15.0801 1.66691 14.4601Z"
        fill="white"
      />
      <path
        d="M20.7043 15.2998C21.2063 15.2998 21.5477 15.6798 21.5477 16.1598C21.5477 16.6398 21.2063 17.0198 20.7043 17.0198C20.2023 17.0198 19.8408 16.6398 19.8408 16.1598C19.8609 15.6798 20.2023 15.2998 20.7043 15.2998ZM20.7043 16.8798C21.126 16.8798 21.4272 16.5598 21.4272 16.1598C21.4272 15.7598 21.1461 15.4398 20.7043 15.4398C20.2625 15.4398 19.9814 15.7598 19.9814 16.1598C19.9814 16.5598 20.2625 16.8798 20.7043 16.8798ZM20.3629 15.6598H20.7445C20.9252 15.6598 21.0658 15.7198 21.0658 15.9198C21.0658 16.0398 21.0055 16.1198 20.885 16.1598C20.9453 16.1798 20.9855 16.2198 21.0256 16.3398C21.0658 16.4398 21.0859 16.5598 21.126 16.6398H20.9453C20.9252 16.5598 20.885 16.4398 20.865 16.3798C20.8449 16.2598 20.8047 16.2398 20.6642 16.2398H20.5437V16.6598H20.3629V15.6598ZM20.5437 16.0998H20.7043C20.8248 16.0998 20.9051 16.0598 20.9051 15.9398C20.9051 15.7998 20.8047 15.7798 20.7244 15.7798H20.5637V16.0998H20.5437Z"
        fill="white"
      />
      <path d="M77.2535 12.58H76.9121V12.46H77.7355V12.58H77.3941V13.6H77.2535V12.58Z" fill="white" />
      <path
        d="M78.4383 13.48L78.7596 12.48H79.0005V13.6H78.86V12.58L78.5186 13.6H78.3379L78.0165 12.58V13.6H77.876V12.46H78.117L78.4383 13.48Z"
        fill="white"
      />
      <path
        d="M48.617 13.5999C50.1432 13.5999 51.3682 12.3799 51.3682 10.8599V6.13988C51.3682 5.21988 52.1112 4.47988 53.0349 4.47988H58.3765C58.4167 4.47988 58.4569 4.43988 58.4569 4.39988V3.45988C58.4569 3.41988 58.4167 3.37988 58.3765 3.37988H51.9304C50.4042 3.37988 49.1793 4.59988 49.1793 6.11988V10.8399C49.1793 11.7599 48.4363 12.4999 47.5125 12.4999H42.2512C42.2111 12.4999 42.1709 12.5399 42.1709 12.5799V13.5199C42.1709 13.5599 42.2111 13.5999 42.2512 13.5999H48.617Z"
        fill="white"
      />
      <path
        d="M60.666 7.2399V3.4799C60.666 3.4399 60.7062 3.3999 60.7463 3.3999H75.3053C75.3454 3.3999 75.3856 3.4399 75.3856 3.4799V4.4199C75.3856 4.4599 75.3454 4.4999 75.3053 4.4999H62.9352C62.895 4.4999 62.8549 4.5399 62.8549 4.5799V7.0399C62.8549 7.4799 63.2163 7.8199 63.638 7.8199H72.8353C74.4418 7.8199 75.7471 9.1199 75.7471 10.7199C75.7471 12.3199 74.4619 13.5999 72.8554 13.5999H60.7463C60.7062 13.5999 60.666 13.5599 60.666 13.5199V12.5799C60.666 12.5399 60.7062 12.4999 60.7463 12.4999H71.7509C72.7549 12.4999 73.5783 11.6999 73.5783 10.6999C73.5783 9.6999 72.7549 8.8799 71.7509 8.8799H62.3328C61.409 8.8799 60.666 8.1399 60.666 7.2399Z"
        fill="white"
      />
      <path
        d="M39.0586 7.79988C39.9823 7.79988 40.7254 7.05988 40.7254 6.13988C40.7254 5.21988 39.9823 4.47988 39.0586 4.47988H27.8934C27.8532 4.47988 27.8131 4.43988 27.8131 4.39988V3.45988C27.8131 3.41988 27.8532 3.37988 27.8934 3.37988H40.143C41.6692 3.37988 42.8941 4.59988 42.8941 6.11988C42.8941 7.63988 41.6692 8.85988 40.143 8.85988H31.6687C30.7449 8.85988 30.0019 9.59988 30.0019 10.5199V13.5199C30.0019 13.5599 29.9618 13.5999 29.9216 13.5999H27.8733C27.8331 13.5999 27.793 13.5599 27.793 13.5199C27.793 12.6999 27.793 10.5199 27.793 10.5199C27.793 8.99988 29.0179 7.77988 30.5441 7.77988H39.0586V7.79988Z"
        fill="white"
      />
    </svg>
  );
};

export default PS5Icon;
