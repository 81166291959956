import styled, { css } from 'styled-components';
import { m } from 'framer-motion';

interface ICustomButton {
  textColor: string;
}

export const OfferDetails = css`
  margin: 0;
  padding: 0.5rem 0;
  display: flex;
  align-items: center;
  width: 100%;
  text-align: left;
  min-height: 3rem;
`;

export const OfferButton = styled.button<ICustomButton>`
  ${OfferDetails};
  appearance: none;
  border: 0;
  background: none;
  cursor: pointer;
  color: ${({ textColor }) => textColor};
`;

export const OfferSpan = styled.span<ICustomButton>`
  ${OfferDetails};
  color: ${({ textColor }) => textColor};
`;

export const OfferItemContainer = styled(m.li)`
  padding: 0;
`;

export const OfferThumbnail = styled.div`
  display: flex;
  align-items: center;
  max-width: 2.5rem;
  height: 2.5rem;
  margin-right: 1rem;
`;

export const OfferThumbnailImage = styled.img`
  width: 100%;
`;

export const OfferTitleContainer = styled.div`
  flex: 1;
`;

export const OfferTitle = styled.div``;

export const OfferSubtitle = styled.div`
  opacity: 0.6;
`;

export const ChevronContainer = styled.div`
  flex: 0 0;
  margin-left: 1rem;
`;
