import { createGlobalStyle } from 'styled-components';

const GlobalStyle = createGlobalStyle`
  .blue,
  .blue-connect {
    color: ${({ theme }) => theme.colors.UbisoftPlus};
  }

  .blue-variant {
    color: ${({ theme }) => theme.colors.DodgerBlue};
  }

  .font-regular {
    font-family: "Ubisoft Sans Regular";
  }

  .font-bold {
    font-family: "Ubisoft Sans Bold";
  }

  .text-underline {
    text-decoration: underline;
  }

  :focus-visible {
    background-color: ${({ theme }) => theme.colors.SchoolBusYellow} !important;
    outline: 2px solid ${({ theme }) => theme.colors.Black} !important;
    color: ${({ theme }) => theme.colors.Black} !important;

    input[type="checkbox"]:checked + label::before {
      background-color: ${({ theme }) => theme.colors.Black} !important;;
    }
  }

  h1, h2, h3, h4, h5, h6 {
    font-weight: normal;
  }
`;

export default GlobalStyle;
