// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import colors from '@mooncake/ui/lib/themes/store/colors';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import uplayColors from '@mooncake/ui/lib/themes/uplay/colors';

const globalColors = {
  BlueRibbon: uplayColors.BlueRibbon,
  CornflowerBlue: '#7469ff',
  DoveGray: '#676767',
  Jade: colors.Jade,
  MineShaft: '#333333',
  SantasGray: '#989CA9',
  Woodsmoke: '#121419',
  TuftsBlue: '#428EE0',
  Gray: '#8E8E8E',
  Nobel: '#B5B5B5',
  SchoolBusYellow: '#FFDD00',
  BlackPearl: '#1b2025',
  DodgerBlue: '#3DA2FF',
  Concrete: '#F2F2F2',
  MineShaftDark: '#262626',
  MineShaftLight: '#343434',
  Mercury: '#E5E5E5',
  RedViolet: '#D317AA',
};

export default globalColors;
