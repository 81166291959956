import { MotionProps } from 'framer-motion';

export const getAmimatedProperties: (index: number) => MotionProps = (index) => {
  return {
    initial: {
      opacity: 0.2,
      x: 5,
    },
    animate: {
      opacity: 1,
      x: 0,
    },
    exit: {
      x: -5,
      opacity: 0.2,
    },
    transition: {
      type: 'spring',
      delay: 0.1 + index * 0.1,
    },
  };
};
