export default {
  translations: {
    global: {
      subscription: {
        subscribeNow: 'JOIN NU',
        reactivate: 'VERNIEUW ABONNEMENT',
        resubscribe: 'Herabonneer',
        subscribeUbisoftPlus: 'Abonneer op Ubisoft+',
        playNowUbisoftPlus: 'Speel nu met Ubisoft+',
        goToMyLibrary: 'Ga naar bibliotheek',
        changePlan: 'Ander abonnement',
        upgradeNow: 'Upgrade Now',
        includedWithUbisoftPlus: 'Inbegrepen met Ubisoft+',
        dayOne: 'Dag 1 met Ubisoft+',
        service: 'Abonnementsdienst',
      },
      offerEnds: 'Aanbieding loopt tot {{date}}',
      outOfStock: 'Niet op voorraad',
      from: 'vanaf',
      preOrder: 'PRE-ORDER',
      buyNow: 'KOOP NU',
      getTheGame: 'HAAL HET',
      addToCart: 'Toevoegen aan winkelwagen',
      playNow: 'Speel nu',
      seeMore: 'Lees meer',
      month: 'maand',
      months: 'maanden',
      day: 'dag',
      days: 'dagen',
      year: 'jaar',
      years: 'jaar',
      buyGame: 'Koop de game',
      free: 'gratis',
      freeTrial: 'Gratis proefperiode',
      tryForFree: 'Probeer gratis',
      then: 'dan',
      and: 'en',
      durationTerm: 'voor',
      containedOffers: 'producten inbegrepen in deze editie',
      gameOwned: 'Je bezig deze editie',
      seeDetails: 'Bekijk details',
      hide: 'Verberg',
      subscribed: 'Abonnee',
      buyOn: { playstation: 'Kopen op PlayStation', microsoft: 'Kopen op Xbox', nintendo: 'Kopen op Nintendo' },
      ageGateRestrictionMessage:
        'Je hebt mogelijk geen toegang tot deze inhoud vanwege leeftijdsbeperkingen. Lees ons <0>Privacybeleid</0>',
      monthly: 'Maandelijks',
      yearly: 'Jaarlijks',
      earlyAccess: 'Vroege toegang',
    },
    platforms: {
      PC: 'PC',
      PS4: 'PS4',
      PS5: 'PS5',
      Xbox1: 'Xbox One',
      XboxX: 'Xbox Series X|S',
      Android: 'Android',
      iOS: 'iOS',
      Switch: 'Switch',
      Stadia: 'Stadia',
      Luna: 'Luna',
    },
    versions: { digital: 'Digitaal', physical: 'Fysiek' },
    subscription: { promoprice: { month: 'daarna {{price}}/maand', year: 'daarna {{price}}/jaar' } },
  },
};
