export default {
  translations: {
    title: 'Comparez les éditions de {{gameName}}',
    subscription: {
      title: 'Inclus dans Ubisoft<span class="blue">+</span>',
      classics: { title: 'Collection Classics', subtitle: 'Plongez au cœur de titres Ubisoft emblématiques' },
      premium: { title: 'Plus de 100 jeux PC', subtitle: 'Inclus les nouveaux jeux' },
    },
    subscriptionModal: {
      classics: {
        title: 'Ubisoft<span class="blue">+</span> Classics',
        items: [
          {
            description: 'Jouez à une sélection en constante évolution de jeux emblématiques avec Ubisoft Connect',
            icon: 'check',
          },
          { description: 'Accès aux éditions standard', icon: 'check' },
          { description: '20 % de remise sur les extensions et les jeux', icon: 'check' },
        ],
      },
      premium: {
        title: 'Découvrez Ubisoft<span class="blue">+</span>',
        items: [
          {
            title: 'Plus de 100 jeux PC',
            description:
              'Jouez aux nouveaux jeux le jour de leur sortie et accédez à notre catalogue toujours croissant',
            icon: 'games',
          },
          {
            title: 'Éditions Premium',
            description: 'Accédez à des extensions, des season pass et à plus de contenu',
            icon: 'diamond',
          },
          {
            title: 'Récompenses mensuelles',
            description:
              'Chaque mois, obtenez de nouveaux éléments de personnalisation, des boosters et des récompenses en jeu',
            icon: 'reward',
          },
          {
            title: 'Annulez et revenez à tout moment',
            description: 'Revenez facilement à votre abonnement quand vous le souhaitez',
            icon: 'replay',
          },
        ],
      },
      learnMoreAboutUbisoftPlus: 'En savoir plus sur Ubisoft+',
    },
  },
};
