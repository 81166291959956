import React from 'react';
import TileSkeleton from '../../../UI/Tiles/TileSkeleton';

const EditionLoader = ({ length }: { length: number }) => {
  return (
    <>
      {[...Array(length)].map((_, index) => (
        <TileSkeleton type="horizontal" height="120px" key={`productEditions_${index}`} />
      ))}
    </>
  );
};
export default EditionLoader;
