import React, { ComponentType } from 'react';
import { AnimatePresence } from 'framer-motion';

interface IModalWrapperProps {
  condition: boolean;
}

const ModalWrapper: ComponentType<IModalWrapperProps> = ({ condition, children }) =>
  condition ? <AnimatePresence>{children}</AnimatePresence> : <>{children}</>;

export default ModalWrapper;
