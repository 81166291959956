import React from 'react';

const UbisoftIcon = () => {
  return (
    <svg x="0px" y="0px" viewBox="0 0 284.86 296.71" enableBackground="new 0 0 284.86 296.71">
      <g>
        <path
          d="M276.31,147.72C273.38,5.33,83.96-47.74,11.03,85.7c3.22,2.33,7.68,5.48,10.9,7.81
		c-5.59,11.72-9.31,23.65-11.35,35.54c-1.42,7.8-2.16,15.83-2.16,24.04c0,73.89,59.99,133.79,134,133.79s134-59.9,134-133.79
		C276.43,151.29,276.38,149.5,276.31,147.72z M41.51,171.62c-1.76,14.49-0.66,19.02-0.67,20.74l-3.22,1.17
		c-1.22-2.38-4.32-10.78-5.68-22.07c-3.49-43.09,25.91-81.91,70.68-89.14c41.05-6.02,80.3,19.43,89.5,55.06l-3.23,1.17
		c-0.96-1.06-2.68-3.94-8.88-10.22C130.49,78.68,52.68,101.45,41.51,171.62z M169.01,195.72c-6.6,9.49-17.59,15.7-30.03,15.7
		c-20.18,0-36.55-16.34-36.55-36.49c0-19.26,14.95-35.04,33.9-36.39l0,0c11.8-0.47,22.79,5.89,28.03,16.22
		c5.64,11.13,3.58,24.86-5.21,34.11C162.43,191.15,165.72,193.43,169.01,195.72z M245.22,197.13
		c-19.33,43.82-59.12,66.75-101.61,66.22c-81.5-4.04-105.21-98.02-50.74-131.95l2.4,2.39c-0.93,1.22-4.09,3.78-9.18,13.73
		c-5.93,12.09-7.74,24.14-7.04,31.68c4.24,65.7,96.29,79.06,128,14.11c40.36-89.08-66.62-179.55-161.6-110.41l-2.19-2.19
		c24.98-39.14,73.8-56.66,120.42-44.95C234.96,53.56,273.24,127.81,245.22,197.13z"
        />
      </g>
    </svg>
  );
};

export default UbisoftIcon;
