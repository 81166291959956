import React from 'react';
import { setSelectedOffer } from '@pdp-rework-hub/slices';
import { useAppDispatch } from 'apps/pdp-rework-hub-comparison-table/src/store';
import { sendAdobeEvent } from '@pdp-rework-hub/utils';
import { IAdobeProductClickEvent, IContain } from '@pdp-rework-hub/utils-types';
import { WEBDAV_URL } from '@pdp-rework-hub/utils-constants';

import {
  OfferItemContainer,
  OfferThumbnail,
  OfferThumbnailImage,
  ChevronContainer,
  OfferButton,
  OfferSpan,
  OfferTitleContainer,
} from './style';

import { ChevronRightIcon } from '@pdp-rework-hub/ui';

interface IOfferItem {
  textColor: string;
  offer: IContain;
  index: number;
  slotNumber: number;
  productInfo: {
    productId: string;
    productName: string;
    productEdition: string;
  };
  locationPosition: string | null;
}

const OfferItem = ({ offer, slotNumber, index, textColor, productInfo, locationPosition }: IOfferItem) => {
  const dispatch = useAppDispatch();
  const selectDetailsOffer = () => {
    dispatch(setSelectedOffer(offer));
    const trackingEvent: IAdobeProductClickEvent = {
      location: 'comparison table',
      locationDetail: 'edition detail',
      action: 'product : display edition details',
      category: 'interaction',
      ...productInfo,
      slotNumber: slotNumber + 1,
    };
    if (locationPosition) {
      trackingEvent.locationPosition = locationPosition;
    }
    sendAdobeEvent(trackingEvent);
  };

  const offerContent = (
    <>
      <OfferThumbnail>
        <OfferThumbnailImage
          src={
            offer.icon?.url
              ? offer.icon.url
              : `${WEBDAV_URL}/Library-Sites-shared-library-web/default/pdp-rework-hub/assets/boxshot_fallback.png`
          }
          alt={''}
          loading={'lazy'}
        />
      </OfferThumbnail>

      <OfferTitleContainer>{offer.shortTitle}</OfferTitleContainer>
      {offer.shortDescription ? (
        <ChevronContainer>
          <ChevronRightIcon color={textColor} />
        </ChevronContainer>
      ) : null}
    </>
  );

  return (
    <OfferItemContainer
      initial={{
        opacity: 0,
        y: 3,
      }}
      whileInView={{
        opacity: 1,
        y: 0,
      }}
      transition={{
        type: 'spring',
        delay: 0.2 + index * 0.1,
      }}
      viewport={{
        once: true,
      }}
    >
      {offer.shortDescription ? (
        <OfferButton textColor={textColor} onClick={selectDetailsOffer}>
          {offerContent}
        </OfferButton>
      ) : (
        <OfferSpan textColor={textColor}>{offerContent}</OfferSpan>
      )}
    </OfferItemContainer>
  );
};

export default OfferItem;
