import { customScrollBar } from '../../../utils/style';
import styled from 'styled-components';
import breakpoints from '../../../themes/utils/breakpoints';
import { ICustomAppProps } from '../../../AppProps';

export const ShowcaseWrapper = styled.div<ICustomAppProps>`
  width: 66.66%;
  background: ${({ theme }) => theme.BuySelector.Colors.background4};
  color: white;
  padding: 2em 5rem;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  max-height: 100%;
  overflow-y: scroll;
  ${({ theme }) =>
    customScrollBar({
      background: theme.BuySelector.Colors.background3,
      innerColor: theme.BuySelector.Colors.background5,
      width: 10,
    })};

  @media screen and (max-width: ${breakpoints.desktop}) {
    padding: 1em;
  }

  @media screen and (max-width: ${breakpoints.tablet}) {
    display: none;
  }
`;

export const UbisoftPlusSubscriberWrapper = styled.div`
  background: radial-gradient(#00479e, #0d0d0d);
  width: 66.66%;
  max-height: 100%;
  overflow-y: scroll;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-content: center;
  ${({ theme }) =>
    customScrollBar({
      background: theme.BuySelector.Colors.background3,
      innerColor: theme.BuySelector.Colors.background5,
      width: 10,
    })};

  @media screen and (max-width: ${breakpoints.desktop}) {
    padding: 1rem;
  }

  @media screen and (max-width: ${breakpoints.tablet}) {
    display: none;
  }

  p {
    width: 33%;
    font-size: 2rem;
    text-align: center;
    font-family: ${({ theme }) => theme.fonts.bold};
    span {
      color: ${({ theme }) => theme.colors.dodgerblue};
    }
  }
`;

export const ShowcaseInner = styled.div`
  min-height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;
