import styled from 'styled-components';
import { TileSkeletonWrapperItem } from '../UI/Tiles/style';

export const BuySelectorWrapper = styled.div`
  position: relative;
  background: ${({ theme }) => theme.BuySelector.Colors.background3};
  overflow: hidden;
  height: 100%;
  width: 100%;
`;

export const BuySelectorHeading = styled.div`
  overflow: hidden;
  display: flex;

  h2 {
    color: white;
    width: 100%;
    text-align: center;
    padding: 0.75rem 3.25rem;
    font-size: 1.125rem;
    margin: 0 auto;
  }

  ${TileSkeletonWrapperItem} {
    margin: auto;
    border-radius: 20px;
  }
`;

export const BuySelectorInner = styled.div`
  overflow: hidden;
  display: flex;
  flex-wrap: wrap;
  background: ${(props) => props.theme.BuySelector.Colors.background3};
  height: calc(100% - 40px);
`;
