import { workspaceTheme } from '@pdp-rework-hub/ui';
import getAppTheme, { OverrideTheme } from './themes';
import { Themes } from '@pdp-rework-hub/utils-types';

export type GloabalAppTheme = {
  [key: string]: unknown;
} & OverrideTheme;

const computedTheme = (themeName: Themes): GloabalAppTheme => {
  const theme = workspaceTheme(themeName);
  const overrideTheme = getAppTheme(themeName);

  return { ...theme, ...overrideTheme };
};

export default computedTheme;
