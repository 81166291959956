import React from 'react';

interface IPlusUbisoftPlus {
  color?: string;
}

const PlusUbisoftPlus = ({ color = 'white' }: IPlusUbisoftPlus) => (
  <svg width="41" height="39" viewBox="0 0 41 39" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M24.6044 14.5861V0H14.7001V14.5861H10.9361H0V24.4184H8.16353H10.9361H14.6953H14.7001V39H24.6044V24.4184H36.8362L41 14.5861H24.6044Z"
      fill={color}
    />
  </svg>
);

export default PlusUbisoftPlus;
