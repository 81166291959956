import React from 'react';
import { IMaster, isPriceRange, ISubscription } from '@pdp-rework-hub/utils-types';
import { EditionDetailsContainer } from './style';
import Container from '@mooncake/ui/lib/components/Container';
import Text from '@mooncake/ui/lib/components/Text';
import OffersList from '../../Offers/OffersList';
import { useSelector } from 'react-redux';
import { RootState } from '../../../store';
import SubscriptionFeatures from '../../SubscriptionFeatures';
import CardButton from '../CardButton';
import CardHeader from '../CardHeader';
import EditionPrice from './EditionPrice';
import { appearingAnimationParams } from '@pdp-rework-hub/ui';
import { SUBSCRIPTION_PLAN_RECURRENCE } from '@pdp-rework-hub/utils-constants';

interface IEditionDetails {
  product: IMaster;
  index: number;
  position: string | null;
  textColor: string;
  backgroundColor: string;
  isSubscription: boolean;
}

const EditionDetails = ({ product, position, index, backgroundColor, textColor, isSubscription }: IEditionDetails) => {
  const { productData, availableSortedSubscriptions } = useSelector((state: RootState) => ({
    productData: state.products.data.product,
    availableSortedSubscriptions: state.products.availableSortedSubscriptions,
  }));

  const availableSortedMonthlySubscriptions =
    availableSortedSubscriptions?.filter(
      (subscription) => subscription.productSubscriptionType === SUBSCRIPTION_PLAN_RECURRENCE.MONTHLY
    ) || null;

  if (!productData) {
    return null;
  }

  const { edition, price } = product;
  const minPrice = isPriceRange(price) ? price.min : price;
  const isOnSale = minPrice.list && minPrice.sales && minPrice.sales.value !== minPrice.list.value;
  const displayedSubscription: ISubscription | null =
    availableSortedMonthlySubscriptions &&
    availableSortedMonthlySubscriptions[availableSortedMonthlySubscriptions.length - 1];

  return (
    <EditionDetailsContainer
      {...appearingAnimationParams(index * 0.1)}
      backgroundColor={backgroundColor}
      isSubscription={isSubscription}
    >
      <Container display={'flex'} flexDirection={'column'} width={'100%'} height={'100%'}>
        <CardHeader
          product={product}
          isPreOrder={productData?.availability?.isPreOrder}
          isSubscription={isSubscription}
        />
        <Container
          display={'flex'}
          flexDirection={'column'}
          alignItems={'center'}
          justifyContent={'space-between'}
          padding={'1.5rem 1rem'}
          flex={'1'}
        >
          <Text.Heading textAlign={'center'} fontSize={'1.25rem'} bold>
            {edition}
          </Text.Heading>
          <OffersList product={product} textColor={textColor} slotNumber={index} locationPosition={position} />
          {isSubscription && displayedSubscription ? (
            <SubscriptionFeatures
              product={product}
              backgroundColor={backgroundColor}
              textColor={textColor}
              slotNumber={index + 1}
              locationPosition={position}
              currentSubscription={displayedSubscription}
            />
          ) : null}
          <Container textAlign={'center'} width={'100%'} padding={'0 1rem'}>
            <Container marginBottom={'1.25rem'}>
              <EditionPrice
                isSubscription={isSubscription}
                isOnSale={isOnSale}
                price={price}
                minPrice={minPrice}
                currentSubscription={displayedSubscription}
              />
            </Container>
            <CardButton
              product={product}
              index={index}
              position={position}
              isSubscription={isSubscription}
              currentSubscription={displayedSubscription}
            />
          </Container>
        </Container>
      </Container>
    </EditionDetailsContainer>
  );
};

export default EditionDetails;
