import styled, { css } from 'styled-components';

interface ITooltipContainerProps {
  alignHorizontal: 'left' | 'center' | 'right';
  alignVertical: 'top' | 'middle' | 'bottom';
  isVisible: boolean;
}

export const TooltipContainer = styled.span<ITooltipContainerProps>`
  width: 17.5rem;
  position: absolute;

  ${({ alignVertical }) => {
    switch (alignVertical) {
      case 'top':
        return css`
          bottom: 100%;
        `;
      case 'middle':
        return css`
          top: 50%;
        `;
      default:
        return css`
          top: 100%;
        `;
    }
  }}

  ${({ alignHorizontal }) => {
    switch (alignHorizontal) {
      case 'left':
        return css`
          left: 0;
        `;
      case 'center':
        return css`
          left: 50%;
        `;
      default:
        return css`
          right: 0;
        `;
    }
  }}

  ${({ alignHorizontal, alignVertical }) => {
    if (alignHorizontal === 'center' && alignVertical === 'middle') {
      return css`
        transform: translate(-50%, -50%);
      `;
    } else if (alignHorizontal === 'center') {
      return css`
        transform: translate(-50%, 0);
      `;
    }
    return;
  }}

  background-color: ${({ theme }) => theme.colors.MineShaft};
  color: ${({ theme }) => theme.colors.White};
  padding: 0.5rem 1rem;
  font-size: 0.875rem;
  border-radius: 4px;
  text-align: left;
  margin: 1rem 0;
  transition: 0.3s all ease-in-out;
  opacity: ${({ isVisible }) => (isVisible ? '1' : '0')};
  visibility: ${({ isVisible }) => (isVisible ? 'visible' : 'hidden')};

  &::before {
    content: '';
    position: absolute;
    width: 0;
    height: 0;
    border-width: 0 0.5rem 0.5rem 0.5rem;
    border-color: transparent transparent ${({ theme }) => theme.colors.MineShaft} transparent;
    border-style: solid;
    left: 50%;
    bottom: 100%;
    transform: translateX(-50%);
  }
  a {
    color: ${({ theme }) => theme.colors.White};
  }
`;
